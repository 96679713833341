import React, { useState } from 'react';
import { Collapse } from "react-bootstrap";
import { FaAngleDown, FaAngleUp, FaEdit, FaFacebookF, FaGooglePlusG, FaInstagram, FaLinkedin, FaMinus, FaPlus, FaStar, FaTwitter } from "react-icons/fa";
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { config } from '../../../Environments';
import { GetAxios, UserRole } from '../../../Shared';
import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { setShowModel } from "../../../Store";
import { useDispatch, useSelector } from "react-redux";
import { VscChromeClose } from "react-icons/vsc";
import { HiUpload } from "react-icons/hi";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export const JobItem = (props: { job: any, index: number, userId: any }) => {

    const x = props.job;
    const i = props.index;
    const [applied, setApplied] = useState(false);
    const [applying, setApplying] = useState(false);
    const [params] = useSearchParams();
    const currentuserId = props.userId;
    const dispatch = useDispatch();
    const [gender, setGender] = useState('');
    const navigate = useNavigate();
    React.useEffect(() => {  
     GetAxios().get(config.apiBase + '/api/General/GetUserSex')
     .then(res => {
     setGender(res.data.data || '');
    })
   }, [gender]);
    const { t: ct } = useTranslation('common');
    const { t: ts } = useTranslation('alert');
    const { t: jt } = useTranslation('job');
    const convertToPlain = (html: string) => {
        var tempDiv = document.createElement("p");
        tempDiv.innerHTML = html;
        var result = (tempDiv.innerText.length >= 160) ? tempDiv.innerText.substring(0, 160) + "..." : tempDiv.innerText;
        return result;

    }
    const handleShare = (type: string) => {

        const url = `${config.apiBase}/api/Jobs/ShareJob?id=${x.id}`;
        if (type === "FaceBook") {

            window.open(
                `https://www.facebook.com/sharer/sharer.php?href=${encodeURIComponent(url)}&u=${encodeURIComponent(url)}`, '_blank'
            );

        }
        else if (type === "LinkedIn") {

            window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank'

            );


        }
        else if (type === "Twitter") {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(x.title)}`, '_blank');

        }


    }
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = React.useState(false);
    const [expanding, setExpending] = React.useState(false);
    const [jobDetail, setDetail] = React.useState<any>();
    React.useEffect(() => {
        if (expanding)
            GetAxios().get(config.apiBase + '/api/Jobs/PublicView?id=' + x.id).then(res => {
                setApplied(res.data?.data?.isAlreadyApplied || false);
                setDetail(res.data.data);
                setExpending(false);
                setShow(true);
            })
    }, [expanding]);

    const auth = useSelector((state: AppStore) => state.auth);
    const userRole = UserRole(auth?.raw?.access_token);
    return (
        <div className="card mb-4 mt-3 border-0 rounded-0 p-0">
            <div className="card-body p-3">

                <div className="d-flex justify-content-between align-items-center mb-2">

                    <Link to={"/job/jobpreview?id=" + x.id} title={ct('clickJob').toString()} state={{ from: "searchCompany" }} className="preview-link card-title h4 mb-0 text-decoration-none text-dark">{x.title}</Link>
                    <div>
                        <h6 className="fw-600 mb-0 text-job-id">
                            {ct('jobId')}: {x.jobNo}
                        </h6>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center align-self-baseline">
                    <Link to={"/job/jobpreview?id=" + x.id} title={ct('clickJob').toString()} state={{ from: "searchCompany" }} className="card-subtitle text-decoration-none pointer-event" dangerouslySetInnerHTML={{ __html: x.shortDescription }}>
                        {/* {convertToPlain(x.description)} */}
                    </Link>

                    {

                        //!props.isPublic &&  
                        //userRole == 'Employer' &&
                        currentuserId == x.companyUserId &&
                        <div>
                            <Link to={'/job/jobregistration?id=' + x.id} className="text-decoration-none language-icon text-white"><FaEdit className="mb-1" size={20} /></Link>
                        </div>
                    }
                </div>
                <div className="candidate-details">
                    <p className="text-start client-sidebar-area card-para card-para-mobile mb-0 mt-3">
                        {x?.department?.name || ''}
                    </p>
                </div>
                {/* <Collapse in={show}>
                    <div
                        id={"job-description" + x.id}>
                        <div className="pt-4 pb-0">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <div className="card border-0 rounded-0">
                                        <img
                                            src={config.apiBase + x.logo}
                                            className="card-img-top rounded-0"
                                            alt="..."
                                            onError={(ev: any) => {
                                                ev.target.src = '/images/ImgPlaceholder.png';
                                            }}
                                        />
                                        <div className="card-body pb-5">
                                            <div className="">
                                                <div>
                                                    <h6 className="text-center mt-3 fw-600">
                                                        <a
                                                            className="text-decoration-none text-color-33 text-uppercase pointer-event">
                                                            {jobDetail?.companyName}
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div className="mt-4 border-bottom border-secondary">
                                                    <h5 className="text-color-33 fw-600 text-uppercase mb-2">
                                                        {jt('job.jobDetails')}
                                                    </h5>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.jobTitle')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.employerName')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.companyName}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.contactPerson')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.contactPerson}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.sectorOfActivity')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.sector}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.classification')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12" style={{marginLeft:"12px"}}>{jobDetail?.classification}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.ageRange')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.ageFrom} - {jobDetail?.ageTo}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.gender')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.gender}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.yearsOfExp')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.experienceFrom} - {jobDetail?.experienceTo} {jt('job.years')} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.minDegree')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">{jobDetail?.minDegree}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.workingHours')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">
                                                                {jobDetail?.workShift}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.salaryRange')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">
                                                                {jobDetail?.salaryFrom}, {jt('job.000')} <strong> {jt('job.to')}</strong> {jobDetail?.salaryTo}, {jt('job.000')} /  {jt('job.yr')} {jobDetail?.currency}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.employerBenefits')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">
                                                                {jobDetail?.benifits}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.departments')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12" style={{marginLeft:"12px"}}>
                                                                {jobDetail?.department}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.contractType')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">
                                                    
                                                                {jobDetail?.contractType}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.workExperience')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">
                                                                {jobDetail?.workExperience}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6">
                                                        <div>
                                                            <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                {jt('job.expiresOn')}:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 px-0">
                                                        <div>
                                                            <span className="fw-12">
                                                                {jobDetail?.expiryDate}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-8 col-sm-12">
                                    <div className="client-description">
                                        <div className="card mb-4 border-0 rounded-0 p-0">
                                            <div className="p-2">
                                                <div className="card-body">
                                                    <div className="candidate-sec">
                                                        <div className="container p-1">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-12 col-12 mt-1 margin-language">
                                                                    <div className="">

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-12 col-12">

                                                                    <div className="language-print text-end">
                                                                        <ul className="list-inline mb-2">

                                                                            <li className={`list-inline-item text-uppercase ${jobDetail?.language === 'fr' ? "active" : ""}`}>
                                                                                <a className="pointer-event">Fr</a>
                                                                            </li>
                                                                            <li className={`list-inline-item text-uppercase ${jobDetail?.language === 'en' ? "active" : ""}`}>
                                                                                <a className="pointer-event">En</a>
                                                                            </li>

                                                                        </ul>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="candidate-title mb-3">
                                                            <p className="h3 mb-3"> {ct('jobDescription')} </p>
                                                        </div>
                                                        <div className="detail-content-preview">
                                                            <p className="fs-14 lh-lg" dangerouslySetInnerHTML={{ __html: jobDetail?.shortDescription }}>

                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="candidate-sec">
                                                        <div className="candidate-title mb-3">
                                                            <p className="h3 mb-3"> {ct('aboutTheJob')} </p>
                                                        </div>
                                                        <div className="detail-content-preview">
                                                            <p className="fs-14 lh-lg" dangerouslySetInnerHTML={{ __html: jobDetail?.description }}>

                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="candidate-sec">
                                                        <h5 className="text-color-33 fw-600 py-3 text-uppercase">
                                                            {ct('requiredSkills')}
                                                        </h5>

                                                        {(jobDetail?.jobPostSkills || []).map((item: any, index: number) =>
                                                            <Skill item={item} key={'skill_item__' + index * 4} />
                                                        )}
                                                      
                                                    </div>
                                                    <div className="mt-5">
                                                        <h5 className="text-color-33 fw-600 text-uppercase">
                                                            {ct('additionalSkill')}
                                                        </h5>
                                                        <div className="d-flex pt-3 additional-requirement-btn">
                                                            <ul className="list-unstyled list-inline-requirement">
                                                                {
                                                                    !!jobDetail?.additionalSkills ? ((
                                                                        jobDetail?.additionalSkills || '').split(',') || []).map((x: any, i: any) => (

                                                                            <li key={'adition__sskl' + i}>
                                                                                <a
                                                                                    className="text-decoration-none me-1 rounded-0 pointer-event">
                                                                                    {x}
                                                                                </a>
                                                                            </li>
                                                                        )) : ""

                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="candidate-sec">
                                            <div className="candidate-title mb-0 mt-5">
                                                <ul className="list-inline d-flex p-0 mb-0">
                                                    <li>
                                                        <p className="h5 fw-bold me-2">{ct('share')}:</p>
                                                    </li>
                                                    <li> <a onClick={() => { handleShare("FaceBook") }} className="tags text-decoration-none mb-0 me-2 facebook-color pointer-event"> <FaFacebookF size={18} /></a></li>
                                                    <li>  <a onClick={() => { handleShare("LinkedIn") }} className="tags text-decoration-none mb-0 me-2 linkedIn-color pointer-event"><FaLinkedin size={18} /></a></li>
                                                    <li>  <a onClick={() => { handleShare("Twitter") }} className="tags text-decoration-none mb-0 me-2 twitter-color pointer-event"><FaTwitter size={18} /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row p-3 pb-0">
                        <div className="candidate-sec p-0">
                            <p className="card-para" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                        </div>
                        <div className="candidate-sec p-0">
                            <h5 className="text-color-33 fw-600 py-3 mb-3 text-uppercase"> {ct('requiredSkills')} </h5>
                            {(detail?.jobPostSkills || []).map((item: any, index: number) =>
                                <Skill item={item} key={'skill_item__' + index * 4} />
                            )}
                            <div className="mt-5">
                                <h5 className="text-color-33 fw-600 text-uppercase mb-3"> {ct('additionalRequirements')} </h5>
                                <div className="d-flex pt-3 additional-requirement-btn">
                                    <ul className="list-unstyled list-inline-requirement">
                                        {
                                            !!detail?.additionalSkills ? ((
                                                detail?.additionalSkills || '').split(',') || []).map((item: any, index: number) =>
                                                    <li key={'addition_sskjk+' + index}><a className="text-decoration-none me-1 rounded-0 pointer-event">{item}</a></li>
                                                ) : ""

                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="candidate-sec p-0">
                            <div className="candidate-title mt-3">
                                <ul className="list-inline d-flex p-0">
                                    <li><p className="h5 fw-bold me-2 "> {ct('share')}:</p></li>
                                    <li> <a onClick={() => { handleShare("FaceBook") }} className="tags text-decoration-none mb-0 me-2 facebook-color pointer-event"> <FaFacebookF size={18} /></a></li>
                                    <li>  <a onClick={() => { handleShare("LinkedIn") }} className="tags text-decoration-none mb-0 me-2 linkedin-color pointer-event"><FaLinkedin size={18} /></a></li>
                                    <li>  <a onClick={() => { handleShare("Twitter") }} className="tags text-decoration-none mb-0 me-2 twitter-color pointer-event"><FaTwitter size={18} /></a></li>

                                </ul>
                            </div>
                        </div>
                    </div> 
                </Collapse> */}
                <div className="d-flex justify-content-end align-items-center mb-2 mt-lg-1 mt-sm-2 m-top-mob">
                    <div className="accordion-item">
                        {
                            // (userRole == 'Employee' || userRole == 'Employer') &&
                            <Button className="border-0 btn-interest fw-600 rounded-0 me-4" onClick={() => {
                                if (auth.isAuthenticated) {

                                    setModalShow(true)
                                } else {
                                    localStorage.setItem("isPreviousPath", window.location.pathname);
                                    toast(ts('employee.applyAlert'), { type: 'error' });
                                    dispatch(setShowModel(true));
                                }
                            }}>
                                 {gender=='Female'?  ct('femaleImInterested'):  ct('maleImInterested')}
                            </Button>
                        }
                        <ApplyPopupModal jobPostId={x.id} handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} />

                    </div>
                    <div className="accordion-item mb-2 mt-2">
                    <button type="submit" id="headingTwo" form="form1" className="border-0 button-default accordion-header SeeMore1 fw-600 rounded-0" onClick={() => {
                                navigate("/job/jobpreview?id=" + x.id , { state: { from: "searchCompany"  } });
                                
                        }}>
                           {ct('moreInfo')} 
                        </button>
                        {/* <button type="submit" id="headingTwo" form="form1" className="border-0 button-default accordion-header SeeMore1 fw-600 rounded-0" onClick={() => {
                            if (show)
                                setShow(false);
                            else
                                setExpending(true);
                        }}>
                            {expanding ? <RotatingLines width="20" /> : show ? (
                                <><FaAngleUp className="me-1" size={25} /> {ct('lessInfo')}</>
                            ) : (
                                <><FaAngleDown className="me-1" size={25} /> {ct('moreInfo')} </>
                            )}
                        </button> */}
                    </div>
                </div>

            </div>
        </div >
    );
}
const Skill = (props: { item: any }) => {
    const [show, setShow] = React.useState(false);
    return (


        <>
            <div className="row mb-3">
                <div className="col-md-3">
                    <div>
                        <div className="d-flex">
                            <div className="col-2">
                                <a
                                    className="minus rounded-0 d-block text-dark border icon-collaps1 pointer-event"
                                    aria-controls={"logodesign"}
                                    aria-expanded={show}
                                    onClick={() => setShow(!show)}
                                >
                                    {show ? <FaMinus size={12} /> : <FaPlus size={12} />}
                                </a>
                            </div>
                            <div className="col-10">
                                <p className="mb-0 ms-3 fsize-13 fw-normal mt-1">
                                    {props.item.skill}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 p-right-mobile">
                    <div>
                        <div className='d-flex'>
                            <div className='col-md-11 col-12'>
                                <div className="progress mb-2 rounded-0 mt-2">
                                    <div
                                        className="progress-bar"
                                        style={{ width: `${props.item.percentage}%` }}></div>
                                </div>
                                <Collapse in={show}>
                                    <div>
                                        <p className="fs-14 fw-normal mb-1 lh-lg">
                                            {props.item.description}
                                        </p>
                                    </div>
                                </Collapse>
                            </div>

                            <div className='col-md-1'>
                                <span className="text-nowrap ms-2">{props.item.percentage}%</span>
                            </div>

                        </div>
                    </div>

                </div>

            </div>




        </>
    );
}


export const ApplyPopupModal = (props: any) => {

    const fileTypes = [
        "application/pdf",
        ".doc",
        ".docx",
        "application/msword",
        ".pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
        "text/html",
    ];

    const { t: ct } = useTranslation('common');


    const [applied, setApplied] = useState(false);
    const [applying, setApplying] = useState(false);
    const [fileName, setFileName] = useState("");


    const [resume, setSelectedFile] = useState("");
    function validFileType(file: any) {
        return fileTypes.includes(file.type);
    }
    function checkFileSize(number: any) {
        if (number < 1024) {
            return true;
        } else if (number >= 1024 && number < 1048576) {
            if (Number((number / 1024).toFixed(1)) > 500) {

                return false;
            }
            else {
                return true;
            }
        } else if (number >= 1048576) {
            return false;
        }
    }

    const onFileSelected = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            if (validFileType(e.target.files[0])) {
                if (checkFileSize(e.target.files[0].size)) {
                    setSelectedFile(e.target.files[0])
                    setFileName(e.target.files[0].name);
                }
                else {
                    toast('File size must be up to 500KB', { type: 'error' });
                }
            }
            else {
                toast('Not a valid file type.', { type: 'error' });
            }
        }

    }

    return (
        <>
            <Modal show={props.handleModalShow} onHide={props.handleModalClose} className="border-blue-resume">
                <Modal.Header style={{ borderBottom: '0px', display: 'block' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Modal.Title className="fw-700"> {ct('selectResume')}

                            </Modal.Title>
                        </div>
                        <VscChromeClose onClick={props.handleModalClose} size={28} className="close-padd cursor-pointer" />

                    </div>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <div className="text-center">
                        <div className="border-bottom border-dark border-1">
                        </div>
                        <div className="my-4">
                            <p className="text-start fw-500"> {ct('uploadPdfText')} </p>
                            <div className="custom-file-button d-flex justify-content-center">
                                <label className="custom-label-files" htmlFor="raised-button-file">
                                    <span className="custom-file-button">
                                        <HiUpload size={26} /> {ct('uploadResume')}
                                    </span>
                                </label>

                                <input type="file" accept=".doc,.docx,application/msword,.pdf,application/pdf,text/plain,text/html" style={{ display: 'none' }}
                                    onChange={onFileSelected} id="raised-button-file" />
                                <input type="text" className="w-50 form-control" value={fileName} />

                            </div>
                        </div>
                        <div className="border-bottom border-dark border-1 mb-3">
                        </div>
                        <p className="float-start mb-0 fw-700 fs-18"> {ct('dontResumeOne')} <Link to="/resumes" className="text-decoration-none"> {ct('here')} </Link> <span className="text-uppercase"> {ct('free')}</span></p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: '0px' }}>
                    <Button className="fs-20 rounded ptb-15 text-white bg-dark-blue border-0 fw-600"
                        disabled={applying || applied}
                        onClick={e => {
                            e.preventDefault();
                            const formData = new FormData();
                            formData.append('Id', props.jobPostId);
                            formData.append('Resume', resume);
                            if (fileName === "") {
                                toast('Please select resume.', { type: 'error' });
                                return;
                            }
                            setApplying(true);
                            GetAxios().post(config.apiBase + '/api/JobApply/ApplyJob', formData
                                , { headers: { "Content-Type": "multipart/form-data" } },
                            ).then(res => {
                                if (res.data.success) {
                                    toast(res.data.message, { type: 'success' });
                                    setApplying(false);
                                    setApplied(true);
                                } else {
                                    toast(res.data.message, { type: 'warning' })
                                    setApplying(false);
                                    setApplied(true);
                                }
                            }).catch(err => {
                                if (err.status == 401 || err.status == 403) {
                                    toast('Only active job seekers can apply.', { type: 'error' });
                                }
                                setApplying(false);
                            });
                        }}>{applying ? <RotatingLines width="20" /> : applied ? <div>{ct('applied')}</div> : <div>{ct('apply')}</div>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}



