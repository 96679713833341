import React, { useCallback, useMemo, useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { FaCcMastercard, FaEllipsisV, FaPaypal, FaPencilAlt, FaRegTrashAlt, FaCreditCard, FaPlayCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetAxios, UserRole } from '../../Shared';
import { setGlobalLoading } from '../../Store';
import { config } from '../../Environments';
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { VscChromeClose } from "react-icons/vsc";
import { AiOutlineClose } from "react-icons/ai";
import { RotatingLines } from 'react-loader-spinner';

export const UpdatePaymentMethodModel = (props) => {
    const { t: ts } = useTranslation('alert');
    const { t } = useTranslation('plan');
    const { t: ct } = useTranslation('common');
    const { t: c } = useTranslation('company');

    const schema = Yup.object().shape({
        cardNumber: Yup.string().required(ts('account.required') || 'Required'),
        expMonth: Yup.string().required(ts('account.required') || 'Required'),
        expYear: Yup.string().required(ts('account.required') || 'Required'),
        securityCode: Yup.string().required(ts('account.required') || 'Required'),
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            id: 0,
            cardType: "Card",
            cardName: "",
            cardNumber: "",
            expMonth: "",
            expYear: "",
            securityCode: "",
            isBackupPayment: false,
        },
        validationSchema: schema,
        onSubmit: (values) => {
            if (loading) {
                toast(ts('account.pleaseWait'), { type: 'info' });
            }
            setLoading(true);
            const formData = new FormData();
            Object.keys(values).map(s => {
                formData.append(s, (values)[s]);
            });
            GetAxios().post(config.apiBase + '/api/Payment/SavePaymentMethod', formData).then(response => {
                if (response.data.success) {
                    toast(ts('plan.paymentMethodCreate'), { type: 'success' });
                    props.loadData();
                } else {
                    if (response.data.errors) {
                        alert(response.data.errors.map((v) => v.description));
                    } else {
                        toast(ts('error.requestSubmit'), { type: 'info' });
                    }
                }
                setLoading(false);
            }).catch(err => {
                console.error(err);
                if (err.status >= 500) {
                    toast(ts('error.internalServer'), { type: 'error' });
                }
            });
        },
    });
    const [interest, setInterest] = useState(false);
    const handleClose = () => setInterest(false);
    const handleShow = () => setInterest(true);
    useEffect(() => {
        GetAxios(navigate).get(config.apiBase + '/api/Payment/GetPaymentMethod?id=' + props.id).then(res => {
            formik.setValues(res.data.data);
        });
        formik.setFieldValue("id", props.id);
    }, [props.id]);
    return (
        <>
            {
                <span
                    onClick={handleShow}>
                    <FaPencilAlt className="me-2 icon-gray" size={14} />{ct('edit')}
                </span>

            }
            <Modal show={interest} onHide={handleClose} dialogClassName="modal-6inch-wprint">
                <Modal.Body className="p-0">
                    <div className='container mt-2'>
                        <div className="d-flex align-items-center justify-content-between px-1 py-1 mt-2">
                            <h4 className='fw-700 '> {t('common.editPaymentMethod')} </h4>
                            <VscChromeClose onClick={handleClose} size={28} className="close-padd cursor-pointer" />
                        </div>
                        <div className='profile-card my-2'>
                            <div className='row gx-3 pb-4'>
                                <div className='col-md-12 mb-3'>
                                    <div className='card p-4'>
                                        <div className='fs-14 text-start position-relative'>
                                            <div className='detail'>
                                                <div className='top-bottom'>
                                                    <div className='row'>

                                                        <div className='col-md-12 mb-2'>
                                                            <div className="form-check form-check-inline">
                                                                <div className='d-flex align-items-center justify-content-start'>
                                                                    <input className="form-check-input" type="radio" name="card" id="inlineCheckbox2" value="card" checked />
                                                                    <label className="form-check-label key-weight mx-1 fs-18 fw-600" htmlFor="inlineCheckbox2"> {t('common.card')}  </label>
                                                                    <img src="/images/payment-logos.png" className='w-100 h-20' alt="Mayment logo" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="nameCard"> {t('common.nameonCC')} </label>
                                                            </div>
                                                            <input type="text" name="cardName" onChange={formik.handleChange} value={formik.values.cardName} className="form-control key-weight" />
                                                            {formik.errors.cardName && formik.touched.cardName ? <small className='text-danger'>{formik.errors.cardName}</small> : ""}
                                                        </div>
                                                        <div className='col-md-12 col-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="cardNumber"> {t('common.cardNumber')} </label>
                                                            </div>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control key-weight" name='cardNumber' onChange={(e) => {
                                                                    const reg = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || reg.test(e.target.value)) {
                                                                        formik.setFieldValue("cardNumber", e.target.value);
                                                                    }
                                                                }} maxLength={16} value={formik.values.cardNumber} placeholder="**** **** **** ****" aria-describedby="cardNumber" />
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                            </div>
                                                            {formik.errors.cardNumber && formik.touched.cardNumber ? <small className='text-danger'>{formik.errors.cardNumber}</small> : ""}

                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="expiryMonth" > {t('common.expirationDate')} </label>
                                                            </div>
                                                            <select name="expMonth" className=' form-select' onChange={formik.handleChange} value={formik.values.expMonth} >
                                                                <option key={'month+___' + 1} value=""> {ct('pleaseSelect')} </option>
                                                                <option key={'month+___' + 2} value="01">01</option>
                                                                <option key={'month+___' + 3} value="02">02</option>
                                                                <option key={'month+___' + 4} value="03">03</option>
                                                                <option key={'month+___' + 5} value="04">04</option>
                                                                <option key={'month+___' + 6} value="05">05</option>
                                                                <option key={'month+___' + 7} value="06">06</option>
                                                                <option key={'month+___' + 8} value="07">07</option>
                                                                <option key={'month+___' + 9} value="08">08</option>
                                                                <option key={'month+___' + 10} value="09">09</option>
                                                                <option key={'month+___' + 11} value="10">10</option>
                                                                <option key={'month+___' + 12} value="11">11</option>
                                                                <option key={'month+___' + 13} value="12">12</option>
                                                            </select>
                                                            {formik.errors.expMonth && formik.touched.expMonth ? <small className='text-danger'>{formik.errors.expMonth}</small> : ""}

                                                        </div>
                                                        <div className='col-md-6 mb-2'>
                                                            <div className='line-height-sta invisible'>
                                                                <label className="form-label key-weight" htmlFor="expiryYear"></label>
                                                            </div>
                                                            <select name="expYear" onChange={formik.handleChange} value={formik.values.expYear} className='form-select mt-2'>
                                                                <option key={'years+___' + 0} value="">  {ct('pleaseSelect')} </option>
                                                                <option key={'years+___' + 1} value="2023">2023</option>
                                                                <option key={'years+___' + 2} value="2024">2024</option>
                                                                <option key={'years+___' + 3} value="2025">2025</option>
                                                                <option key={'years+___' + 4} value="2026">2026</option>
                                                                <option key={'years+___' + 5} value="2027">2027</option>
                                                                <option key={'years+___' + 6} value="2028">2028</option>
                                                                <option key={'years+___' + 7} value="2029">2029</option>
                                                                <option key={'years+___' + 8} value="2030">2030</option>
                                                                <option key={'years+___' + 9} value="2031">2031</option>
                                                                <option key={'years+___' + 10} value="2032">2032</option>
                                                            </select>
                                                            {formik.errors.expYear && formik.touched.expYear ? <small className='text-danger'>{formik.errors.expYear}</small> : ""}

                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 col-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="securityCode">  {t('common.securityCode')} </label>
                                                            </div>
                                                            <div className="input-group">
                                                                <input type="text" onChange={(e) => {
                                                                    const reg = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || reg.test(e.target.value)) {
                                                                        formik.setFieldValue("securityCode", e.target.value);
                                                                    }
                                                                }}

                                                                    maxLength={3} value={formik.values.securityCode} className="form-control key-weight" name='securityCode' placeholder="***" aria-describedby="securityCode" />
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                            </div>
                                                            {formik.errors.securityCode && formik.touched.securityCode ? <small className='text-danger'>{formik.errors.securityCode}</small> : ""}

                                                        </div>
                                                    </div>
                                                    <div className='my-2'>
                                                        <button disabled={loading} type="submit" onClick={formik.handleSubmit} className="border-0 fs-18 p-btn btn-contine text-white fw-600">
                                                            {
                                                                loading ? <RotatingLines
                                                                    strokeColor="white"
                                                                    strokeWidth="5"
                                                                    animationDuration="0.75"
                                                                    width="18"
                                                                    visible={true}
                                                                /> : <>
                                                                    {ct('save')} <FaPlayCircle className='fa-lg ms-1' />
                                                                </>
                                                            }
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    )
}