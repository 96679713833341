import React from 'react';
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { config } from '../../../Environments';
import { UserRole } from '../../../Shared';
import { ProfilePublicView } from './Profile';
import { useTranslation } from 'react-i18next';

const SideContent = (props:
    {
        data: ProfilePublicView | undefined,
        count: number
    }) => {

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');

    const data = props.data;
    const [searchParams] = useSearchParams();
    const auth = useSelector((state: AppStore) => state.auth);
    return (
        <>
            <div className="mb-0">
                <img src={config.apiBase + data?.logo} onError={(e: any) => {
                    e.target.src = "/images/ImgPlaceholder.png"
                }} className="card-img-top rounded-0" alt="company profile image" />
            </div>
            <div className="sidebar-widget p-3">
                <div className="search-widget">
                    {/* <div className='d-flex align-items-center'>
                        <Link to="" className="me-3 text-dark"><FaShareAlt size={26}/></Link>
                        <Link to="" className="btn btn-outline-dark text-decoration-none text-uppercase fw-600 p-follow rounded-pill" role="button" data-bs-toggle="button">Follow</Link>
                    </div> */}
                    <div className="widget-content-para mt-1 d-flex flex-column">
                        <div className="container p-0">
                            <div className="d-flex flex-column border-bottom">
                                <div className="d-flex align-items-center justify-content-between mb-2 fs-14 fw-400">
                                    <h5 className="text-uppercase text-start h6 fw-600 mb-0"> {t('compProfile.employerDetails')} </h5>
                                    {
                                        !searchParams.has('id') && UserRole(auth.raw?.access_token) == 'Employer' &&
                                        <div>
                                            <Link to="/company/editcompanyprofile" className="m-0 text-decoration-none d-flex align-items-center"><FaRegEdit className="" size={16} /><span className="me-2"> {ct('edit')} </span></Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sec">
                        <div className="client-side-title mt-3">
                            <p className="text-uppercase mb-0"> {t('compProfile.specialties')} </p>
                        </div>
                        <div className="widget-content">
                            <p className="fs-14 fw-400">
                                {data?.specialities}
                            </p>
                        </div>
                    </div>
                    {
                        data?.industry &&
                        <div className="sec">
                            <div className="client-side-title mt-3">
                                <p className="text-uppercase mb-0"> {t('compProfile.industry')} </p>
                            </div>
                            <div className="widget-content">
                                <p className="fs-14 fw-400">{data?.industry}</p>
                            </div>
                        </div>
                    }
                    {
                        data?.sector &&
                        <div className="sec">
                            <div className="client-side-title mt-3">
                                <p className="text-uppercase mb-0"> {t('compProfile.type')} </p>
                            </div>
                            <div className="widget-content">
                                <p className="fs-14 fw-400">{data?.sector}</p>
                            </div>
                        </div>
                    }

                    {
                        data?.department &&
                        <div className="sec">
                            <div className="client-side-title mt-3">
                                <p className="text-uppercase mb-0"> {t('compProfile.department')} </p>
                            </div>
                            <div className="widget-content">
                                <p className="fs-14 fw-400">{data?.department}</p>
                            </div>
                        </div>
                    }
                    {
                        data?.profitNonProfit &&
                        <div className="sec">
                            <div className="client-side-title mt-3">
                                <p className="text-uppercase mb-0"> {t('compProfile.organizationType')} </p>
                            </div>
                            <div className="widget-content">
                                <p className="fs-14 fw-400">{data?.profitNonProfit}</p>
                            </div>
                        </div>
                    }
                    <div className="sec">
                        <div className="client-side-title mt-3">
                            <p className="text-uppercase mb-0"> {t('compProfile.headquarters')} </p>
                        </div>
                        <div className="widget-content">
                            <p className="fs-14 fw-400" dangerouslySetInnerHTML={{ __html: data?.address || '' }}></p>
                        </div>
                    </div>
                    {
                        data?.noOfEmployees &&
                        <div className="sec">
                            <div className="client-side-title mt-3">
                                <p className="text-uppercase mb-0"> {t('compProfile.employerSize')} </p>
                            </div>
                            <div className="widget-content">
                                <p className="fs-14 fw-400">{data?.noOfEmployees || 'UnKnown'} {t('compProfile.employees')} </p>
                            </div>
                        </div>
                    }
                    <div className="widget-content-para mt-4 d-flex flex-column">
                        <div className="container p-0">
                            <div className="d-flex flex-column border-bottom">
                                <div className="d-flex align-items-center justify-content-between mb-2 fs-14 fw-400">
                                    <h5 className="text-uppercase text-start h6 fw-600 mb-0"> {t('compProfile.contactDetails')} </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sec">
                        <div className="client-side-title mt-3">
                            <p className="text-uppercase mb-0"> {t('compProfile.Website')} </p>
                        </div>
                        <div className="widget-content">
                            <a href={'//' + (data?.website || '')} target='_blank' className="fs-14 fw-400 text-wrap">{data?.website}</a>
                        </div>
                    </div>
                    <div className="sec">
                        <div className="client-side-title mt-3">
                            <p className="text-uppercase mb-0"> {t('compProfile.telephone')} </p>
                        </div>
                        <div className="widget-content">
                            <a href={'tel:' + data?.phoneNumber} className="fs-14 fw-400 text-decoration-none">{data?.phoneNumber || 'N/A'}</a>
                        </div>
                    </div>
                    <div className="sec">
                        <div className="client-side-title mt-3">
                            <p className="text-uppercase mb-0"> {t('compProfile.contactPerson')} </p>
                        </div>
                        <div className="widget-content">
                            <p className="fs-14 fw-400">{data?.contactPerson || 'N/A'}</p>
                        </div>
                    </div>
                    <div className="sec">
                        <div className="client-side-title mt-3">
                            <p className="text-uppercase mb-0"> {ct('email')} </p>
                        </div>
                        <div className="widget-content">
                            <a href={'mailto:' + data?.email} className="fs-14 fw-400 text-decoration-none">{data?.email}</a>
                        </div>
                    </div>
                    <div className="sec">
                        <div className="client-side-title mt-3 border-bottom">
                            <p className="text-uppercase mb-2"> {t('compProfile.jobs')} </p>
                        </div>
                        <div className="widget-content">
                            <p className="fs-13px mt-2"> {t('compProfile.thisEmployerHasTotal')} {props.count} {t('compProfile.availableJob')} {props.count > 1 ? 's.' : '.'}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SideContent;