import { Link, useSearchParams } from 'react-router-dom';
import RangeSlider from 'react-bootstrap-range-slider';
import { FieldArray, FormikProvider } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import React, { useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';

const MainContent = (props) => {


    const { t: ct } = useTranslation('common');



    const [searchParams] = useSearchParams();
    const formik = props.formik;
    const dropDown = props?.dropDown;
    const isAdmin = props?.isAdmin | false;
    const setEditState = props?.setEditState;
    const editorState = props?.editorState;
    const setJobEditState = props?.setJobEditState;
    const jobEditorState = props?.jobEditorState;

    const maxLength = 150;


    return (
        <>
            <div className=''>
                <div className="client-description">
                    <div className="card border-0 rounded-0">
                        <div className="p-4">
                            <div className="card-body p-0">
                                <div className="candidate-sec">
                                    <div className="container p-1">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-12 mt-1 margin-language">
                                                <div className="">

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div>
                                                    {/* <div className=" mb-2">
                                                        <select id="inputState" onChange={formik.handleChange} value={formik.values.language}
                                                             name='language' className="form-select form-control key-weight w-selected select-mob">
                                                            <option value="en">EN</option>
                                                            <option value="fr">FR</option>
                                                        </select>
                                                        {formik.errors.language && formik.touched.language ? <small className="text-danger">{formik.errors.language}</small> : ""}

                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="candidate-title mb-3">
                                        <div className="row border-bottom border-3 border-light border-top">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3">
                                                <div>
                                                    <label htmlFor="" className="col-form-label label-text text-capitalize"> {ct('shortDescription')} </label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <div className="">
                                                        <Editor
                                                            editorState={editorState}
                                                            editorClassName="editor-height bg-white"
                                                            wrapperClassName='bg-white'
                                                            stripPastedStyles={true}

                                                            onEditorStateChange={e => {
                                                                setEditState(e);
                                                                formik.setFieldValue("shortDescription", draftToHtml(convertToRaw(e.getCurrentContent())));

                                                                // setValue({ content: draftToHtml(convertToRaw(e.getCurrentContent())) });

                                                            }}
                                                            toolbar={{
                                                                options: ['inline', 'textAlign', 'colorPicker'],
                                                                inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough',] },
                                                                textAlign: { inDropdown: false },
                                                                colorPicker: { inDropdown: true },
                                                            }}
                                                            handleBeforeInput={e => {
                                                                const textLength = editorState.getCurrentContent().getPlainText().length;
                                                                if (e && textLength >= maxLength) {
                                                                    return 'handled';
                                                                }
                                                                return 'not-handled';
                                                            }}
                                                            handlePastedText={e => {
                                                                const textLength = editorState.getCurrentContent().getPlainText().length;
                                                                return ((e.length + textLength) >= maxLength);
                                                            }}
                                                        />
                                                        {formik.errors.shortDescription && formik.touched.shortDescription ? <small className="text-danger">{formik.errors.shortDescription}</small> : ""}
                                                    </div>
                                                    {/* <textarea onChange={formik.handleChange} value={formik.values.description} name="description" className="form-control key-weight" id="exampleFormControlTextarea1" rows="3" required=""></textarea> */}

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border-bottom border-3 border-light border-top">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3">
                                                <div>
                                                    <label htmlFor="" className="col-form-label label-text text-capitalize"> {ct('aboutTheJob')}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <div className="">
                                                        <Editor
                                                            editorState={jobEditorState}
                                                            editorClassName="editor-height bg-white"
                                                            wrapperClassName='bg-white'
                                                            stripPastedStyles={true}
                                                            onEditorStateChange={e => {
                                                                setJobEditState(e);
                                                                formik.setFieldValue("description", draftToHtml(convertToRaw(e.getCurrentContent())));

                                                                // setValue({ content: draftToHtml(convertToRaw(e.getCurrentContent())) });
                                                            }}

                                                        />
                                                        {/* <textarea onChange={formik.handleChange} value={formik.values.description} name="description" className="form-control key-weight" id="exampleFormControlTextarea1" rows="3" required=""></textarea> */}
                                                        {formik.errors.description && formik.touched.description ? <small className="text-danger">{formik.errors.description}</small> : ""}
                                                    </div>


                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row border-bottom border-3 border-light">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3">
                                                <div>
                                                    <label htmlFor="" className="col-form-label label-text text-capitalize"> {ct('skillsRequired')} </label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3">

                                                <div className=" mt-4 mb-4">
                                                    <FormikProvider value={formik}>
                                                        <FieldArray
                                                            name="jobPostSkills"
                                                            render={arrayHelpers => (
                                                                <div>
                                                                    {formik.values.jobPostSkills.map((item, index) => (
                                                                        <div key={'job_sera++-__' + index}>
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="d-block repeat-btns">
                                                                                    <a className="d-block btn delete-btn-color btn-sm rounded-0 text-decoration-none text-white pointer-event text-nowrap" onClick={() => arrayHelpers.remove(index)}> {ct('delete')} </a>
                                                                                </div>
                                                                                <div className="d-flex ps-3">
                                                                                    <div className="">
                                                                                        <div className="mb-2">
                                                                                            <select id="inputState" name={`jobPostSkills.${index}.skillId`} value={item.skillId} onChange={formik.handleChange} className="form-select form-control text-muted input-text" required="">
                                                                                                <option value=""> {ct('selectYouSkill')} </option>
                                                                                                {
                                                                                                    (dropDown?.Skills || []).map((v, index) => (
                                                                                                        <option key={'skl_items_' + index} value={v.key}>{v.value}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            {formik.errors &&
                                                                                                formik.errors.jobPostSkills &&
                                                                                                formik.errors.jobPostSkills[index] &&
                                                                                                formik.errors.jobPostSkills[index].skillId &&
                                                                                                formik.touched &&
                                                                                                formik.touched.jobPostSkills &&
                                                                                                formik.touched.jobPostSkills[index] &&
                                                                                                formik.touched.jobPostSkills[index].skillId && <small className="text-danger">{formik.errors.jobPostSkills[index]?.skillId}</small>}
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <textarea rows="3" cols="50" name={`jobPostSkills.${index}.description`} value={item.description} onChange={formik.handleChange} placeholder={ct('description').toString()} className="form-control mt-3 input-text"></textarea>
                                                                                            {formik.errors &&
                                                                                                formik.errors.jobPostSkills &&
                                                                                                formik.errors.jobPostSkills[index] &&
                                                                                                formik.errors.jobPostSkills[index].description &&
                                                                                                formik.touched &&
                                                                                                formik.touched.jobPostSkills &&
                                                                                                formik.touched.jobPostSkills[index] &&
                                                                                                formik.touched.jobPostSkills[index].description && <small className="text-danger">{formik.errors.jobPostSkills[index]?.description}</small>}
                                                                                        </div>
                                                                                        <div className="mt-4 mb-3 skill-progress-color border-bottom border-1">
                                                                                            <RangeSlider
                                                                                                value={item.percentage}
                                                                                                name={`jobPostSkills.${index}.percentage`}
                                                                                                onChange={e => formik.setFieldValue(`jobPostSkills.${index}.percentage`, e.target.value)}
                                                                                                tooltipLabel={currentValue => `${currentValue}%`}
                                                                                                min={0}
                                                                                                max={100}
                                                                                                tooltipPlacement='top'
                                                                                                tooltip='on'
                                                                                                type="range"
                                                                                                variant='primary'
                                                                                                bsPrefix='range-slider'
                                                                                            />
                                                                                            {formik.errors &&
                                                                                                formik.errors.jobPostSkills &&
                                                                                                formik.errors.jobPostSkills[index] &&
                                                                                                formik.errors.jobPostSkills[index].percentage &&
                                                                                                formik.touched &&
                                                                                                formik.touched.jobPostSkills &&
                                                                                                formik.touched.jobPostSkills[index] &&
                                                                                                formik.touched.jobPostSkills[index].percentage && <small className="text-danger">{formik.errors.jobPostSkills[index]?.percentage}</small>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    <div className="mb-3 text-end">
                                                                        <button type="button" onClick={() => formik.values.jobPostSkills.length < 5 && arrayHelpers.push({ skillId: "", description: "", percentage: "50" })} className="button-default mt-3 border-0 text-decoration-none"> {ct('addASkill')} </button>
                                                                    </div>

                                                                </div>
                                                            )}
                                                        />

                                                    </FormikProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border-bottom border-3 border-light mt-lg-2 mb-lg-2 mt-md-2 mb-md-2">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3">
                                                <div>
                                                    <label htmlFor="" className="col-form-label label-text text-capitalize text-nowrap"> {ct('additionalSkill')} </label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <input type="text" value={formik.values.additionalSkills} name="additionalSkills" onChange={formik.handleChange} className="form-control input-text" id="" placeholder={ct('addSkillSeparatedComma').toString()} required="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gap-2 d-md-block mt-3 ps-2">
                                            <div>
                                                <button disabled={props.loading} type="submit" onClick={props.confirmSubmit} className="button-default text-decoration-none cursor-pointer mt-1 border-0 me-1">
                                                    {
                                                        props.loading ? <RotatingLines
                                                            strokeColor="white"
                                                            strokeWidth="5"
                                                            animationDuration="0.75"
                                                            width="18"
                                                            visible={true}
                                                        /> : <div>
                                                            {ct('save')}
                                                        </div>
                                                    }
                                                </button>
                                                <button type="button" className="button-default mt-1 border-0 cancel-btn"> {ct('cancel')} </button>
                                                {
                                                    searchParams.get('id') && searchParams.get('id') > 0 && isAdmin == false &&
                                                    <Link to={"/job/jobpreview?" + searchParams.toString()} target='_blank' state={{ from: "jobRegister" }} className="border-0 text-decoration-none mx-1 btn-preview-register text-white fw-600"> {ct('preview')} </Link>
                                                }
                                                {
                                                    searchParams.get('id') && searchParams.get('id') > 0 && isAdmin == true &&
                                                    <Link to={"/admin/jobpreview?" + searchParams.toString()} target='_blank' state={{ from: "admin" }} className="border-0 text-decoration-none mx-1 btn-preview-register text-white fw-600"> {ct('preview')} </Link>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MainContent;


