import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { range } from "../../Shared";

export const DatePickSelect = (props: {
    disabled?: boolean,
    yearFrom: number,
    yearTo: number,
    selected: Date | string | null | undefined,
    placeHolder: string,
    customInput?: React.ReactNode,
    onChange: (date: Date | null) => void,
    maxDate: Date | null | undefined,
    minDate: Date | null | undefined,
    //pastDate?: boolean
}) => {
    const years = range(props.yearFrom, props.yearTo + 1);
    const months = moment.months();
    return (<ReactDatePicker        
        readOnly={!!props.disabled}
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        }) => (
            <div
                style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <button className="btn btn-sm btn-default" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                </button>
                <select
                    className="form-select form-select-sm me-1"
                    value={(date.getFullYear())}
                    onChange={({ target: { value } }) => changeYear(Number(value))}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <select
                    className="form-select form-select-sm"
                    value={months[(date.getMonth())]}
                    onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                    }
                >
                    {months.map((option: any) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <button className="btn btn-sm btn-default" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                </button>
            </div>
        )}
        disabled={!!props.disabled}
        maxDate={props.maxDate}
       // minDate={new Date()}
        minDate={props.minDate}
        placeholderText={props.placeHolder}
        className='form-control'
        selected={props.selected ? new Date(props.selected) : undefined}
        onChange={props.onChange}
        customInput={props.customInput}
    />
  

    );
}