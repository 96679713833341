import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegAddressCard, FaEye, FaTrash, FaRegUser, FaFile, FaToggleOn, FaToggleOff, FaPlayCircle, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import AddPaymentModal from "../Payments/PaymentModel";
import { useTranslation } from 'react-i18next';
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";



const EmployeeTable = (props: { pageSkillLimit: number, update: boolean, 
  setPageSkillLimit: (arg:number)=>void ,searchString:string, setUpdate: (arg:boolean) => void } ) => {
  const dispatch = useDispatch();
  const [employeelist, setEmployeeList] = useState<any>([]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation('admin');
  const { t: ts } = useTranslation('alert');


  React.useEffect(() => {
    fetchEmployees();
  }, [props.update]);

  function fetchEmployees() {
    dispatch(setGlobalLoading(true));
    GetAxios()
      .get(config.apiBase + "/api/Admin/GetJobSeekers?search=" + props.searchString)
      .then((res) => {
        setEmployeeList(res.data.data || []);
        dispatch(setGlobalLoading(false));
      });
  }

  function handleOnViewClick(empId:string) {
    navigate("/admin/jobseekerprofile?id=" + empId);
  }

  function handleOnResumeClick(empId:string) {
    navigate("/admin/jobseeker/"+empId+"/resumes");
  }


  function  handleOnDeleteClick(empId:string) {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('employee.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Employee/DeleteEmployee?id='+empId,             
            ).then(s => {  
              toast( ts('employee.delete'), { type: 'success' });        
              dispatch(setGlobalLoading(false));
              props.setUpdate(!props.update);
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    }); 
  }

  function handleApproveClick(userId:string) {
    confirmAlert({
      title: t('common.confirmtoActivate').toString(),
      message: t('common.sureActiveMember').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/ActiveDeactivateUser?userId=" + userId)
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });       
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleDisapproveClick(userId:string) {
    confirmAlert({
      title: t('common.confirmtoDeactivate').toString(),
      message: t('common.sureInactiveMember').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/ActiveDeactivateUser?userId=" + userId)
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });       
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  return (
    <>

      <div className="table-responsive">
        <Table bordered className="bg-white">
          <thead>
            <tr>
              <th className="fw-700 fw-16">{t('common.name')}</th>
              <th className="fw-700 fw-16">{t('common.accountNo')}</th>
              <th className="fw-700 fw-16">{t('common.city')}</th>
              <th className="fw-700 fw-16">{t('common.department')}</th>
              <th className="fw-700 fw-16">{t('common.nationality')}</th>
              <th className="fw-700 fw-16 ">{t('common.tel')}</th>
              <th className="fw-700 fw-16 ">{t('common.email')}</th>
              <th className="fw-700 fw-16 text-center">{t('common.status')}</th>
              <th className="text-center w-15">{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {(employeelist || []).slice((currentPage - 1) * props.pageSkillLimit, currentPage * props.pageSkillLimit).map((item:any, index:number) => {
              return (
                <tr key={index}>
                  <td>{item.fullName}</td>
                  <td className="text-uppercase" >{item?.accountNo?.split('-')[0] || ""}</td>
                  <td>{item.city}</td>
                  <td>{item.department}</td>
                  <td>{item.nationality}</td>
                  <td>{item.tel}</td>
                  <td>{item.email}</td>
                  <td className="text-center">
                    {item.isActive ? t('common.active').toString() : t('common.inactive').toString()}
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary px-2 py-1"
                      onClick={() => handleOnViewClick(item.accountNo)}
                    >
                      <FaEye className="text-white" title={t('common.view') || ''} size={16} />
                    </button>
                    {item.isActive ? (
                      <button
                        type="button"
                        className="btn btn-success mx-1 px-2 py-1"
                        onClick={() => handleDisapproveClick(item.accountNo)}
                      >
                        <FaToggleOn size={16} title={t('common.setInactiveMember') || ''} />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger mx-1 px-2 py-1"
                        onClick={() => handleApproveClick(item.accountNo)}
                      >
                        <FaToggleOff size={16} title={t('common.setActiveMember') || ''} />
                      </button>
                    )}
                     <button
                      type="button"
                      className="btn btn-warning px-2 py-1"
                      style={{"background":"#474afc", "borderColor":"#474afc"}}
                      onClick={() => handleOnResumeClick(item.accountNo)}>
                      <FaRegAddressCard className="text-white" title={t('common.resume') || ''} size={16} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark  mx-1 px-2 py-1"
                      onClick={() => handleOnDeleteClick(item.accountNo)}
                    >
                      <FaTrash className="text-white" title={t('common.delete') || ''} size={16} />
                    </button>
                   
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="">
          <div className="d-flex align-items-center">
            <span>  {t('common.showing')}  {(currentPage > employeelist.length) ? employeelist.length : currentPage}  {t('common.to')}  {(props.pageSkillLimit > employeelist.length) ? employeelist.length : props.pageSkillLimit}  {t('common.of')}  {employeelist.length} {t('common.entries')}</span>
          </div>
        </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}
                >
                  {" "}
                  <FaChevronLeft />
                </button>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(employeelist.length / props.pageSkillLimit))} >
                  {Math.ceil(employeelist.length / props.pageSkillLimit)}
                </a>
              </li>
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(employeelist.length / props.pageSkillLimit)
                      ? false
                      : true
                  }
                >
                  {" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default EmployeeTable;


