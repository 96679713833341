import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export const BottomButtons = (props: {
    onSubmit: (e: () => void) => void,
    onBack?: (e: void) => void,
    routePrefix?: string
}) => {

    const { t } = useTranslation('resume');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { resumeId } = useParams();
    const resume = useSelector((state: AppStore) => state.resume);
    const [isLastStep, setIsLastStep] = useState(false);
    useEffect(() => {
        setIsLastStep(resume.activeStep === resume.stepsInformation.length);
    }, [resume.activeStep, resume.stepsInformation]);
    return (
        <div className="bottom-btn-sticky">
            <div className='next-previous-btns d-flex align-items-center justify-content-between'>
                <div className=''>
                    {
                        resume.activeStep > 1 &&
                        <Button className="fw-500 previous-btn" onClick={e => {
                            if (props.onBack) props.onBack();
                            if (resume.activeStep > 2)
                                navigate((props.routePrefix || '../') + resume.stepsInformation[resume.activeStep - 2].path);
                            else
                                navigate('../../templates/' + resumeId);
                        }}><FaArrowLeft className="me-2" /> {t('previous')} </Button>
                    }
                </div>
                <div className='text-end'>
                    <Button
                        className="fw-500 next-btn-resume"
                        onClick={s => {
                            props.onSubmit(() => {
                                //dispatch(setResumeActiveStep(resume.stepsInformation[resume.activeStep + 1].position));
                                if (isLastStep) {
                                    navigate((props.routePrefix || '../../') + resumeId + '/finish')
                                } else {
                                    navigate((props.routePrefix || '../') + resume.stepsInformation[resume.activeStep].path)
                                }
                            });
                        }}
                    >{isLastStep ? <>{t('finish')}</> : <>{t('next')}</>} <FaArrowRight className="ms-2" /> </Button>
                </div>
            </div>
        </div>

    );
}