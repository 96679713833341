import axios from "axios";
import React from "react";
import Select, { components, StylesConfig } from "react-select";
import countryList from "react-select-country-list";
import { config } from "../../Environments";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export function CountryFlag(props:
    {
        size?: number,
        code: string
    }) {
    return (
        <span
            className={"mx-2 fi fi-" + props.code}
            style={{ fontSize: props.size || 12 }}
        />
    );
}

export const CountryFlagValueContainer = (props: any) => {
    const code = (props.hasValue && props.getValue()[0].value) || false;

    return (
        <div className="optionCOntainer" style={{ display: "flex", flexGrow: 1 }}>
            {(code && <CountryFlag code={code.toLowerCase()} />) || null}
            <components.ValueContainer {...props}>
                {props.children}
            </components.ValueContainer>
        </div>
    );
};

const options = countryList().getData();
const countryname = countryList().getLabel("HT")
const countrycode = countryList().getValue("Haiti")
const { Option } = components;
const IconOption = (props: any) => {
    return (
        <Option {...props}>
            <CountryFlag code={props.value.toLowerCase()} />
            {props.data.label}
        </Option>
    );
}

export const CountrySelect = (props: {
    name?: string,
    value?: string,
    colorConfig?: StylesConfig,
    onChange: (code: string) => void
}) => {
    const [value, setValue] = React.useState<string | undefined>();
    const [code, setCode] = React.useState<string>('HT');
    const changeHandler = (value: any) => {
        setValue(value.value);
        props.onChange(value.value);
    };
    // React.useEffect(() => {
    //     axios.get(config.apiBase + '/api/General/Info').then(res => {
    //         setCode(res?.data?.country || 'HT');
    //     });
    // }, []);
    React.useMemo(() => {
        setValue(props.value?.trim() || code);
    }, [props.value, code]);
    return (
        <Select name={props.name}
            options={options}
            styles={props?.colorConfig}
            onChange={changeHandler}
            value={options.find(s => s.value == value)}
            components={{ Option: IconOption, ValueContainer: CountryFlagValueContainer }}
        />
    );
}