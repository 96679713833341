import React, { useState } from "react";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { DatePickSelect } from "../../../Components";
import { FaMoneyCheck, FaPlayCircle } from "react-icons/fa";
import { RotatingLines } from 'react-loader-spinner';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Table from "react-bootstrap/Table";
import { useTranslation } from 'react-i18next';
export const AddPaymentModal = (props:{
  paymentId:number , change:()=>void ,paymentStatus:string
}) => {


  const [interest, setInterest] = useState(false);
  const handleClose = () => setInterest(false);
  const handleShow = () => setInterest(true);
  const [paymentDetail, setPaymentDetail] = useState<any>();

  const { t: ts } = useTranslation('alert');
  const { t } = useTranslation('admin');
  React.useEffect(() => {
    if (props.paymentId > 0  && props.paymentStatus == "Paid") {
      GetAxios().get(config.apiBase + '/api/Admin/ViewPayment?id=' + props.paymentId).then(res => {
        if (res.data.success) {  
          setPaymentDetail(res.data.data);
        }
      });
    }
  }, [props.paymentStatus]);
  const paymentSchema = Yup.object().shape({

    paidAmount: Yup.number().required(ts('account.required')|| 'Required'),
    txnRef: Yup.string().required(ts('account.required')|| 'Required'),
    paidDate: Yup.string().required(ts('account.required')|| 'Required'),

  });

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      paymentId: 0,
      paidAmount: 0,
      txnRef: "",
      paidDate: "",
      comments: "",


    },
    validationSchema: paymentSchema,
    onSubmit: (values, { resetForm }) => {

      // values => {
      if (loading) {
        toast(ts('account.pleaseWait'), { type: 'info' });
      }
      setLoading(true);

      const formData = new FormData();
      Object.keys(values).map(s => {
        formData.append(s, (values as any)[s]);
      });
      //formData.append('id', props.typeId);
      GetAxios().post(config.apiBase + '/api/Admin/SavePayment', formData).then(res => {
        if (res.data.success) {
          toast(res.data.message, { type: 'success' });
          resetForm();
          props.change();
        } else {
          toast(ts('error.unSavePayment'), { type: 'error' });
        }
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        console.error(err);
        if (err.status >= 500) {
          toast(ts('error.internalServer'), { type: 'error' });
        }
      });
    },
  });


  return (
    <>
      {

        <button
          type="button"
          className="btn btn-warning mx-1 px-2 py-1"
          onClick={() => handleShow()}
        >
          <FaMoneyCheck className="text-white"  title={t('common.addPayment') ||''} size={16} />
        </button>
      }

      <Modal size="lg" show={interest} onHide={handleClose} animation={true} className="">
        <Modal.Header className='popup-gray-bg' closeButton onClick={handleClose} >
          <Modal.Title className="fw-600">{props.paymentStatus == "UnPaid"? t('common.addPayment').toString(): t('common.receivedpaymemntDetails').toString()} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            props.paymentStatus != "" && props.paymentStatus == "UnPaid" &&
            <div className="payment-detail">
              <div className="row">
                <div className="col-md-4 col-12 mb-12">
                  <div className='line-height-sta'>
                    <label className="form-label key-weight" htmlFor="receivedDate">{t('common.receivedDate')}: </label>
                  </div>
                    <DatePickSelect
                    yearFrom={1960}
                    yearTo={new Date().getFullYear()}
                    maxDate={new Date()}
                    minDate={new Date(1960, 1, 1)}
                    placeHolder={t('common.receivedDate').toString()}
                    selected={formik.values.paidDate}
                    onChange={(date) => {
                      formik.setFieldValue("paidDate", date?.toISOString())
                      formik.setFieldValue("paymentId", props.paymentId )
                     
                    }}
                  />
                  {formik.errors.paidDate && formik.touched.paidDate ? <small className='text-danger d-block'>{formik.errors.paidDate}</small> : ""}

                </div>
                <div className="col-md-4 col-12 mb-12">
                  <div className='line-height-sta'>
                    <label className="form-label key-weight" htmlFor="paidAmount">{t('common.receivedAmount')}: </label>
                  </div>
                  <input type="number" step=".01" className="form-control" name="paidAmount" onChange={formik.handleChange} value={formik.values.paidAmount} />
                </div>
                <div className="col-md-4 col-12 mb-12">
                  <div className='line-height-sta'>
                    <label className="form-label key-weight" htmlFor="txnRef"> {t('common.txn')}: </label>
                  </div>
                  <input type="text" className="form-control" name="txnRef" onChange={formik.handleChange} value={formik.values.txnRef} />

                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-12 mb-12">
                  <div className='line-height-sta'>
                    <label className="form-label key-weight" htmlFor="comments"> {t('common.comments')}:</label>
                  </div>
                  <textarea className="form-control" name="comments" rows={4} onChange={formik.handleChange} value={formik.values.comments} />
                </div>
              </div>
            </div>
          }
          {props.paymentStatus != "" && props.paymentStatus == "Paid" && paymentDetail != undefined &&
            <div className="table-responsive">
              <Table bordered className="bg-white">
                <thead>
                  <tr>
                    <th className="fw-700 fw-16">{t('common.paymentMethod')}</th>
                    <th className="fw-700 fw-16">{t('common.paidAmount')}</th>
                    <th className="fw-700 fw-16">{t('common.transReferenceNumber')}</th>
                    <th className="fw-700 fw-16">{t('common.paymentDate')}</th>

                  </tr>
                </thead>
                <tbody>
                  <tr key="payemntrow">
                    <td>{paymentDetail.paymentMethod || 'N/A'}</td>
                    <td>{paymentDetail.paidAmount || '$0.0'}</td>
                    <td>{paymentDetail.txnRef || "N/A"}</td>
                    <td>{paymentDetail.paidDate || "N/A"}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className="fs-18 rounded ptb-15 text-white border-0 fw-500" onClick={() => {
            props.change();
            handleClose();
          }
          }>
          {t('common.close')}
          </Button>
          {
             props.paymentStatus != "" && props.paymentStatus == "UnPaid" &&
            <button disabled={loading} type="submit" onClick={formik.handleSubmit as any} className="fs-18 rounded ptb-15 text-white btn btn-primary border-0 fw-500">
              {
                loading ? <RotatingLines
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="18"
                  visible={true}
                /> : <>
                 {t('common.submit')} <FaPlayCircle className='fa-lg ms-1' size={20} />
                </>
              }
            </button>
          }


        </Modal.Footer>
      </Modal>
    </>
  )
}
export default AddPaymentModal;
