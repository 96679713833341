import React, { RefAttributes, useRef, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import { FaPencilAlt, FaSlidersH, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { config } from '../../../../../../Environments';
import { GetAxios, useDebouncedEffect, parseJwt } from '../../../../../../Shared';
import { setGlobalLoading } from '../../../../../../Store';
import { setResumeActiveStep, setResumeForEdit, setResumeTemplate } from '../../../../../../Store/Slices/ResumeSlice';
import { AddAnother } from '../../../../Components/AddAnother';
import ExtraSectionPopup from '../../../../Components/ExtraSectionPopup';
import ResumeSectionCard from '../../../../Components/ResumeSectionCard';
import { StylesFullScreenModal } from '../../../../Components/StylesFullScreenModal';
import { TemplateSlider } from '../../../../Components/TemplateSlider';
import { DownloadServiceMain } from '../../../../DownloadServiceMain';
import { AvailableExtraSteps, AvailableSteps } from '../../../../SharedData';
import { ResumePreviewMain } from '../../Preview/ResumePreviewMain';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';


export const FinishLayout = () => {

  const { t } = useTranslation('resume');
  const { t: ct } = useTranslation('common');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: AppStore) => state.auth);

  const [showFullScreen, setShowFullScreen] = useState(false);

  const resume = useSelector((state: AppStore) => state.resume);

  const [title, setTitle] = useState(resume.title || '');

  React.useEffect(() => {
    setTitle(resume.title);

  }, [resume]);

  const maximumPossibleSteps = AvailableSteps.concat(AvailableExtraSteps);

  const { resumeId } = useParams();

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [modalShow, setModalShow] = useState(false);
  React.useEffect(() => {
    dispatch(setResumeActiveStep(resume.stepsInformation.length as AvailableSteps));
  }, [resume.stepsInformation]);

  useDebouncedEffect(() => {
    if (title) {
      dispatch(setResumeForEdit({ title: title }));
      GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/UpdateTitle', undefined, {
        params: {
          resumeId,
          title: title
        }
      });
    }
  }, [title], 1000);

  const componentRef = useRef<any>();
  return (
    <div>
      <div className='row'>
        <div className='col-md-7 col-12 scrollbar-col-6'>
          <div className='doc-preview-finish'>
            <div className='container d-flex justify-content-between mb-4 mob-flex-col preview-zoom'>
              <div className='d-flex flex-start'>
                <div className="me-2 mb-1">
                  <input
                    onChange={e => setTitle(e.target.value)}
                    value={title}
                    type="text"
                    className="form-control border-0 untitle ps-0 key-weight fw-16 bg-trans"
                    placeholder={t('documentUntitled').toString()} />
                </div>
                <span>
                  <Link to={''}>
                    <OverlayTrigger
                      overlay={<Tooltip>{t('newResumeCard.rename')}</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                      placement="top">
                      {({ ref, ...triggerHandler }) => (
                        <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaPencilAlt className="icon-gray" /></span>
                      )}
                    </OverlayTrigger>
                  </Link>
                </span>
              </div>
              <div className="d-flex justify-content-end">
                <TemplateSlider onTemplateClick={e => GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SetTemplate', undefined, {
                  params: {
                    id: resumeId,
                    templateId: e.id
                  }
                }).then(s => {
                  dispatch(setResumeTemplate(e.id));
                  dispatch(setGlobalLoading(false));
                })} />
                <button className="me-1 temp-btn fw-600" onClick={() => setShowFullScreen(true)}>
                  <FaSlidersH className="me-1" size={18} /> {t('styles')} </button>
                <StylesFullScreenModal show={showFullScreen} onCloseClick={() => setShowFullScreen(false)} />
              </div>
            </div>
            <div className='PrintingTHis' ref={componentRef}>
              {/* <DownloadServiceMain ref={componentRef} /> */}

              <ResumePreviewMain />
            </div>
          </div>
        </div>
        <div className='col-md-5 col-12 scrollbar-col-6 bg-white'>
          <div className="py-5 pl-pr-80">
            <div className="text-center">
              <p className="step1-heading mb-3"> {t('hereYourResume')}!</p>
              <div className="mb-2">

                <ReactToPrint
                  documentTitle={new Date().toDateString() + ".pdf"}
                  trigger={() => <p className='fw-600 text-decoration-none'> <span className='cursor-pointer'> {ct('preview')}</span></p>}
                  content={() => componentRef.current}
                />


              </div>
              <ReactToPrint
                documentTitle={new Date().toDateString()}
                trigger={() => <Button className='fw-500 text-uppercase rounded-pill download-btn  mb-3'> {ct('download')}</Button>}
                content={() => componentRef.current}
              />
              <div className="mb-4">
                {/* <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}>
                  <Link to='' className="fw-600 text-decoration-none"><FaUpload /> Share it online</Link>
                </OverlayTrigger> */}

              </div>
            </div>

            <div className="resume-section">
              <div className="border-bottom border-2 mb-4"></div>
              <p className="text-uppercase fw-12 fw-500 icon-gray mb-4"> {t('resumeSections')}</p>

              <ResumeSectionCard />
              {
                resume.stepsInformation.length <= maximumPossibleSteps.length - 1 &&
                <>
                  <AddAnother action={handleShow} />
                  <ExtraSectionPopup display={showModal} modalClose={handleClose} />
                </>
              }
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

const renderTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & RefAttributes<HTMLDivElement>) => (
  <Tooltip id="button-tooltip" {...props}
    style={{
      position: 'absolute',
      backgroundColor: '#000',
      padding: '5px 15px',
      color: 'white',
      borderRadius: 3,
      width: '450px',
      ...props.style,
    }}
  >
    <div className="text-center" style={{ width: '400px' }}>
      <div className="fw-500">
        <Badge bg="primary" className="rounded me-1 rounded-pill me-3">New</Badge>
        <span className="text-uppercase">share your resume</span>
      </div>
      <p className="mb-0">Send a private to your resume or share it on social media. This online version will automatically update when you make changes.</p>
    </div>
  </Tooltip>
);


