import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import AddPaymentModal from "./PaymentModel";
import { FaToggleOn, FaToggleOff, FaRegClock, FaChevronRight, FaChevronLeft, FaEnvelope ,FaPlayCircle} from "react-icons/fa";
import { RotatingLines } from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next';
const PaymentTable = (props: { pageSkillLimit: number, update: boolean, paymentMethod:string,
  paymentStatus:string , paymentType:string ,
  setPageSkillLimit: (arg:number)=>void ,searchString:string, setUpdate: (arg:boolean) => void } ) => {


  const dispatch = useDispatch();
  const [paymentlist, setPaymentList] = useState<any>([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [editorState, setEditorState] = useState("");
  
  const { t: ts } = useTranslation('alert');
  const { t } = useTranslation('admin');
  const [modalShow, setModalShow] = useState(false);

  React.useEffect(() => {
    fetchPayments();
  }, [props.update]);

  function fetchPayments() {
    dispatch(setGlobalLoading(true));
    GetAxios()
      .get(config.apiBase + "/api/Admin/GetPayments?method=" + props.paymentMethod
        + "&type=" + props.paymentType + "&status=" + props.paymentStatus + "&search=" + props.searchString)
      .then((res) => {
        setPaymentList(res.data.data || []);

        dispatch(setGlobalLoading(false));
      });
  }


  // function handleApproveClick(refId, type) {
  //   confirmAlert({
  //     title: t('common.confirmtoActive').toString(),
  //     message: t('common.sureActive').toString(),
  //     buttons: [
  //       {
  //         label:  t('common.yes').toString(),
  //         onClick: () => {
  //           dispatch(setGlobalLoading(true));
  //           GetAxios()
  //             .get(config.apiBase + "/api/Admin/UpdateCompanyPlanStatus?refId=" + refId + "&type=" + type)
  //             .then((res) => {
  //               if (res.data.success) {
  //                 toast(ts('admin.planActive'), { type: "success" });
  //                 props.setUpdate(!props.update);
  //                 dispatch(setGlobalLoading(false));
  //               }
  //               else {
  //                 toast(ts('admin.planInActive'), { type: "error" });
  //                 dispatch(setGlobalLoading(false));
  //               }
  //             })
  //             .catch((error) => {
  //               toast(ts('error.wentWrong'), { type: "error" });
  //             });
  //         },
  //       },
  //       {
  //         label: t('common.no').toString(),
  //         onClick: () => { },
  //       },
  //     ],
  //   });
  // }

  function handleResendInvoice(invoiceId :number, invoicetype:string) {
    dispatch(setGlobalLoading(true));
    GetAxios()
      .get(config.apiBase + "/api/Admin/ResendInvoice?id=" + invoiceId + "&type=" + invoicetype)
      .then((res) => {
        if (res.data.success) {
          toast(ts('admin.sendInvoice'), { type: "success" });
          dispatch(setGlobalLoading(false));
        }
        else {
          toast(ts('admin.unsendInvoice'), { type: 'error' });
          dispatch(setGlobalLoading(false));
        }
      })
      .catch((error) => {
        toast(ts('error.wentWrong'), { type: "error" });
      });
  }


 

  return (
    <>
      <div className="table-responsive">
        <Table bordered className="bg-white">
          <thead>
            <tr>
              <th className="fw-700 fw-16">{t('payment.invoiceNo')}</th>
              <th className="fw-700 fw-16">{t('payment.purchaseDate')}</th>
              <th className="fw-700 fw-16">{t('payment.paidVia')}</th>
              <th className="fw-700 fw-16">{t('common.companyName')}</th>
              <th className="fw-700 fw-16">{t('payment.totalValue')}</th>
              <th className="fw-700 fw-16">{t('payment.paidValue')}</th>
              <th className="fw-700 fw-16">{t('payment.plan')}</th>
              <th className="fw-700 fw-16">{t('payment.type')}</th>
              <th className="fw-700 fw-16">{t('common.status')}</th>
              <th className="fw-700 fw-16">{t('payment.details')}</th>
              {/* <th className="fw-700 fw-16 text-center">Payment Status</th> */}
              <th className="text-center w-15">{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {(paymentlist || []).slice((currentPage - 1) * props.pageSkillLimit, currentPage * props.pageSkillLimit).map((item:any, index:number) => {

              {/* {joblist.map((item, index) => { */ }
              return (
                <tr key={index}>
                  <td>{item.invoiceNo}</td>
                  <td>{item.purchaseDate}</td>
                  <td>{item.paymentMethod}</td>
                  <td>{item.companyName}</td>
                  <td>{item.totalAmount}</td>
                  <td>{item.paidAmount || "$0.00"}</td>
                  <td> {item.planStatus ?t('common.active').toString():t('common.inactive').toString()}</td>
                  <td>{item.planType == "T" ? t('payment.tender').toString() : item.planType == "A" ?t('payment.announce').toString() :t('payment.jobpost').toString()}</td>
                  <td>{item.paymentStatus=="Paid"? t('payment.paid').toString():t('payment.unpaid').toString()}</td>
                  <td>{item.days}</td>
                  <td className="text-center">
                    {/* {item.planStatus ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger px-2 py-1"
                        onClick={() => handleApproveClick(item.referenceId, item.planType)}
                      >
                        <FaToggleOff size={16} title={t('common.setActive')}/>
                      </button>
                    )} */}
                    <AddPaymentModal paymentId={item.id}  change={fetchPayments} paymentStatus={item.paymentStatus} />
                    <button
                      type="button"
                      className="btn btn-primary px-2 py-1"
                      onClick={() => handleResendInvoice(item.id, item.planType)}
                    >
                      <FaEnvelope size={16} title={t('common.resendInvoice') || ''} />
                    </button>
                    {/* <button
                      type="button"
                      className="btn btn-success mx-2 px-2 py-1"
                      onClick={() => setModalShow(true)}
                    >
                      <FaRegClock size={16} title="Send Remainder" />
                    </button> */}
                    <SendReminderModal paymentId={item.id} companyEmail={item.email} plan={item.planType} invoiceNo={item.invoiceNo} paymentStatus={item.paymentStatus} amount={item.totalAmount} 
                    editorState={editorState} setEditorState={setEditorState}/>

                    {/* <ReminderEmailModal handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} />
                  */}
                   </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <div className="">
          <div className="d-flex align-items-center">
            <span>   {t('common.showing')} {(currentPage > paymentlist.length) ? paymentlist.length : currentPage}  {t('common.to')} {(props.pageSkillLimit > paymentlist.length) ? paymentlist.length : props.pageSkillLimit}  {t('common.of')} {paymentlist.length} {t('common.entries')}</span>
          </div>
        </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}
                >
                  {" "}
                  <FaChevronLeft />
                </button>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(paymentlist.length / props.pageSkillLimit))} >
                  {Math.ceil(paymentlist.length / props.pageSkillLimit)}
                </a>
              </li>
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(paymentlist.length / props.pageSkillLimit)
                      ? false
                      : true
                  }
                >
                  {" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default PaymentTable;


// export const ReminderEmailModal = (props) => {

//   const setJobEditState = props?.setJobEditState;
//   const jobEditorState = props?.jobEditorState;

//   return (
//     <>
//       <Modal size="lg" show={props.handleModalShow} onHide={props.handleModalClose} animation={true} className='popup-modal-width top-cl'>
//         <Modal.Header className='popup-gray-bg' closeButton>
//           <Modal.Title>Add Reminder Email </Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{
//           maxHeight: 'calc(100vh - 210px)',
//           overflowY: 'auto'
//         }}>
//           <div className="payment-detail">
//             <div className="row">
//               <div className="col-md-6 col-12 mb-12">
//                 <div className='line-height-sta'>
//                   <label className="form-label key-weight" htmlFor="paidAmount"> Email: </label>
//                 </div>
//                 <input type="text" className="form-control" name="txnRef" />
//               </div>
//               <div className="col-md-6 col-12 mb-12">
//                 <div className='line-height-sta'>
//                   <label className="form-label key-weight" htmlFor="txnRef"> Subject: </label>
//                 </div>
//                 <input type="text" className="form-control" name="txnRef" />
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-12 col-12 mb-12">
//                 <div className='line-height-sta'>
//                   <label className="form-label key-weight" htmlFor="comments"> Body: </label>
//                 </div>
//                 <div>
//                   <Editor
//                     editorState={jobEditorState}
//                     editorClassName="editor-height bg-white"
//                     wrapperClassName='bg-white'
//                     stripPastedStyles={true}
//                     onEditorStateChange={e => {
//                       setJobEditState(e);
//                       // formik.setFieldValue("description", draftToHtml(convertToRaw(e.getCurrentContent())));
//                    
//                       // setValue({ content: draftToHtml(convertToRaw(e.getCurrentContent())) });
//                     }}

//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div>
//             <Button variant="primary" className='fw-500 rounded-0 me-2' onClick={props.handleModalClose}>
//               Save
//             </Button>
//             <Button variant="danger" className='fw-500 rounded-0' onClick={props.handleModalClose}>
//               Close
//             </Button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//     </>
//   )
// }


export const SendReminderModal = (props: { paymentId: number, companyEmail:string,
  plan:string , invoiceNo:string,paymentStatus:string,amount:any,
  editorState:any , setEditorState: (arg:any)=>void }) => {
  const { t: ts } = useTranslation('alert');
  const { t } = useTranslation('admin');
 
  const [interest, setInterest] = useState(false);
  const handleClose = () => setInterest(false);
  const handleShow = () => setInterest(true);

  const [loading, setLoading] = useState(false);
  const remainderSchema = Yup.object().shape({
    subject: Yup.string().required(ts('account.required')|| 'Required'),
    message: Yup.string().required(ts('account.required')|| 'Required'),

  });
  //const message= `The ${props.amount} of your ${props.plan} plan package against invoice no ${props.invoiceNo} is due. Please post a cheque to the order Mastero Media at 25 bis , 2eme Ruelle Nazon Port-au-Prince , Haiti HT6111`;
  // const html = convertFromHTML(message);
  // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))

  const formik = useFormik({
    initialValues: {
      id: 0,
      email: "",
      subject: "",
      message: ""

    },
    validationSchema: remainderSchema,
    onSubmit: (values, { resetForm }) => {

      if (loading) {
        toast(ts('account.pleaseWait'), { type: 'info' });
      }
      setLoading(true);

      const formData = new FormData();
      Object.keys(values).map(s => {
        formData.append(s, (values as any)[s]);
      });
      // formData.append('id', props.paymentId);
      // formData.append('email', props.companyEmail);   
      GetAxios().post(config.apiBase + '/api/Admin/SendPaymentRemainder', formData).then(res => {
        if (res.data.success) {
          toast(ts('admin.sendRemainder'), { type: 'success' });
          resetForm();
          props.setEditorState('');
        } else {
          toast(ts('admin.unsendRemainder'), { type: "error" });
       
        }
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        console.error(err);
        if (err.status >= 500) {
          toast(ts('error.internalServer'), { type: "error" });
         
        }
      });
    },
  });

  return (
    <>
      {props.paymentStatus!='' && props.paymentStatus=="Paid" ? (
      <button
      type="button"
      className="btn btn-success mx-1 px-2 py-1 "
      style={{"background":"white","borderColor":"white"}}>
      <FaRegClock size={16} title={t('common.sendRemainder') || ''} />
     </button>
      ) : (
        <button
          type="button"
          className="btn btn-success mx-1 px-2 py-1"
          onClick={() => handleShow()}
        >
         <FaRegClock size={16} title={t('common.sendRemainder') || ''} />
        </button>
      )}

      <Modal size="lg" show={interest} onHide={handleClose} animation={true} className='popup-modal-width top-cl'>
        <Modal.Header className='popup-gray-bg' closeButton>
          <Modal.Title>{t('common.sendReminderEmail')} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
        }}>
          <div className="payment-detail">
            <div className="row">
              <div className="col-md-6 col-12 mb-12">
                <div className='line-height-sta'>
                  <label className="form-label key-weight" htmlFor="email"> {t('common.email')}: </label>
                </div>
                <input type="text" disabled className="form-control" onChange={formik.handleChange} value={props.companyEmail} name="email" />
                {formik.errors.email && formik.touched ? <small className='text-danger'>{formik.errors.email}</small> : ""}
              </div>
              <div className="col-md-6 col-12 mb-12">
                <div className='line-height-sta'>
                  <label className="form-label key-weight" htmlFor="subject">{t('common.subject')}: </label>
                </div>
                <input type="text" className="form-control"  
                    onChange={(e) => {
                      formik.setFieldValue("subject", e.target.value)
                      formik.setFieldValue("email", props.companyEmail)
                      formik.setFieldValue("id", props.paymentId)
                    
                    }}
          
                value={formik.values.subject} name="subject" />
                {formik.errors.subject && formik.touched ? <small className='text-danger'>{formik.errors.subject}</small> : ""}
         
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12 mb-12">
                <div className='line-height-sta'>
                  <label className="form-label key-weight" htmlFor="message"> {t('common.message')}: </label>
                </div>
                <div>
                  <Editor
                    editorState={props.editorState}
                    editorClassName="editor-height bg-white"
                    wrapperClassName='bg-white'
                    stripPastedStyles={true}
                    //placeholder={message}
                    onEditorStateChange={e => {
                      props.setEditorState(e);
                      formik.setFieldValue("message", draftToHtml(convertToRaw(e.getCurrentContent())));
                                                          
                    }}
                    
                  />
                  {formik.errors.message && formik.touched ? <small className='text-danger'>{formik.errors.message}</small> : ""}
         
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
          <Button variant="danger" className="fs-18 rounded ptb-15 text-white border-0 fw-500" onClick={() => {
            handleClose();
            formik.resetForm();
            props.setEditorState('');
          }
          }>
           {t('common.close')}
          </Button>   
            <button disabled={loading} type="submit" onClick={formik.handleSubmit as any} className="mx-2 fs-18 rounded ptb-15 text-white btn btn-primary border-0 fw-500">
              {
                loading ? <RotatingLines
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="18"
                  visible={true}
                /> : <>
                {t('common.send')}<FaPlayCircle className='fa-lg ms-1' size={20} />
                </>
              }
            </button>       
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

