import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ResumeBankIndex = () => {
    const location = useLocation();
    return (
        <>
            <div className="container">
                <div className="row py-50">
                    <div className="col-12 px-2">
                        <div style={{ height: '60vh' }}>
                            <h1 className="mb-2 mt-0"> Resume Bank </h1>
                            <p>This is the my resume bank page.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}