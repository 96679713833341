import { config } from "../../Environments";
import PrintIcon from '../../Components/Common/PrintIcon';
import { Link } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import { useTranslation } from 'react-i18next';
import { GetAxios} from "../../Shared";
import React, { useEffect, useRef, useState } from "react";

const Content = () => {
 
  const componentRef =  useRef<HTMLInputElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current ,
  });

  return (
    <>
      <div className=''>
        <div className="border-0">
            {/* <div className='d-inline-block cursor-pointer border-none' onClick={handlePrint}>
                <PrintIcon />
              </div> */}
        </div>
        <div className="print-sec">
          <ComponentToPrint ref={componentRef} />
        </div>
      </div>
    </>
  )
}
export default Content;


export const ComponentToPrint = React.forwardRef<HTMLInputElement>((props, ref ) => {
  const [gender, setGender] = useState('');   
   useEffect(() => {  
    GetAxios().get(config.apiBase + '/api/General/GetUserSex')
    .then(res => {
    setGender(res.data.data || '');
   })
  }, [gender]);
  const { t } = useTranslation('candidate');

  return (
    <>
      <div className='' ref={ref}>
        <div className='matcher-para fw-600'>
          <p>{gender=='Female'?t('job.matcher.femaleCareerPath'):t('job.matcher.maleCareerPath') }</p>
          <p> {t('job.matcher.tellUsAboutSkill')}.</p>
        </div>
        <div className="skill-border-btn text-center">
          <Link to={'/job-matcher-assessment'} className="matcher-skill-btn">
            {t('job.matcher.startJobMatcher')}
          </Link>
        </div>
      </div>
    </>
  );
});