import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import {FaEdit,  FaTrash, FaMoneyCheck,FaEye ,FaToggleOn,FaToggleOff, FaChevronRight, FaChevronLeft, FaPlayCircle} from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const SucessStoryList =(props: { pageSkillLimit: number, update: boolean, 
  setPageSkillLimit: (arg:number)=>void ,searchString:string, setUpdate: (arg:boolean) => void } ) => {

  const dispatch = useDispatch();
  const [storylist, setStoryList] = useState<any>([]);
  //const [update, setUpdate] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation('admin');
  const { t :ts} = useTranslation('alert');
  // React.useEffect(() => {
  //   fetchStories();
  // }, []);

  React.useEffect(() => {
    fetchStories();
  }, [props.update]);

  function fetchStories() {
    dispatch(setGlobalLoading(true));
    GetAxios()
      .get(config.apiBase + "/api/Admin/GetSucessStories?search="+props.searchString)
      .then((res) => {
        setStoryList(res.data.data || []);
        dispatch(setGlobalLoading(false));
      });
  }

  function handleOnViewClick(storyId:number) {

    navigate("/admin/storypreview?storyId=" +storyId);
  }

  function handleEditClick(storyId:number) {
    navigate('/admin/successstories/AddEdit?storyId=' +storyId);
  }

  function  handleOnDeleteClick(storyId:number) {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('story.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Admin/DeleteStory?id='+storyId,             
            ).then(s => {  
              toast( ts('story.delete'), { type: 'success' });        
              dispatch(setGlobalLoading(false));
              props.setUpdate(!props.update);
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    }); 
  }
  function handleApproveClick(storyId:number) {
    confirmAlert({
      title: t('common.confirmtoActive').toString(),
      message: t('common.sureActive').toString(),
      buttons: [
        {
          label:  t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/UpdateStoryStatus?storyId=" +storyId)
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => {},
        },
      ],
    });
  }

  function handleDisapproveClick(storyId:number) {
    confirmAlert({
      title:  t('common.confirmtoInactive').toString(),
      message: t('common.sureInactive').toString(),
      buttons: [
        {
          label:  t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
            .get(config.apiBase + "/api/Admin/UpdateStoryStatus?storyId=" +storyId)
              .then((res) => {
                props.setUpdate(!props.update); 
               toast(ts('account.statusUpdate'), { type: "success" });
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <>
      <div className="table-responsive">
        <Table bordered className="bg-white">
          <thead>
            <tr>
              <th className="fw-700 fw-16">{t('sucessstories.personName')}</th>
              <th className="fw-700 fw-16">{t('sucessstories.personTitle')}</th>            
              <th className="fw-700 fw-16 text-center">{t('sucessstories.createdDate')}</th>
              <th className="fw-700 fw-16 text-center">{t('common.status')}</th>       
              <th className="fw-700 fw-16 text-center">{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {/* {storylist.map((item, index) => { */}

            {(storylist || []).slice((currentPage - 1) * props.pageSkillLimit, currentPage * props.pageSkillLimit).map((item:any, index:number) => 
            {
              return (
                <tr key={index}>
                  <td>{item.personName}</td>
                  <td>{item.personTitle}</td>
                  <td className="text-center">{item.createdDate}</td>                
                  <td className="text-center">
                    {item.isActive ?  t('common.active').toString():t('common.inactive').toString()}
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary mx-1 px-2 py-1"
                      onClick={() => handleOnViewClick(item.id)}
                    >
                      <FaEye className="text-white" title={t('common.view') ||''} size={16} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-info  px-2 py-1"
                      onClick={() => handleEditClick(item.id)}
                     >
                      <FaEdit className="text-white" size={16} title={t('common.edit')  ||''}/>
                    </button>
                    {item.isActive ? (
                      <button
                        type="button"
                        className="btn btn-success mx-1 px-2 py-1"
                    
                        onClick={() => handleDisapproveClick(item.id)}
                      >
                      <FaToggleOn size={16} title={t('common.setInactive') ||''}  />
  
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger mx-1 px-2 py-1"
                        onClick={() => handleApproveClick(item.id)}
                      >
                     <FaToggleOff size={16} title={t('common.setActive') ||''}/>
                      </button>


                    )}
                   <button
                      type="button"
                      className="btn btn-dark  px-2 py-1"
                      onClick={() => handleOnDeleteClick(item.id)}
                    >
                      <FaTrash className="text-white" title={t('common.delete') ||''} size={16} />
                    </button>
                  
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <div className="">
         
        <div className="d-flex align-items-center">
          <span> {t('common.showing')} {(currentPage>storylist.length)? storylist.length:currentPage } {t('common.to')}  {(props.pageSkillLimit>storylist.length)? storylist.length:props.pageSkillLimit } {t('common.of')}  {storylist.length} {t('common.entries')} </span>
      </div>
        </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}
                >
                  {" "}
                  <FaChevronLeft />
                </button>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event"  onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(storylist.length / props.pageSkillLimit))} >
                  {Math.ceil(storylist.length / props.pageSkillLimit)}
                </a>
              </li>
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(storylist.length / props.pageSkillLimit)
                      ? false
                      : true
                  }
                >
                  {" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default SucessStoryList;
