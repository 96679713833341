import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { config } from "../../../Environments";
import { FaFacebookF, FaLinkedin, FaTwitter , FaWhatsapp } from "react-icons/fa";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
const MainContent = (props:any) => {
  const data = props.data;
  const { t } = useTranslation('company');
  const { t:ct } = useTranslation('common');
  const MySwal = withReactContent(Swal);
  var isMobile = window.orientation > 1;
  let message = `${window.location.href}/tender/tenderpreview?tid=${data?.id}`;
  const handleShare = (type:string) => {
    const url = `${config.apiBase}/api/Tender/ShareTender?id=${data?.id}`;
    if (type === "FaceBook") {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?href=${encodeURIComponent(url)}&u=${encodeURIComponent(url)}`, '_blank'
      );
    }
    else if (type === "LinkedIn") {
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank'

      );
    }
    else if (type === "Twitter") {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(data.title)}`, '_blank');

    }
    else if (type === "Whatsapp") {
      MySwal.fire({
        title: 'Enter whatsapp number',
        input: 'text',
        inputPlaceholder: "+509-455-563-6507",
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
          return null;
        },
        confirmButtonText: 'Submit',
        customClass: {

          title: 'my-swal-title',
          input: 'my-swal-input',
          container: 'my-swal-container',
          popup: 'my-swal-popup',
          confirmButton: 'my-swal-confirm-button',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the selected option
          const whatsappnumber = result.value;
          // Do something with the selected option
      
          let number = whatsappnumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");
          if (isMobile) {
            let url = `whatsapp://send?phone=${number}`;
            url += `&text=${encodeURI(message)}&app_absent=0`;
            window.open(url);
          }
          else {
            let url = `https://web.whatsapp.com/send?phone=${number}`;
            url += `&text=${encodeURI(message)}&app_absent=0`;
            window.open(url);

          }
        }
      });

    }
  }
  return (
    <>
      <div className="client-description">

        <div className="card mb-4 border-0 rounded-0 p-0">
          <div className="p-2">
            <div className="card-body">
              <div className="candidate-sec">
                <div className="candidate-title mb-3">
                  {/* <p className="h3 mb-3">{t('callTender.object')}</p> */}
                  <p className="h3 mb-3">{data?.objectTitle}</p>
                </div>
                <div className="detail-content-preview">
                  <p className="fs-14 lh-lg">
                    <div dangerouslySetInnerHTML={{ __html: data?.object }}></div>
                  </p>
                </div>
              </div>
              <div className="candidate-sec">
                <div className="candidate-title mb-3">
                  {/* <p className="h3 mb-3">{t('callTender.procedure')}</p> */}
                  <p className="h3 mb-3">{data?.procedureTitle}</p>
                </div>
                <div className="detail-content-preview">
                  <p className="fs-14 lh-lg">
                    <div dangerouslySetInnerHTML={{ __html: data?.procedure }}></div>
                  </p>
                </div>
              </div>
              <div>
                <div className="candidate-title mb-3">
                  <p className="h3 mb-3  text-nowrap">{t('callTender.legalEconomic')} {t('callTender.financialAnd')} <br />{t('callTender.technicalInformation')}</p>
                </div>
              </div>
              <div className="candidate-sec">
                <div className="candidate-title mb-3">
                  <p className="h4 mb-3">{data?.participationTitle}</p>
                  {/* <p className="h4 mb-3">{t('callTender.conditionsforParticipation')}</p> */}
                </div>
                <div className="detail-content-preview">
                  <p className="fs-14 lh-lg">
                    <div dangerouslySetInnerHTML={{ __html: data?.participation }}></div>
                  </p>
                </div>
              </div>
              <div className="candidate-sec">
                <div className="candidate-title mb-3">
                  <p className="h4 mb-3">{data?.economicStandingTitle}</p>
                  {/* <p className="h4 mb-3">{t('callTender.ecnomicFinancial')}</p> */}
                </div>
                <div className="detail-content-preview">
                  <p className="fs-14 lh-lg">
                    <div dangerouslySetInnerHTML={{ __html: data?.economicStanding }}></div>
                  </p>
                </div>
              </div>
              <div className="candidate-sec">
                <div className="candidate-title mb-3">
                  <p className="h4 mb-3">{data?.technicalAbilityTitle}</p>
                  {/* <p className="h4 mb-3">{t('callTender.technicalAbility')} </p> */}
                </div>
                <div className="detail-content-preview">
                  <p className="fs-14 lh-lg">
                    <div dangerouslySetInnerHTML={{ __html: data?.technicalAbility }}></div>
                  </p>
                </div>
              </div>
              <div className="candidate-sec">
                <div className="candidate-title mb-0 mt-5">
                  <ul className="list-inline d-flex p-0 mb-0">
                    <li>
                      <p className="h5 fw-bold me-2">{ct('share')}:</p>
                    </li>
                    {
                      data?.facebookShare == true &&
                      <li><a onClick={() => { handleShare("FaceBook") }} className="tags text-decoration-none mb-0 me-2 facebook-color pointer-event"> <FaFacebookF size={18} /></a></li>
                    }
                    {
                      data?.linkedInShare == true &&
                      <li><a onClick={() => { handleShare("LinkedIn") }} className="tags text-decoration-none mb-0 me-2 linkedIn-color pointer-event"><FaLinkedin size={18} /></a></li>
                    }
                    {
                      data?.whatsappShare == true &&
                      <li><a onClick={() => { handleShare("Whatsapp") }} className="tags text-decoration-none mb-0 me-2 whatsapp-bgcolor pointer-event"><FaWhatsapp size={18} /></a></li>
                    }
                    {
                      data?.twitterShare == true &&
                      <li><a onClick={() => { handleShare("Twitter") }} className="tags text-decoration-none mb-0 me-2 twitter-color pointer-event"><FaTwitter size={18} /></a></li>
                    }

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainContent;
