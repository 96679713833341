
import { Skills } from "./Skills";

export const SkillsLayout = () => {
    return (
        <>
            <Skills />
           
        </>
    );
}