import React from "react";

const Index = () => {
  return (
    <>
      <div className="container">
        <div className="row py-50">
          <div className="" style={{ height: "600px" }}>
            <h2 className="">My Business Listing</h2>
            <p>This is my business listing page.</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
