import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const UpgradeAccountNavbar = () => {

    const { t: t } = useTranslation('plan');

    return (
        <>
            <div className='container mt-2'>
                <div className='row p-2 navbar-account-bg'>
                    <div className='col-md-12 col-12'>
                        <div className='d-flex align-items-center justify-content-around'>
                            <div className="nav-item dropdown text-uppercase">
                                <Link to="/employer-account/upgrade-my-posting-account" className='account-nav text-decoration-none'>
                               {t('heading.jobPostRDA')}
                                </Link>
                            </div>
                            <div className="nav-item dropdown text-uppercase">
                                <Link to="/employer-account/announce-plans" className='account-nav text-decoration-none'>
                                {t('heading.yourAnnounces')}
                                </Link>
                            </div>
                            <div className="nav-item dropdown text-uppercase">
                                <Link to="/employer-account/tender-plans" className='account-nav text-decoration-none'>
                                {t('heading.callForTenders')}
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Outlet />
        </>
    )
}