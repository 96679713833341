import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { FaRegFileAlt, FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePickSelect } from '../../../../../../Components';
import { config } from '../../../../../../Environments';
import { GetAxios } from '../../../../../../Shared';
import { setGlobalLoading } from '../../../../../../Store';
import { setResumeActive, setResumeAddRecord } from '../../../../../../Store/Slices/ResumeSlice';
import { CustomDateSelectControl } from '../../Common';
import TipsPopup from '../../../../Components/TipsPopup';
import { ParseHTMLTips } from '../../../../../../Components/Common/ParseTipsHTML';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';


const defaultDate = '0001-01-01T00:00:00';
export const EducationAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation('resume');

    const { id, resumeId } = useParams();

    const educations = useSelector((state: AppStore) => state.resume.educations);
    const information = useSelector((state: AppStore) => state.resume.activeEducation);
    const [isDefaultContentLoaded, setDefaultContextLoaded] = useState(false);

    const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);

    useEffect(() => {
        if (information?.startDate === defaultDate) {
            setValue({ startDate: undefined })
        }
        const startDate = !information?.startDate || information?.startDate == defaultDate ? undefined : information?.startDate;
        if ((information?.currentlyStudying && !information.degree))
            setIsSubmitEnabled(false);
        else if (information?.currentlyStudying && !startDate)
            setIsSubmitEnabled(false);
        else if (startDate && (!information?.currentlyStudying && !information?.completeDate))
            setIsSubmitEnabled(false);
        else if (startDate && information?.completeDate) {
            if (startDate >= information.completeDate)
                setIsSubmitEnabled(false);
            else
                setIsSubmitEnabled(true);
        } else {
            setIsSubmitEnabled(true);
        }
    }, [information])

    const [editorState, setEditState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const defaultValue = educations.find(s => s.id == Number(id));
        const htmlContent = defaultValue?.description;
        if (!isDefaultContentLoaded) {
            const html = convertFromHTML(htmlContent || '');
            setEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
            if (defaultValue || htmlContent) {
                setDefaultContextLoaded(true);
            }
        }
        dispatch(setResumeActive({ educations: defaultValue }));
    }, [educations, id, resumeId]);

    const setValue = (value: { [n in keyof ResumeEducation]: ResumeEducation[n] }) => {
        const info = { ...(information || {}), ...value };
        dispatch(setResumeActive({ educations: info }));
        dispatch(setResumeAddRecord({ educations: info }));
    };

    const Savebtn = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).post(config.apiBase + '/api/Resume/SaveEducation', {
            ...information,
            id: id,
            resumeId: resumeId
        }).then(s => {
            navigate('..');
            dispatch(setGlobalLoading(false));
        })
    }

    const Backbtn = () => {
        confirmAlert({
            title: t('newResumeCard.backAlertMsg.title').toString(),
            message: t('newResumeCard.backAlertMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('save').toString(),
                    onClick: Savebtn
                },
                {
                    label: t('exit').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        navigate('..');
                    }
                }
            ]
        })
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-4'>
                        <TipsPopup title="contactStep.tips" description="educTipsMdl.description"
                            tipsList={ParseHTMLTips(educationTipsBody, t)} />
                    </div>
                </div>
                <div className="mb-4">
                    <div className="dZcjzV sections-header">
                        <div className="feVKyO"> {t('educationStep.educAdd.title')}?</div>
                    </div>
                    <div className="gJaquO"> {t('educationStep.educAdd.description')}.</div>
                </div>
                <div className='col-md-6'>
                    <div className='form-float'>
                        <div className="form-floating mb-3 rounded fw-500">
                            <input type="text" className={"form-control rounded bg-field" + (information?.currentlyStudying && !information?.degree ? 'border border-danger' : '')} placeholder={t('educationStep.educAdd.fashionMerchandising').toString()} value={information?.degree} onChange={e => setValue({ degree: e.target.value })} />
                            <label className='label-float'> {t('educationStep.educAdd.degree')} </label>
                            {
                                information?.currentlyStudying && !information?.degree ?
                                    <span className='helper-text text-danger ms-3'> {t('educationStep.educAdd.degreeNeededSave')}.</span>
                                    : <span className='helper-text ms-3'>{'eg'}. {t('educationStep.educAdd.fashionMerchandising')}</span>
                            }
                        </div>
                    </div>

                </div>
                <div className='col-md-6'>
                    <div className='form-float'>
                        <div className="form-floating mb-3 rounded fw-500">
                            <input type="text" className="form-control rounded bg-field" placeholder={t('educationStep.educAdd.cityCapHaitien').toString()} value={information?.institute} onChange={e => setValue({ institute: e.target.value })} />
                            <label className='label-float'> {t('educationStep.educAdd.nameOfSchool')} </label>
                            <span className='helper-text ms-3'>{'eg'}. {t('educationStep.educAdd.cityPrince')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='form-float'>
                <div className="form-floating mb-3 rounded fw-500">
                    <input type="text" className="form-control rounded bg-field" placeholder={t('educationStep.educAdd.sanfranciscoCA').toString()} value={information?.location} onChange={e => setValue({ location: e.target.value })} />
                    <label className='label-float'> {t('educationStep.educAdd.cityStateCountry')} </label>
                    <span className='helper-text ms-3'>{'eg'}. {t('educationStep.educAdd.portHaiti')}</span>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 date-zindex'>
                    <DatePickSelect
                        yearFrom={1930}
                        yearTo={new Date().getFullYear()}
                        maxDate={new Date()}
                        minDate={new Date(1930, 1, 1)}
                        placeHolder={t('dateOfBirth').toString()}
                        selected={information?.startDate}
                        onChange={(date) => {

                            setValue({

                                startDate: date?.toISOString()
                            })
                        }}
                        customInput={<CustomDateSelectControl label={t('startDate').toString()} className={information?.currentlyStudying && (!information?.startDate || information?.startDate === defaultDate) ? 'border border-danger' : ''} />}
                    />
                    {
                        information?.currentlyStudying && (!information?.startDate || information?.startDate === defaultDate) &&
                        <label className='text-danger'>  {t('educationStep.educAdd.dateRequiredEnd')}</label>
                    }
                </div>
                <div className='col-md-6 date-zindex'>
                    <DatePickSelect
                        disabled={information?.currentlyStudying}
                        yearFrom={1930}
                        yearTo={new Date().getFullYear()}
                        minDate={new Date(1930, 1, 1)}
                        maxDate={new Date()}
                        placeHolder={t('dateOfBirth').toString()}
                        selected={information?.completeDate}
                        onChange={(date) => { date && setValue({ completeDate: date?.toISOString() }) }}
                        customInput={<CustomDateSelectControl label={information?.currentlyStudying ? '-' + t('present').toString() : t('graduationDate').toString()} placeHolder={'Date'} className={(!information?.currentlyStudying && information?.startDate) && !information.completeDate ? 'border border-danger' : ''} />}
                    />
                    {
                        (!information?.currentlyStudying && information?.startDate) && !information.completeDate &&
                        <label className='text-danger'>  {t('educationStep.educAdd.dateRequiredStart')} </label>
                    }
                    <label>
                        <input type='checkbox' className="me-2" checked={information?.currentlyStudying} onChange={e => {
                            if (e.target.checked) {
                                setValue({ completeDate: undefined, currentlyStudying: e.target.checked })
                            } else {
                                setValue({ currentlyStudying: e.target.checked });
                            }
                        }} /> {t('educationStep.educAdd.graduatedYet')}
                    </label>
                </div>
            </div>
            <div className='col-md-12 mb-2'>
                <div className="gQiRPX">
                    <div className="mb-2 sections-header">
                        <div className="dJqmzX">
                            <div className="hfzZao">
                                <div className="czFa-Df fw-16 fw-600"> {t('educationStep.educAdd.descriptionAchievements')} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Editor
                    editorState={editorState}
                    editorClassName="editor-height"
                    stripPastedStyles={true}
                    toolbar={{
                        options: ['inline', 'textAlign', 'list'],
                        inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough',] },
                        textAlign: { inDropdown: false, options: ['left', 'center', 'right'] },
                        list: { inDropdown: false, options: ['unordered', 'ordered'], },
                    }}
                    onEditorStateChange={e => {
                        setEditState(e);
                        setValue({ description: draftToHtml(convertToRaw(e.getCurrentContent())) });
                    }}
                />
                <p className='helper-text ms-3 text-level'>{'eg'}. {t('educationStep.educAdd.presentedAward')}.</p>
            </div>
            <div className="bottom-btn-sticky">
                <div className='next-previous-btns d-flex align-items-center justify-content-between'>
                    <div className=''>
                        <Button className="fw-500 previous-btn bg-back" onClick={Backbtn}>
                            <FaArrowLeft className="me-2" /> {t('back')}
                        </Button>
                    </div>
                    <div className=''>
                        <Button className="save-btn-resume fw-500"
                            disabled={!isSubmitEnabled}
                            onClick={Savebtn}
                        ><FaRegFileAlt className="me-1" size={20} /> {t('save')} </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

const educationTipsBody = {
    "tipsMdlTitle": [
        "educTipsMdl.tipsList1",
        "educTipsMdl.tipsList2",
    ],
    "educTipsMdl.titleExample": ["educTipsMdl.exampleList1",
        "educTipsMdl.exampleList2",
        "educTipsMdl.exampleList3",
        "educTipsMdl.exampleList4",
    ],
}