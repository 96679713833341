import React from 'react';
import { useTranslation } from 'react-i18next';

const Sidebar = (props:{title:string}) => {

    const { t } = useTranslation('candidate');

    return (
        <>
            <div className='sidebar-skill-widget'>
                <div className='ribbon-widget'>
                    <div className='ribbon-title'>
                        <span>{props.title}</span>
                    </div>
                    <div className='pb-3 mb-3'>
                        <ul className='fs-14 ms-2 mb-2'>
                            <li> {t('skill.matcher.60quickQuestion')}</li>
                            <li> {t('skill.matcher.5minutes')}</li>
                            <li> {t('skill.matcher.aboutCareers')}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Sidebar;