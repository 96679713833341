import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay, Pagination } from "swiper";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setShowModel } from "../../Store";
import { Link } from "react-router-dom";


export const CustomSliderCard = styled.div`
position: relative;
    background-color: rgba(156,178,220,.2);
    padding: 24px;
    display: block;
    text-align: center;
    border-radius: 20px;
    transition: background-color .2s;
`
export const TemplateResumeImg = styled.img`
box-shadow: 0 4px 8px rgba(0,0,0,.1);
border-radius: 8px;
color: transparent;
width: 100%;
height: auto;
`

export const StartTempBtn = styled.div`
text-align: center;
    transform: scale(0.9);
    opacity: 0;
    position: absolute;
    bottom: 30px;
    left: 0%;
    
`

const TemplateSlider_HeroSection = () => {
    const { t } = useTranslation('home');

    const dispatch = useDispatch();
    const auth = useSelector((state: AppStore) => state.auth);

    const templateResume = [
        {
            id: 1,
            img: "/images/resume-img/template-images/montvideo-template.png",
        },
        {
            id: 2,
            img: "/images/resume-img/template-images/newyork-template.png",
        },
        {
            id: 3,
            img: "/images/resume-img/template-images/Omsk-template.png",
        },
        {
            id: 4,
            img: "/images/resume-img/template-images/johannesburg-template.png",
        },
    ];
    const paginationStyle = {
        marginTop: '260px !important',
    };
    const params = {
        slidesPerView: 4,
        spaceBetween: 2,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            style: paginationStyle,
        },
        autoplay: {
            delay: 7800,
            disableOnInteraction: false,
        },
        modules: [Autoplay, Navigation, Pagination],
        className: "mySwiper px-3",
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            410: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            510: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1800: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
        },
    };



    return (
        <>
            <div className="resume-template-slider-sec mb-3">
                <Swiper {...params} style={{ height: '0px !important' }}>
                    {templateResume.map((item, index) => (
                        <SwiperSlide key={index}>
                            <CustomSliderCard>
                                <div className="img-cont">
                                    <TemplateResumeImg src={item.img} className="template-img" alt="resume template image" />
                                    <div className="overlay-temp overlay-resume-slide"></div>
                                    <StartTempBtn className="w-100 start-temp-btn-SD01" >
                                        {!auth.isAuthenticated ?
                                            <>
                                                <Link to={''} onClick={e => dispatch(setShowModel(true))}
                                                    className="btn text-decoration-none text-white rounded-3 fw-600 btn-template text-nowrap text-capitalize animate__animated animate__fadeInUpBig">
                                                    {t('startWithThisTemplate')}
                                                </Link>
                                            </>
                                            :
                                            <Link to={'/resumes/builder/templates/50'}
                                                className="btn text-decoration-none text-white rounded-3 fw-600 btn-template text-nowrap text-capitalize animate__animated animate__fadeInUpBig">
                                                {t('startWithThisTemplate')}
                                            </Link>
                                        }
                                    </StartTempBtn>
                                </div>
                            </CustomSliderCard>
                        </SwiperSlide>
                    ))}

                    <div className="swiper-button-next swiper-btn-custom-CD01"></div>
                    <div className="swiper-button-prev swiper-btn-custom-CD01"></div>
                    <div className="swiper-pagination"></div>
                </Swiper>
            </div>
        </>
    );
};
export default TemplateSlider_HeroSection;
