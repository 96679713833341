import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowModel } from "./Store";
import { parseJwt } from "./Shared";
import { toast } from "react-toastify";
import HomeIndex from "./Pages/home/Index";
import { AdminLogin } from "./Pages/Admin/AdminLogin";
import { useTranslation } from "react-i18next";

export const PrivateRoute = (props: {
  children: JSX.Element;
  role: string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t: ts } = useTranslation("alert");
  const auth = useSelector((state: AppStore) => state.auth);

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      dispatch(setShowModel(true));
    }
  }, [!auth.isLoading && !auth.isAuthenticated && !auth.raw?.access_token]);

  if (!auth.isLoading && !auth.isAuthenticated && !auth.raw?.access_token) {
    localStorage.setItem(
      "isPreviousPath",
      window.location.pathname + window.location.search
    );
    // localStorage.setItem("isPreviousPathQuery", window.location.search);
    if (window.location.pathname.includes("admin")) {
      navigate("/admin");
    } else {
      navigate("/");
      dispatch(setShowModel(true));
    }
  }
  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.isAuthenticated && !auth.isLoading) {
    const parsedClaims = parseJwt(auth.raw?.access_token || ".");
    const userRole = parsedClaims?.role || "";
    if (props.role == "All") {
      return props.children;
    } else if (userRole != props.role && userRole != "") {
      toast(
        props.role == "Admin"
          ? ts("error.accessAdmin")
          : props.role == "Employer"
          ? ts("error.accessEmployer")
          : ts("error.accessEmployee"),
        { type: "error", autoClose: 25000 }
      );

      if (props.role == "Admin") {
        navigate("/admin");
        return <AdminLogin />;
      } else {
        navigate("/");
        return <HomeIndex />;
      }
    } else {
      return props.children;
    }
  } else {
    return <div>Loading...</div>;
  }
};
