import React, { useState } from "react";
import { CardTitle } from "../Tenders/Index";
import { FaPlayCircle } from "react-icons/fa";
import { DatePickSelect } from "../../../Components";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { setGlobalLoading } from "../../../Store";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from 'react-router-dom';
import { useFormik  } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
export const AddEditStories = () => {
    const storySchema = Yup.object().shape({
     
          
           
        personTitle: Yup.string().required('Required'),
        personName: Yup.string().required('Required'),
        story: Yup.string().required('Required'),
        status: Yup.string().required('Required'),
      });

     
  const dispatch = useDispatch();
  const { t } = useTranslation('admin');
  const { t:ts } = useTranslation('alert');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();


  const formik = useFormik({
    initialValues: {
      id: 0,
      personName: "",
      personTitle: "",
      story : "",
      status:"",
    },
    validationSchema: storySchema ,
    onSubmit: values => {
      GetAxios().post(config.apiBase + '/api/Admin/SaveSucessStory', values).then(res => {
        if (res.data.success) {
          formik.setFieldValue('id', res.data.data);
          navigate({
            pathname: window.location.pathname,
            search: '?' + new URLSearchParams({ storyId: res.data.data }).toString(),
          });
          //params.push()
          toast(res.data.message, { type: 'success' });
        } else {
          console.warn(res);
          toast(ts('error.unsaveStory'), { type: 'error' });
        }
      }).catch(err => {
        console.error(err);
        toast(ts('error.unsaveStory'), { type: 'error' });
      });
    },
  });
  React.useEffect(() => {
    if (searchParams.get('storyId')) {
      dispatch(setGlobalLoading(true));
      GetAxios().get(config.apiBase + '/api/Admin/GetEditStory?storyId=' + searchParams.get('storyId')).then(res => {
        if (!res.data.success) {
          dispatch(setGlobalLoading(false));
          toast(ts('error.ungetStory'), { type: 'error' });
        }
        else {
         
          formik.setValues(res.data.data);
          dispatch(setGlobalLoading(false));
        }
      });
    }
  }, [searchParams]);  

    return (
        <>
            <div className="container py-50">
                <div className="card custom-shadow">
                    <div className="card-header">
                        <CardTitle>{t('sucessstories.addEdit')}</CardTitle>
                    </div>
                    <div className="card-body px-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-detail pt-1">
                                    <div className="row my-3">
                                        <div className="col-md-6 col-12 mb-12">
                                            <div className='line-height-sta'>
                                                <label className="form-label key-weight" htmlFor="personName">  {t('sucessstories.personName')}:</label>
                                            </div>
                                            <input type="text" name='personName' className="form-control" id="personName" onChange={formik.handleChange}
                                                value={formik.values.personName} />
                                                 {formik.errors.personName && formik.touched.personName ? <small className="text-danger">{formik.errors.personName}</small> : ""}
                            
                                          
                                        </div>
                                        <div className="col-md-6 col-12 mb-12">
                                                <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="personTitle">  {t('sucessstories.personTitle')}: </label>
                                                </div>
                                                <input type="text" name='personTitle' className="form-control" id="personTitle" onChange={formik.handleChange}
                                                value={formik.values.personTitle} />
                                                 {formik.errors.personTitle && formik.touched.personTitle ? <small className="text-danger">{formik.errors.personTitle}</small> : ""}
                              
                                       </div>
                                        {/* <div className="col-md-6 col-12 mb-12">
                                                <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="receivedAmount"> Created Date UTC: </label>
                                                </div>
                                                <DatePickSelect
                                                yearFrom={1960}
                                                yearTo={new Date().getFullYear()}
                                                maxDate={new Date()}
                                                minDate={new Date(1960, 1, 1)}
                                                placeHolder="" selected={undefined} onChange={function (date: Date | null): void {
                                                    throw new Error("Function not implemented.");
                                                } }                                                />
                                        </div> */}
                                        <div className="col-md-6 col-12 mb-12">
                                               <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="status">  {t('common.status')}: </label>
                                                </div> 
                            
                                      
                                                <div className='d-flex align-items-center mt-1'>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" checked={formik.values.status === "Active" ? true : false} type="radio" name="status" onChange={formik.handleChange} id="inlineCheckbox1" value="Active" />
                                                    <label className="form-check-label key-weight" htmlFor="inlineCheckbox1"> {t('common.active')}</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" checked={formik.values.status === "Inactive" ? true : false} type="radio"name="status" onChange={formik.handleChange}  id="inlineCheckbox2" value="Inactive" />
                                                    <label className="form-check-label key-weight" htmlFor="inlineCheckbox2"> {t('common.inactive')}</label>
                                                </div>
                                                {formik.errors.status && formik.touched.status ? <small className="text-danger">{formik.errors.status}</small> : ""}
                      
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12 mb-12">
                                            <div className='line-height-sta'>
                                                <label className="form-label key-weight" htmlFor="story"> {t('sucessstories.story')}: </label>
                                            </div>
                                            <textarea className="form-control" name="story" rows={4} onChange={formik.handleChange} value={formik.values.story} />
            
                                        </div>
                                    </div>
                                    <div className="text-start my-3 d-flex aligm-items-center">
                                             
                                        <button type="submit" onClick={() => formik.handleSubmit()} className="border-0 d-flex align-items-center p-2 px-4 btn-contine text-white fw-600 text-capitalize">
                                        {t('common.save')}<FaPlayCircle className='fa-lg ms-1' />

                                        </button>
                                        {
                                            searchParams.get('storyId') && Number(searchParams.get('storyId')) > 0 &&
                                            <Link to={"/admin/storypreview?" + searchParams.toString()} target='_blank' className="border-0 text-decoration-none ms-2 btn-preview-register text-white fw-600 text-capitalize"> {t('common.preview')}</Link>
                                         }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}