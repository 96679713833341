import React, { useState } from 'react';
import SideContent from './SideContent';
import MainContent from './MainContent';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { GetAxios } from '../../../Shared';
import { config } from '../../../Environments';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { setGlobalLoading } from "../../../Store";
import { useDispatch } from "react-redux";
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import { confirmAlert } from "react-confirm-alert";

const Index = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const { t } = useTranslation('company');
  const [dropDown, setDropDown] = useState({});
  const [objEditorState, setObjState] = useState("");
  const [procEditorState, setProcState] = useState("");
  const [partEditorState, setPartState] = useState("");
  const [techEditorState, setTechState] = useState("");
  const [economicEditorState, setEconomicState] = useState("");
  const { t: ts } = useTranslation('alert');
  const MySwal = withReactContent(Swal);
  const resetEditors = () => {
    setEconomicState(""); setObjState("");
    setProcState(""); setTechState(""); setPartState("");
  }
  const TenderSchema = Yup.object().shape({

    title: Yup.string().required(ts('account.required')),
    companyName: Yup.string().required(ts('account.required')),
    address: Yup.string().required(ts('account.required')),
    departmentIds: Yup.array().min(1, ts('account.atLeastOne')),
    sectorId: Yup.string().required(ts('account.required')),
    classificationId: Yup.string().required(ts('account.required')),
    recurrence: Yup.string().required(ts('account.required')),
    dispatchDate: Yup.string().required(ts('account.required')),
    openingDate: Yup.string().required(ts('account.required')),
    expireDate: Yup.string().required(ts('account.required')),
    object: Yup.string().required(ts('account.required')),
    procedure: Yup.string().required(ts('account.required')),
    participation: Yup.string().required(ts('account.required')),
    economicStanding: Yup.string().required(ts('account.required')),
    technicalAbility: Yup.string().required(ts('account.required')),
    objectTitle: Yup.string().required(ts('account.required')),
    procedureTitle: Yup.string().required(ts('account.required')),
    participationTitle: Yup.string().required(ts('account.required')),
    economicStandingTitle: Yup.string().required(ts('account.required')),
    technicalAbilityTitle: Yup.string().required(ts('account.required')),
    lang: Yup.string().required(ts('account.required'))
  });
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      id: 0,
      title: "",
      companyName: "",
      address: "",
      city: "",
      departmentIds: [],
      email: "",
      tel: "",
      contactPerson: "",
      sectorId: "",
      classificationId: "",
      recurrence: "Yes",
      dispatchDate: "",
      openingDate: "",
      expireDate: "",
      hour: "00",
      min: "00",
      object: "",
      procedure: "",
      participation: "",
      economicStanding: "",
      technicalAbility: "",
      objectTitle: "",
      procedureTitle: "",
      participationTitle: "",
      economicStandingTitle: "",
      technicalAbilityTitle: "",
      planId: 0,
      isAdmin: props.isAdmin || false,
      lang: i18n.language,
    },
    validationSchema: TenderSchema,
    onSubmit: (values, { resetForm }) => {
        if (props.checkEligible && props.checkEligible == 1 && !searchParams.get('tid')) {
          dispatch(setGlobalLoading(true));
          GetAxios().get(config.apiBase + '/api/Tender/CheckMultipleActivePlanExist?lang='+i18n.language).then(res => {
            if (res.data.data.havePlan) {
              if (res.data.data.isMultiple) {
                dispatch(setGlobalLoading(false));
    
                const inputOptions = new Map
                res.data.data.plans.forEach(item => inputOptions.set(item.planId, item.planTitle))            
                  MySwal.fire({
                    title: t('plan.chooseTitle').toString(),
                    input: 'radio',
                    // inputOptions: {
                    //   option1: '1 Day Plan',
                    //   option2: '30 Days Plan',
                    //   option3: '10 Days Plan',
                    // },
                    inputOptions: inputOptions,
                    inputValidator: (value) => {
                      // Validate the selected option if needed
                      if (!value) {
                        return t('plan.mustChoose').toString();
                      }
                    },
                    //showCancelButton: true,
                    confirmButtonText: t('plan.submit').toString(),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      const selectedOption = result.value;
                      formik.setFieldValue('planId', result.value);
                      values.planId=result.value;
                  
                      SaveTenderData(values)
                    }
                  });
              }
              else {
                dispatch(setGlobalLoading(false));
                const singleplan=res.data.data.plans[0].planId;
                formik.setFieldValue('planId', singleplan);
                values.planId=singleplan;
                SaveTenderData(values)
              }
            }
            else{
              SaveTenderData(values);
            }
          });
        }
        else {
          SaveTenderData(values)
        }
      },
    });


  const SaveTenderData = (values) => {
    if (loading) {
      toast(ts('account.pleaseWait'), { type: 'info' });
    }
    setLoading(true);

    GetAxios().post(config.apiBase + '/api/Tender/SaveTender', values).then(res => {
      setLoading(false);
      if (res.data.success) {

        formik.setFieldValue('id', res.data.data);
        toast(res.data.message, { type: 'success' });
        if (props.isAdmin) {
          navigate('/admin/tenders');
        }
        else if ((window.location.pathname.includes("employer-account"))) {
          formik.resetForm();
          resetEditors();
          props.redirect('adslist');
        }
        else {
          navigate('/employer-account');
        }
      } else {
        console.warn(res);
        toast(ts('error.unsaveTender'), { type: 'error' });
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      toast(ts('error.unsaveTender'), { type: 'error' });
    });


  }

  const confirmSubmit = () => {
    confirmAlert({
      title: t('save.areYouSure').toString(),
      message: t('save.sureSave').toString(),
      buttons: [
        {
          label: t('save.yes').toString(),
          onClick: () => {
            formik.handleSubmit();
          },
        },
        {
          label: t('save.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }


  React.useEffect(() => {

    if (searchParams.get('tid')) {
      dispatch(setGlobalLoading(true));
      GetAxios().get(config.apiBase + '/api/Tender/GetEditTender?tid=' + searchParams.get('tid')).then(res => {
        if (!res.data.success) {
          dispatch(setGlobalLoading(false));
          toast(ts('error.ungetTender'), { type: 'error' });
        }
        else {

          if (res.data.data.object) {
            const html = convertFromHTML(res.data.data.object || '');
            setObjState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
          }
          if (res.data.data.procedure) {

            const html = convertFromHTML(res.data.data.procedure || '');
            setProcState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
          }
          if (res.data.data.participation) {
            const html = convertFromHTML(res.data.data.participation || '');
            setPartState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))

          }
          if (res.data.data.technicalAbility) {
            const html = convertFromHTML(res.data.data.technicalAbility || '');
            setTechState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))

          }
          if (res.data.data.economicStanding) {
            const html = convertFromHTML(res.data.data.economicStanding || '');
            setEconomicState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
          }
          formik.setValues(res.data.data);
          dispatch(setGlobalLoading(false));
        }
      });
    }
    else {

    }
  }, [searchParams]);


  React.useEffect(() => {
    formik.setFieldValue('lang', i18n.language);
  }, [i18n.language]);
  React.useEffect(() => {
    if (searchParams.get('tid')) {
      GetAxios().post(config.apiBase + '/api/General/GetListBySystemType',
        {
          lang: i18n.language,
          types: ['Sectors']
        }
      ).then(res => {
        setDropDown(res.data);
      });
    }
    else {
      GetAxios().post(config.apiBase + '/api/General/GetListBySystemType',
        {
          lang: i18n.language,
          types: ['Sectors']
        }
      ).then(res => {
        setDropDown(res.data);
      });
    }

  }, [i18n.language]);

  return (
    <>
      <div className="container">
        <div className="row py-3">
          <div className="col-md-4 col-sm-12 ps-lg-0">
            <SideContent formik={formik} dropDown={dropDown} isAdmin={props.isAdmin} />
          </div>
          <div className="col-md-8 col-sm-12 pe-lg-0">
            <MainContent formik={formik} objEditorState={objEditorState} setObjState={setObjState} procEditorState={procEditorState} setProcState={setProcState}
              partEditorState={partEditorState} setPartState={setPartState} techEditorState={techEditorState} setTechState={setTechState} economicEditorState={economicEditorState} setEconomicState={setEconomicState} isAdmin={props.isAdmin}
              confirmSubmit={confirmSubmit} loading={loading} setLoading={setLoading} />
          </div>

        </div>
        <div>
        </div>
      </div>
    </>
  )
}
export default Index;


