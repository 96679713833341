import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import auth from './locales/en/auth.json';
import common from './locales/en/common.json';
import home from './locales/en/home.json';
import job from './locales/en/job.json';
import resume from './locales/en/resume.json';
import footer from './locales/en/footer.json';
import seminar from './locales/en/seminar.json';
import contact from './locales/en/contact.json';
import header from './locales/en/header.json';
import candidate from './locales/en/candidate.json';
import company from './locales/en/company.json';
import sendFeedback from './locales/en/sendFeedback.json';
import alert from './locales/en/alert.json';
import admin from './locales/en/admin.json';
import plan from './locales/en/plan.json';

import auth_sp from './locales/sp/auth.json';
import common_sp from './locales/sp/common.json';
import home_sp from './locales/sp/home.json';
import job_sp from './locales/sp/job.json';
import resume_sp from './locales/sp/resume.json';
import footer_sp from './locales/sp/footer.json';
import seminar_sp from './locales/sp/seminar.json';
import contact_sp from './locales/sp/contact.json';
import header_sp from './locales/sp/header.json';
import candidate_sp from './locales/sp/candidate.json';
import company_sp from './locales/sp/company.json';
import sendFeedback_sp from './locales/sp/sendFeedback.json';
import alert_sp from './locales/sp/alert.json';
import admin_sp from './locales/sp/admin.json';
import plan_sp from './locales/sp/plan.json';

export const defaultNS = 'common';

const en = {
    auth,
    common,
    home,
    job,
    resume,
    footer,
    seminar,
    contact,
    header,
    candidate,
    company,
    sendFeedback,
    alert,
    admin,
    plan
};

const sp: typeof en = {
    auth: auth_sp,
    common: common_sp,
    home: home_sp,
    job: job_sp,
    resume: resume_sp,
    footer: footer_sp,
    seminar: seminar_sp,
    contact: contact_sp,
    header: header_sp,
    candidate: candidate_sp,
    company: company_sp,
    sendFeedback: sendFeedback_sp,
    alert: alert_sp,
    admin:admin_sp,
    plan:plan_sp
};

export const resources = {
    sp,
    en
} as const;

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        defaultNS,
        ns: 'common',
        resources,
        fallbackLng: 'en',
        lng: 'en', // default language
        debug: false,
        detection: {
            lookupQuerystring: 'lang',
            lookupFromPathIndex: 0,
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
