import { config } from '../../../Environments';
import { GetAxios, LabelValuePair } from '../../../Shared';
import * as Yup from "yup";
import { DatePickSelect } from '../../../Components';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { FaPlayCircle } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setGlobalLoading } from "../../../Store";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import TipExamplesPopup from '../TipExamplesPopup';

export const AnnounceRegister = (props: {
  isAdmin: boolean, checkEligible: number,redirect:any
}) => {
  const { t: ts } = useTranslation('alert');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showTips, setShowTips] = useState(false);
  const MySwal = withReactContent(Swal);

  const seminarSchema = Yup.object().shape({


    name: Yup.string().required(ts('account.required') || 'Required'),
    departmentIds: Yup.array().min(1, ts('account.atLeastOne') || 'At least one'),
    // department: Yup.string().required(ts('account.required') || 'Required'),
    phoneNumber: Yup.string().required(ts('account.required') || 'Required'),
    announceDate: Yup.string().required(ts('account.required') || 'Required'),
    banner: Yup.string().required(ts('account.required') || 'Required'),
  });
  const { i18n } = useTranslation();


  ///First Check Plan 
  // React.useEffect(() => {

  //   if (props.checkEligible && props.checkEligible == 1) {
  //     // Check Eligibilty for seminar registeration
  //     dispatch(setGlobalLoading(true));
  //     GetAxios().get(config.apiBase + '/api/Announce/CheckEligibleforRegister?lang=' + i18n.language).then(res => {
  //       if (!res.data.data) {
  //         dispatch(setGlobalLoading(false));
  //         toast(res.data.message, { type: 'error' });
  //         navigate("/employer-account/announce-plans", { replace: true });
  //       }
  //       else {
  //         // GetAxios().get(config.apiBase + '/api/Announce/CheckEligibleforTipExamples').then(res => {
  //         //   setShowTips(res.data);
  //         // });
  //         dispatch(setGlobalLoading(false));
  //       }
  //     });


  //   }
  // }, [props.checkEligible]);

  const { t } = useTranslation('company');
  const { t: ct } = useTranslation('common');

  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(ct('selectSeminar'));
  const [fileName, setFileName] = useState(ct('nofileChosen'));

  React.useEffect(() => {
    setFileName(ct('nofileChosen'))
    setFileError(ct('selectSeminar'))
  }, [i18n.language]);

  const [departments, setDepartments] = useState<LabelValuePair[]>([]);
  const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon"
  ];

  function validFileType(file: any) {
    return fileTypes.includes(file.type);
  }

  React.useEffect(() => {
    GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
      setDepartments(res.data);
    });
  }, []);

  interface MyFormValues {

    name: string,
    banner: any,
    departmentIds: number[],
    phoneNumber: string,
    announceDate: string,
    isAdmin: boolean,
    planId: number
  }


  const formik = useFormik<MyFormValues>({
    initialValues: {
      name: "",
      //department: """
      departmentIds: [],
      phoneNumber: "",
      announceDate: "",
      banner: null,
      planId: 0,
      isAdmin: props.isAdmin || false,
    },
    validationSchema: seminarSchema,
    onSubmit: (values, { resetForm }) => {
      if (props.checkEligible && props.checkEligible == 1) {
        dispatch(setGlobalLoading(true));
        GetAxios().get(config.apiBase + '/api/Announce/CheckMultipleActivePlanExist?lang=' + i18n.language).then(res => {
          if (res.data.data.havePlan) {
            if (res.data.data.isMultiple) {
              dispatch(setGlobalLoading(false));

              const inputOptions = new Map
              res.data.data.plans.forEach((item: any) => inputOptions.set(item.planId, item.planTitle))
              MySwal.fire({
                title: t('plan.chooseTitle').toString(),
                input: 'radio',
                inputOptions: inputOptions,
                inputValidator: (value) => {
                  // Validate the selected option if needed
                  if (!value) {
                    return t('plan.mustChoose').toString();
                  }
                  return null;
                },
                confirmButtonText: t('plan.submit').toString(),
              }).then((result) => {
                if (result.isConfirmed) {
                  const selectedOption = result.value;
                  formik.setFieldValue('planId', result.value);
                  values.planId = result.value;

                  SaveSeminarData(values)
                }
              });
            }
            else {
              dispatch(setGlobalLoading(false));
              const singleplan = res.data.data.plans[0].planId;
              formik.setFieldValue('planId', singleplan);
              values.planId = singleplan;
              SaveSeminarData(values)
            }
          }
          else {
            SaveSeminarData(values);
          }
        });
      }
      else {
        SaveSeminarData(values)
      }
    },
  });
  const SaveSeminarData = (values: any) => {
    if (loading) {
      toast(ts('account.pleaseWait'), { type: 'info' });
    }
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === 'departmentIds') {
        values[key].forEach((id: any, index: any) => {
          formData.append(`${key}[${index}]`, id);
        });
      } else {
        formData.append(key, values[key]);
      }
    });
    // const formData = new FormData();
    // Object.keys(values).map(s => {
    //   formData.append(s, (values)[s]);
    // });
    GetAxios().post(config.apiBase + '/api/Announce/CreateNewAnnounce', formData).then(response => {
      if (response.data.success) {
        toast(ts('seminar.create'), { type: 'success' });    
        if (props.isAdmin) {
          navigate('/admin/announces');
        }
        else if ((window.location.pathname.includes("employer-account"))) {
          formik.resetForm();
          setFileName(ct('nofileChosen'));
          props.redirect('adslist');
        }
        else {
          navigate('/employer-account');
        }

      } else {
        if (response.data.errors) {
          alert(response.data.errors.map((v: any) => v.description));
        } else {
          toast(ts('error.requestSubmit'), { type: 'info' });
        }
      }
      setLoading(false);
    }).catch(err => {
      console.error(err);
      if (err.status >= 500) {
        toast(ts('error.internalServer'), { type: 'error' });
      }
    });
  }
  return (
    <>
      <div className="card">
        <div className="card-body pt-4">
          <div className="container px-lg-5 px-md-5 px-sm-4">
            <h2 className="mt-4 fw-700"> {t('seminarTab.seminarUploadForm')}</h2>
            <div className="row">
              <div className="col-12 px-lg-5 px-md-4 px-sm-2 seminar-bg-form">
                <div className="card my-3">
                  <div className="card-body">
                    <div className="form-flyer p-lg-2 p-md-2 p-sm-2">
                      <div className="heading-flyer">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="fw-600 mt-0"> {t('seminarTab.submitA')} <span className="text-uppercase fw-700"> {t('seminarTab.flyer')}</span></h2>
                          {/* {
                            showTips==true&&
                            <TipExamplesPopup />
                          } */}

                        </div>
                      </div>
                      <div className="border border-bottom"></div>

                      <div className="form-detail pt-1">
                        <div className="row my-3">
                          <h6 className="fw-700"> {t('seminarTab.informationAboutSeminar')} </h6>
                          <div className="col-md-6 col-12">
                            <input type="text" name="name" onChange={formik.handleChange} value={formik.values.name} className="form-control " id="name" />

                            <label htmlFor="name" className="form-label text-gray fw-500"> {t('seminarTab.nameOfSeminar')} </label>
                            {formik.errors.name && formik.touched.name ? <small className='text-danger d-block'>{formik.errors.name}</small> : ""}

                          </div>
                          <div className="col-md-6 col-12">
                            <DatePickSelect
                              yearFrom={2023}
                              yearTo={2030}
                              minDate={new Date()}
                              maxDate={new Date(2030, 12, 31)}
                              placeHolder={t('seminarTab.dateOfSeminar').toString()}
                              selected={formik.values.announceDate}
                              onChange={(date) => {

                                formik.setFieldValue("announceDate", date?.toLocaleString())
                              }
                              }
                            />

                            {formik.errors.announceDate && formik.touched.announceDate ? <small className='text-danger d-block'>{formik.errors.announceDate}</small> : ""}

                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700"> {t('seminarTab.telephone')} </h6>
                            <input type="tel" name='phoneNumber' onChange={formik.handleChange} value={formik.values.phoneNumber} className="form-control " id="phoneNumber" />
                            {formik.errors.phoneNumber && formik.touched.phoneNumber ? <small className='text-danger d-block'>{formik.errors.phoneNumber}</small> : ""}

                          </div>
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700"> {ct('department')}</h6>
                            <Select
                              onChange={(e) => {
                                formik.setFieldValue("departmentIds", Array.isArray(e) ? e.map((x: any) => x.value) : [])

                              }}
                              placeholder={ct('pleaseSelect').toString()}
                              value={departments.filter(obj => formik.values.departmentIds.includes(obj.value))} // set selected values
                              className="basic-multi-select text-capitalize"
                              isMulti
                              defaultValue={departments[1]}
                              options={departments}

                            />
                            {formik.errors.departmentIds && formik.touched.departmentIds ? <small className="text-danger">{formik.errors.departmentIds}</small> : ""}

                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700"> {t('seminarTab.uploadFlyer')} </h6>
                            <div className="custom-file-button d-flex justify-content-center">
                              <label className="custom-label2-files" htmlFor="raised-button-file">
                                <span className="custom-file-button">
                                  {ct('chooseFile')}
                                </span>
                              </label>
                              <input className="form-control " type="file" style={{ display: 'none' }} id="raised-button-file"
                                accept=".jpg, .jpeg, .png"
                                multiple
                                onChange={e => {
                                  if (e.target.files && e.target.files[0]) {
                                    if (validFileType(e.target.files[0])) {
                                      formik.setFieldValue('banner', e.target.files[0]);
                                      setFileName(e.target.files[0].name);
                                    }
                                    else {
                                      formik.setFieldError('banner', ts('resume.inValidFile') || 'Not a valid file type');
                                      setFileError(ts('resume.inValidFile') || 'Not a valid file type')
                                    }
                                  }

                                }} />
                              <input type="text" className="form-control" value={fileName || ''} />

                            </div>

                            {formik.errors.banner && formik.touched.banner ? <small className='text-danger d-block'>{fileError}</small> : ""}

                          </div>
                        </div>
                        <div className="text-start my-3">
                          <button disabled={loading} type="submit" onClick={formik.handleSubmit as any} className="border-0  p-btn btn-contine text-white fw-600 text-uppercase">
                            {
                              loading ? <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="18"
                                visible={true}
                              /> : <>
                                {ct('submit')} <FaPlayCircle className='fa-lg ms-1' />
                              </>
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
