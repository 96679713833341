import React, { useState } from 'react';
import SideContent from './SideContent';
import MainContent from './MainContent';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { GetAxios, UserRole } from '../../../Shared';
import { config } from '../../../Environments';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setGlobalLoading } from "../../../Store";
import { useDispatch } from "react-redux";
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from "react-confirm-alert";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

/* const JobDetailoldschema = Yup.object().shape({
 
  ageFrom:Yup.string().required(ts('account.required')),
  ageTo: Yup.number().when('ageFrom', {
    is: '',
    then: Yup.number().min(1),
    otherwise: Yup.number().moreThan(Yup.ref('ageFrom'), ts('account.tooLess')),
  }).required(ts('account.required')), 
  experienceFrom: Yup.number().required(ts('account.required')),
  experienceTo: Yup.number().when('experienceFrom', {
    is: '',
    then: Yup.number().min(1),
    otherwise: Yup.number().min(Yup.ref('experienceFrom'), ts('account.tooLess')),
  }).required(ts('account.required')),
  salaryTo: Yup.number().when('salaryFrom', {
    is: '',
    then: Yup.number().min(1),
    otherwise: Yup.number().min(Yup.ref('salaryFrom'), ts('account.required')),
  }).required(ts('account.required')),
  )
}).['ageFrom', 'ageTo', 'experienceFrom', 'experienceTo', 'salaryFrom', 'salaryTo']);*/

const Index = (props:{checkEligible:number , isAdmin:boolean , redirect:any
}) => {
  const { t: ts } = useTranslation('alert');
  const [dropDown, setDropDown] = useState({});
  const blocksFromHTML = convertFromHTML('');
  const [editorState, setEditState] = useState<EditorState>(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML.contentBlocks,blocksFromHTML.entityMap)));
  const [jobEditorState, setjobEditState] = useState<EditorState>(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML.contentBlocks,blocksFromHTML.entityMap)));
  const [salaryError, setSalaryError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  //const [expError, setExpError] = useState(false);
  const { t } = useTranslation('company');
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const resetEditors = () => {
    setEditState(EditorState.createEmpty()); setjobEditState(EditorState.createEmpty());
    setSalaryError(false); setAgeError(false);
  }
  const JobDetailschema = Yup.object().shape({
    title: Yup.string().required(ts('account.required') || 'Required'),
    companyName: Yup.string().required(ts('account.required')|| 'Required'),
    description: Yup.string().required(ts('account.required')|| 'Required'),
    sectorId: Yup.string().required(ts('account.required')|| 'Required'),
    //  departmentId: Yup.string().required(ts('account.required')),
    departmentIds: Yup.array().min(1, ts('account.atLeastOne') || 'At least select one'),
    expiryDate: Yup.string().required(ts('account.required')|| 'Required'),
    workExperienceId: Yup.string().required(ts('account.required')|| 'Required'),
    contractType: Yup.string().required(ts('account.required')|| 'Required'),
    classificationId: Yup.string().required(ts('account.required')|| 'Required'),
    ageTo: Yup.number().when('ageFrom', {
      is: null,
      then: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      otherwise: Yup.number().min(Yup.ref('ageFrom'), ts('account.tooLess')  || 'Too less').typeError(ts('account.required')|| 'Required'),
    }),
    gender: Yup.array().min(1, ts('account.atLeastOne')  || 'At least select one'),
    // experienceTo: Yup.number().when('experienceFrom', {
    //   is: null,
    //   then: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
    //   otherwise: Yup.number().min(Yup.ref('experienceFrom'), ts('account.tooLess')|| 'Too less').typeError(ts('account.required')|| 'Required'),
    // }),
    salaryTo: Yup.number().when('salaryFrom', {
      is: null,
      then: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      otherwise: Yup.number().min(Yup.ref('salaryFrom'), ts('account.tooLess')|| 'Too less').typeError(ts('account.required')|| 'Required'),
    }),

    language: Yup.string().required(ts('account.required') || 'Required'),
    jobPostSkills: Yup.array().of(
      Yup.object().shape({
        skillId: Yup.string().required(ts('account.required') || 'Required'),
        description: Yup.string().required(ts('account.required') || 'Required'),
        percentage: Yup.number().required(ts('account.required')|| 'Required')
      })
    )
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);

  // React.useEffect(() => {
  //   if (props.checkEligible && props.checkEligible == 1 && !searchParams.get('id')) {
  //     dispatch(setGlobalLoading(true));
  //     GetAxios().get(config.apiBase + '/api/Jobs/CheckEligibleforRegister?lang=' + i18n.language).then(res => {
  //       if (!res.data.data) {
  //         dispatch(setGlobalLoading(false));
  //         toast(res.data.message, { type: 'error' });
  //         navigate("/employer-account/upgrade-my-posting-account", { replace: true });
  //       }
  //       else {
  //         dispatch(setGlobalLoading(false));
  //       }
  //     });
  //   }
  // }, [props.checkEligible]);



  const formik = useFormik({
    initialValues: {
      id: 0,
      title: "",
      contactPerson: "",
      companyName: "",
      description: "",
      shortDescription: "",
      sectorId: "",
      departmentIds: [],
      classificationId: "",
      workExperienceId: "",
      contractType: "FullTime",
      ageFrom: null,
      ageTo: null,
      gender: [],
      experienceFrom: null,
      //experienceTo: null,
      minDegree: "",
      workShiftId: 0,
      salaryFrom: null,
      salaryTo: null,
      benifits: "",
      language: i18n.language,
      expiryDate: "",
      currency: "",
      isAdmin: props.isAdmin || false,
      jobPostSkills: [{ skillId: "", description: "", percentage: 50 }],
      additionalSkills: '',
      planId: 0
    },
    validationSchema: JobDetailschema,
    onSubmit: values => {
      if ((values.salaryFrom == null && values.salaryTo != null)|| (values.ageFrom == null && values.ageTo != null))
       {
        setSalaryError(values.salaryFrom == null && values.salaryTo != null);
        setAgeError(values.ageFrom == null && values.ageTo != null);
        //setExpError(values.experienceFrom == null)
        return;
      }
      if (props.checkEligible && props.checkEligible == 1 && !searchParams.get('id')) {
        dispatch(setGlobalLoading(true));
        GetAxios().get(config.apiBase + '/api/Jobs/CheckMultipleActivePlanExist?lang=' + i18n.language).then(res => {
          if (res.data.data.havePlan) {
            if (res.data.data.isMultiple) {
              dispatch(setGlobalLoading(false));
              const inputOptions = new Map
              res.data.data.plans.forEach((item:any) => inputOptions.set(item.planId, 
                (item.premium==true)? t('plan.premium').toString(): t('plan.quick').toString()
                ))
              MySwal.fire({
                title: t('plan.chooseJobType').toString(),
                input: 'radio',          
                inputOptions: inputOptions,
                inputValidator: (value) => {             
                  if (!value) {
                    return t('plan.mustChoose').toString();
                  }
                  return null;
                },
                confirmButtonText: t('plan.submit').toString(),
              }).then((result) => {
                if (result.isConfirmed) {
                  const selectedOption = result.value;
                  formik.setFieldValue('planId', result.value);
                  values.planId = result.value;
                  SaveJobData(values)
                }
              });
            }
            else {
              dispatch(setGlobalLoading(false));
              const singleplan = res.data.data.plans[0].planId;
              formik.setFieldValue('planId', singleplan);
              values.planId = singleplan;
              SaveJobData(values)
            }
          }
          else {
            SaveJobData(values);
          }
        });
      }
      else {
        SaveJobData(values)
      } 
    },
  });

  const SaveJobData = (values:any) => {
    if (loading) {
      toast(ts('account.pleaseWait'), { type: 'info' });
    }
    setLoading(true);
    GetAxios().post(config.apiBase + '/api/Jobs/SaveJob', values).then(res => {
      setLoading(false);
      if (res.data.success) {
        formik.setFieldValue('id', res.data.data);
        toast(res.data.message, { type: 'success' });
        if (props.isAdmin) {
          navigate('/admin/jobs');
        }
        else if ((window.location.pathname.includes("employer-account"))) {
          formik.resetForm();    
          props.redirect('adslist');
        }
        else {
          navigate('/employer-account');
          // navigate({
          //   pathname: window.location.pathname,
          //   search: '?' + new URLSearchParams({ id: res.data.data }).toString(),
          // });
        }
      } else {
        console.warn(res);
        toast(ts('error.unSaveJob'), { type: 'error' });
      }
    }).catch(err => {
      setLoading(false);
      toast(ts('error.unSaveJob'), { type: 'error' });
    });
    }


  const confirmSubmit = () => {
    confirmAlert({
      title: t('save.areYouSure').toString(),
      message: t('save.sureSave').toString(),
      buttons: [
        {
          label: t('save.yes').toString(),
          onClick: () => {
            formik.handleSubmit();
          },
        },
        {
          label: t('save.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  React.useEffect(() => {
    if (searchParams.get('id')) {
      dispatch(setGlobalLoading(true));
      GetAxios().get(config.apiBase + '/api/Jobs/GetEditJob?id=' + searchParams.get('id')).then(res => {
        if (!res.data.success) {
          dispatch(setGlobalLoading(false));
          toast(ts('error.ungetJob'), { type: 'error' });
        }
        else {

          if (res.data.data.shortDescription) {
            const html = convertFromHTML(res.data.data.shortDescription || '');
            setEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
          }

          if (res.data.data.description) {

            const html = convertFromHTML(res.data.data.description || '');
            setjobEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
          }

          formik.setValues(res.data.data);
          formik.setFieldValue('gender', res.data.data.gender || []);

          dispatch(setGlobalLoading(false));
        }
      });
    }
  }, [searchParams]);


  React.useEffect(() => {

    GetAxios().post(config.apiBase + '/api/General/GetListBySystemType',
      {
        lang: i18n.language,
        types: ['Skills', 'Sectors', 'Shift', 'Degree', 'ContractType', 'WorkExperience']
      }
      // ['Skills', 'Sectors', 'Shift', 'Degree', 'ContractType', 'WorkExperience']
    ).then(res => {
      setDropDown(res.data);
    });


  }, [i18n.language]);

  React.useEffect(() => {
    formik.setFieldValue('language', i18n.language);
  }, [i18n.language]);

  return (
    <>
      <div className="container">
        <div className="row py-3">
          <div className="col-md-4 col-sm-12 ps-lg-0">
            <SideContent formik={formik} dropDown={dropDown} isAdmin={props.isAdmin} salaryError={salaryError} setSalaryError={setSalaryError}
              ageError={ageError} setAgeError={setAgeError} />
               {/* expError={expError} setExpError={setExpError} /> */}
          </div>
          <div className="col-md-8 col-sm-12 pe-lg-0">
            <MainContent formik={formik} dropDown={dropDown} editorState={editorState} setEditState={setEditState} jobEditorState={jobEditorState} setJobEditState={setjobEditState}
              isAdmin={props.isAdmin} confirmSubmit={confirmSubmit} loading={loading} setLoading={setLoading} />
          </div>
        </div>
      </div>
    </>
  )
}
export default Index;