import MainContent from './MainContent';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';


const SkillMatcher = () => {

    const { t } = useTranslation('candidate');

    return (
        <div className='row bg-white p-3 mx-auto'>
            <div className="col-md-4">
                <Sidebar title={t('skill.matcher.skillsAssessment')} />
            </div>
            <div className="col-md-8">
                <MainContent />
            </div>
        </div>
    )
}

export default SkillMatcher;