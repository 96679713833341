import React, { useState } from 'react';
import Search from '../../../Components/Common/Search';
import { FaMapMarkerAlt, FaDotCircle, FaExternalLinkAlt } from "react-icons/fa";
import { GetAxios } from '../../../Shared';
import { config } from '../../../Environments';
import Pagination, { sort } from '../../../Components/Pagination';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalLoading } from '../../../Store';
import { useTranslation } from 'react-i18next';
const PageSize = 10;
const Index = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [list, setList] = React.useState<any>([]);
    const [sortCol, setSortCol] = React.useState('name|asc');
    const [filter, setFilter] = React.useState('');
    React.useEffect(() => {
        dispatch(setGlobalLoading(true));
        GetAxios().get(config.apiBase + '/api/Companies/GetCompaniesList?lang=' + i18n.language).then(res => {
            setList(res.data.data || []);
            dispatch(setGlobalLoading(false));
        });
    }, [i18n.language]);

    const [currentPage, setCurrentPage] = React.useState(1);

    const currentTableData = React.useMemo<any>(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return (list || []).slice(firstPageIndex, lastPageIndex);
    }, [currentPage, list]);


    return (
        <>
            <div id="page-content-main">
                <div className='container'>
                    <div className='row py-50'>
                        <div className='col-lg-12 col-12'>
                            <div className='row'>
                                <div className="language-print text-end">
                                    <select value={sortCol} onChange={(e) => setSortCol(e.target.value)} style={{ width: "200px" }} className="form-select form-control key-weight w-select-list float-end">
                                        <option value={'name|asc'}>Name A -{'>'} Z</option>
                                        <option value={'name|desc'}>Name Z -{'>'} A</option>
                                        <option value={'sector|asc'}>Sector A -{'>'} Z</option>
                                        <option value={'sector|desc'}>Sector Z -{'>'} A</option>
                                        <option value={'jobs|asc'}>Jobs Asc</option>
                                        <option value={'jobs|desc'}>Jobs Desc</option>
                                    </select>
                                </div>
                                <div className="flex-column top-bg-company py-1 mt-3">
                                    <div className="top-bg-company">
                                        <Search onChange={(e) => setFilter(e.target.value || '')} />
                                    </div>
                                </div>
                            </div>
                            <div className="row px-0">
                                <div className="card mb-4 border-0 rounded-0 p-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table id="example" className="table mb-0 nowrap">
                                                <thead className="table-header">
                                                    <tr className="">
                                                        <th className="ps-4 cols table-head-padding" colSpan={2}>Employers</th>
                                                        <th className="text-center border-start pe-4 table-head-padding">Jobs</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sort(currentTableData.filter((s: { name?: string; }) => (s.name || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 || filter === ''),
                                                        sortCol.split('|')[0],
                                                        sortCol.split('|')[1] as any)
                                                        .map((item, index) => (
                                                            <CompanyListItem item={item} index={index} key={'item+sorted+_' + index} />
                                                        )
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column p-lg-0 mb-0">
                                <div className="d-flex flexcolumnsm align-items-center justify-content-between">
                                    <p className="fs-14">Displaying {currentTableData.length} out of {list.length} items</p>
                                    <div className="">
                                        <nav aria-label="Page navigation example">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={list.length}
                                                pageSize={PageSize}
                                                onPageChange={(page: any) => setCurrentPage(page)}
                                            />
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CompanyListItem = (props: any) => {
    const [isAlternateImg, setIsAlternateImg] = useState(false);
    const { item, index } = props;
    const convertToPlain = (html: string) => {
        var tempDiv = document.createElement("p");
        tempDiv.innerHTML = html;
        var result = (tempDiv.innerText.length >= 200) ? tempDiv.innerText.substring(0, 200) + "..." : tempDiv.innerText;
        return result;

    }
    return (
        <tr>
            <td className="border-start border-end w-20 align-middle text-center">
                <div>
                    {(item.logo && !isAlternateImg) ?
                        <img src={config.apiBase + item.logo} className="img-w-345px" alt="company image" onError={(ev: any) => {
                            // ev.target.src = '/images/dummy-img-company.png'
                            setIsAlternateImg(true)
                        }} />
                        :
                        <div className='no-img-box'>
                            <span className='no-img-title'>{item?.name ? item?.name.charAt(0)?.toUpperCase() : ''}</span>
                        </div>
                    }
                </div>
            </td>
            <td className="p-4">
                <h4 className="card-title mb-0"><Link className="text-decoration-none text-dark pointer-event" to={'/company/profile?id=' + item.id}>{item?.name}</Link></h4>
                <a className="card-subtitle text-decoration-none pointer-event">{item?.sector || 'N/A'}</a>
                <div className="d-flex mobflexcolumn w-100 alignment-list justify-content-between align-items-center mt-3 font-sab-table">
                    <div className="card-description">
                        {
                            item?.locations?.length > 0 && item.locations[0].country &&
                            <a className="text-decoration-none me-2 address-mob pointer-event" target={'_blank'} href={'http://maps.google.com/?q=' + item.locations[0].country}>
                                <FaMapMarkerAlt className="me-1" size={16} />{item?.locations?.length > 0 ? item.locations[0].country : item.locations[0].country}
                            </a>
                        }
                    </div>
                    <div>
                        <ul className="mobflexcolumn list-inline p-0 d-flex d-inline-flex mb-0">
                            {
                                item?.contactPerson &&
                                <li><FaDotCircle className='me-1' size={10} />Contact Person: <span className="weight-list me-3">{item?.contactPerson}</span></li>
                            }
                            {
                                item?.info?.length > 0 && item.info[0].phoneNumber &&
                                <li><FaDotCircle className='me-1' size={10} />Tel: <a href={'tel:' + (item?.info?.length > 0 ? item.info[0].phoneNumber : '')} className="weight-list me-3">{item?.info?.length > 0 ? item.info[0].phoneNumber : 'N/A'}</a></li>
                            }
                            {
                                item?.info?.length > 0 && item.info[0].email &&
                                <li><FaDotCircle className='me-1' size={10} />Email: <a href={'mailto:' + (item?.info?.length > 0 ? item.info[0].email : '')} className="weight-list me-3">{item?.info?.length > 0 ? item.info[0].email : 'N/A'}</a></li>
                            }
                        </ul>
                    </div>
                </div>
                <div className='mt-3'>
                    <h6 className='text-uppercase fw-600'>About {item?.name}</h6>
                    {
                        item?.description &&
                        <p className='mb-0 fs-15 read-btn'>{convertToPlain(item?.description)}</p>
                    }
                    <Link to={'/company/profile?id=' + item.id} className="weight-list me-3 text-decoration-none">Read more...</Link>
                </div>
            </td>
            <td className="border-start align-middle">
                <Link to={'/company/profile?id=' + item.id} className="text-decoration-none">
                    <p className='hidding'>
                        <h4 className="jobsNumber text-decoration-none mb-0 text-center">{item.jobs}</h4>
                        <span className='text-nowrap'>See Jobs <FaExternalLinkAlt /></span>
                    </p>
                </Link>
            </td>
        </tr>
    )
}
export default Index;