import { FaLock } from 'react-icons/fa'
import './Error.css'

export const Forbidden403 = () => {
    return (
        <div className='container mt-5'>
            <div><FaLock style={{ fontSize: 41 }} /></div>
            <div className="message">
                <h1>Access to this page is restricted</h1>
                <p>Please check with the site admin if you believe this is a mistake.</p>
            </div>
        </div>
    )
}