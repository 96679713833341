import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { FaEdit, FaMoneyCheck,  FaTrash, FaEye, FaRegUser , FaFile, FaToggleOn, FaToggleOff, FaPlayCircle , FaChevronRight, FaChevronLeft} from "react-icons/fa";
import AddPaymentModal from "../Payments/PaymentModel";
import { useTranslation } from 'react-i18next';



const TenderTable =  (props: { pageSkillLimit: number, update: boolean, 
  setPageSkillLimit: (arg:number)=>void ,searchString:string, setUpdate: (arg:boolean) => void } ) => {
  const dispatch = useDispatch();
  const [tenderlist, setTenderList] = useState<any>([]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation('admin');
  const { t :ts} = useTranslation('alert');
  // React.useEffect(() => {
  //   fetchTenders();
  // }, []);

  React.useEffect(() => {
    fetchTenders();
  }, [props.update]);

  function fetchTenders() {
    dispatch(setGlobalLoading(true));
    GetAxios()
      .get(config.apiBase + "/api/Admin/GetTenders?search="+props.searchString)
      .then((res) => {
        setTenderList(res.data.data || []);
        dispatch(setGlobalLoading(false));
      });
  }

  function handleOnViewClick(tenderId:number) {

    navigate("/admin/tenderpreview?tid=" + tenderId , { state: { from: "admin"  } });
  }
  function handleOnEditClick(tenderId:number) {

    navigate("/admin/tenderregistration?tid=" +tenderId);
  }
    
  function handleOnApplicantClick(tenderId:number) {
   
    navigate("/admin/tenderapplicants?id=" +tenderId);
  }
  function handleApproveClick(tenderId:number) {
    confirmAlert({
      title: t('common.confirmtoActive').toString(),
      message: t('common.sureActive').toString(),
      buttons: [
        {
          label:  t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(  config.apiBase +  "/api/Admin/UpdateTenderStatus?tenderId=" +tenderId )
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });
            
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleDisapproveClick(tenderId:number) {
    confirmAlert({
      title:  t('common.confirmtoInactive').toString(),
      message: t('common.sureInactive').toString(),
      buttons: [
        {
          label:  t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(
                config.apiBase +
                "/api/Admin/UpdateTenderStatus?tenderId=" +
                tenderId
              )
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });
               
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleOnDeleteClick(tenderId:number) {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('tender.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Tender/DeleteTender?id=' + tenderId,
            ).then(s => {
              toast(ts('tender.delete'), { type: 'success' });
              dispatch(setGlobalLoading(false));
              props.setUpdate(!props.update);
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    });
  }

  return (
    <>
    
      <div className="table-responsive">
        <Table bordered className="bg-white">
          <thead>
            <tr>
            <th className="fw-700 fw-16 w-12">{t('common.adNo')}</th>
              <th className="fw-700 fw-16  w-15">{t('common.advertisngNo')}</th>
              <th className="fw-700 fw-16">{t('common.title')}</th>
              <th className="fw-700 fw-16">{t('common.companyName')}</th>          
              <th className="fw-700 fw-16 text-center">{t('common.status')}</th>
              <th className="fw-700 fw-16">{t('common.paymentStatus')}</th>
              <th className="text-center w-20">{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
          {(tenderlist || []).slice((currentPage - 1) * props.pageSkillLimit, currentPage * props.pageSkillLimit).map((item:any, index:number) => {
         
            {/* {tenderlist.map((tender, index) => { */}
              return (
                <tr key={index}>
                   <td>{item.tenderNo}</td>
                  <td>{item.advertisingNo}</td>
                  <td>{item.title}</td>
                  <td>{item.companyName}</td>
                  <td className="text-center">
                    {item.status ? t('common.active').toString():t('common.inactive').toString()}
                  </td>
                  <td>{item.paymentStatus=="Paid"? t('payment.paid').toString(): item.paymentStatus=="UnPaid"? t('payment.unpaid').toString():  t('payment.free').toString()}</td>
                  <td className="text-center">
                  <button
                      type="button"
                      className="btn  btn-warning mx-1 px-2 py-1"
                      onClick={() => handleOnApplicantClick(item.id)}
                    >
                        <FaFile className="text-white" title={t('common.applicants') || ''} size={16} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary px-2 py-1"
                      onClick={() => handleOnViewClick(item.id)}
                    >
                      <FaEye className="text-white"  title={t('common.view')|| ''} size={16} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-info mx-1 px-2 py-1"
                      onClick={() => handleOnEditClick(item.id)}
                    >
                      <FaEdit className="text-white" title={t('common.edit')|| ''}size={16} />
                    </button>
                    {item.status ? (
                      <button
                        type="button"
                        className="btn btn-success px-2 py-1"
                       
                        onClick={() => handleDisapproveClick(item.id)}
                      >
                         <FaToggleOn size={16} title={t('common.setInactive') || ''}  />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger px-2 py-1"
                       
                        onClick={() => handleApproveClick(item.id)}
                      ><FaToggleOff size={16} title={t('common.setActive') || ''}/>           
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-dark mx-1 px-2 py-1"
                      onClick={() => handleOnDeleteClick(item.id)}
                    >
                      <FaTrash className="text-white" title={t('common.delete')|| ''} size={16} />
                    </button>
        
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="">
        <div className="d-flex align-items-center">
          <span>  {t('common.showing')}  {(currentPage>tenderlist.length)? tenderlist.length:currentPage }  {t('common.to')}  {(props.pageSkillLimit>tenderlist.length)? tenderlist.length:props.pageSkillLimit }  {t('common.of')}  {tenderlist.length} {t('common.entries')}</span>
      </div>
      </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}
                >
                  {" "}
                  <FaChevronLeft />
                </button>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event"  onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(tenderlist.length / props.pageSkillLimit))} >
                  {Math.ceil(tenderlist.length / props.pageSkillLimit)}
                </a>
              </li>
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(tenderlist.length / props.pageSkillLimit)
                      ? false
                      : true
                  }
                >
                  {" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default TenderTable;




// export const AddPaymentModal = (props) => {

//   const [interest, setInterest] = useState(false);
//   const handleClose = () => setInterest(false);
//   const handleShow = () => setInterest(true);
//   const paymentSchema = Yup.object().shape({
   
//     paidAmount:  Yup.number().required("Required"),
//     txnRef : Yup.string().required("Required"),
//     paidDate: Yup.string().required("Required"),
   
//   });
//   const [loading, setLoading] = useState(false);
//   const formik = useFormik({
//     initialValues: {
//       tenderId: 0,
//       paymentType: "CC",
//       paidAmount: 0,
//       txnRef : "",
//       paidDate:"",
//       remarks:"",
     
//     },
//     validationSchema: paymentSchema,
//     onSubmit: (values, { resetForm }) => {
    
//    // values => {
//       if (loading) {
//         toast('Please Wait! Already Submitting...', { type: 'info' });
//       }
//       setLoading(true);
    
//       const formData = new FormData();
//       Object.keys(values).map(s => {
//         formData.append(s, (values)[s]);
//       });
//       formData.append('tenderId', props.tenderId);
//       GetAxios().post(config.apiBase + '/api/Admin/SaveTenderPayment', formData).then(res => {
//         if (res.data.success) {
//           toast(res.data.message, { type: 'success' });
          
//           resetForm();
//         } else {
//           toast('Unable to save payment.', { type: 'error' });
//         }
//         setLoading(false);
//       }).catch(err => {
//         setLoading(false);
//         console.error(err);
//         if (err.status >= 500) {
//           toast('Internal server error.', { type: 'error' });
//         }
//       });
//     },
//   });


//   return (
//     <>
//       <Modal size="lg" show={props.handleModalShow} onHide={props.handleModalClose} animation={true} className="border-blue-resume">
//         <Modal.Header className='popup-gray-bg' closeButton>
//           <Modal.Title className="fw-600">Received Payment Details {props.tenderId}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="payment-detail">
//             <div className="row">
//               <div className="col-md-4 col-12 mb-12">
//                 <div className='line-height-sta'>
//                   <label className="form-label key-weight" htmlFor="receivedDate"> Received Date: </label>
//                 </div>  
                                     
//                 <DatePickSelect
//                   yearFrom={1960}
//                   yearTo={new Date().getFullYear()}
//                   maxDate={new Date()}
//                   minDate={new Date(1960, 1, 1)}
//                   placeHolder="Received Date"
//                   selected={formik.values.paidDate}
//                   onChange={(date) => {

//                     formik.setFieldValue("paidDate", date.toISOString())
//                     formik.setFieldValue("tenderId", date.toISOString())
//                   } }
//                 />
//                   {formik.errors.paidDate && formik.touched.paidDate ? <small className='text-danger d-block'>{formik.errors.paidDate}</small> : ""}
                                        
//               </div>
//               <div className="col-md-4 col-12 mb-12">
//                 <div className='line-height-sta'>
//                   <label className="form-label key-weight" htmlFor="paidAmount"> Received Amount: </label>
//                 </div>
//                 <input type="number" step=".01" className="form-control" name="paidAmount" onChange={formik.handleChange} value={formik.values.paidAmount}  />
             
//               </div>
//               <div className="col-md-4 col-12 mb-12">
//                 <div className='line-height-sta'>
//                   <label className="form-label key-weight" htmlFor="txnRef"> Trx Ref. # </label>
//                 </div>
//                 <input type="text" className="form-control" name="txnRef" onChange={formik.handleChange} value={formik.values.txnRef}  />
          
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-12 col-12 mb-12">
//                 <div className='line-height-sta'>
//                   <label className="form-label key-weight" htmlFor="remarks"> Comments </label>
//                 </div>
//                 <textarea className="form-control" name="remarks"  rows="4" onChange={formik.handleChange} value={formik.values.remarks} />
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="danger" className="fs-18 rounded ptb-15 text-white border-0 fw-500" onClick={props.handleModalClose}>
//             Close
//           </Button>
//           <button disabled={loading} type="submit" onClick={formik.handleSubmit} className="fs-18 rounded ptb-15 text-white bg-dark-blue border-0 fw-500">
//                             {
//                               loading ? <RotatingLines
//                                 strokeColor="white"
//                                 strokeWidth="5"
//                                 animationDuration="0.75"
//                                 width="18"
//                                 visible={true}
//                               /> : <>
//                               Submit <FaPlayCircle className='fa-lg ms-1'size={20} />
//                               </>
//                             }
//                           </button>
        
//         </Modal.Footer>
//       </Modal>
//     </>
//   )
// }