
import { TemplateList } from '../../../TemplateImageJSON';
import { AvailableExtraSteps, AvailableSteps } from "../../Resumes/SharedData";
import React, { useEffect, useState, useRef } from "react";
import { GetAxios, Sorter, } from "../../../Shared";
import { config } from "../../../Environments";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

const ResumePage = (props: { onNavigatedToSection?: () => void, }) => {
  const { t } = useTranslation('resume');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maxPossibleSteps = AvailableSteps.concat(AvailableExtraSteps);
  const [params] = useSearchParams();
  const resumeId  = params?.get('id')
  const [resume, setResume] = React.useState<ResumeSlice>({
    experiences: [],
    educations: [],
    skills: [],
    languages: [],
    references: [],
    submitting: false,
    imageData: {
      isRounded: false
    },
    objectives:"<p></p>",
    additionalSettings: {
      fontFamily: 'Classic',
      fontSize: 'medium',
      lineHeight: 1
    },
    title: '',
    activeStep: 1,
    stepsInformation: [],
    contactInformation: {

    },
    accessInformation:{
      deptId:0,
      classId:0,
      sectorId:0
    },
    customSection: []
  });

  useEffect(() => {
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/Resume/GetResume', {
      params: {
        id: resumeId
      }
    }).then(s => {
      if (s.data.data) {
        setResume({
          ...s.data.data,
          customSection: s.data.data.sections,
          imageData: JSON.parse(s.data.data.imageData || '{}'),
          image: s.data.data.image ? config.apiBase + s.data.data.image : undefined,
          stepsInformation: (s.data.data.appliedSections as AppliedSections[]).map(s => (maxPossibleSteps[s.position - 1])).sort(Sorter('description'))
        });
      }
    });
  }, []);

  const publicationsId = maxPossibleSteps.find(x => x.title == 'Publications')?.position;
  const projectsId = maxPossibleSteps.find(x => x.title == 'Projects')?.position;
  const honoursId = maxPossibleSteps.find(x => x.title == 'Honors & Awards')?.position;
  const customSectionsId = maxPossibleSteps.find(x => x.title == 'Custom sections')?.position;
  const certificatesId = maxPossibleSteps.find(x => x.title == 'Certifications & Courses')?.position;
  const achievementsId = maxPossibleSteps.find(x => x.title == 'Achievements')?.position;

  const component = TemplateList.find(s => s.id == resume.templateId);
  if (component && component.component) { 
    const PreviewWindow = component.component;
    return (
      <>
       
         <div className="container py-5 modal-8inch-wprint">
          <PreviewWindow
            objectives={resume.objectives}
            contactInformation={resume.contactInformation}
            experiences={resume.experiences}
            educations={resume.educations}
            languages={resume.languages}
            skills={resume.skills}     
            achievements={resume.customSection.filter(x => x.sectionId === achievementsId)}
            certificates={resume.customSection.filter(x => x.sectionId === certificatesId)}
            customSections={resume.customSection.filter(x => x.sectionId === customSectionsId)}
            honours={resume.customSection.filter(x => x.sectionId === honoursId)}
            projects={resume.customSection.filter(x => x.sectionId === projectsId)}
            publications={resume.customSection.filter(x => x.sectionId === publicationsId)}
            references={resume.references}      
            image={resume.image}
            imageData={resume.imageData}
            additionalSettings={resume.additionalSettings}
            stepsInformation={resume.stepsInformation}
          />
        </div> 
      </>
    );
  }
  else {
    return (
      <>
        <div className="container">
          <p>Testing</p>
        </div>
      </>
    )
  }
}
export default ResumePage;