import React from 'react';
import MainContent from './MainContent';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';

const Index = () => {

  const { t } = useTranslation('candidate');

  return (
    <>
      <div className="container">
        <div className="py-50">
          <div className='row'>
            <div className='col-md-4'>
              <div className="text-center">
                <span className="skill-banner-title bg-light-gray border-0 text-nowrap">
                  {t('job.matcher.jobMatcherTitle')}
                </span>
              </div>
            </div>
          </div>
          <div className='row bg-white pt-3 p-2 mx-auto'>
            <div className='col-md-4'>
              <Sidebar />
            </div>
            <div className='col-md-8 pb-4'>
              <MainContent />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Index;