import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Index = () => {

    const { t } = useTranslation('candidate');

    return (
        <div className="container">
            <div className="py-50">
                <div className='row'>
                    <div className="col-md-4">
                        <div className='text-center'>
                            <span className="skill-banner-title border-0 text-nowrap">
                                {t('skill.matcher.skillsMatcherTitle')}
                            </span>
                        </div>
                    </div>
                </div>
                <Outlet />
                {/* {location.pathname.toLowerCase() === "/jobmatcher" ? <JobMatcher /> : null}
                {location.pathname.toLowerCase() === "/jobassessment" ? <JobMatcherAssessmentList /> : null} */}
            </div>
        </div>
    )
}




export default Index;