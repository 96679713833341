import moment from 'moment';
import React, { useRef, useEffect, useState } from 'react';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export const Europass = (props: TemplateComponentProps) => {

  const { t } = useTranslation('resume');

  const {
    navigate,
    contactInformation,
    image,
    imageData,
    objectives,
    experiences,
    educations,
    languages,
    skills,
    achievements,
    certificates,
    customSections,
    honours,
    projects,
    publications,
    references,
    additionalSettings,
    stepsInformation,
    onNavigatedToSection
  } = props;

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const [section1Height, setSection1Height] = useState(0);
  const [section2Height, setSection2Height] = useState(0);

  useEffect(() => {
    if (section1Ref.current) {
      setSection1Height(section1Ref.current);
    }
    if (section2Ref.current) {
      setSection2Height(section2Ref.current);
    }
  }, []);

  const pageHeight = 792; // assuming page size of 8.5" x 11" at 72 DPI

  return (
    <>
      <WrapperDiv additionalSettings={additionalSettings}>
        <div className={navigate ? ' p-0' : ''}>
          <div className='template'>
            <div className='preview-template'>
              <div id="template-full">
                <div className='template-main'>
                  {
                    (contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) &&
                    <div className='template-header' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                      <section className='head selectable-section'>
                        <div className='template__name'>
                          <span>{contactInformation.firstName} {contactInformation.lastName}</span>
                        </div>
                        <div className='template_subtitle'>
                          <p>{contactInformation.occupation}</p>
                        </div>
                      </section>
                    </div>
                  }
                  <div className='template_content'>
                    {
                      Object.values(contactInformation).filter(s => s).length > 0 &&
                      <section className='contact-sec contact-information d-flex selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title text-wrap'> {t('Contact Information')} </p>
                        <div className='template-header-info'>
                          <div className='d-flex justify-content-between'>
                            <div className=''>
                              <ul className='list-unstyled'>
                                {
                                  contactInformation.email &&
                                  <li><p className='sec-subtitle'> {t('email')}: <a className='color-black ms-2 text-decoration-none' href={'mailto:' + contactInformation.email}>{contactInformation.email}</a></p></li>
                                }
                                {
                                  contactInformation.address &&
                                  <li><p className='sec-subtitle'> {t('address')}: <span className='color-black ms-2'>{contactInformation.address}</span></p></li>
                                }
                                {
                                  contactInformation.phone &&
                                  <li><p className='sec-subtitle'> {t('phone')}: <a href={'tel:' + contactInformation.phone} className='color-black ms-2 text-decoration-none'>{contactInformation.phone}</a></p></li>
                                }
                                {
                                  contactInformation.dob &&
                                  <li><p className='sec-subtitle'> {t('resDateBirth')}: <span className='color-black ms-2'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</span></p></li>
                                }
                                {
                                  contactInformation.nationality &&
                                  <li><p className='sec-subtitle'> {t('nationality')}: <span className='color-black ms-2'>{contactInformation.nationality}</span></p></li>
                                }
                                {
                                  contactInformation.linkedInLink &&
                                  <li><p className='sec-subtitle'> {t('link')}: <span className='color-black ms-2'>{contactInformation.linkedInLink}</span></p></li>
                                }
                              </ul>
                            </div>
                            <div className='float-end'>

                              {
                                image &&
                                <img src={image}
                                  className={imageData.isRounded ? "rounded-img" : 'simple-img'}
                                  onError={e => {
                                    if (e.target instanceof HTMLImageElement) {
                                      e.target.src = DefaultProfileImage;
                                    }
                                  }} alt="europass profile image" />
                              }

                            </div>
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Objective') > -1 && objectives &&
                      <section className='objective-sec contact-information d-flex mt-3 selectable-section' ref={section1Ref} onClick={_ => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'> {t('Objective')} </p>
                        <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                          <div className='w-objective w-100 obj-list-margin-left'>
                            <p className='sec-value' dangerouslySetInnerHTML={{ __html: objectives }}></p>
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Experiences') > -1 && experiences.length > 0 ?
                        <section className='experience-sec selectable-section' ref={section2Ref} onClick={_ => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}>
                          <div className='mt-3 mb-16'>
                            <p className='text-uppercase title-objective mb-16 fw-600'> {t('Experiences')} </p>
                            {
                              experiences.map((e, i) =>
                                <div className='contact-information d-flex' key={'exp_' + i}>
                                  <div className='w-150 m-right float-left width-25 border-bottoms-pdf mr-16 pb-37 temp-header-info-pdf'>
                                    {
                                      e.location ?
                                        <p className='fw-600 color-blue-head'>{e.location}</p> : <></>
                                    }
                                    {
                                      (e.startDate || e.endDate) ?
                                        <p className='w-150 m-right color-blue-head'>
                                          {
                                            e.startDate ?
                                              <span>{new Date(e.startDate).toLocaleString('default', { month: 'short' })} {new Date(e.startDate).getFullYear()}</span> : <></>
                                          }
                                          {
                                            (e.endDate && !e.currentlyWorking) ?
                                              <span> - {new Date(e.endDate).toLocaleString('default', { month: 'short' })} {new Date(e.endDate).getFullYear()}</span> : <></>
                                          }
                                          {
                                            e.currentlyWorking ?
                                              <em> - {t('present')}</em> : <></>
                                          }
                                        </p> : <></>
                                    }
                                  </div>
                                  <div className='header-info header-info-pdf width-75 float-right border-bottoms-pdf pb-8 temp-header-info-pdf'>
                                    {
                                      e.title &&
                                      <p className='sec-subtitle me-2'>{e.title}</p>
                                    }
                                    {
                                      e.company &&
                                      <p className=''>{e.company}</p>
                                    }
                                    {
                                      e.description &&
                                      <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                    }
                                  </div>
                                </div>

                              )
                            }
                          </div>
                        </section> : <></>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Education') > -1 && educations.length > 0 &&
                      <section className='education-sec selectable-section' onClick={_ => SelectableSectionClicked('education', navigate, onNavigatedToSection)}>
                        <div className='mt-3 mb-16'>
                          <p className='text-uppercase title-objective mb-16 fw-600'> {t('Education')} </p>
                          {
                            educations.map((s, i) =>
                              <div className='contact-information d-flex' key={'edu_' + i}>
                                <div className='w-150 m-right border-bottoms float-left width-25 border-bottoms-pdf mr-16 pb-37'>
                                  {
                                    s.location &&
                                    <p className='fw-600 color-blue-head'>{s.location}</p>
                                  }
                                  {
                                    (s.startDate || s.completeDate) &&
                                    <p className='color-blue-head w-150 m-right'>
                                      {
                                        s.startDate &&
                                        <span>{new Date(s.startDate).toLocaleString('default', { month: 'short' })} {new Date(s.startDate).getFullYear()}</span>
                                      }
                                      {
                                        (s.completeDate && !s.currentlyStudying) &&
                                        <span> - {new Date(s.completeDate).toLocaleString('default', { month: 'short' })} {new Date(s.completeDate).getFullYear()}</span>
                                      }
                                      {
                                        (s.currentlyStudying) &&
                                        <span> - {t('present')} </span>
                                      }
                                    </p>
                                  }
                                </div>
                                <div className='header-info border-bottoms header-info-pdf width-75 float-right border-bottoms-pdf pb-8 temp-header-info-pdf'>
                                  <div className=''>
                                    {
                                      s.degree &&
                                      <p className='sec-subtitle me-2'>{s.degree}</p>
                                    }
                                    {
                                      s.institute &&
                                      <p className='sec-value'>{s.institute}</p>
                                    }
                                    {
                                      s.description &&
                                      <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: s.description }}></p>
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </section>
                    }
                    <section className='language-skill-sec'>
                      <div className='mt-3 mb-16'>
                        {
                          stepsInformation.findIndex(x => x.title == 'Languages') > -1 &&
                          <div className='contact-information d-flex'>
                            <div className='float-left width-25 border-bottoms mr-16 selectable-section' onClick={_ => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}>
                              <p className='text-uppercase title-objective mb-16 fw-600'> {t('Languages')} </p>
                              {
                                languages.length > 0 &&
                                languages.map((s, i) =>
                                  <div className='w-150 m-right mb-2' key={'languages_' + s.name + s.id + i}>
                                    {
                                      s.name &&
                                      <p className='fw-600 color-blue-head'>{s.name}</p>
                                    }
                                    {
                                      s.level &&
                                      <p className='w-150 m-right'>{s.level}</p>
                                    }
                                    {
                                      s.course &&
                                      <p className='w-150 m-right'>{s.course}</p>
                                    }
                                  </div>
                                )
                              }
                            </div>
                            <div className='header-info border-bottoms selectable-section header-info-pdf selectable-section temp-header-info-pdf float-right w-100 border-bottoms-pdf pb-37' onClick={_ => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}>
                              <div className=''>
                                <p className='text-uppercase title-objective mb-16 fw-600'> {t('Skills')} </p>
                                <div className='row'>
                                  {
                                    skills.length > 0 &&
                                    <span className='fw-500 color-blue-head mb-1'>{skills.map(s => s.name?.trim()).join(', ')}</span>
                                    // skills.map((s, i) =>
                                    //   <div className='col-6' key={'skill_' + s.name + s.id + i}>
                                    //     <p className='fw-500 color-blue-head mb-1'>{s.name}</p>
                                    //   </div>
                                    // )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </section>
                    {
                      stepsInformation.findIndex(x => x.title == 'Projects') > -1 && projects.length > 0 &&
                      <section className='projects-sec contact-information d-flex mt-3 selectable-section' onClick={_ => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'> {t('Projects')} </p>
                        <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                          <div className='w-objective w-100'>
                            {
                              projects.map((v, i) =>
                                <div key={'projects__' + i}>
                                  <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Achievements') > -1 && achievements.length > 0 &&
                      <section className='achievement-sec contact-information d-flex mt-3 selectable-section' onClick={_ => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'> {t('Achievements')} </p>
                        <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                          <div className='w-objective w-100'>
                            {
                              achievements.map((v, i) =>
                                <div key={'achievements__' + i}>
                                  <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 &&
                      <section className='certificate-course-sec contact-information d-flex mt-3 selectable-section' onClick={_ => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'> {t('Certifications & Courses')} </p>
                        <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                          <div className='w-objective w-100'>
                            {
                              certificates.map((v, i) =>
                                <div key={'certificates__' + i}>
                                  <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Honors & Awards') > -1 && honours.length > 0 &&
                      <section className='honours-award-sec contact-information d-flex mt-3 selectable-section' onClick={_ => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'> {t('Honors & Awards')} </p>
                        <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                          <div className='w-objective w-100'>
                            {
                              honours.map((v, i) =>
                                <div key={'honour__' + i}>
                                  <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Publications') > -1 && publications.length > 0 &&
                      <section className='publications-sec contact-information d-flex mt-3 selectable-section' onClick={_ => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'> {t('Publications')} </p>
                        <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                          <div className='w-objective w-100'>
                            {
                              publications.map((v, i) =>
                                <div key={'publication__' + i} className="mb-2">
                                  <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                      <section className='reference-sec contact-information d-flex mt-3 selectable-section' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                        <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'> {t('References')} </p>
                        <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                          <div className='w-objective w-100'>
                            {
                              references.map((v, i) =>
                                <div key={'refer__' + i} className='position-relative mb-16'>
                                  <p className='sec-value quote-img mb-2 editor-list-margin-left'>{v.name}</p>
                                  <p className='sec-value quote-img mb-2 editor-list-margin-left'>{v.city} {(v.city != '' && v.tel != '') ? "|" : ""} {v.tel}</p>
                                  {/* <p className='sec-value quote-img mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p> */}
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </section>
                    }
                    {
                      stepsInformation.findIndex(x => x.title == 'Custom sections') > -1 && customSections.map((v, i) =>
                        <section key={'custom_' + i} className='driving-license contact-information d-flex mt-3 selectable-section' onClick={_ => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}>
                          <p className='text-uppercase template-section-title float-left width-25 border-bottoms mr-16 pb-20'>{v.title}</p>
                          <div className='template-header-info temp-header-info-pdf float-right width-75 border-bottoms pb-13'>
                            <div className='w-objective w-100'>
                              <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                            </div>
                          </div>
                        </section>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WrapperDiv>
    </>
  )
}

const WrapperDiv = styled.div`
& .sidebar-template{
    background-color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-value , .sec-value *{
    line-height:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
    font-size:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + " !important"};
   
}

& * {
    font-family:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontFamily
    &&
    props?.additionalSettings?.fontFamily === "Classic" ? "Noto Serif, serif;" :
    props?.additionalSettings?.fontFamily === "Modern" ? "Rubik, sans-serif;" :
      props?.additionalSettings?.fontFamily === "Elegant" ? "Libre Baskerville, serif;" :
        props?.additionalSettings?.fontFamily === "Tech" ? "Roboto Mono, monospace;" :
          props?.additionalSettings?.fontFamily === "Professional" ? "Bitter, serif;" : "default;"}
}
`