import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate , useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SucessStoryList from "./SucessStoryGrid";
import { CardTitle } from "../Tenders/Index";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const Index = () => {

  const navigate = useNavigate();
  const [pageSkillLimit, setPageSkillLimit] = useState(10);
  const [searchString, setSearchString] = useState('');
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation('admin');

  return (
    <>
      <div className="container-fuild py-50 margin15">
        <div className="card custom-shadow">
          <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
              <CardTitle> {t('common.successStoryList')}</CardTitle>
              </div>
              <div className="d-flex align-items-center">
                <Link
                  to="/admin/successstories/AddEdit"
                  className="button-default text-decoration-none cursor-pointer mt-1 border-0 me-1">{t('sucessstories.addnewStory')}</Link>
              </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between">
              <div>
                
              </div>
              <div>
                <CustomButton onClick={() => navigate('/admin/successstories/AddEdit')} className="cursor-pointer">{t('sucessstories.addnewStory')}</CustomButton>
              </div>
            </div> */}
          </div>
          <div className="card-body px-4">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="fw-500">{t('common.show')}</span>
                    <select
                      id="inputState"
                      style={{ width: "75px", height: "31px" }}
                      value={pageSkillLimit}
                      onChange={e => setPageSkillLimit(Number(e.target.value))}
                      className="form-select form-control w-select-list fsize-13 mx-2">
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                      <option value={60}>60</option>
                    </select>
                    <span className="fw-500">{t('common.entries')}</span>
                  </div>
                  <div className="me-2 d-flex align-items-center">
                    <span className="me-1 fw-500">{t('common.search')}:</span>
                    <input type="text" name='searchString' className="form-control" id="searchString" value={searchString} 
                    onChange={(e) => {
                      setSearchString(e.target.value);
                     //call story list
                      setUpdate(!update);
                    
                  }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <SucessStoryList pageSkillLimit={pageSkillLimit} setPageSkillLimit={setPageSkillLimit}  searchString={searchString} update={update} setUpdate={setUpdate}/>
              </div>
              
            </div>


          </div>
        </div>
      </div>
    </>
  );
};
export default Index;


export const CustomButton = styled.div`
text-decoration: none;
color: #fff;
padding: 8px 16px;
background-color: #0a58ca;
border-radius: 3px;
&:hover {
  color: #fff;
  background-color: #0b5ed7;
}
` 