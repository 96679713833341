import React , {useState} from "react";
import SideContent from "./SideContent";
import MainContent from "./MainContent";
import { config } from "../../../Environments";
import { GetAxios } from "../../../Shared";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../Store";

const Index = () => {
  const [jobsList, setJobsList] = useState<any[]>([]);
  const [deptName,setDeptName]= useState<string>('');
  const [className,setClassName]= useState<string>('');
  const [sectorName,setSectorName]= useState<string>('');

  const dispatch = useDispatch();

  return (
    <>
      <div className="container">
        <div className="row py-50">
          <div className="col-md-4 col-12 ps-lg-0">
          <SideContent  sectorName={sectorName} deptName={deptName} className={className}         
           onSearch={(query, idType,searchId,sector , dept, classId, contract,  workExp, lang, DaysPostedWithIn) => {          
              dispatch(setGlobalLoading(true));
              GetAxios().post(config.apiBase + '/api/Jobs/SearchPremiumJobs', {
                Query: query,
                IdType:idType,
                SearchId: searchId,
                Sector: sector,
                DepartmentId: dept,
                ClassificationId: classId,        
                Contract: contract,
                WorkExperienceId: workExp,
                lang:lang,
                DaysPostedWithIn
              }).then(res => {
                setJobsList(res.data.data.joblist || []);              
                setDeptName(res.data.data.deptJobCount || '')
                setClassName(res.data.data.classJobCount || '')
                setSectorName(res.data.data.sectorJobCount || '')
                dispatch(setGlobalLoading(false));
              })
          
            
          }}  />
          </div>
          <div className="col-md-8 col-12 pe-lg-0">
            <MainContent jobsList={jobsList} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
