import React from 'react';


const Register1 = () => {
    return (
        <>
            <div className="process-content">
                <div className="container">
                    <h3 className="text-center step1-heading">Sign Up for Curriculosi</h3>
                    <div className="form-section">
                        <div className="process-no">
                            <ul className="list-unstyled p-0">
                                <li className="current border-end border-1">
                                    <p className="mb-0 m-0"><span className="">1</span>Account Info</p>
                                </li>
                                <li className="border-end border-1">
                                    <p className="mb-0 m-0"><span className="">2</span>Confirm your Email</p>
                                </li>
                                <li className="">
                                    <p className="mb-0 m-0"><span className="">3</span>Account Complete</p>
                                </li>
                            </ul>
                        </div>
                        <div className="padding-step1">
                            <div className="row mb-12">
                                <div className="">
                                    <input type="text" className="form-control key-weight" placeholder="Employer Name" />
                                </div>
                            </div>
                            <div className="row mb-12">
                                <div className="col-6">
                                    <input type="text" className="form-control key-weight" placeholder="First Name" />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control key-weight" placeholder="Last Name" />
                                </div>
                            </div>
                            <div className="row mb-12">
                                <div className="">
                                    <input type="email" className="form-control key-weight" placeholder="Email" />
                                </div>
                            </div>
                            <div className="row mb-12">
                                <div className="col-6">
                                    <input type="password" className="form-control key-weight" placeholder="Password" />
                                </div>
                                <div className="col-6">
                                    <input type="password" className="form-control key-weight" placeholder="Confirm Password" />
                                </div>
                            </div>
                            <div className="row mb-12">
                                <div className="">
                                    <input type="tel" className="form-control key-weight" id="inputCity" placeholder="Telephone" />
                                </div>
                            </div>
                            <div className="row mt-4 mb-12">
                                <div className="col-6">
                                    <p className="mb-0 fw-normal fs-14">Already a member? <a className="text-decoration-none pointer-event">Sign In</a></p>
                                </div>
                                <div className="col-6 text-end">
                                    <button type="submit" className="border-0 p-btn btn-contine text-white fw-600">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Register1;