import React, { useEffect, useState } from 'react';
import { FaChevronRight, FaAngleUp, FaAngleDown } from "react-icons/fa";
import Collapse from 'react-bootstrap/Collapse';
import { GetAxios, KeyPairValue, TenderCountValue } from '../../../Shared';
import { config } from '../../../Environments';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SliderContent from './SliderContent';
import { useTranslation } from 'react-i18next';
import { ValueContainer } from 'react-select/dist/declarations/src/components/containers';

const SideContent = (props: {
    sectorName: string,
    deptName: string,
    className: string
    onSearch: (query: string,
        idType: string,
        searchId: string,
        sector: number,
        dept: number,
        classId: number,
        lang: string,
        filter: boolean,
        limit: number,
        DaysPostedWithIn: number) => void,
}) => {

    const { t } = useTranslation('home');
    const { t: ct } = useTranslation('common');
    const { t: jt } = useTranslation('job');
    const { t: tc } = useTranslation('company');

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [searchParam , setSearchParam]=useState(0);

    const [intialLoad , setIntialLoad]=useState(0);
    const [open, setOpen] = useState(false);
    const [industry, setIndustry] = useState(false);
    const [typeservice, settypeService] = useState(false);
    // const [sectors, setSectors] = useState<TenderCountValue[]>([]);
    // const [departments, setDepartments] = useState<TenderCountValue[]>([]);
    // const [Classification, setClassifications] = useState<TenderCountValue[]>([]);

    const [query, setQuery] = useState('');
    const [searchId, setSearchId] = useState('');
    const [idType, setIdType] = useState('J');

    const [sector, setSector] = useState(0);
    const [dept, setDept] = useState(0);
    const [classId, setClassId] = useState(0);
    const [filterSector, setFilterSector] = useState(0);
    const [filterDept, setFilterDept] = useState(0);
    const [filterClass, setFilterClass] = useState(0);
    const [sectorTitle, setSectorTitle] = useState(props.sectorName);
    const [classTitle, setClassTitle] = useState(props.className);
    const [DaysPostedWithIn, setDaysPostedWithIn] = useState<number>(2147483647);
    const { i18n } = useTranslation();
    const [resultCounts, setResultCounts] = useState<{
        departments: { name: string, id: number, postedTenderCount: number }[],
        classifications: { name: string, id: number, postedTenderCount: number }[],
        sectors: { name: string, id: number, postedTenderCount: number }[]
    }>({
        classifications: [],
        departments: [],
        sectors: []
    });

    const [searchresultCounts, setSearchResultCounts] = useState<{
        departments: TenderCountValue[],
        classifications: TenderCountValue[],
        sectors: TenderCountValue[]
    }>({
        classifications: [],
        departments: [],
        sectors: []
    });

    useEffect(() => {
        setSectorTitle(props.sectorName);
        setClassTitle(props.className);
    }, [props.sectorName, props.className]);


    React.useMemo(() => {
        if (searchParams.has('days'))
      //  setSearchParam(1);
            setDaysPostedWithIn(Number(searchParams.get('days')) || 2147483647);
        if (searchParams.has('query'))
       // setSearchParam(1);
            setQuery(searchParams.get('query') || '');
        if (searchParams.has('classification'))
      //  setSearchParam(1);
            setClassId(Number(searchParams.get('classification')) || 0);
        if (searchParams.has('department'))
        //setSearchParam(1);
            setDept(Number(searchParams.get('department')) || 0);
        if (searchParams.has('sector'))
       // setSearchParam(1);
            setSector(Number(searchParams.get('sector')) || 0);

        props.onSearch(searchParams.get('query') || '',
            '',
            '',
            Number(searchParams.get('sector')) || 0,
            Number(searchParams.get('department')) || 0,
            Number(searchParams.get('classification')) || 0,
            i18n.language,
            false,
            100000,
           //  searchParam==1? 1000:10,
            Number(searchParams.get('days')) || 2147483647);
            //setIntialLoad(1);
    }, [searchParams]);

    useEffect(() => {

        if (filterSector != 0 || filterDept != 0 || filterClass != 0) {
            GetAxios().post(config.apiBase + '/api/Jobs/GetL2NameandCount', {
                lang: i18n.language,
                sectorTitle: sectorTitle,
                classTitle: classTitle,
                sectorId: filterSector,
                classId: filterClass,
            }).then(res => {
                setSectorTitle(res.data.data.sectorName);
                setClassTitle(res.data.data.className)
            })
        }
    }, [i18n.language]);

    const ResultCount = (sectId: Number, departId: Number, classfyId: Number, type: string) => {
        GetAxios().post(config.apiBase + '/api/Tender/ResultCounts', {
            lang: i18n.language,
            SectorId: sectId,
            DeptId: departId,
            ClassId: classfyId,
        }).then(res => {
            if (type === "Filter") {
                setResultCounts(res.data.data);
            }
            else {
                setSearchResultCounts(res.data.data);
            }
        })
    }



    useEffect(() => { 
        ResultCount(sector, dept, classId, "Search");
        ResultCount(filterSector, filterDept, filterClass, "Filter");
      
    }, [i18n.language]);

    // useEffect(() => {
    //     if(intialLoad==1){
    //         props.onSearch('', '','', 0,  0, 0,i18n.language,false, 1000, 2147483647);     
    //     }
       
    // }, [intialLoad]);

    const onSearchId = () => {
        props.onSearch('', idType, searchId, 0, 0, 0, i18n.language, false,1000, DaysPostedWithIn);
    }
    const onSearch = () => {
        props.onSearch(query, '', '', sector, dept, classId, i18n.language, false,1000, DaysPostedWithIn);
    }
    const onFilter = () => {
        props.onSearch('', '', '', filterSector, filterDept, filterClass, i18n.language, true, 1000,DaysPostedWithIn);
    }

    const onFilterChange = (id: any, type: string) => {
        if (type === "Sector") {
            setFilterSector(Number(id));
            ResultCount(id, filterDept, filterClass, "Filter");
            window.scrollTo(0, 300);
            props.onSearch('', '', '', id, filterDept, filterClass, i18n.language, true,1000, DaysPostedWithIn);
        }
        else if (type === "Department") {
            setFilterDept(Number(id));
            ResultCount(filterSector, id, filterClass, "Filter");
            window.scrollTo(0, 300);
            props.onSearch('', '', '', filterSector, id, filterClass, i18n.language, true,1000, DaysPostedWithIn);
        }
        else if (type === "Classification") {
            setFilterClass(Number(id));
            ResultCount(filterSector, filterDept, id, "Filter");
            window.scrollTo(0, 300);
            props.onSearch('', '', '', filterSector, filterDept, id, i18n.language, true,1000,DaysPostedWithIn);
        }
    }

    const onSearchChange = (id: any, type: string) => {
        if (type === "Sector") {
            setSector(Number(id));
            ResultCount(id, dept, classId, "Search");
        }
        else if (type === "Department") {
            setDept(Number(id));
            ResultCount(sector, id, classId, "Search");
        }
        else if (type === "Classification") {
            setClassId(Number(id));
            ResultCount(sector, dept, id, "Search");
        }
    }

    const setPostedDays = (value: any) => {
        setDaysPostedWithIn(value);
        props.onSearch('', '', '', filterSector, filterDept, filterClass, i18n.language, true,1000, value);
    }


    return (
        <>
            <aside>
                <div className="sidebar-widget">
                    <div className="search-widget">
                        <div className="widget-title">
                            <h5 className="text-uppercase side-title">{ct('search')}</h5>

                        </div>
                        <div className="widget-content">
                            <div className='pb-3'>
                                {/* <span className="search-tex">Job ID or Employer Name</span> */}
                                <div className="form-check form-check-inline fs-radio">
                                    <input className="form-check-input" checked={idType === "T" ? true : false} value="T" type="radio" name="searchType" onChange={e => setIdType(e.target.value)} id="inlineCheckbox1" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">{tc('tendersearch.tenderId')}</label>
                                </div>
                                <div className="form-check form-check-inline fs-radio">
                                    <input className="form-check-input" type="radio" checked={idType === "E" ? true : false} value="E" onChange={e => setIdType(e.target.value)} name="searchType" id="inlineCheckbox2" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">{jt('search.employerNo')}</label>
                                </div>
                                <div className='d-flex flexcolumn-mob-start-j02'>
                                    <input
                                        onChange={e => setSearchId(e.target.value)}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                onSearchId();
                                            }
                                        }}
                                        value={searchId}
                                        type="text"
                                        className="form-control key-weight"
                                        id="inputName"
                                        placeholder={tc('tendersearch.searchTenderId').toString()}
                                    />
                                    <div className='ms-1 mt-10px-j02'>
                                        <button type="submit" className="button-default border-0 float-left" onClick={onSearchId}><span className="d-lg-inline">{ct('search')}</span></button>
                                    </div>
                                </div>
                                <div className="first my-4">
                                    <span className="bar text-uppercase fw-600">{ct('or')}</span>
                                </div>
                            </div>
                            <span className="search-tex">{tc('tendersearch.lookingTender')}</span>
                            <select onChange={(e) => { onSearchChange(Number(e.target.value), "Sector"); }} value={sector} className="form-select form-control key-weight mt-2" >
                                <option key={'sseinde__00'} value={0}>{ct('selectSector')}</option>
                                {searchresultCounts.sectors.map((v, index) => (
                                    <option key={'sseinde__' + index} value={v.id}>{v.name} ({v.postedTenderCount})</option>
                                ))
                                }
                            </select>
                            <div className='mt-3'>
                                <input
                                    onChange={e => setQuery(e.target.value)}
                                    value={query}
                                    type="text"
                                    className="form-control key-weight"
                                    id="inputCity"
                                    placeholder={ct('keywords').toString()}
                                />
                            </div>
                            <div className="hsb-text-1 text-muted mt-1">in</div>
                            <div className='mt-3'>
                                <select onChange={(e) => { onSearchChange(Number(e.target.value), "Classification"); }} value={classId} className="form-select form-control key-weight mt-2">
                                    <option key={'%_00'} value={0}>{ct('selectClassification')}</option>
                                    {
                                        searchresultCounts.classifications.map((v, i) => (
                                            <option key={'%_' + i} value={v.id}>{v.name} ({v.postedTenderCount})</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <select onChange={(e) => { onSearchChange(Number(e.target.value), "Department"); }} value={dept} className="form-select form-control key-weight mt-2">
                                    <option key={'%__00'} value={0}>{ct('selectDepartment')}</option>
                                    {
                                        searchresultCounts.departments.map((v, i) => (
                                            <option key={'%__' + i} value={v.id}>{v.name} ({v.postedTenderCount})</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <button type="submit" className="button-default mt-3 border-0 float-left" onClick={onSearch}><span className="d-lg-inline">{ct('search')}</span></button>
                        </div>
                    </div>
                    <div className="filter-result-widget">
                        <div className="widget-title mt-4">
                            <h5 className="text-uppercase side-title">{jt('search.filterResult')}</h5>
                        </div>
                        <div className="widget-content-para">
                            <h6 id="filterSector" className="fw-bold" >{sectorTitle || <div> {jt('search.bySector')} </div>}</h6>

                            <Collapse in={open}>
                                <ul className="filter-list p-0 filter-list-ul">
                                    <li key={'sect__00'} value={0}><a onClick={() => { onFilterChange(Number(0), "Sector"); setOpen(false); }} className="pointer-event"> <FaChevronRight className="me-2" />{ct('selectSector')}</a></li>

                                    {
                                        resultCounts.sectors.map((res, i) => (
                                            <li key={'sect__' + i} value={res.id}><a onClick={() => { onFilterChange(Number(res.id), "Sector"); setOpen(false); }} className="pointer-event"> <FaChevronRight className="me-2" />{res.name} <span>({res.postedTenderCount})</span></a></li>
                                        ))
                                    }
                                </ul>
                            </Collapse>
                        </div>

                        <span className="toggle" onClick={() => setOpen(!open)} aria-controls="example-collapse-text"
                            aria-expanded={open}>{open ? <FaAngleUp size={18} className="up-toggle" /> : <FaAngleDown size={18} className="up-toggle" />}</span>

                        <div className="widget-content-para mt-4">
                            <h6 id="filterClass" className="fw-bold" >{classTitle || <div> {jt('search.byClassfication')} </div>}</h6>
                            <Collapse in={typeservice}>
                                <div id='type-services'>

                                    <ul className="filter-list p-0 filter-list-ul">
                                        <li key={'classi__00'} value={0}><a onClick={() => { onFilterChange(Number(0), "Classification"); settypeService(false); }} className="pointer-event"> <FaChevronRight className="me-2" />{ct('selectClassification')}</a></li>

                                        {
                                            resultCounts?.classifications?.map((res, i) => (

                                                <li key={'classi__' + i} value={res.id}><a onClick={() => { onFilterChange(Number(res.id), "Classification"); settypeService(false); }} className="pointer-event"> <FaChevronRight className="me-2" />{res.name} <span>({res.postedTenderCount})</span></a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </Collapse>
                            <a className='pointer-event'>
                                <span className="toggle" onClick={() => settypeService(!typeservice)} aria-controls="type-services"
                                    aria-expanded={typeservice}>{typeservice ? <FaAngleUp size={18} className="up-toggle" /> : <FaAngleDown size={18} className="up-toggle" />}</span>

                            </a>
                        </div>
                        <div className='industry-sec'>
                            <div className="widget-content-para mt-4">
                                <h6 id="filterDept" className="fw-bold">{props.deptName || <div> {jt('search.byDepartment')} </div>}</h6>

                                <Collapse in={industry}>
                                    <ul className="filter-list p-0 filter-list-ul">
                                        <li key={'dept__00'} value={0}><a onClick={() => { onFilterChange(Number(0), "Department"); setIndustry(false); }} className="pointer-event"> <FaChevronRight className="me-2" />{ct('selectDepartment')}</a></li>
                                        {

                                            resultCounts?.departments?.map((res, i) => (

                                                <li key={'dept__' + i} value={res.id}><a onClick={() => { onFilterChange(Number(res.id), "Department"); setIndustry(false); }} className="pointer-event"> <FaChevronRight className="me-2" />{res.name} <span>({res.postedTenderCount})</span></a></li>
                                            ))
                                        }
                                    </ul>
                                </Collapse>
                            </div>
                        </div>
                        <span className="toggle" onClick={() => setIndustry(!industry)} aria-controls="industry-sec"
                            aria-expanded={industry}>{industry ? <FaAngleUp size={18} className="up-toggle" /> : <FaAngleDown size={18} className="up-toggle" />}</span>

                        <div className="widget-content-para mt-4 d-flex flex-column">
                            <div className='d-flex justify-content-between align-items-baseline'>
                                <h6 className="fw-bold" > {tc('tendersearch.tenderPostWithin')} </h6>
                                <select onChange={e => {
                                    setPostedDays(Number(e.target.value))

                                }}
                                    value={DaysPostedWithIn} className="form-select form-control form-select-sm" style={{ width: '90px' }}>
                                    <option value="2147483647">{ct('select')}</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                    <option value="60">60</option>
                                    <option value="70">70</option>
                                    <option value="80">80</option>
                                    <option value="90">90</option>
                                    <option value="100">100</option>
                                </select>

                            </div>
                            <h6 className="fw-bold" >{jt('search.days')}</h6>
                        </div>
                        <button type="submit" className="button-default mt-3 border-0" onClick={onFilter}>{jt('search.filter')}</button>
                    </div>
                    {/* <div>
                            <div className="mt-4 mb-3 my-bottom-slider " >
                            </div>
                            <h5 className="fw-bold darkblue text-center mb-3">Success Stories Just In!</h5>

                            <SliderContent />
                            <h6 className="mt-4 fw-bold text-center mb-3" style={{ color: "#007AFF" }}>Read More Success Stories  <img style={{ marginBottom: "4px" }} src='/bluearrow.png' height="14px" width="15px" alt="tender search" /> </h6>
                            <div className="mt-4 mb-3 my-bottom-slider " >
                            </div>
                        </div> */}
                </div>
            </aside>
        </>
    )
}
export default SideContent;


