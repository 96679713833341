import React, { useEffect, useState } from "react";
import PaymentTable from "./PaymentTable";
import { CardTitle } from "../Tenders/Index";
import { useTranslation } from 'react-i18next';
const Index = () => {
  const [pageSkillLimit, setPageSkillLimit] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const { t } = useTranslation('admin');
  const [update, setUpdate] = useState(false);
  return (
    <>
      <div className="container-fuild py-50 margin15" >
        <div className="card custom-shadow">
          <div className="card-header">
            <CardTitle>{t('header.invoices')}</CardTitle>
          </div>
          <div className="card-body px-4">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="fw-500">{t('payment.paidVia')}:</span>
                    <select
                      id="inputState"
                      style={{ width: "150px", height: "31px" }}
                      value={paymentMethod}
                      onChange={(e) => {
                        setPaymentMethod(e.target.value);
                        setUpdate(!update);                     
                      }}
                      className="form-select form-control w-select-list fsize-13 mx-2"
                    >
                      <option value="">  {t('payment.all')}</option>
                      <option value="CC">{t('payment.creditCard')}</option>
                      <option value="Cheque">{t('payment.cheque')}</option>
                      {/* <option value="MonCash">{t('payment.monCash')}</option> */}
                    </select>
                    <span className="fw-500">{t('common.status')}:</span>
                    <select
                      id="inputState"
                      style={{ width: "100px", height: "31px" }}
                      value={paymentStatus}
                      onChange={(e) => {
                        setPaymentStatus(e.target.value);
                        setUpdate(!update);                     
                      }}
                      className="form-select form-control w-select-list fsize-13 mx-2"
                    >
                      <option key={"status_" + 1} value="">
                      {t('payment.all')}
                      </option>
                      <option key={"status_" + 2} value="Paid">
                      {t('payment.paid')}
                      </option>
                      <option key={"status_" + 3} value="UnPaid">
                      {t('payment.unpaid')}
                      </option>
                    </select>
                    <span className="fw-500">{t('payment.type')}:</span>
                    <select
                      id="inputState"
                      style={{ width: "120px", height: "31px" }}
                      value={paymentType}
                      onChange={(e) => {
                        setPaymentType(e.target.value);
                        setUpdate(!update);                     
                      }}                  
                      className="form-select form-control w-select-list fsize-13 mx-2" >
                      <option key={"type_" + 1} value="">  {t('payment.all')} </option>
                      <option key={"type_" + 2} value="T">
                      {t('payment.tender')}
                      </option>
                      <option key={"type_" + 3} value="A">
                      {t('payment.announce')}
                      </option>
                      <option key={"type_" + 3} value="J">
                      {t('payment.jobpost')}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="fw-500">{t('common.show')}</span>
                    <select
                      id="inputState"
                      style={{ width: "75px", height: "31px" }}
                      value={pageSkillLimit}
                      onChange={(e) =>
                        setPageSkillLimit(Number(e.target.value))
                      }
                      className="form-select form-control w-select-list fsize-13 mx-2"
                    >
                      <option key={"limit_" + 1} value={10}>
                        10
                      </option>
                      <option key={"limit_" + 2} value={20}>
                        20
                      </option>
                      <option key={"limit_" + 3} value={30}>
                        30
                      </option>
                      <option key={"limit_" + 4} value={40}>
                        40
                      </option>
                      <option key={"limit_" + 5} value={50}>
                        50
                      </option>
                      <option key={"limit_" + 6} value={60}>
                        60
                      </option>
                    </select>
                    <span className="fw-500">{t('common.entries')}</span>
                  </div>
                  <div className="me-2 d-flex align-items-center">
                    <span className="me-1 fw-500">{t('common.search')}:</span>
                    <input
                      type="text"
                      name="searchString"
                      className="form-control"
                      id="searchString"
                      value={searchString}
                      onChange={(e) => {
                        setSearchString(e.target.value);
                        setUpdate(!update);              
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <PaymentTable
                  pageSkillLimit={pageSkillLimit}
                  setPageSkillLimit={setPageSkillLimit}
                  searchString={searchString}
                  paymentMethod={paymentMethod}
                  paymentStatus={paymentStatus}
                  paymentType={paymentType}
                  update={update}
                  setUpdate={setUpdate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
