import {
    Europass,
    JohannesBurg,
    MonteVideo,
    NewYork,
    Toronto,
    Omsk,
    Amsterdam,
    Illinois,
    Auckland,
    Haiphong,
    Munich,
    Zurich,
    Vienna,
    Barcelona,
    Florence,
    Prague,
    SanFrancisco,
    Quebec,
    European,
    Stockholm,
    IvyLeague,
    Polished,
    Creative,
    DoubleColumn,
    Stylish,
    Monochrome,
    Compact,
    Elegant,
    Classic,
} from "./Pages/Resumes/ResumeTemplatesAll";
import { Timeline } from "./Pages/Resumes/ResumeTemplatesAll/Timeline";

export const TemplateList: Step1TemplateSelection[] = [
    {
        id: "b7b6c96f-a925-425b-ac70-a55833eb79ef",
        component: Toronto,
        type: ["All", "Minimalist"],
        name: "TORONTO",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/toronto-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "7647e16e-3079-4720-837c-7cb05283363e",
        component: Europass,
        type: ["All", "Modern", "Minimalist"],
        name: "EUROPASS",
        badge: "EUROPEAN FORMAT",
        imgsrc: "/images/resume-img/template-images/europass-template.png",
        colors: [{ color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "a8cb0f41-5615-4609-97c5-f30a8d34111f",
        component: MonteVideo,
        type: ["All", "Modern"],
        name: "MONTEVIDEO",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/montvideo-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "f16ecb7f-9cfd-47de-b5c0-288bb395547a",
        component: NewYork,
        type: ["All", "Modern"],
        name: "NEW YORK",
        imgsrc: "/images/resume-img/template-images/newyork-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "dd1ffbfe-6ca5-4978-a004-f31f384945d6",
        component: Omsk,
        type: ["All", "Modern"],
        name: "OMSK",
        imgsrc: "/images/resume-img/template-images/Omsk-template.png",
        colors: [{ color: "rgb(129, 246, 220)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(208, 226, 255)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(253, 206, 211)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(208, 208, 208)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(238, 238, 238)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(244, 223, 205)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "6bfd5e83-effc-4963-bb91-a5c539db76e7",
        component: JohannesBurg,
        type: ["All", "Minimalist"],
        name: "JOHANNESBURG",
        imgsrc: "/images/resume-img/template-images/johannesburg-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "71d9b5e3-15b5-40a8-98e3-fb00889cf1bc",
        component: Illinois,
        type: ["All", "Minimalist"],
        name: "ILLINOIS",
        imgsrc: "/images/resume-img/template-images/illinois-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "769e4a65-2151-4771-984b-04fdb1d52112",
        component: Auckland,
        type: ["All", "Minimalist", "Classic"],
        name: "AUCKLAND",
        imgsrc: "/images/resume-img/template-images/Auckland-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "47830ac3-6126-4b81-ada0-43b37850256b",
        component: Haiphong,
        type: ["All", "Modern"],
        name: "HAIPHONG",
        imgsrc: "/images/resume-img/template-images/Haiphong-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "a5053e2e-cebe-4616-b68a-8291b0eed4c7",
        component: Amsterdam,
        type: ["All", "Modern"],
        name: "AMSTERDAM",
        imgsrc: "/images/resume-img/template-images/Amsterdam-template.png",
        colors: [{ color: "rgb(153, 162, 152)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(153, 162, 179)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(191, 169, 169)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(163, 163, 163)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(191, 180, 167)", borderColor: "rgb(150, 155, 158)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "ec2a5554-ff19-4b86-bb81-0d42480fcd93",
        component: SanFrancisco,
        type: ["All", "Classic"],
        name: "SAN FRANCISCO",
        badge: "NEW",
        imgsrc: "/images/resume-img/template-images/sanfrancisco-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "a5065cd9-5a86-482b-a7e9-ddde7b853108",
        component: Prague,
        type: ["All", "Modern", "Classic"],
        name: "PRAGUE",
        imgsrc: "/images/resume-img/template-images/prague-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "edb1f990-b654-4046-aebe-60fa65835145",
        component: Zurich,
        type: ["All", "Modern", "Minimalist"],
        name: "ZURICH",
        badge: "NEW",
        imgsrc: "/images/resume-img/template-images/Zurich-template.png",
        colors: [{ color: "rgb(24, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 223)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(220, 127, 56)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(191, 191, 191)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" },],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "4d816916-bddc-4ec9-9a85-ab434fab7533",
        component: Vienna,
        type: ["All", "Modern"],
        name: "VIENNA",
        badge: "",
        imgsrc: "/images/resume-img/template-images/Vienna-template.png",
        colors: [{ color: "rgb(226, 233, 232)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(243, 228, 221)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(230, 229, 226)", borderColor: "rgb(191, 191, 191)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "b98e664e-3abf-4619-990d-a73d7845fcb5",
        component: Quebec,
        type: ["All", "Modern"],
        name: "QUEBEC",
        badge: "MOST POPULAR",
        imgsrc: "/images/resume-img/template-images/quebec-template.webp",
        colors: [{ color: "rgb(129, 246, 220)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(208, 226, 255)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(253, 206, 211)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(208, 208, 208)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(238, 238, 238)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(244, 223, 205)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "2098bd25-8ae7-40bd-af6f-76e68996ee08",
        component: Munich,
        type: ["All", "Minimalist"],
        name: "MUNICH",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/munich-template.webp",
        colors: [{ color: "rgb(61, 64, 66)", borderColor: "rgb(87, 87, 87)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "bf06cf95-e52d-40eb-8650-b9a588254ffb",
        component: Barcelona,
        type: ["All", "Classic"],
        name: "BARCELONA",
        badge: "New",
        imgsrc: "/images/resume-img/template-images/barcelona-template.webp",
        colors: [{ color: "rgb(188, 186, 162)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(255, 100, 100)", borderColor: "rgb(253, 33, 59)" },
        { color: "rgb(175, 186, 193)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(255, 140, 56)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "3d9f6909-e244-4bc0-a41e-22322e172ca4",
        component: Florence,
        type: ["All", "Classic"],
        name: "FLORENCE",
        badge: "New",
        imgsrc: "/images/resume-img/template-images/florence-template.webp",
        colors: [{ color: "rgb(80, 103, 235)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(255, 142, 119)", borderColor: "rgb(253, 33, 59)" },
        { color: "rgb(63, 63, 63)", borderColor: "rgb(87, 87, 87)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "e13358ef-f434-474f-9151-9119d40bdf45",
        component: Stockholm,
        type: ["All", "Modern"],
        name: "STOCKHOLM",
        badge: "",
        imgsrc: "/images/resume-img/template-images/stockholm-template.webp",
        colors: [{ color: "rgb(45, 173, 126)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(87, 84, 252)", borderColor: "rgb(118, 164, 234)" },
        { color: "rgb(255, 116, 31)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(66, 66, 66)", borderColor: "rgb(87, 87, 87)" },
        { color: "rgb(220, 220, 220)", borderColor: "rgb(191, 191, 191)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "22d1c023-2d4c-43c7-8a33-5d32bae33130",
        component: European,
        type: ["All", "Modern"],
        name: "EUROPEAN 2023",
        badge: "NEW",
        imgsrc: "/images/resume-img/template-images/european2023-template.webp",
        colors: [{ color: "rgb(220, 230, 241)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(0, 51, 153)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "7252b4c5-3bd8-47fb-98cf-11e73d0852a2",
        component: IvyLeague,
        type: ["All", "Classic"],
        name: "IVY LEAGUE",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/Ivy-league-template.webp",
        colors: [{ color: "rgb(111 120 120)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(196 136 28)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(1 142 175)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "02a28790-b9f8-4eee-977b-3b35e1331731",
        component: Polished,
        type: ["All", "Modern"],
        name: "POLISHED",
        badge: "New",
        imgsrc: "/images/resume-img/template-images/polished_template.webp",
        colors: [{ color: "rgb(0 102 102)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(25 39 60)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(93 26 56)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "21e1b2d7-72ac-4c74-a374-ac3f06e76103",
        component: Creative,
        type: ["All", "Classic"],
        name: "CREATIVE",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/creative_template.webp",
        colors: [{ color: "rgb(34 47 92)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(0 102 102)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(220 38 127)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "754ae26d-5420-4cd5-951f-2916b399606a",
        component: Timeline,
        type: ["All", "Minimalist", "Classic"],
        name: "TIMELINE",
        badge: "NEW",
        imgsrc: "/images/resume-img/template-images/timeline_template.webp",
        colors: [{ color: "rgb(249 107 7)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(86 172 242)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(220 38 127)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "f5a3398d-67e9-49de-b14d-7e3a64c2a9d6",
        component: DoubleColumn,
        type: ["All", "Classic"],
        name: "DOUBLE COLUMN",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/simple_double_column_template.webp",
        colors: [{ color: "rgb(30 144 255)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(226 158 26)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(60 179 113)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "e1cc362b-77de-42f0-95ec-3a61d7d00433",
        component: Stylish,
        type: ["All", "Minimalist"],
        name: "STYLISH",
        badge: "New",
        imgsrc: "/images/resume-img/template-images/stylish_template.webp",
        colors: [{ color: "rgb(30 144 255)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(60 179 113)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(125 142 142)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "7694b927-0043-43aa-964c-ffc2fd2c4140",
        component: Monochrome,
        type: ["All", "Classic"],
        name: "MONOCHROME",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/monochrome_template.webp",
        colors: [{ color: "rgb(104 104 104)", borderColor: "rgb(118, 164, 234)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "b67523d8-26b4-41ba-bffd-dbd1860659fe",
        component: Compact,
        type: ["All", "Classic"],
        name: "COMPACT",
        badge: "NEW",
        imgsrc: "/images/resume-img/template-images/compact_template.webp",
        colors: [{ color: "rgb(30 144 255)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(196 136 28)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(220 38 127)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "03d2397d-3785-4d39-adaa-9b358d0e7392",
        component: Elegant,
        type: ["All", "Minimalist"],
        name: "ELEGANT",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/elegant_template.webp",
        colors: [{ color: "rgb(34 64 92)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(0, 182, 203)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(60, 109, 240)", borderColor: "rgb(118, 164, 234)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
    {
        id: "d5a69a80-15a6-48b0-abf1-4e766d8853a4",
        component: Classic,
        type: ["All", "Classic"],
        name: "SIMPLE CLASSIC",
        badge: "NEW",
        imgsrc: "/images/resume-img/template-images/simple_classic_template.webp",
        colors: [{ color: "rgb(111 120 120)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(86 172 242)", borderColor: "rgb(152, 122, 113)" }, { color: "rgb(220 38 127)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight: 1.0
    },
]