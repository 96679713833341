
import React from "react";

export const AboutUsIndex = () => {
    return (
        <>
            <div className="container">
                <div className="row py-50">
                    <div className="col-12 px-2">
                        <div style={{ height: '100vh' }}>
                            <h1 className="mb-2 mt-0">About Us</h1>
                            <p>This is my curriculosi about us page.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}