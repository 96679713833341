import React, { useState, useEffect } from 'react';
import {
   Barcelona,
   Compact,
   Vienna,
   Zurich,
} from './ResumeTemplatesAll';
import TemplateSlider_HeroSection from '../home/TemplateSlider_HeroSection';
import Index from './Templates/Index';


const AllTemplatesListDummy = () => {

   return (
      <>
         <div className='container'>
            <div className='row'>
               <div className='col-md-6'>
                  <div className='heading-sec'>
                     <h1 className="top-heading-sec m-bottom-4 text-center-xsm">Curriculosi <span className="text-blue">Resume</span> <span className="text-blue">Builder</span> helps you get hired at top companies</h1>
                  </div>
               </div>
               <div className='col-md-6'>
                  <TemplateSlider_HeroSection />
               </div>
               <div className='col-md-12'>
                  <Index />
               </div>
            </div>
         </div>
      </>
   )
}

export default AllTemplatesListDummy
