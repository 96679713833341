import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { config } from "../Environments";

export const AuthCallback = () => {
    const auth = useSelector((state: AppStore) => state.auth);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (auth.isAuthenticated) {
            let url = localStorage.getItem(config.identity.returnUrlKey) || '/';
            if (
                url.indexOf('require-confirm') > -1 ||
                url.indexOf('forbidden403') > -1
            )
                url = '/';
            localStorage.removeItem(config.identity.returnUrlKey);
            navigate(url, {
                replace: true,
            });
        }
    }, [auth.isAuthenticated, navigate]);
    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    return (<></>);
};