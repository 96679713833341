import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCcMastercard, FaChevronCircleDown, FaCreditCard, FaEllipsisV, FaPaypal, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';

export const OrderCheckout = () => {

    const [cardNumber, setCardNumber] = useState<boolean>(false);
    const [payCheque, setPayCheque] = useState<boolean>(false);

    const handleCardNumber = () => {
        setCardNumber(true);
        setPayCheque(false);
    }
    const handlePayCheque = () => {
        setCardNumber(false);
        setPayCheque(true);
    }

    return (
        <>
            <div className='container pb-5'>
                <h2 className='fw-700 my-4'> Order Checkout </h2>
                <div className='card my-4'>
                    <div className='row pb-4 p-3'>
                        <div className='col-md-8 mb-3'>
                            <div className='billing border border-1 p-3 mb-3'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6 className='fw-600'> Billing Information</h6>
                                    <FaChevronCircleDown size={18} className='text-blue' />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='fw-500 text-gray mb-0'> Expiress </p>
                                    <p className='fw-500 text-gray mb-0'>
                                        <Link to="" className=''>Edit</Link>
                                    </p>
                                </div>
                                <p className='fw-500 text-gray mb-0'> 25bis, 2eme ruelle Nazon, Port-au-Prince, Ouest </p>
                            </div>
                            <div className='billing border border-1 p-3'>
                                <div className='fs-14'>
                                    <div className='detail'>
                                        <div className='top-bottom'>
                                            <div className='row'>
                                                <div className='col-md-12 mb-2'>
                                                    <div className='payment-method'>
                                                        <div className='title'>
                                                            <h5 className='fw-700'> Payment Method </h5>
                                                            <div className='border border-top border-1 my-3'></div>
                                                        </div>
                                                        <div className="form-check my-2 d-flex align-items-center justify-content-start">
                                                            <input className="form-check-input" type="radio" name="card" id="inlineCheckbox2" value="card" />
                                                            <img src="/images/content/mastercard-img.png" className='h-28 mx-1' alt="master card img" />
                                                            <label className="form-check-label key-weight fs-21 fw-600" htmlFor="inlineCheckbox2"> Ending with 6045 </label>
                                                        </div>
                                                        <div className="form-check my-2 d-flex align-items-center justify-content-start">
                                                            <input className="form-check-input" type="radio" name="card" id="paypalAgree" value="card" />
                                                            <img src="/images/content/paypal-img.png" className='h-28 mx-1' alt="paypal image" />
                                                            <label className="form-check-label key-weight fs-21 fw-600" htmlFor="paypalAgree"> Paypal Agreement ####31C </label>
                                                        </div>
                                                        <div className="form-check my-2 d-flex align-items-center justify-content-start">
                                                            <input className="form-check-input" type="radio" name="card" id="ending8040" value="card" />
                                                            <img src="/images/content/mastercard-img.png" className='h-28 mx-1' alt="master card" />
                                                            <label className="form-check-label key-weight fs-21 fw-600" htmlFor="ending8040"> Ending with 8040 </label>
                                                        </div>
                                                    </div>
                                                    <div className='border border-top border-1 my-3'></div>
                                                </div>
                                                <div className='col-md-12 col-12 mb-2'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" checked={cardNumber} onChange={handleCardNumber} name="cardNumber" id="cardNumber1" />
                                                            <label className="form-check-label key-weight" htmlFor="cardNumber1"> Card Number </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="payCheque" checked={payCheque} onChange={handlePayCheque} id="payCheque1" />
                                                            <label className="form-check-label key-weight" htmlFor="payCheque1"> Pay By Cheque </label>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {cardNumber ?
                                                <>
                                                    <div className='row'>
                                                        <div className='col-md-12 mb-2'>
                                                            <div className="input-group">
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                                <input type="number" className="form-control key-weight" name='cardNumber' value="" placeholder="**** **** **** ****" aria-describedby="cardNumber" />
                                                            </div>
                                                            <small className="text-danger">Required</small>
                                                        </div>
                                                        <div className='col-md-4 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="expiryYear"> Expiration Date: </label>
                                                            </div>
                                                            <select name="expiryMonth" value="expiryMonth" className=' form-select'>
                                                                <option key={'month+___' + 1} value=""> Please Select </option>
                                                                <option key={'month+___' + 2} value="01">01</option>
                                                                <option key={'month+___' + 3} value="02">02</option>
                                                                <option key={'month+___' + 4} value="03">03</option>
                                                                <option key={'month+___' + 5} value="04">04</option>
                                                                <option key={'month+___' + 6} value="05">05</option>
                                                                <option key={'month+___' + 7} value="06">06</option>
                                                                <option key={'month+___' + 8} value="07">07</option>
                                                                <option key={'month+___' + 9} value="08">08</option>
                                                                <option key={'month+___' + 10} value="09">09</option>
                                                                <option key={'month+___' + 11} value="10">10</option>
                                                                <option key={'month+___' + 12} value="11">11</option>
                                                                <option key={'month+___' + 13} value="12">12</option>
                                                            </select>
                                                            <small className="text-danger"> Required </small>
                                                        </div>
                                                        <div className='col-md-4 mb-2'>
                                                            <div className='line-height-sta invisible'>
                                                                <label className="form-label key-weight" htmlFor="expiryYear"></label>
                                                            </div>
                                                            <select name="expiryYear" value="expiryYear" className='form-select mt-2'>
                                                                <option key={'years+___' + 0} value=""> Please Select </option>
                                                                <option key={'years+___' + 1} value="2023">2023</option>
                                                                <option key={'years+___' + 2} value="2024">2024</option>
                                                                <option key={'years+___' + 3} value="2025">2025</option>
                                                                <option key={'years+___' + 4} value="2026">2026</option>
                                                                <option key={'years+___' + 5} value="2027">2027</option>
                                                                <option key={'years+___' + 6} value="2028">2028</option>
                                                                <option key={'years+___' + 7} value="2029">2029</option>
                                                                <option key={'years+___' + 8} value="2030">2030</option>
                                                                <option key={'years+___' + 9} value="2031">2031</option>
                                                                <option key={'years+___' + 10} value="2032">2032</option>
                                                            </select>
                                                            <small className="text-danger"> Required </small>
                                                        </div>
                                                        <div className='col-md-4 col-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="securityCode"> Security Code </label>
                                                            </div>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control key-weight" name='securityCode' value="" placeholder="***" aria-describedby="securityCode" />
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                            </div>
                                                            <small className="text-danger">Required</small>
                                                        </div>
                                                    </div>
                                                </>
                                                : null}

                                            {payCheque ?
                                                <>
                                                    <div className='mt-2 mb-3'>
                                                        <p className='fw-500 text-gray mb-0'> Please post a cheque to the order curriculosi.com at 25 bis , 2eme Ruelle Nazon Port-au-Prince , Dominican Republic HT6111", </p>
                                                    </div>
                                                </>
                                                : null}

                                            <div className='row'>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='d-grid gap-2'>
                                                        <button type="submit" className="border-0 fs-18 p-btn btn-contine text-white fw-600">
                                                            Submit Order
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='d-grid gap-2'>
                                                        <button type="submit" className="border-0 fs-18 p-btn btn-contine text-white fw-600">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {cardNumber ?
                                                <>
                                                    <div className="form-check my-2 d-flex align-items-start">
                                                        <input className="form-check-input feedback-input" type="checkbox" id="isEasytoFindInfo" name="backupPayment" value="backupPayment" />
                                                        <label className="form-check-label fs-14 ms-2 fw-600" htmlFor="backupPayment">
                                                            Use as backup payment method for this account
                                                        </label>
                                                    </div>
                                                </>
                                                : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='p-3 order-summary-bg'>
                                <h5 className='fw-700 my-2'> Order Summary </h5>
                                <div className='edit-sec'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'> 6 items </p>
                                        </div>
                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'>
                                                <Link to="" className=''> Change Plan </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='border border-top border-1 my-2'></div>
                                </div>
                                <div className='detail-order'>
                                    <div>
                                        <Link to="" className='fs-12'> No of Package Days </Link>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> Subtotal </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> $71.94 </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <Link to="" className='fs-14'> Taxes $ Fees </Link>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> $1.08 </p>
                                        </div>
                                    </div>
                                    <div className='border border-top border-1 my-2'></div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <h5 className='fw-500 mb-0'> Total (USD) </h5>
                                        </div>
                                        <div>
                                            <h5 className='fw-500 mb-0 text-blue'> $73.02 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}