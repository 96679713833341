import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { config } from "../../Environments";
import { GetAxios } from "../../Shared";
import FsLightbox from "fslightbox-react";
import { useTranslation } from 'react-i18next';

const Sidebar = () => {

   const { t } = useTranslation('candidate');


   const [seminarlist, setSeminarList] = useState<any>([]);
   const [sortBy, setSortBy] = useState('HTL');
   const [random, setRandom] = useState(0);

   // const min= Math.ceil(1);
   // const max = Math.floor(3);
   // const rand = Math.floor(Math.random() * (max - min + 1)) + min


   React.useEffect(() => {
      GetAxios().get(config.apiBase + '/api/Announce/GetListingAnnounces', {
         params: {
           listingNo:5
         }
      }).then(s => {
         setRandom(Math.floor(Math.random() * (Math.floor(s.data.data.length - 1) - Math.ceil(0) + 1)) + Math.ceil(0));
         setSeminarList(s.data.data || []);
      });
   }, []);


   const [lightbox, setLightbox] = useState({
      toggler: false,
      image: '',
   });

   const handleImageClick = (img: string) => {

      setLightbox({
         toggler: !lightbox.toggler,
         image: img
      });
   }


   return (
      <div className='sidebar-result'>
         <div className='title-sidebar bg-gray'>
            <Link className='text-decoration-none text-white pointer-event' to={'/job-matcher-assessment'}>
               <FaChevronLeft className="me-2" size={14} />  {t('job.assessmentResult.changeAnswers')}
            </Link>
         </div>
         <div className='panel mt-4'>
            <div className='panel-para bg-light-gray'>
               <h4 className='title-exper mb-3'> {t('job.assessmentResult.yourSkills')} </h4>
               <div className='para-skill-result fw-500'>
                  <p className='mb-1'> {t('job.assessmentResult.yourSkillsList1')}.</p>
                  <p className='mb-1'> {t('job.assessmentResult.yourSkillsList2')}.</p>
                  {/* <p className='mb-1'> {t('job.assessmentResult.yourSkillsList3')}.</p> */}
                  <p className='mb-1'> {t('job.assessmentResult.yourSkillsList4')}.</p>
               </div>
            </div>
         </div>
         <div className="mt-4">
            <span style={{ float: "right" }}> {t('job.assessmentResult.seminarAd')}</span>
            {seminarlist.length > 0 &&
               <div>
                  <div onClick={() => handleImageClick(config.apiBase + seminarlist[random]?.banner)}>
                     <img className="w-100" src={config.apiBase + seminarlist[random]?.banner} alt="Announce" />
                  </div>
               </div>
            }

         </div>
         {
            <FsLightbox
               toggler={lightbox.toggler}
               type={'image'}
               sources={[lightbox.image]}
            />
         }
      </div>
   )
}
export default Sidebar;

