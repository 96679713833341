import Content from './Content';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';


const SkillMatcher = () => {

  const { t } = useTranslation('candidate');

  return (
    <>

      <div className='row bg-white p-3 mx-auto'>
        <div className="col-md-4 col-sm-12">
          <Sidebar title={t('job.matcher.jobMatcherTitle')} description={t('job.matcher.rateLevelWorkolace')} />
        </div>
        <div className="col-md-8 col-sm-12">
          <Content />
        </div>
      </div>
    </>
  )
}
export default SkillMatcher;