import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { FaEdit, FaMoneyCheck, FaEye, FaTrash, FaRegUser, FaFile, FaToggleOn, FaToggleOff, FaPlayCircle, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import AddPaymentModal from "../Payments/PaymentModel";
import { useTranslation } from 'react-i18next';



const EmployerTable = (props: { pageSkillLimit: number, update: boolean, 
  setPageSkillLimit: (arg:number)=>void ,searchString:string, setUpdate: (arg:boolean) => void } ) => {
  const dispatch = useDispatch();
  const [employerlist, setEmployerList] = useState<any>([]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation('admin');
  const { t: ts } = useTranslation('alert');


  React.useEffect(() => {
    fetchEmployers();
  }, [props.update]);

  function fetchEmployers() {
    dispatch(setGlobalLoading(true));
    GetAxios()
      .get(config.apiBase + "/api/Admin/GetEmployers?search=" + props.searchString)
      .then((res) => {
        setEmployerList(res.data.data || []);
      
        dispatch(setGlobalLoading(false));
      });
  }

  function handleOnViewClick(empId: string) {
   
    navigate("/admin/employerprofile?id=" + empId);
  }
  function handleOnDeleteClick(empId: string) {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('company.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Employer/DeleteEmployer?id=' + empId,
            ).then(s => {
              toast(ts('company.delete'), { type: 'success' });
              dispatch(setGlobalLoading(false));
              props.setUpdate(!props.update);
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    });
  }
  function handleApproveClick(userId:string) {
    confirmAlert({
      title: t('common.confirmtoActivate').toString(),
      message: t('common.sureActiveMember').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/ActiveDeactivateUser?userId=" + userId)
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleDisapproveClick(userId:string) {
    confirmAlert({
      title: t('common.confirmtoDeactivate').toString(),
      message: t('common.sureInactiveMember').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/ActiveDeactivateUser?userId=" + userId)
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });     
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  return (
    <>

      <div className="table-responsive">
        <Table bordered className="bg-white">
          <thead>
            <tr>
              <th className="text-center fw-700 fw-16 w-12">{t('common.employerNo')}</th>
              <th className="fw-700 fw-16">{t('common.companyName')}</th>
              <th className="fw-700 fw-16">{t('common.personRep')}</th>
              <th className="fw-700 fw-16">{t('common.city')}</th>
              <th className="fw-700 fw-16">{t('common.department')}</th>
              <th className="fw-700 fw-16 ">{t('common.tel')}</th>
              <th className="fw-700 fw-16 ">{t('common.email')}</th>
              <th className="fw-700 fw-16 ">{t('common.status')}</th>
              <th className="text-center w-12">{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {(employerlist || []).slice((currentPage - 1) * props.pageSkillLimit, currentPage * props.pageSkillLimit).map((item:any, index:number) => {
              return (
                <tr key={index}>
                  <td>{item.employerNo}</td>
                  <td>{item.companyName}</td>
                  <td>{item.personRep}</td>
                  <td>{item.address}</td>
                  <td>{item.department}</td>
                  <td>{item.tel}</td>
                  <td>{item.email}</td>
                  <td className="text-center">
                    {item.isActive ? t('common.active').toString() : t('common.inactive').toString()}
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary px-2 py-1"
                      onClick={() => handleOnViewClick(item.userId)}
                    >
                      <FaEye className="text-white" title={t('common.view') || ''} size={16} />
                    </button>
                    {item.isActive ? (
                      <button
                        type="button"
                        className="btn btn-success px-2 py-1 mx-1"
                        onClick={() => handleDisapproveClick(item.userId)}
                      >
                        <FaToggleOn size={16} title={t('common.setInactiveMember') || ''} />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger px-2 py-1 mx-1"
                        onClick={() => handleApproveClick(item.userId)}
                      >
                        <FaToggleOff size={16} title={t('common.setActiveMember') || ''} />
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-dark px-2 py-1"
                      onClick={() => handleOnDeleteClick(item.userId)}
                    >
                      <FaTrash className="text-white" title={t('common.delete') || ''} size={16} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="">
          <div className="d-flex align-items-center">
            <span>  {t('common.showing')}  {(currentPage > employerlist.length) ? employerlist.length : currentPage}  {t('common.to')}  {(props.pageSkillLimit > employerlist.length) ? employerlist.length : props.pageSkillLimit}  {t('common.of')}  {employerlist.length} {t('common.entries')}</span>
          </div>
        </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}
                >
                  {" "}
                  <FaChevronLeft />
                </button>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(employerlist.length / props.pageSkillLimit))} >
                  {Math.ceil(employerlist.length / props.pageSkillLimit)}
                </a>
              </li>
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(employerlist.length / props.pageSkillLimit)
                      ? false
                      : true
                  }
                >
                  {" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default EmployerTable;


