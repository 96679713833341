import Topbar from './Header/Topbar';
import BottomNavBar from './Header/BottomNavbar';

function Header() {



  
  return (
    <>
      <div className="Mobile ">
        <div className="header-notification-bar">
          <Topbar />
          <BottomNavBar />
        </div>
      </div>
      <div className="Dektop">
        <div className="header-notification-bar">
          <Topbar />
        </div>
        <BottomNavBar />
      </div>
    </>
  );
}
export default Header;