import { FaEnvelope, FaFacebookF, FaGooglePlusG, FaTwitter, FaLinkedinIn, FaPinterest } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs"
import React from "react";
import { useEffect, EffectCallback, DependencyList, useRef } from "react";


export const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload || '{}');
};

export const UserRole = (token?: string): 'Employee' | 'Employer' | 'Admin' | '' => {
    return parseJwt(token || '.')?.role || '';
};

// export const UserGender = (token?: string): 'Male' | 'Female' | 'Both' | '' => {
//     return parseJwt(token || '.')?.gender|| '';
// };




export const parseLinkIcon = (link: string) => {
    switch (true) {
        case link.includes('facebook'):
            return <FaFacebookF />;
        case link.includes('twitter'):
            return <FaTwitter />;
        case link.includes('gmail'):
            return <FaEnvelope />;
        case link.includes('mail'):
            return <FaGooglePlusG />;
        case link.includes('linkedin'):
            return <FaLinkedinIn />;
        case link.includes('pinterest'):
            return <FaPinterest />;
        default:
            return <BsGlobe />;
    }
}
export const parseSocialLinkColor = (link: string) => {
    switch (true) {
        case link.includes('facebook'):
            return 'fb-icon';
        case link.includes('twitter'):
            return 'twitter-icon';
        case link.includes('gmail'):
            return 'googleplus-icon';
        case link.includes('mail'):
            return 'mail-icon';
        case link.includes('linkedin'):
            return 'linkedin-icon';
        case link.includes('pinterest'):
            return 'pinterest-icon';
        default:
            return "web-icon";
    }
}

export const range = (from: number, to: number) => {
    return [...Array.from(Array(to + 1).keys())].slice(from, to);
}
export const Sorter = <T extends {}>(column: keyof T) => (a: T, b: T,) => {
    if (a[column] < b[column]) {
        return -1;
    }
    if (a[column] > b[column]) {
        return 1;
    }
    return 0;
}
export const decodeHTMLEntities = (text?: string) => {
    return new DOMParser().parseFromString(text || '', 'text/html').documentElement.textContent;
}
export const useDebouncedEffect = (effect: React.EffectCallback, deps: React.DependencyList, delay: number) => {
    React.useEffect(() => {
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
    }, [...(deps || []), delay]);
}
export const reorder = <T extends any[]>(list: T, startIndex: number, endIndex: number): T => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result as T;
};

/**
 * This hook gets called only when the dependencies change but not during initial render.
 *
 * @param {EffectCallback} effect The `useEffect` callback function.
 * @param {DependencyList} deps An array of dependencies.
 *
 * @example
 * ```
 *  useNonInitialEffect(()=>{
 *      alert("Dependency changed!");
 * },[dependency]);
 * ```
 */
export const useNonInitialEffect = (effect: React.EffectCallback, deps?: React.DependencyList | undefined) => {
    const initialRender = useRef(true);

    useEffect(() => {
        let effectReturns: any = () => { };

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            (effectReturns as any) = effect();
        }

        if (effectReturns && typeof effectReturns === "function") {
            return effectReturns;
        }
    }, deps);
};