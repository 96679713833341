import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { config } from "../../../Environments";
import { GetAxios } from "../../../Shared";

import { setGlobalLoading } from "../../../Store";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const Index = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = React.useState<any>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('admin');
  const { t:ts } = useTranslation('alert');
  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    if (searchParams.get('storyId')) {

      GetAxios(navigate).get(config.apiBase + '/api/Admin/ViewStory?storyId=' + searchParams.get('storyId')).then(res => {
        if (!res.data.success) {
          dispatch(setGlobalLoading(false));
          toast(ts('error.ungetStory'), { type: 'error' });
        } else {

          console.warn(res.data.data);
          setData(res.data.data);
          dispatch(setGlobalLoading(false));
        }
      });
    }
  }, [searchParams]);
  return (
    <>
      <div className="container py-50">
        <div className="card custom-shadow">
          <div className="card-body px-4">
          <div className="P-2">
            <h4 className='fw-700'>{t('sucessstories.preview')}</h4>
            <div className="border-bottom my-2 border-2"></div>
          </div>
            <div className="row pt-2">
              <div className='col-md-4'>
                <p className='text-uppercase fw-600 mb-0'> {t('sucessstories.personName')}: </p>
                <p className='fw-500 text-gray'>{data?.personName}</p>
              </div>
              <div className='col-md-4'>
                <p className='text-uppercase fw-600 mb-0'>{t('sucessstories.personTitle')}:</p>
                <p className='fw-500 text-gray'>{data?.personTitle || 'N/A'}</p>
              </div>

              <div className='col-md-4'>

                <p className='text-uppercase fw-600 mb-0'>{t('sucessstories.createdDate')}:</p>
                <p className='fw-500 text-gray'>{data?.createdDate || 'N/A'}</p>

              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <p className='text-uppercase fw-600 mb-0'>{t('sucessstories.story')}:</p>
                <p className='fw-600 text-gray'>{data?.story || 'N/A'} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Index;
