import React from 'react';

const Search = (props: { onChange: React.ChangeEventHandler<HTMLInputElement> | undefined }) => {
    return (
        <>
            <div className="form-group has-search float-end">
                <span className="fa fa-search form-control-feedback search-icon position-absolute ms-1"></span>
                <input type="text" className="form-control ps-4 " placeholder="Search" onChange={props.onChange} />
            </div>
        </>
    )
}

export default Search; 