
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaCheck, FaLaptop, FaShareSquare, FaRegCalendarAlt } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import { FaPlayCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

export const AnnouncePlans = () => {

    const { t: t } = useTranslation('plan');
    const navigate = useNavigate();

    return (
        <>
            <div className='container upgrade-account-sec mb-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='mb-4 mx-auto text-center'>
                            <h2 className='fw-700 text-blue'>
                                {t('seminar.greaterAudience')} <br />
                                <span className='text-uppercase'> {t('seminar.forAllYour')}</span> <br />
                                {t('seminar.webinars')} <br />
                                {t('seminar.workshop')} <br />
                                {t('seminar.seminars')}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='row py-3 px-5 g-4 g-lg-4 g-md-4'>
                    <div className='col-md-4 col-12'>
                        <div>
                            <Card className='upgrade-account-card position-relative'>
                                <div className='px-3'>
                                    <ListGroup variant="flush" className='list-group-border-none'>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                                                <div>
                                                    <label className="form-check-label plan-label-radio" htmlFor="free">
                                                        3 {t('common.days')}
                                                    </label>
                                                </div>
                                                <div>
                                                    {/* <input className="form-check-input" type="radio" id="selectedPlan" name="selectedPlan" value="1"
                                                        checked={selectedPlan == 1 ? true : false}
                                                        onChange={handlePlanChange} /> */}
                                                </div>
                                            </div>
                                            <div className="bor-bottom-plan-account mb-2">
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div>
                                                <h2 className='fw-800'>$9.99</h2>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-2'>
                                                    <FaLaptop size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'> {t('seminar.simpleFlyer')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-2'>
                                                    <FaShareSquare size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.blowup')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-2'>
                                                    <FaRegCalendarAlt size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>3 {t('common.days')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <div className='plan-btn-box mb-3'>
                                        <button type="submit" onClick={() => {
                                            navigate("/employer-account/announce-plan-checkout?sId=1");
                                        }
                                        } className="border-0 btn-contine text-white fw-600 text-uppercase">
                                            {t('common.select')}
                                        </button>
                                    </div>
                                </div>

                            </Card>

                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div>
                            <Card className='upgrade-account-card position-relative'>
                                <div className='account-badge-plan text-uppercase fw-600'>{t('common.mostPopular')}</div>
                                <div className='px-3'>
                                    <ListGroup variant="flush" className='list-group-border-none'>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                                                <div>
                                                    <label className="form-check-label plan-label-radio" htmlFor="days">
                                                        10 {t('common.days')} {t('seminar.unlimited')}
                                                    </label>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                            <div className="bor-bottom-plan-account mb-2">
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='me-2'>
                                                <h2 className='fw-800'>$19.99</h2>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaLaptop size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.multipleListing')} (3 pages)</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <GoChecklist size={22} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.multipleFlyer')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaShareSquare size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.blowup')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaRegCalendarAlt size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>10 {t('common.days')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <div className='plan-btn-box mb-3'>
                                        <button type="submit" onClick={() => {
                                            navigate("/employer-account/announce-plan-checkout?sId=2");
                                        }
                                        } className="border-0 btn-contine text-white fw-600 text-uppercase">
                                            {t('common.select')}
                                        </button>
                                    </div>
                                </div>

                            </Card>

                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div>
                            <Card className='upgrade-account-card position-relative'>
                                <div className='px-3'>
                                    <ListGroup variant="flush" className='list-group-border-none'>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                                                <div>
                                                    <label className="form-check-label plan-label-radio" htmlFor="monthly">
                                                        30 {t('common.days')} {t('seminar.unlimited')}
                                                    </label>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                            <div className="bor-bottom-plan-account mb-2">
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='me-2'>
                                                <h2 className='fw-800'>$59.9</h2>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaLaptop size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.multipleListing')} (5 pages)</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <GoChecklist size={22} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.multipleFlyer')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaShareSquare size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.blowup')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaRegCalendarAlt size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>30 {t('common.days')} </p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        {/* <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <GoChecklist size={22} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('seminar.tipExample')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className="bor-bottom-plan-account mb-2">
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaCheck size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('common.onlineSupport')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item> */}
                                    </ListGroup>
                                    <div className='plan-btn-box mb-3'>
                                        <button type="submit" onClick={() => {
                                            navigate("/employer-account/announce-plan-checkout?sId=3");
                                        }
                                        } className="border-0 btn-contine text-white fw-600 text-uppercase">
                                            {t('common.select')}
                                        </button>
                                    </div>
                                </div>

                            </Card>
                        </div>
                    </div>
                    {/* <div className='col=md-12 col-12'>    
                       <div className="text-start " style={{ float: "right" }}>
                           <button disabled={loading} type="submit" onClick={setSeminarPlan} className="border-0  p-btn btn-flyer text-white fw-600 text-uppercase">
                               {
                                   loading ? <RotatingLines
                                       strokeColor="white"
                                       strokeWidth="5"
                                       animationDuration="0.75"
                                       width="18"
                                       visible={true}
                                   /> : <>
                                        {t('common.choosePlan')} <FaPlayCircle className='fa-lg ms-1' />
                                   </>
                               }
                           </button>
                       </div>
      
               </div>*/}
                </div>
            </div>
        </>
    )
}