import { Link, useSearchParams } from 'react-router-dom';
import RangeSlider from 'react-bootstrap-range-slider';
import { FieldArray, FormikProvider } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import React, { useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { FaPlayCircle } from "react-icons/fa";
import { RotatingLines } from 'react-loader-spinner';


const MainContent = (props) => {

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');


    const [searchParams] = useSearchParams();
    const formik = props.formik;

    const objEditorState = props?.objEditorState;
    const setObjState = props?.setObjState;
    const procEditorState = props?.procEditorState;
    const setProcState = props?.setProcState;
    const partEditorState = props?.partEditorState;
    const setPartState = props?.setPartState;
    const techEditorState = props?.techEditorState;
    const setTechState = props?.setTechState;
    const economicEditorState = props?.economicEditorState;
    const setEconomicState = props?.setEconomicState;
    const isAdmin = props?.isAdmin | false;



    return (
        <>
            <div className=''>
                <div className="client-description">
                    <div className="card border-0 rounded-0">
                        <div className="p-4">
                            <div className="card-body p-0">
                                <div className="candidate-sec">
                                    <div className="container p-1">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-12 mt-1 margin-language">
                                                <div className="">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="candidate-title mb-3">
                                        <div className="row border-bottom border-3 border-light border-top">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    {/* <label htmlFor="" className="col-form-label label-text fw-bold text-capitalize"> {t('callTender.object')}</label> */}
                                                    <input type="text" name="objectTitle"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.objectTitle}
                                                        className="form-control   font-input" placeholder={t('callTender.title').toString()} />
                                                    {formik.errors.objectTitle && formik.touched.objectTitle ? <small className="text-danger">{formik.errors.objectTitle}</small> : ""}

                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <div className="">
                                                        <Editor
                                                            editorState={objEditorState}
                                                            editorClassName="editor-height bg-white"
                                                            wrapperClassName='bg-white'
                                                            stripPastedStyles={true}
                                                            onEditorStateChange={e => {
                                                                setObjState(e);
                                                                formik.setFieldValue("object", draftToHtml(convertToRaw(e.getCurrentContent())));
                                                            }}

                                                        />
                                                        {formik.errors.object && formik.touched.object ? <small className="text-danger">{formik.errors.object}</small> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row border-bottom border-3 border-light border-top">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    {/* <label htmlFor="" className="col-form-label label-text fw-bold text-capitalize"> {t('callTender.procedure')} </label> */}
                                                    <input type="text" name="procedureTitle"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.procedureTitle}
                                                        className="form-control  font-input" placeholder={t('callTender.title').toString()} />
                                                    {formik.errors.procedureTitle && formik.touched.procedureTitle ? <small className="text-danger">{formik.errors.procedureTitle}</small> : ""}

                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <div className="">
                                                        <Editor
                                                            editorState={procEditorState}
                                                            editorClassName="editor-height bg-white"
                                                            wrapperClassName='bg-white'
                                                            stripPastedStyles={true}
                                                            onEditorStateChange={e => {
                                                                setProcState(e);
                                                                formik.setFieldValue("procedure", draftToHtml(convertToRaw(e.getCurrentContent())));
                                                            }}

                                                        />
                                                        {formik.errors.procedure && formik.touched.procedure ? <small className="text-danger">{formik.errors.procedure}</small> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border-bottom border-3 border-light border-top">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    {/* <label htmlFor="" className="col-form-label label-text fw-bold "> {t('callTender.legalEconomic')}<br />  {t('callTender.financialAnd')} <br />  {t('callTender.technicalInformation')}</label> */}
                                                    <input type="text" name="participationTitle"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.participationTitle}
                                                        className="form-control  font-input" placeholder={t('callTender.title').toString()} />
                                                    {formik.errors.participationTitle && formik.touched.participationTitle ? <small className="text-danger">{formik.errors.participationTitle}</small> : ""}

                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <div className="">
                                                        <Editor
                                                            editorState={partEditorState}
                                                            editorClassName="editor-height bg-white"
                                                            wrapperClassName='bg-white'
                                                            //placeholder="Condition for Participation"
                                                            stripPastedStyles={true}
                                                            onEditorStateChange={e => {
                                                                setPartState(e);
                                                                formik.setFieldValue("participation", draftToHtml(convertToRaw(e.getCurrentContent())));
                                                            }}

                                                        />
                                                        {formik.errors.participation && formik.touched.participation ? <small className="text-danger">{formik.errors.participation}</small> : ""}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row border-bottom border-3 border-light border-top">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <input type="text" name="economicStandingTitle"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.economicStandingTitle}
                                                        className="form-control   font-input" placeholder={t('callTender.title').toString()} />
                                                    {formik.errors.economicStandingTitle && formik.touched.economicStandingTitle ? <small className="text-danger">{formik.errors.economicStandingTitle}</small> : ""}

                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <div className="">
                                                        <Editor
                                                            editorState={economicEditorState}
                                                            editorClassName="editor-height bg-white"
                                                            wrapperClassName='bg-white'
                                                            // placeholder="Economic and Financial Standing"
                                                            stripPastedStyles={true}
                                                            onEditorStateChange={e => {
                                                                setEconomicState(e);
                                                                formik.setFieldValue("economicStanding", draftToHtml(convertToRaw(e.getCurrentContent())));
                                                            }}

                                                        />
                                                        {formik.errors.economicStanding && formik.touched.economicStanding ? <small className="text-danger">{formik.errors.economicStanding}</small> : ""}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row border-bottom border-3 border-light border-top">
                                            <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <input type="text" name="technicalAbilityTitle"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.technicalAbilityTitle}
                                                        className="form-control font-input" placeholder={t('callTender.title').toString()} />
                                                    {formik.errors.technicalAbilityTitle && formik.touched.technicalAbilityTitle ? <small className="text-danger">{formik.errors.technicalAbilityTitle}</small> : ""}

                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                                                <div>
                                                    <div className="">
                                                        <Editor
                                                            editorState={techEditorState}
                                                            editorClassName="editor-height bg-white"
                                                            wrapperClassName='bg-white'
                                                            //placeholder="Technical and professional ability"
                                                            stripPastedStyles={true}
                                                            onEditorStateChange={e => {
                                                                setTechState(e);
                                                                formik.setFieldValue("technicalAbility", draftToHtml(convertToRaw(e.getCurrentContent())));
                                                            }}

                                                        />
                                                        {formik.errors.technicalAbility && formik.touched.technicalAbility ? <small className="text-danger">{formik.errors.technicalAbility}</small> : ""}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="gap-2 d-md-block mt-3 ps-2">
                                            <div>
                                                <button disabled={props.loading} type="submit" onClick={props.confirmSubmit} className="button-default text-decoration-none cursor-pointer mt-1 border-0 me-1">
                                                    {
                                                        props.loading ? <RotatingLines
                                                            strokeColor="white"
                                                            strokeWidth="5"
                                                            animationDuration="0.75"
                                                            width="18"
                                                            visible={true}
                                                        /> : <div>
                                                            {ct('submit')}
                                                        </div>
                                                    }
                                                </button>
                                                <button className="button-default mt-1 border-0 cancel-btn"> {ct('cancel')}</button>
                                                {
                                                    searchParams.get('tid') && searchParams.get('tid') > 0 && isAdmin == false &&
                                                    <Link to={"/tender/tenderpreview?" + searchParams.toString()} state={{ from: "tenderRegister" }} target='_blank' className="border-0 text-decoration-none mx-1 btn-preview-register text-white fw-600"> {ct('preview')}</Link>
                                                }
                                                {
                                                    searchParams.get('tid') && searchParams.get('tid') > 0 && isAdmin == true &&
                                                    <Link to={"/admin/tenderpreview?" + searchParams.toString()} state={{ from: "admin" }} target='_blank' className="border-0 text-decoration-none mx-1 btn-preview-register text-white fw-600"> {ct('preview')}</Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MainContent;


