import axios from "axios";
import { config } from "../Environments";

export class SignInManager {
    options = {
        client_id: config.identity.clientId,
        client_secret: config.identity.clientSecret,
        grant_type: config.identity.grantType,
        response_type: 'code',
        username: '',
        password: ''
    };
    constructor() {

    }
    async Login(userName?: string, password?: string) {
        this.options.username = userName || '';
        this.options.password = password || '';
        const response = await axios.post<TokenResponse>(config.identity.tokenUrl, new URLSearchParams(this.options));
        return response;
    }
    get IsAuthenticated(): boolean {
        const token = this.GetToken();
        const e = Number(localStorage.getItem('expiry'));
        const g = localStorage.getItem('generated');
        if (token && e && g) {
            const expiry = e;
            const generated = new Date(g);
            generated.setSeconds(generated.getSeconds() + expiry);
            return generated > new Date();
        } else {
            return false;
        }
    }
    UserRole() {

    }
    SetToken(token: TokenResponse) {
        //in active expire time
        const expireTime=Date.now()+300000;
        localStorage.setItem("expireTime",expireTime.toString());
        localStorage.setItem("isLoggedIn","true");     
        localStorage.setItem('token', JSON.stringify(token));   
        localStorage.setItem('expiry', token.expires_in.toString());
        localStorage.setItem('generated', new Date().toISOString());
    }
    GetToken() {
        return JSON.parse(localStorage.getItem('token') || '{}') as TokenResponse | null
    }

    public get AccessToken(): string {
        const token = this.GetToken();
        if (token) {
            return token.access_token;
        }
        else {
            return '';
        }
    }

    RemoveToken() {
        
        localStorage.removeItem('token');
        localStorage.removeItem('expiry');
        localStorage.removeItem('generated');
    }
}