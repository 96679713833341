export const ParseHTMLTips = (list: any, t?: any) => {
  return Object.keys(list)?.map(x =>
    `  <div className='tips-sec'>
        <h6 className='fw-700 fs-14'>${t(x)}</h6>
        <div>
        <ul key={i}>
            ${list[x]?.map((z: string, i: number) => `<li class="fs-14 mb-3 tip-line-height fw-500 tips-dot">${t?t(z):z}</li>`)?.join(" ")}
        </ul>
        </div>
      </div>`
  )
}