import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { sort } from '../../../Components/Pagination';
import { config } from '../../../Environments';
import { ApiResponseE, GetAxios, UserRole } from '../../../Shared';
import { JobItem } from './JobItem';
import { ProfilePublicView } from './Profile';
import { parseJwt } from '../../../Shared';
import { useTranslation } from 'react-i18next';


const MainContent = (props:
    {
        data: ProfilePublicView | undefined,
      
        onJobCount: (count: number) => void,
         userId: string ,
    }) => {
    const data = props.data;


    const { t: ct } = useTranslation('common');

    const auth = useSelector((state: AppStore) => state.auth);
    const [JobData, setJobData] = useState<any[]>([]);
    const [searchParams] = useSearchParams();
    const [sortCol, setSortCol] = React.useState('name|asc');
    const [currentUserId, setcurrentUserId] = React.useState('');
    useEffect(() => {
        //if (auth.isAuthenticated)

        GetAxios().get<ApiResponseE<any[]>>(config.apiBase + '/api/Jobs/MyPostedJobs?id=' + (props.userId==null? searchParams.get('id'):props.userId)).then(res => {
        //GetAxios().get<ApiResponseE<any[]>>(config.apiBase + '/api/Jobs/MyPostedJobs?id=' + (searchParams.get('id') || '')).then(res => {
                props.onJobCount(res.data.data?.length || 0);
                setJobData(res.data.data);
            });
        //}, [auth]);
    }, );
    React.useEffect(() => {
        if (auth.isAuthenticated) {
          const parsedClaims = parseJwt(auth.raw?.access_token || '.');     
          setcurrentUserId(parsedClaims?.sub);
          
        }
      }, [auth, auth.isAuthenticated]);
    return (
        <>
            <div className="card border-0 rounded-0">
                <div className="card-body candidate-sec">
                    <div className="candidate-title mb-3">
                        <p className="h4 mb-3 text-uppercase"> {ct('about')} {data?.companyName}</p>
                    </div>
                    <div className="detail-content lh-lg" dangerouslySetInnerHTML={{ __html: data?.companyDescription || '' }}>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="d-flex w-100 justify-content-between align-items-center mt-5">
                    <p className="text-start mb-0 h5 text-capitalize"> {ct('availableJob')} </p>
                    <div>
                        <select value={sortCol} onChange={(e) => setSortCol(e.target.value)} style={{ width: "200px" }} className="form-select form-control key-weight w-select-list float-end">
                            <option  value={'title|asc'}>{ct('nameA')} -{'>'} Z</option>
                            <option value={'title|desc'}>{ct('nameZ')} -{'>'} {ct('a')}</option>
                            <option value={'id|desc'}>{ct('newToOld')}</option>
                            <option value={'id|asc'}>{ct('oldToNew')}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="client-description app-content">
                {sort(JobData, sortCol.split('|')[0], sortCol.split('|')[1] as any).map((x, i) =>
                    <JobItem  job={x} index={i} userId={currentUserId} key={'job_items__' + i * 2} />
                    //<JobItem isPublic={searchParams.has('id') || [...searchParams.values()].length > 0 || UserRole(auth.raw?.access_token) != 'Employer'} job={x} index={i} key={'job_items__' + i * 2} />
               
                )}
                {
                    (JobData?.length || 0) == 0 &&
                    <p className='text-danger ms-2 ps-1'> {ct('noJobFound')} </p>
                }
            </div>
        </>
    )
}

export default MainContent;