import { useMemo } from 'react';

export const DOTS = '...';

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = (props: {
    totalCount: number,
    pageSize: number,
    siblingCount?: number,
    currentPage: number
}) => {
    const totalCount = props.totalCount;
    const pageSize = props.pageSize;
    const siblingCount = props.siblingCount || 1;
    const currentPage = props.currentPage;

    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        /*
          We do not want to show dots if there is only one position left 
          after/before the left/right page count as that would lead to a change if our Pagination
          component size which we do not want
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
};

export const SortList = (arr: any[], field: string, mode: 'asc' | 'desc') => {
    if (field.includes('id'))
        return sortNumber(arr, field, mode);
    else
        return sort(arr, field, mode);
}
export const sort = (arr: any[], field: string, mode: 'asc' | 'desc') => {
    if (mode == 'asc') {
        return arr.sort((a, b) => {
            if ((a[field] + '').replace('undefined', '').replace('null', '').toLowerCase() > (b[field] + '').replace('undefined', '').replace('null', '').toLowerCase()) { return 1; }
            if ((b[field] + '').replace('undefined', '').replace('null', '').toLowerCase() > (a[field] + '').replace('undefined', '').replace('null', '').toLowerCase()) { return -1; }
            return 0;
        })
    } else {
        return arr.sort((a, b) => {
            if ((a[field] + '').replace('undefined', '').replace('null', '').toLowerCase() < (b[field] + '').replace('undefined', '').replace('null', '').toLowerCase()) { return 1; }
            if ((b[field] + '').replace('undefined', '').replace('null', '').toLowerCase() < (a[field] + '').replace('undefined', '').replace('null', '').toLowerCase()) { return -1; }
            return 0;
        })
    }
}
export const sortNumber = (arr: any[], field: string, mode: 'asc' | 'desc') => {
    if (mode == 'asc') {
        return arr.sort((a, b) => {
            if (Number(a[field]) > Number(b[field])) { return 1; }
            if (Number(b[field]) > Number(a[field])) { return -1; }
            return 0;
        })
    } else {
        return arr.sort((a, b) => {
            if (Number(a[field]) < Number(b[field])) { return 1; }
            if (Number(b[field]) < Number(a[field])) { return -1; }
            return 0;
        })
    }
}