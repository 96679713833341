import React, { useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import PrintIcon from '../../Components/Common/PrintIcon';
import { useReactToPrint } from "react-to-print";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { config } from "../../Environments";
import { GetAxios } from "../../Shared";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setGlobalLoading } from "../../Store";
import { useTranslation } from 'react-i18next';


const MainContent = () => {

  const { t } = useTranslation('candidate');
  const { t: ct } = useTranslation('common');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
  });
  const { i18n } = useTranslation();
  const [interestResults, setInterestResults] = useState<any>([]);
  const [sortBy, setSortBy] = useState('HTL');
  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios().get(config.apiBase + '/api/SkillMatcher/GetResult', {
      params: {
        lang: i18n.language,
        sortBy: sortBy
      }
    }).then(s => {
      setInterestResults(s.data.data || []);
      dispatch(setGlobalLoading(false));
    });
  }, [i18n.language, sortBy]);



  return (
    <>
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-center border-bottom-light">
          <div className="border-0">
            <div className="cursor-pointer border-none" onClick={handlePrint}>
              {/* <PrintIcon /> */}
            </div>
          </div>
        </div>
        <div className="assessment-para pt-3">
          <p className="mb-1 fs-22 fw-700">
            {t('skill.assessmentResult.assessmentResultTitle')}!
          </p>
          <p className="mb-1 fs-13 fw-500"> {t('skill.assessmentResult.assessmentResultSubtitle')}.</p>
          <p className="mb-3 fs-13 fw-500"> {t('skill.assessmentResult.assessmentResultDesc')}.</p>
        </div>
        <div className="result-table">
          <div className="theader">
            <div className="py-2">
              <span className="d-flex align-items-center justify-content-center justify-content-lg-end justify-content-md-end me-2">
                <b> {t('skill.assessmentResult.sortBy')} </b>
                <select
                  value={sortBy}
                  onChange={s => setSortBy(s.target.value)}
                  style={{ width: "165px", height: "31px" }}
                  className="form-select form-control w-select-list float-end fsize-13"
                >
                  <option key={"s_" + 1} value={'HTL'}> {t('skill.assessmentResult.matchHighToLow')} </option>
                  <option key={"s_" + 2} value={'LTH'}> {t('skill.assessmentResult.matchLowToHigh')} </option>
                  <option key={"s_" + 3} value={'ATZ'}> {t('skill.assessmentResult.jobAToZ')} </option>
                  <option key={"s_" + 4} value={'ZTA'}> {t('skill.assessmentResult.jobZToA')} </option>
                </select>
              </span>
            </div>
          </div>
          <div className="print-sec">
            <ComponentToPrint ref={componentRef} interestResults={interestResults} />
          </div>
        </div>
        <div className="download-file mt-3">
          <select
            id="inputState"
            style={{ width: "124px", height: "31px" }}
            className="form-select form-control w-select-list float-start fsize-13"
          >
            <option value="Download">
              {ct('download')}
            </option>
            <option value="PDF"> {t('skill.assessmentResult.pdf')} </option>
            <option value="Word"> {t('skill.assessmentResult.Word')} </option>
          </select>
        </div>
      </div>
    </>
  );
};
export default MainContent;

export const ComponentToPrint = React.forwardRef((props: any, ref: any) => {

  const { t } = useTranslation('candidate');

  const [currentPage, setCurrentPage] = useState(1);

  const [pageSkillLimit, setPageSkillLimit] = useState(20);
  const { interestResults } = props;
  return (
    <>
      <Table ref={ref}>
        <thead className="theader">
          <tr>

            <th className="p_header">  {t('skill.assessmentResult.serialNo')}</th>
            <th className="p_header">  {t('skill.assessmentResult.match')} </th>
            <th className="p_header"> {t('skill.assessmentResult.education')}</th>
          </tr>
        </thead>
        <tbody>
          {(interestResults || []).slice((currentPage - 1) * pageSkillLimit, currentPage * pageSkillLimit)
            .map((Item: any, Index: number) => {
              return (
                <tr className="tr-skill-result fsize-13" key={'job_skills))_' + Index}>

                  <td>{(currentPage - 1) * pageSkillLimit + Index + 1}</td>
                  <td className="p-result-td">
                    <span>{Item.name}</span>
                  </td>
                  <td>{Item.education}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center">
        <div className="">
          <select
            id="inputState"
            value={pageSkillLimit}
            onChange={e => setPageSkillLimit(Number(e.target.value))}
            style={{ width: "75px", height: "31px" }}
            className="form-select form-control w-select-list float-start fsize-13">



            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
          </select>
          <span className="ms-2">  {t('skill.assessmentResult.perPage')}</span>
        </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}
                >
                  {" "}
                  <FaChevronLeft />
                </button>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              <li className="page-item me-2 pb-result"

              >
                <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(interestResults.length / pageSkillLimit))} >
                  {Math.ceil(interestResults.length / pageSkillLimit)}
                </a>
              </li>
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(interestResults.length / pageSkillLimit)
                      ? false
                      : true
                  }
                >
                  {" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
});