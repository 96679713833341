import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { FaRegFileAlt, FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../../../../Environments';
import { GetAxios } from '../../../../../../Shared';
import { setGlobalLoading } from '../../../../../../Store';
import { setResumeActive, setResumeAddRecord } from '../../../../../../Store/Slices/ResumeSlice';
import TipsPopup from '../../../../Components/TipsPopup';
import { ParseHTMLTips } from '../../../../../../Components/Common/ParseTipsHTML';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';

//const currentSectionId = 13;
export const ReferenceAdd = () => {

    const { t } = useTranslation('resume');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, resumeId } = useParams();


    const references = useSelector((state: AppStore) => state.resume.references);
    const information = useSelector((state: AppStore) => state.resume.activeReference);


    useEffect(() => {
        const defaultValue = references.find(s => s.id == Number(id));
        dispatch(setResumeActive({ references: defaultValue }));
    }, [references, id, resumeId]);

    const setValue = (value: { [n in keyof ResumeReferences]: ResumeReferences[n] }) => {
        const info = { ...(information || {}), ...value };
        dispatch(setResumeActive({ references: info }));
        dispatch(setResumeAddRecord({ references: info }));
    };

    const Savebtn = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).post(config.apiBase + '/api/Resume/SaveReference', {
            ...information,
            id: id,
            resumeId: resumeId
        }).then(s => {
            navigate('..');
            dispatch(setGlobalLoading(false));
        })
    }


    const Backbtn = () => {
        confirmAlert({
            title: t('newResumeCard.backAlertMsg.title').toString(),
            message: t('newResumeCard.backAlertMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('save').toString(),
                    onClick: Savebtn
                },
                {
                    label: t('exit').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        navigate('..');
                    }
                }
            ]
        })
    }


    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-4'>
                        <TipsPopup title="contactStep.tips" description="referenceMdl.description"
                            tipsList={ParseHTMLTips(referenceTipsBody, t)} />
                    </div>
                    <div className="mb-3">
                        <div className="dZcjzV sections-header">
                            <div className="feVKyO">  {t('referenceStep.referenceTitle')} </div>
                        </div>
                        <div className="gJaquO">  {t('referenceStep.referenceDesc')}. </div>
                    </div>
                    <div className="fFIUJl sections-header">
                        <div className="dJqmzX">
                            <div className="hfzZao">
                                <div className="czFa-Df"> {t('referenceStep.reference')} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.chloe').toString()} value={information?.name || ''} onChange={e => setValue({ name: e.target.value })} />
                        <label className='label-float'> {t('referenceStep.personName')}</label>
                        <span className='helper-text ms-3'>{t('contactStep.egChloe').toString()}  </span>
                    </div>
                </div>

            </div>
            <div className='col-md-12'>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <input type="text" className="form-control rounded bg-field" placeholder={t('experStep.experAdd.sanFranciscoCa').toString()} value={information?.city || ''} onChange={e => setValue({ city: e.target.value })} />
                        <label className='label-float'> {t('experStep.experAdd.cityStateCountry')} </label>
                        <span className='helper-text ms-3'>{t('eg')}. {t('experStep.experAdd.sanFranciscoCa')}</span>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <input type="text" className="form-control rounded bg-field" placeholder='551 123-7676' value={information?.tel || ''} onChange={e => setValue({ tel: e.target.value })} />
                        <label className='label-float'> {t('phone')} </label>
                        <span className='helper-text ms-3'>{t('contactStep.eg')}. {`(551 123-7676)`}</span>
                    </div>
                </div>
            </div>
            <div>
                {/* <Editor
                    editorState={editorState}
                    editorClassName="editor-height"
                    stripPastedStyles={true}
                    onEditorStateChange={e => {
                        setEditState(e);
                        setValue({ content: draftToHtml(convertToRaw(e.getCurrentContent())) });
                    }}
                /> */}
                {/* <p className='helper-text ms-3 text-level'>{t('eg')}. {t('referenceStep.referenceHelpText')}.</p> */}
            </div>
            <div className="bottom-btn-sticky">
                <div className='next-previous-btns d-flex align-items-center justify-content-between'>
                    <div className=''>
                        <Button className="fw-500 previous-btn bg-back" onClick={Backbtn}>
                            <FaArrowLeft className="me-2" /> {t('back')}
                        </Button>
                    </div>
                    <div className=''>
                        <Button className="save-btn-resume fw-500"
                            onClick={Savebtn}
                        ><FaRegFileAlt className="me-1" size={20} /> {t('save')} </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

const referenceTipsBody = {
    "tipsMdlTitle": ["referenceMdl.tipsList1",
        "referenceMdl.tipsList2",
        "referenceMdl.tipsList3",
        "referenceMdl.tipsList4",
        'referenceMdl.tipsList5',
        'referenceMdl.tipsList6',
        "referenceMdl.tipsList7",
        "referenceMdl.tipsList8",
        "referenceMdl.tipsList9",
    ],
    "referenceMdl.exmp.title": ["referenceMdl.exmp.exmpList1",
        "referenceMdl.exmp.exmpList2",
        "referenceMdl.exmp.exmpList3",
        "referenceMdl.exmp.exmpList4",
    ],
}