import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
//import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { config } from "../../Environments";
import { GetAxios } from "../../Shared";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useTranslation } from 'react-i18next';
import FsLightbox from "fslightbox-react";



const skillInterestPopup = [
   {
      mtitle: "skill.realisticModl.mtitle",
      ftitle: "skill.realisticModl.ftitle",
      modalShow: false,
      description: "skill.realisticModl.description",
      listtitle: "skill.realisticModl.theyLike",
      listItem: [
         { listText: "skill.realisticModl.listText1" },
         { listText: "skill.realisticModl.listText2" },
         { listText: "skill.realisticModl.listText3" },
      ]
   },
   {
      mtitle: "skill.investigateModl.mtitle",
      ftitle: "skill.investigateModl.ftitle",
      modalShow: false,
      description: "skill.investigateModl.description",
      listtitle: "skill.investigateModl.theyLike",
      listItem: [
         { listText: "skill.investigateModl.listText1" },
         { listText: "skill.investigateModl.listText2" },
      ]
   },
   {
      mtitle: "skill.artisticModl.mtitle",
      ftitle: "skill.artisticModl.ftitle",
      modalShow: false,
      description: "skill.artisticModl.description",
      listtitle: "skill.artisticModl.theyLike",
      listItem: [
         { listText: "skill.artisticModl.listText1" },
         { listText: "skill.artisticModl.listText2" },
      ]
   },
   {
      mtitle: "skill.socialModl.mtitle",
      ftitle: "skill.socialModl.ftitle",
      modalShow: false,
      description: "skill.socialModl.description",
      listtitle: "skill.socialModl.theyLike",
      listItem: [
         { listText: "skill.socialModl.listText1" },
         { listText: "skill.socialModl.listText2" },
         { listText: "skill.socialModl.listText3" },
      ]
   },
   {
      mtitle: "skill.enterprisingModl.mtitle",
      ftitle: "skill.enterprisingModl.ftitle",
      modalShow: false,
      description: "skill.enterprisingModl.description",
      listtitle: "skill.enterprisingModl.theyLike",
      listItem: [
         { listText: "skill.enterprisingModl.listText1" },
         { listText: "skill.enterprisingModl.listText2" },
         { listText: "skill.enterprisingModl.listText3" },
      ]
   },
   {
      mtitle: "skill.conventionalModl.mtitle",
      ftitle: "skill.conventionalModl.ftitle",
      modalShow: false,
      description: "skill.conventionalModl.description",
      listtitle: "skill.conventionalModl.theyLike",
      listItem: [
         { listText: "skill.conventionalModl.listText1" },
         { listText: "skill.conventionalModl.listText2" },
      ]
   },
]


const Sidebar = () => {

   const { t } = useTranslation('candidate');

   const [seminarlist, setSeminarList] = useState([]);

   const [random, setRandom] = useState(0);
   // const min= Math.ceil(1);
   // const max = Math.floor(3);
   // const rand = Math.floor(Math.random() * (max - min + 1)) + min


   React.useEffect(() => {
      GetAxios().get(config.apiBase + '/api/Announce/GetListingAnnounces', {
         params: {
            listingNo: 5
         }
      }).then(s => {
         setRandom(Math.floor(Math.random() * (Math.floor(s.data.data.length - 1) - Math.ceil(0) + 1)) + Math.ceil(0));
         setSeminarList(s.data.data || []);
      });
   }, []);

   const options = {
      scales: {
         x: {
            title: {
               display: true,
               text: t('skill.assessmentResult.interests').toString(),
            },

         },
         y: {
            title: {
               display: true,
               text: t('skill.assessmentResult.score').toString(),
            },
            min: 0,
            max: 80,
            ticks: {
               stepSize: 20
            }
         }
      },
   };

   const [interestlists, setInterestScores] = useState([]);
   const [interestJson, setInterestJson] = useState([]);
   React.useEffect(() => {

      setInterestJson(skillInterestPopup);
      GetAxios().get(config.apiBase + '/api/SkillMatcher/GetChartData',
      ).then(s => {
         setInterestScores(s.data.data || []);

      });
   }, []);

   const [lightbox, setLightbox] = useState({
      toggler: false,
      image: '',
   });

   const handleImageClick = (img) => {

      setLightbox({
         toggler: !lightbox.toggler,
         image: img
      });
   }


   // const handleInterestClick = (index) => {
   //    var temp = [...interestJson];
   //    for (var i = 0; i <= temp.length; i++) {
   //       if (i !== index) {
   //          temp[index].modalShow = false;
   //       }
   //    }
   //    temp[index].modalShow = true;
   //    setInterestJson(temp);
   // }

   // const handleInterestHide = (index) => {
   //    var temp = [...interestJson];
   //    for (var i = 0; i <= temp.length; i++) {
   //       temp[index].modalShow = false;
   //    }
   //    setInterestJson(temp);
   // }

   const realistic = interestlists.filter(x => x.tag === 'R')[0]?.score || 0;
   const investigative = interestlists.filter(x => x.tag === 'I')[0]?.score || 0;
   const artistic = interestlists.filter(x => x.tag === 'A')[0]?.score || 0;
   const social = interestlists.filter(x => x.tag === 'S')[0]?.score || 0;
   const enterprising = interestlists.filter(x => x.tag === 'E')[0]?.score || 0;
   const conventional = interestlists.filter(x => x.tag === 'C')[0]?.score || 0;

   // const label=t('skill.assessmentResult.yourskillsscores').toString();

   return (
      <>

         <div className='sidebar-result'>
            <div className='title-sidebar bg-gray'>
               <Link to="/skill-matcher-assessment" className='text-decoration-none text-white pointer-event'><FaChevronLeft className="me-2" size={14} /> {t('skill.assessmentResult.changeAnswers')} </Link>
            </div>
            <div className='img-sec'>
               <div className='img-border p-2'>
                  <Bar
                     height={"400px"}
                     data={{
                        labels: ['R', 'I', 'A', 'S', 'E', 'C'],
                        datasets: [
                           {

                              label: t('skill.assessmentResult.yourskillsscores').toString(),
                              backgroundColor: [
                                 '#B8D9C3',
                                 '#E5BED3',
                                 '#FCFCB3',
                                 '#C6B8D9',
                                 '#F2D3B5',
                                 '#BFDAED'
                              ],
                              borderColor: [
                                 '#B8D9C3',
                                 '#E5BED3',
                                 '#FCFCB3',
                                 '#C6B8D9',
                                 '#F2D3B5',
                                 '#BFDAED'
                              ],
                              barPercentage: 0.6,
                              data: [realistic, investigative, artistic, social, enterprising, conventional],
                           },
                        ],
                     }}
                     options={options} />


                  <div className='skillAssessmentBadge mt-4'>
                     <SkillResultBadge realistic={realistic} investigative={investigative} artistic={artistic}
                        social={social} enterprising={enterprising} conventional={conventional} />
                  </div>
                  {/* <div className="border-top border-3 mt-3"></div>
                  <div className='click-interest pt-3'>
                     <p className='fw-700 fs-18'>You can click on any interest below to learn more.</p>
                     <ul className='fw-600'>
                        {interestJson.map((item, index) => {
                           return (
                              <>
                                 <li className='mb-1' key={index}>
                                    <Link className="text-decoration-underline fw-600" onClick={() => handleInterestClick(index)} >{item.title}</Link>
                                    <InterestModal modalTitle={item.title}
                                       modalDescription={item.description}
                                       modalListtitle={item.listtitle}
                                       modalListItem={item.listItem}
                                       InterestModalShow={item.modalShow}
                                       InterestModalHide={() => handleInterestHide(index)} />
                                 </li>
                              </>
                           )
                        })}
                     </ul>
                  </div> */}
               </div>
            </div>
            <div className='panel mb-5 mt-4' >
               <span style={{ float: "right" }}> {t('skill.assessmentResult.seminarAd')} </span>
               {/* <img className="w-100" src={"/images/seminars/seminar-img-" + [rand] + ".jpg"} alt="Seminar"/>
            */}
               {
                  seminarlist.length > 0 &&
                  <div>
                     <div onClick={() => handleImageClick(config.apiBase + seminarlist[random]?.banner)}>
                        <img className="w-100" src={config.apiBase + seminarlist[random]?.banner} alt="Seminar" />

                        {/* <img className="w-100" src={config.apiBase + seminarlist[Math.floor(Math.random() * (Math.floor(seminarlist.length - 1) - Math.ceil(0) + 1)) + Math.ceil(0)].banner} alt="Seminar" />
                 */} </div>
                  </div>
               }

               {
                  <FsLightbox
                     toggler={lightbox.toggler}
                     type={'image'}
                     sources={[lightbox.image]}
                  />

               }
            </div>
         </div>
      </>
   )
}
export default Sidebar;

// export const options = {
//    scales: {
//       x: {
//          title: {
//             display: true,
//             text: "skill.assessmentResult.interests"
//          },

//       },
//       y: {
//          title: {
//             display: true,
//             text: 'Score'
//          },
//          min: 0,
//          max: 80,
//          ticks: {                             
//             stepSize: 20
//          }
//       }
//    },
//    // vAxis: { ticks: [0, 20, 40, 60, 80] },
//    // chart: {
//    //    title: "Your Skills",
//    //    subtitle: "Skill score details",
//    // },
// };

export const SkillResultBadge = (props) => {

   const { t } = useTranslation('candidate');

   const [greenInterestBox, setGreenInterestBox] = useState(false);
   const [pinkInterestBox, setPinkInterestBox] = useState(false);
   const [yellowInterestBox, setYellowInterestBox] = useState(false);
   const [blueInterestBox, setBlueInterestBox] = useState(false);
   const [brownInterestBox, setBrownInterestBox] = useState(false);
   const [cyanInterestBox, setCyanInterestBox] = useState(false);

   const realisticScore = props.realistic;
   const investigativeScore = props.investigative;
   const artisticScore = props.artistic;
   const socialScore = props.social;
   const enterprisingScore = props.enterprising;
   const conventionalScore = props.conventional
   const styleBox = { backgroundColor: "#888888", color: "#fff" };
   const [gender, setGender] = useState('');

   React.useEffect(() => {
      GetAxios().get(config.apiBase + '/api/General/GetUserSex')
         .then(res => {
            setGender(res.data.data || '');
         })
   }, [gender]);

   const [modalShow, setModalShow] = useState(false);
   const [modelIndex, setModelIndex] = useState(0);
   return (
      <>
         <div className='d-flex pb-3 justify-content-between flex-wrap'>
            <div className='green'>
               {/* <InterestBox green></InterestBox> */}
               <InterestText greenText style={greenInterestBox ? styleBox : null} onMouseEnter={() => setGreenInterestBox(true)} onMouseLeave={() => setGreenInterestBox(false)}>R</InterestText>
            </div>
            <div className='magentaPink'>
               {/* <InterestBox magentaPink></InterestBox> */}
               <InterestText magentaPinkText style={pinkInterestBox ? styleBox : null} onMouseEnter={() => setPinkInterestBox(true)} onMouseLeave={() => setPinkInterestBox(false)}>I</InterestText>
            </div>
            <div className='yellowGreen'>
               {/* <InterestBox yellowGreen></InterestBox> */}
               <InterestText yellowGreenText style={yellowInterestBox ? styleBox : null} onMouseEnter={() => setYellowInterestBox(true)} onMouseLeave={() => setYellowInterestBox(false)}>A</InterestText>
            </div>
            <div className='blueMagenta'>
               {/* <InterestBox blueMagenta></InterestBox> */}
               <InterestText blueMagentaText style={blueInterestBox ? styleBox : null} onMouseEnter={() => setBlueInterestBox(true)} onMouseLeave={() => setBlueInterestBox(false)}>S</InterestText>
            </div>
            <div className='brown'>
               {/* <InterestBox brown></InterestBox> */}
               <InterestText brownText style={brownInterestBox ? styleBox : null} onMouseEnter={() => setBrownInterestBox(true)} onMouseLeave={() => setBrownInterestBox(false)}>E</InterestText>
            </div>
            <div className='cyanBlue'>
               {/* <InterestBox cyanBlue></InterestBox> */}
               <InterestText cyanBlueText style={cyanInterestBox ? styleBox : null} onMouseEnter={() => setCyanInterestBox(true)} onMouseLeave={() => setCyanInterestBox(false)}>C</InterestText>
            </div>
         </div>
         <div className='bottom-list graph pt-4'>
            <InterestItemBox>
               <InterestItem bgGreen style={greenInterestBox ? styleBox : null} onClick={() => { setModelIndex(0); setModalShow(true) }} onMouseEnter={() => setGreenInterestBox(true)} onMouseLeave={() => setGreenInterestBox(false)}>
                  <InterestListText className='interest-text'> {t('skill.assessmentResult.realisticTitle')} </InterestListText>
                  <InterestListText className='interest-text'>{realisticScore}</InterestListText>

               </InterestItem>

            </InterestItemBox>
            <InterestItemBox>
               <InterestItem bgMagentaPink style={pinkInterestBox ? styleBox : null} onClick={() => { setModelIndex(1); setModalShow(true) }} onMouseEnter={() => setPinkInterestBox(true)} onMouseLeave={() => setPinkInterestBox(false)}>
                  <InterestListText>  {gender == 'Female' ? t('skill.assessmentResult.femaleinvestigativeTitle') : t('skill.assessmentResult.maleinvestigativeTitle')} </InterestListText>
                  <InterestListText>{investigativeScore}</InterestListText>
               </InterestItem>
            </InterestItemBox>
            <InterestItemBox>
               <InterestItem bgYellowGreen style={yellowInterestBox ? styleBox : null} onClick={() => { setModelIndex(2); setModalShow(true) }} onMouseEnter={() => setYellowInterestBox(true)} onMouseLeave={() => setYellowInterestBox(false)}>
                  <InterestListText> {t('skill.assessmentResult.artisticTitle')} </InterestListText>
                  <InterestListText>{artisticScore}</InterestListText>
               </InterestItem>
            </InterestItemBox>
            <InterestItemBox>
               <InterestItem bgBlueMagenta style={blueInterestBox ? styleBox : null} onClick={() => { setModelIndex(3); setModalShow(true) }} onMouseEnter={() => setBlueInterestBox(true)} onMouseLeave={() => setBlueInterestBox(false)}>
                  <InterestListText> {gender == 'Female' ? t('skill.assessmentResult.femalesocialTitle') : t('skill.assessmentResult.malesocialTitle')} </InterestListText>
                  <InterestListText>{socialScore}</InterestListText>
               </InterestItem>
            </InterestItemBox>
            <InterestItemBox>
               <InterestItem bgBrown style={brownInterestBox ? styleBox : null} onClick={() => { setModelIndex(4); setModalShow(true) }} onMouseEnter={() => setBrownInterestBox(true)} onMouseLeave={() => setBrownInterestBox(false)}>
                  <InterestListText>  {t('skill.assessmentResult.enterprisingTitle')} </InterestListText>
                  <InterestListText>{enterprisingScore}</InterestListText>
               </InterestItem>
            </InterestItemBox>
            <InterestItemBox>
               <InterestItem bgCyanBlue style={cyanInterestBox ? styleBox : null} onClick={() => { setModelIndex(5); setModalShow(true) }} onMouseEnter={() => setCyanInterestBox(true)} onMouseLeave={() => setCyanInterestBox(false)}>
                  <InterestListText> {gender == 'Female' ? t('skill.assessmentResult.femaleconventionalTitle') : t('skill.assessmentResult.maleconventionalTitle')} </InterestListText>
                  <InterestListText>{conventionalScore}</InterestListText>
               </InterestItem>
            </InterestItemBox>
            <TagModal item={skillInterestPopup[modelIndex]} handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} />

         </div>
      </>
   )
}


// export const InterestModal = (props) => {

//    return (
//       <>
//          <Modal show={props.InterestModalShow} onHide={props.InterestModalHide} animation={true} className='popup-modal-width top-cl'>
//             <Modal.Header className='popup-gray-bg' closeButton>
//                <Modal.Title>{props.modalTitle}</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                <div className='g-3'>
//                   <div className="rounded mb-4">
//                      <div className="">
//                         <p className='mb-0 fw-500'>{props.modalDescription}</p>
//                      </div>
//                      <div className='tips-sec mt-2'>
//                         <h6 className='fw-700 fs-14'>{props.modalListtitle}</h6>
//                         <div>
//                            <ul>
//                               {props.item.listItem.map((x, i) => {
//                                  return <>
//                                     <li className="fs-14 mb-3 tip-line-height fw-500 tips-dot">{x.listText}</li>
//                                  </>
//                               })}
//                            </ul>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </Modal.Body>
//             <Modal.Footer>
//                <div>
//                   <Button variant="danger" className='fw-500 rounded-0' onClick={props.InterestModalHide}>
//                      Close
//                   </Button>
//                </div>
//             </Modal.Footer>
//          </Modal>
//       </>
//    )
// }


export const TagModal = (props) => {

   const { t } = useTranslation('candidate');
   const { t: ct } = useTranslation('common');
   const [gender, setGender] = useState('');

   React.useEffect(() => {
      GetAxios().get(config.apiBase + '/api/General/GetUserSex')
         .then(res => {
            setGender(res.data.data || '');
         })
   }, [gender]);
   return (
      <>
         <Modal show={props.handleModalShow} onHide={props.handleModalClose} animation={true} className='popup-modal-width top-cl'>
            <Modal.Header className='popup-gray-bg' closeButton>
               <Modal.Title>{gender == 'Female' ? t(props.item.ftitle) : t(props.item.mtitle)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='g-3'>
                  <div className="rounded mb-4">
                     <div className="">
                        <p className='mb-0 fw-500'>{t(props.item.description)}</p>
                     </div>
                     <div className='tips-sec mt-2'>
                        <h6 className='fw-700 fs-14'>{t(props.item.listtitle)}</h6>
                        <div>
                           <ul>
                              {props.item.listItem.map((x, i) => {
                                 return <>
                                    <li class="fs-14 mb-3 tip-line-height fw-500 tips-dot">{t(x.listText)}</li>
                                 </>
                              })}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <div>
                  <Button variant="danger" className='fw-500 rounded-0' onClick={props.handleModalClose}>
                     {ct('close')}
                  </Button>
               </div>
            </Modal.Footer>
         </Modal>
      </>
   )
}





const InterestBox = styled.div`
background: ${props => (props.green ? '#00802A' : null)} ${props => (props.magentaPink ? '#800044' : null)} ${props => (props.yellowGreen ? '#727200' : null)} ${props => (props.blueMagenta ? '#350080' : null)} ${props => (props.brown ? '#984C00' : null)} ${props => (props.cyanBlue ? '#005580' : null)};
display: block;
width: 37px;
height: 37px;
cursor: pointer;
`

const InterestText = styled.div`
background: ${props => (props.greenText ? '#B8D9C3' : null)} ${props => (props.magentaPinkText ? '#E5BED3' : null)} ${props => (props.yellowGreenText ? '#FCFCB3' : null)} ${props => (props.blueMagentaText ? '#C6B8D9' : null)} ${props => (props.brownText ? '#F2D3B5' : null)} ${props => (props.cyanBlueText ? '#BFDAED' : null)};
display: block;
width: 37px;
height: 37px;
text-align: center;
padding: 6px 0;
font-size: 14px;
font-weight: bold;
cursor: pointer;
`

const InterestItemBox = styled.div`
border-bottom: solid 3px #ddd;
cursor: pointer;
`

const InterestItem = styled.div`
background:   ${props => (props.bgGreen ? '#B8D9C3' : null)} ${props => (props.bgMagentaPink ? '#E5BED3' : null)} ${props => (props.bgYellowGreen ? '#FCFCB3' : null)} ${props => (props.bgBlueMagenta ? '#C6B8D9' : null)} ${props => (props.bgBrown ? '#F2D3B5' : null)} ${props => (props.bgCyanBlue ? '#BFDAED' : null)};
padding: 4px 10px;
display: flex;
align-items: center;
justify-content: space-between;

`

const InterestListText = styled.div`
display: block;
text-align: center;
font-size: 16px;
font-weight: 600;
`