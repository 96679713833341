import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Prague = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume')

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <WrapperDiv additionalSettings={additionalSettings}>
         <div className='template'>
            <div className='preview-template'>
               <div id='template-full'>
                  <div className='template-main'>
                     <div className='prague-template-container h-100'>
                        <div className='container-fluid'>
                           <div className='row'>
                              <div className='col-6'>
                                 {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                    <section
                                       className='selectable-section'
                                       onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                    >
                                       {image && (
                                          <img
                                             className={
                                                imageData.isRounded
                                                   ? 'template-prague-img rounded-circle img-fluid'
                                                   : 'template-prague-img img-fluid'
                                             }
                                             src={image}
                                             alt='profile-img'
                                          />
                                       )}
                                    </section>
                                 )}

                                 {(contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) && (
                                    <section
                                       className='template-prague-name-sec selectable-section text-uppercase mt-3 fw-700'
                                       onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                    >
                                       <div className='template-prague-name'>
                                          <h1>{contactInformation.firstName}</h1>
                                          <h1>{contactInformation.lastName}</h1>
                                       </div>
                                       <p className='fw-700 template-prague-occupation'>{contactInformation.occupation}</p>
                                    </section>
                                 )}
                              </div>

                              <div className='col-6'>
                                 {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                    <section
                                       className='template-prague-contact-sec selectable-section lh-lg'
                                       onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                    >
                                       <div className='d-flex fs-11'>
                                          <p className='template-prague-contact-title'>Email</p>
                                          <p className='text-decoration-none text-black template-prague-contact fw-500'>
                                             {' '}
                                             {contactInformation.email}
                                          </p>
                                       </div>
                                       <div className='d-flex'>
                                          <p className='template-prague-contact-title'>Address</p>
                                          <p className='template-prague-contact fw-500'>{contactInformation.address}</p>
                                       </div>
                                       <div className='d-flex'>
                                          <p className='template-prague-contact-title'>Phone</p>
                                          <a
                                             className='text-decoration-none text-black template-prague-contact'
                                             href={`tel:${contactInformation.phone}`}
                                          >
                                             {contactInformation.phone}
                                          </a>
                                       </div>
                                       <div className='d-flex'>
                                          <p className='template-prague-contact-title'>Date of birth</p>
                                          <p className='template-prague-contact fw-500'>
                                             {moment(contactInformation.dob).format('MM/DD/YYYY')}
                                          </p>
                                       </div>
                                       <div className='d-flex'>
                                          <p className='template-prague-contact-title'>Nationality</p>
                                          <p className='template-prague-contact fw-500'>{contactInformation.nationality}</p>
                                       </div>
                                       <div className='d-flex'>
                                          <p className='template-prague-contact-title'>Link</p>
                                          <a
                                             className='template-prague-contact fw-500 text-decoration-none'
                                             href={`https://${contactInformation.linkedInLink}`}
                                          >
                                             {contactInformation.linkedInLink}
                                          </a>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                                    <section
                                       className='template-prague-objective-sec'
                                       onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                                    >
                                       <div className='selectable-section mt-2'>
                                          <p className='text-uppercase template-prague-heading '>{t('Objective')}</p>
                                          <p
                                             className='sec-value my-2 editor-list-margin-left'
                                             dangerouslySetInnerHTML={{ __html: objectives }}
                                          ></p>
                                       </div>
                                    </section>
                                 )}
                              </div>
                           </div>

                           {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                              <section
                                 className='template-prague-experience-sec selectable-section mt-1'
                                 onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                              >
                                 <div className='prague-section-lh'>
                                    <p className='text-uppercase template-prague-heading '>{t('Experiences')}</p>
                                    <div className='row'>
                                       {experiences.map((e, i) => (
                                          <div className='col-6' key={'exp_' + i}>
                                             <div className='exper-card'>
                                                {e.location && <p>{e.location}</p>}
                                                <p className='fst-italic'>
                                                   {e.startDate || e.endDate ? (
                                                      <>
                                                         {e.startDate ? (
                                                            <span>
                                                               {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.startDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}
                                                         {e.endDate && !e.currentlyWorking ? (
                                                            <span className='barline'>
                                                               {new Date(e.endDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.endDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}

                                                         {e.currentlyWorking ? <em className='barline'>{t('present')} </em> : <></>}
                                                      </>
                                                   ) : (
                                                      <></>
                                                   )}
                                                </p>
                                                <p className='fw-700 parague-exp-sec-occupation'>{e.title}</p>
                                                {e.company && <p className='fst-italic'>{e.company}</p>}
                                                {e.description && (
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left template-prague-desc'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              </section>
                           ) : (
                              <></>
                           )}

                           {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                              <section
                                 className='template-prague-experience-sec selectable-section mt-1'
                                 onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                              >
                                 <div className='prague-section-lh'>
                                    <p className='text-uppercase template-prague-heading '>{t('Education')}</p>
                                    <div className='row'>
                                       {educations.map((e, i) => (
                                          <div className='col-6' key={'edu__' + i}>
                                             <div className='exper-card'>
                                                {e.location && <p>{e.location}</p>}
                                                <p className='fst-italic'>
                                                   {e.startDate || e.completeDate ? (
                                                      <>
                                                         {e.startDate ? (
                                                            <span>
                                                               {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.startDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}
                                                         {e.completeDate && !e.currentlyStudying ? (
                                                            <span className='barline'>
                                                               {new Date(e.completeDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.completeDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}

                                                         {e.currentlyStudying ? <em className='barline'>{t('present')} </em> : <></>}
                                                      </>
                                                   ) : (
                                                      <></>
                                                   )}
                                                </p>
                                                <p className='fw-700 parague-exp-sec-occupation'>{e.degree}</p>
                                                {e.institute && <p className='fst-italic'>{e.institute}</p>}
                                                {e.description && (
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left template-prague-desc'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              </section>
                           ) : (
                              <></>
                           )}

                           <div className='row mt-4 gap-5'>
                              <div className='col'>
                                 {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                                    <section
                                       className='template-prague-skills-sec selectable-section'
                                       onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                                    >
                                       <p className='text-uppercase template-prague-heading '>{t('Skills')}</p>
                                       <div className='lh-base'>
                                          <ul className='ps-4 fs-12 sec-value'>
                                             {skills.length > 0 &&
                                                skills.map((s, i) => <li key={'skill_' + s.name + s.id + i}>{s.name}</li>)}
                                          </ul>
                                       </div>
                                    </section>
                                 )}
                              </div>
                              <div className='col'>
                                 {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                                    <section
                                       className='template-prague-languages-sec selectable-section'
                                       onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                                    >
                                       <p className='text-uppercase template-prague-heading '>{t('Languages')}</p>
                                       <div className='row'>
                                          {languages.length > 0 &&
                                             languages.map((s, i) => (
                                                <div className='col-6' key={'languages_' + s.name + s.id + i}>
                                                   <div className='lang-card fs-12'>
                                                      {s.name && <p className='fw-700'>{s.name}</p>}
                                                      <p className='lh-sm'>
                                                         {s.level && <span>{s.level}</span>}. {s.course && <span>{s.course}</span>}
                                                      </p>
                                                   </div>
                                                </div>
                                             ))}
                                       </div>
                                    </section>
                                 )}
                              </div>
                           </div>

                           {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                              <section
                                 className='template-prague-projects-sec'
                                 onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                              >
                                 <div className=' selectable-section mt-4'>
                                    <p className='text-uppercase template-prague-heading'>{t('Projects')}</p>
                                    {projects.map((v, i) => (
                                       <p
                                          key={'projects__' + i}
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </section>
                           )}

                           {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                              <section
                                 className='template-prague-achievements-sec'
                                 onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                              >
                                 <div className=' selectable-section mt-4'>
                                    <p className='text-uppercase template-prague-heading'>{t('Achievements')}</p>
                                    {achievements.map((v, i) => (
                                       <p
                                          key={'achievements__' + i}
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </section>
                           )}

                           {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 && (
                              <section
                                 className='template-prague-achievements-sec'
                                 onClick={(_) => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}
                              >
                                 <div className=' selectable-section mt-4'>
                                    <p className='text-uppercase template-prague-heading'>{t('Certifications & Courses')}</p>
                                    {certificates.map((v, i) => (
                                       <p
                                          key={'certificates__' + i}
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </section>
                           )}

                           {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                              <section
                                 className='template-prague-awards-sec'
                                 onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                              >
                                 <div className=' selectable-section mt-4'>
                                    <p className='text-uppercase template-prague-heading'>{t('Honors & Awards')}</p>
                                    {honours.map((v, i) => (
                                       <p
                                          key={'honour__' + i}
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </section>
                           )}

                           {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                              <section
                                 className='template-prague-awards-sec'
                                 onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                              >
                                 <div className=' selectable-section mt-4'>
                                    <p className='text-uppercase template-prague-heading'>{t('Honors & Awards')}</p>
                                    {honours.map((v, i) => (
                                       <p
                                          key={'honour__' + i}
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </section>
                           )}

                           {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                              <section
                                 className='template-prague-publications-sec'
                                 onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                              >
                                 <div className='selectable-section mt-4'>
                                    <p className='text-uppercase template-prague-heading'>{t('Publications')}</p>
                                    {publications.map((v, i) => (
                                       <p
                                          key={'publication__' + i}
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </section>
                           )}

                           {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                              <section
                                 className='template-prague-references-sec'
                                 onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                              >
                                 <div className='selectable-section mt-4'>
                                    <p className='text-uppercase template-prague-heading'>{t('References')}</p>
                                    {references.map((v, i) => (
                                       <div key={'refer__' + i} className='sec-value my-2 editor-list-margin-left'>
                                          <p>{v.name}</p>
                                          <p>
                                             {v.city} {v.city != '' && v.tel != '' ? '|' : ''} {v.tel}
                                          </p>
                                       </div>
                                    ))}
                                 </div>
                              </section>
                           )}
                           {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                              customSections.map((v, i) => (
                                 <section
                                    key={'custom__' + i}
                                    className='template-prague-custom-sec'
                                    onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                                 >
                                    <div className='selectable-section mt-4'>
                                       <p className='text-uppercase template-prague-heading'>{v.title}</p>
                                       <p
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    </div>
                                 </section>
                              ))}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </WrapperDiv>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .template-prague-heading {
      color: ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
      border-bottom: 1px solid
         ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & .prague-template-container {
      border-left: 20px solid
         ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & .template-prague-name-sec {
      color: ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`
