import React, { useState, useEffect } from "react";

const FlipSlider_Section = () => {

    return (
        <>
            <div className="FoldImageAnimated_Card">
                <div className="FoldImageAnimated_Content">
                    <div className="FoldImageAnimated_front">
                        <div className="position-relative">
                            <img
                                alt="Resume Example"
                                // src="https://enhancv.com/_next/static/images/resume1-041174df8af5a7c1cb31889766691714.webp"
                                src="images/home/slider/european-resume-slider-1.webp"
                                width="430"
                                height="608"
                                decoding="async"
                                data-nimg="1"
                                className="FoldImageAnimated_resumeOne__D0ARv"
                                style={{ color: 'transparent', maxWidth: '100%', height: 'auto', zIndex: 1 }}
                            />
                            <img
                                alt="Resume Example 3"
                                // src="https://enhancv.com/_next/static/images/resume3-fdd7e3eafb8f16ef8e0aa6f5ef523dca.webp"
                                src="images/home/slider/resume3.webp"
                                width="507"
                                height="563"
                                decoding="async"
                                data-nimg="1"
                                className="FoldImageAnimated_resumeThree__l_nL0"
                                loading="lazy"
                                style={{ color: 'transparent', maxWidth: '100%', height: 'auto', zIndex: 1 }}
                            />
                        </div>
                    </div>
                    <div className="FoldImageAnimated_back">
                        <div className="FoldImageAnimated_resumeTwoContainer">
                            <img
                                alt="Resume Example 2"
                                // src="https://enhancv.com/_next/static/images/resume2-b2965eb483a56527911b4e6161c678a9.webp"
                                src="images/home/slider/resume1-rotate.webp"
                                width="366"
                                height="518"
                                decoding="async"
                                data-nimg="1"
                                className="FoldImageAnimated_resumeTwo__Yu6L5"
                                style={{ color: 'transparent' }}
                            />
                            <div className="FoldImageAnimated_fonts animation-slide-fade-in-left">
                                <img
                                    alt="Resume Fonts"
                                    // src="https://enhancv.com/_next/static/images/fonts-e5631dfac5d3a9b1530cb1363ac57c3d.webp"
                                    src="images/home/slider/resume-fonts.webp"
                                    width="229"
                                    height="451"
                                    decoding="async"
                                    data-nimg="1"
                                    loading="lazy"
                                    style={{ color: 'transparent', width: '100%', height: 'auto' }}
                                />
                            </div>
                            <div className="FoldImageAnimated_newEntry animation-slide-fade-in-right">
                                <img
                                    alt="Resume Text Decoration"
                                    // src="https://enhancv.com/_next/static/images/new-entry-6305022632e4b14ae5714a49bb3af011.webp"
                                    src="images/home/slider/new-entry-editor.webp"
                                    width="287"
                                    height="143"
                                    decoding="async"
                                    data-nimg="1"
                                    loading="lazy"
                                    style={{ color: 'transparent', width: '100%', height: 'auto' }}
                                />
                            </div>
                            <div className="FoldImageAnimated_profileTwo">
                                <img
                                    alt="Real Resume Example"
                                    // src="https://enhancv.com/_next/static/images/profile2-53d26c0032bd1e0aea0baaa821753a02.webp"
                                    src="images/home/slider/resume-profile-img.webp"
                                    width="228"
                                    height="228"
                                    decoding="async"
                                    data-nimg="1"
                                    loading="lazy"
                                    style={{ color: 'transparent', width: '100%', height: 'auto' }}
                                />
                            </div>
                            <div className="FoldImageAnimated_hiredText bg-primary-cc fw-700 p-inline-2 p-block-0 animation-bounce">HIRED</div>
                        </div>
                        <div className="FoldImageAnimated_resumeFourContainer">
                            <div className="FoldImageAnimated_resumeFour_CR01">
                                <img
                                    alt="Resume Example 4"
                                    // src="https://enhancv.com/_next/static/images/resume4-eb8e2bacc73eb143b714ffec42b44926.webp"
                                    src="images/home/slider/resume4.webp"
                                    width="594"
                                    height="612"
                                    decoding="async"
                                    data-nimg="1"
                                    loading="lazy"
                                    style={{ color: 'transparent', maxWidth: '100%', height: 'auto', zIndex: 1 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FlipSlider_Section;