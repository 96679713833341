import moment from 'moment';
import React, { useState } from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const SideContent = (props: {
    jobs: {
        id: number,
        title: string,
        createdDateUtc: string,
        department: { name: string }
    }[],
    onTabChange: (jobId: number) => void;
}) => {

    const { t } = useTranslation('company');

    let navigate = useNavigate();
    const jobApplications = props.jobs;
    const [currentTab, setCurrentTab] = useState(0);

    React.useMemo(() => {
        if (currentTab == 0)
            setCurrentTab(props.jobs.length > 0 ? props.jobs[0].id : 0);

    }, [props.jobs]);

    React.useEffect(() => {
        if (currentTab != 0) {
            props.onTabChange(currentTab);
            window.scrollTo(0, 0);
        }




    }, [currentTab]);

    return (
        <>
            <div className='mt-1 mb-4'>
                <p className="text-start h5 fw-600"> {t('compApplicant.institutionAvailableJob')} </p>
            </div>
            <div className="tabs" id="tabs">
                <div className="list-group">
                    {jobApplications.map((item, index) => {
                        return (
                            <div key={"appTb_" + index}>
                                <a onClick={() => setCurrentTab(item.id)} className={"test10 color-tab list-group-item list-group-item-action rounded-0 mb-3 border-0 p-3 pointer-event" + (currentTab === item.id ? ' active' : '')} aria-current="true">
                                    <div className="d-flex justify-content-between align-items-start">
                                        <span className="mb-1 fw-600 fs-14 h5">{item.title}</span>
                                        <div className='my-0'><FaEdit size={16} onClick={() => navigate('/job/jobregistration?id=' + item.id)} /></div>
                                    </div>
                                    <span className="publish-id fw-12 fw-600">
                                        <>
                                            {moment(item.createdDateUtc).local().toDate().toLocaleDateString()} <b> | </b> {item.id}
                                        </>
                                    </span>
                                    <small><span className="me-2 fsize-13 fw-normal d-block list-title"><FaMapMarkerAlt className="me-1" size={16} /> {item.department?.name} </span></small>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
export default SideContent;

