import { createSlice } from "@reduxjs/toolkit"
import { SignInManager } from "../../Auth/SignInManager";

const initialState: AppAuthSlice = {
    isAuthenticated: new SignInManager().IsAuthenticated,
    showModel: false,
    purchasePlan:false,
    isLoading: false
}

const userSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state: AppAuthSlice, action: IAction<boolean>) => {
            state.isAuthenticated = action.payload
        },
        setAuthSubmitting: (state: AppAuthSlice, action: IAction<boolean>) => {
            state.isLoading = action.payload
        },
        setShowModel: (state: AppAuthSlice, action: IAction<boolean>) => {
            state.showModel = action.payload
        },
        setToken: (state: AppAuthSlice, action: IAction<string | undefined>) => {
            state.token = action.payload;
        },
        setResponse: (state: AppAuthSlice, action: IAction<TokenResponse | undefined | null>) => {
            if (action.payload && Object.keys(action.payload).length > 0) {
                state.raw = action.payload;
                state.isAuthenticated = true;
                state.generated = new Date().toISOString();
                state.token = action.payload.access_token;
                const generated = new Date();
                generated.setSeconds(generated.getSeconds() + action.payload.expires_in);
                state.validUntil = generated.toISOString();
            }
            else {
                state.raw = undefined;
                state.isAuthenticated = false;
                state.generated = undefined;
                state.token = undefined;
                state.validUntil = undefined;
            }
        },
        setSignout: (state: AppAuthSlice, action: IAction<undefined>) => {
            state.raw = undefined;
            state.isAuthenticated = false;
            state.generated = undefined;
            state.token = undefined;
            state.purchasePlan=false;
            state.validUntil = undefined;
        },
        setPlan: (state: AppAuthSlice, action: IAction<boolean>) => {
             state.purchasePlan=action.payload;   
        }
    
    
    },
});

export const {
    setIsAuthenticated,
    setResponse,
    setShowModel,
    setToken,
    setPlan,
    setAuthSubmitting,
    setSignout
} = userSlice.actions;

export default userSlice.reducer
