import React from 'react';
import JobMatcher from './JobMatcher';
import JobMatcherAssessment from './JobMatcherAssessment';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Index = () => {

  const { t } = useTranslation('candidate');

  const skillURL = useLocation();

  return (
    <>
      <div className="container">
        <div className="py-50">
          <div className='row'>
            <div className='col-md-4'>
              <div className="text-center">
                <span className="skill-banner-title border-0 text-nowrap">
                  {t('job.matcher.jobMatcherTitle')}
                </span>
              </div>
            </div>
          </div>
          <Outlet />
          {/* {skillURL.pathname === "/Skills-Matcher" && <SkillMatcher />}
          {skillURL.pathname === "/Skills-Matcher-Assessment" && <SkillMatcherAssessment />} */}

        </div>
      </div>
    </>
  )
}
export default Index;