import { config } from '../../../Environments';
import { GetAxios, KeyPairValue, LabelValuePair } from '../../../Shared';
import * as Yup from "yup";
import { DatePickSelect } from '../../../Components';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { FaPhoneAlt, FaPlayCircle } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { setGlobalLoading } from '../../../Store';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import TipExamplesPopup from '../TipExamplesPopup';
export const AnnounceEditForm = () => {
  const { t: ts } = useTranslation('alert');
  const { t } = useTranslation('company');
  const { t: ct } = useTranslation('common');

  const announceSchema = Yup.object().shape({

    name: Yup.string().required(ts('account.required') || 'Required'),
    //department: Yup.string().required(ts('account.required')|| 'Required'),
    departmentIds: Yup.array().min(1, ts('account.atLeastOne') || 'At least select one'),
    phoneNumber: Yup.string().required(ts('account.required') || 'Required'),
    announceDate: Yup.string().required(ts('account.required') || 'Required'),

  });
  const dispatch = useDispatch();
  const [showTips, setShowTips] = useState(false);
  const { flyerId } = useParams();
  const [picture, setPicture] = useState('');
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<LabelValuePair[]>([]);
  const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon"
  ];
  function validFileType(file: any) {
    return fileTypes.includes(file.type);
  }
  let myref = useRef();
  React.useEffect(() => {
    GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
      setDepartments(res.data);
    })
  }, []);
  interface MyFormValues {
    id: any;
    name: string,
    banner: string,
    departmentIds: number[],
    phoneNumber: string,
    announceDate: string,
    isAdmin: boolean
  }
  const formik = useFormik<MyFormValues>({
    initialValues:
    {
      id: 0,
      name: "",
      departmentIds: [],
      phoneNumber: "",
      announceDate: "",
      banner: "",
      isAdmin: false,
    },
    validationSchema: announceSchema,
    onSubmit: async values => {
      if (loading) {
        toast(ts('account.pleaseWait'), { type: 'info' });
      }

      setLoading(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'departmentIds') {
          values[key].forEach((id: any, index: any) => {
            formData.append(`${key}[${index}]`, id);
          });
        } else {
          formData.append(key, (values as any)[key]);
        }
      });
      GetAxios().post(config.apiBase + '/api/Announce/SaveAnnounce', formData).then(response => {
        if (response.data.success) {
          toast(ts('seminar.edit'), { type: 'success' });
        } else {

          toast(ts('error.unSave'), { type: 'error' });

        }
        setLoading(false);
      }).catch(err => {
        console.error(err);
        if (err.status >= 500) {
          toast(ts('error.internalServer'), { type: 'error' });
        }
      });
    },
  });

  useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios().get(config.apiBase + '/api/Announce/GetEditAnnounce?id=' + flyerId).then(res => {
      setPicture(config.apiBase + res.data.data.banner);
      formik.setValues(res.data.data);
      dispatch(setGlobalLoading(false));
    });
  }, []);

  const onFileSelected = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      if (validFileType(e.target.files[0])) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {

          setPicture(reader.result?.toString() || '');
          const formData = new FormData();
          formData.append('Image', e.target.files[0])
          formData.append('Id', formik.values.id)
          GetAxios().post(config.apiBase + '/api/Announce/UpdateFlyer', formData).then(res => {
            if (res.data.success) {
              toast(res.data.message, { type: 'success' });
            } else {
              toast(ts('error.updatePic'), { type: 'error' });
            }
          }).catch(err => {
            console.error(err);
            toast(ts('error.updatePic'), { type: 'error' });
          });

        });
        reader.readAsDataURL(e.target.files[0]);
      }
      else {
        toast(ts('resume.inValidFile'), { type: 'error' });
      }

    }

  }

  // React.useEffect(() => {

  //   if (formik.values.isAdmin == false) {
  //     GetAxios().get(config.apiBase + '/api/Announce/CheckEligibleforTipExamples').then(res => {
  //       setShowTips(res.data);
  //     });
  //   }
  // }, []);

  return (
    <>
      <div className="card">
        <div className="card-body pt-4">
          <div className="container px-5">
            <h2 className="mt-4 fw-700"> {t('seminarTab.seminarEditForm')} </h2>
            <div className="row">
              <div className="col-12 px-lg-5 px-md-4 px-sm-2 seminar-bg-form">
                <div className="card my-3">
                  <div className="card-body">
                    <div className="form-flyer p-3">
                      <div className="heading-flyer">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="fw-600 mt-0 mb-3"> {t('seminarTab.submitA')}<span className="text-uppercase fw-700"> {t('seminarTab.flyer')}</span></h2>
                          {/* {
                            showTips == true &&
                            <TipExamplesPopup />
                          } */}
                        </div>
                      </div>
                      <div className="border border-bottom"></div>

                      <div className="form-detail pt-1">
                        <div className="row my-3">
                          <h6 className="fw-700"> {t('seminarTab.informationAboutSeminar')}</h6>
                          <div className="col-md-6 col-12">
                            <input type="text" name="name" onChange={formik.handleChange} value={formik.values.name} className="form-control " id="name" />

                            <label htmlFor="name" className="form-label text-gray fw-500"> {t('seminarTab.nameOfSeminar')} </label>
                            {formik.errors.name && formik.touched.name ? <small className='text-danger d-block'>{formik.errors.name}</small> : ""}

                          </div>
                          <div className="col-md-6 col-12">
                            <DatePickSelect
                              yearFrom={2023}
                              yearTo={2030}
                              maxDate={new Date(2030, 12, 31)}
                              minDate={new Date()}
                              placeHolder={t('seminarTab.dateOfSeminar').toString()}
                              selected={formik.values.announceDate}
                              onChange={(date) => {
                                formik.setFieldValue("announceDate", date?.toLocaleString())
                              }
                              }
                            />

                            {formik.errors.announceDate && formik.touched.announceDate ? <small className='text-danger d-block'>{formik.errors.announceDate}</small> : ""}

                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700"> {t('seminarTab.telephone')} </h6>
                            <input type="tel" name='phoneNumber' onChange={formik.handleChange} value={formik.values.phoneNumber} className="form-control " id="phoneNumber" />
                            {formik.errors.phoneNumber && formik.touched.phoneNumber ? <small className='text-danger d-block'>{formik.errors.phoneNumber}</small> : ""}

                          </div>
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700"> {ct('department')} </h6>

                            <div>
                              <Select
                                onChange={(e) => {
                                  formik.setFieldValue("departmentIds", Array.isArray(e) ? e.map(x => x.value) : [])
                                }}
                                placeholder={ct('pleaseSelect').toString()}
                                value={departments.filter(obj => formik.values.departmentIds.includes(obj.value))} // set selected values
                                className="basic-multi-select "
                                isMulti
                                defaultValue={departments[1]}
                                options={departments}
                              // classNamePrefix="select"
                              />
                              {formik.errors.departmentIds && formik.touched.departmentIds ? <small className="text-danger">{formik.errors.departmentIds}</small> : ""}

                            </div>

                          </div>
                        </div>
                        <div className="row my-2">



                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700"> {t('seminarTab.uploadFlyer')} </h6>
                            <div className="">
                              <img src={!!picture ? picture : "/images/logo-banner-bg.jpg"} alt="announce edit image" className="img-fluid rounded institution-logo" />
                            </div>
                            <div className="text-start mb-3">
                              <input className="hidden d-none" id='logo-input' ref={(r: any) => { myref = r }} type='file' accept=".jpg, .jpeg, .png" multiple onChange={onFileSelected} />
                              <button type="button" className="button-default mt-3 border-0" onClick={() => (myref as any)?.click()}>Choose Image</button>
                            </div>

                          </div>
                        </div>
                        <div className="text-start my-3">
                          <button disabled={loading} type="submit" onClick={formik.handleSubmit as any} className="border-0  p-btn btn-contine text-white fw-600 text-uppercase">
                            {
                              loading ? <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="18"
                                visible={true}
                              /> : <>
                                {ct('submit')} <FaPlayCircle className='fa-lg ms-1' />
                              </>
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
