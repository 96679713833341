import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TemplateList } from "../../../../../TemplateImageJSON";
import { AvailableExtraSteps, AvailableSteps } from "../../../SharedData";
import { useTranslation } from 'react-i18next';

export const ResumePreviewMain = (props: { onNavigatedToSection?: () => void, }) => {
    const navigate = useNavigate();

    const maxPossibleSteps = AvailableSteps.concat(AvailableExtraSteps);

    const { t } = useTranslation('resume');

    const { resumeId } = useParams();

    const resume = useSelector((state: AppStore) => state.resume);
    // const referencesId = maxPossibleSteps.find(x => x.title == 'References')?.position;
    const publicationsId = maxPossibleSteps.find(x => x.title == 'Publications')?.position;
    const projectsId = maxPossibleSteps.find(x => x.title == 'Projects')?.position;
    const honoursId = maxPossibleSteps.find(x => x.title == 'Honors & Awards')?.position;
    const customSectionsId = maxPossibleSteps.find(x => x.title == 'Custom sections')?.position;
    const certificatesId = maxPossibleSteps.find(x => x.title == 'Certifications & Courses')?.position;
    const achievementsId = maxPossibleSteps.find(x => x.title == 'Achievements')?.position;

    const component = TemplateList.find(s => s.id == resume.templateId);
    if (component && component.component) {
        const PreviewWindow = component.component;
        return (
            <>
               
                <PreviewWindow
                    objectives={resume.objectives}
                    contactInformation={resume.contactInformation}
                    experiences={resume.experiences}
                    educations={resume.educations}
                    languages={resume.languages}
                    skills={resume.skills}
                    navigate={navigate}
                    achievements={resume.customSection.filter(x => x.sectionId === achievementsId)}
                    certificates={resume.customSection.filter(x => x.sectionId === certificatesId)}
                    customSections={resume.customSection.filter(x => x.sectionId === customSectionsId)}
                    honours={resume.customSection.filter(x => x.sectionId === honoursId)}
                    projects={resume.customSection.filter(x => x.sectionId === projectsId)}
                    publications={resume.customSection.filter(x => x.sectionId === publicationsId)}
                    references={resume.references}
                    // references={resume.customSection.filter(x => x.sectionId === referencesId)}
                    image={resume.image}
                    imageData={resume.imageData}
                    additionalSettings={resume.additionalSettings}
                    stepsInformation={resume.stepsInformation}
                    onNavigatedToSection={props.onNavigatedToSection}
                />
            </>
        );
    } else {
        return <p>{t('steps.needToSelect')} <Link to={'/resumes/builder/templates/' + resumeId}>{t('steps.clickHere')}</Link> {t('steps.toSelecTemplate')}</p>
    }
}