
import React, { useState } from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import FsLightbox from "fslightbox-react";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";

export const AdSliderSection = () => {
  const dispatch = useDispatch();
  const [seminarlist, setSeminarList] = useState<any>([]);

  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios().get(config.apiBase + '/api/Announce/GetListingAnnounces', {
      params: {
        listingNo: 5
      }
    }).then(s => {
      setSeminarList(s.data.data || []);
      dispatch(setGlobalLoading(false));
    });
  }, []);
  const jobAdsData = [
    {
      img: "/images/seminars/seminar-img-1.jpg",
    },
    {
      img: "/images/seminars/seminar-img-2.jpg",
    },
    {
      img: "/images/seminars/seminar-img-3.jpg",
    },
    {
      img: "/images/seminars/seminar-img-1.jpg",
    },
  ]


  const [lightbox, setLightbox] = useState({
    toggler: false,
    slide: 1
  });

  const handleImageClick = (index: number) => {
    setLightbox({
      toggler: !lightbox.toggler,
      slide: index + 1,
    });
  }


  return (
    <>{seminarlist.length > 0 &&
      <>
        <div className="bg-white w-100 mt-mob ads-sec-slider mb-3">

          <Swiper
            slidesPerView={3}
            spaceBetween={5}
            modules={[Autoplay, Navigation]}
            autoplay={{
              delay: 15000,
              disableOnInteraction: false,
            }}
            loop
            navigation={{
              enabled: true
            }}
            className="px-4"
          >
            {/* {jobAdsData.map((item, index) => { */}
            {seminarlist.map((item: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <div className="template-container-resume">
                    <div className="img-cont" onClick={() => handleImageClick(index)}>

                      <img src={config.apiBase + item.banner} className="seminar-img w-100" />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>

        <FsLightbox
          toggler={lightbox.toggler}
          slide={lightbox.slide}
          type={'image'}
          sources={seminarlist.map((x: any, i: number) => (
            config.apiBase + x.banner
          ))}
        />
      </>

    }
    </>
  )
}