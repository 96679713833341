import { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from 'react-router-dom';
import { setResumeActiveStep } from "../../../../../Store/Slices/ResumeSlice";

export const ShareableLayout = () => {
    const resume = useSelector((state: AppStore) => state.resume.stepsInformation);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/').filter(s => s);
        const lastPath = path[4];
        const index = location.pathname.toLowerCase().endsWith('finish') ? -1 : resume.findIndex(x => x.path.includes(lastPath));
        if (index === -1) {
            dispatch(setResumeActiveStep(resume.length as AvailableSteps));
        } else {
            dispatch(setResumeActiveStep(index + 1 as AvailableSteps));
        }
    }, [location, resume]);
    return (
        <>
            <Outlet />
        </>
    );
}