import { environment } from "./Environment";

export const api_base = process.env.REACT_APP_API_URL || '';
export const identity_base = process.env.REACT_APP_IDENTITY_URL || '';

export const ip_url = 'http://ip-api.com/json';

export const config = environment.production ? {
    apiBase: api_base,
    ip_url,
    identity: {
        clientId: 'client_password',
        clientSecret: 'client_secret_password',
        grantType: 'password',
        scopes: 'openid profile email phone address roles genders',
        usePkce: true,
        authority: identity_base,
        authUrl: identity_base + '/connect/authorize',
        tokenUrl: identity_base + '/connect/token',
        returnUrlKey: 'returnUrl',
        redirectToAfterLogin: '/signin-oidc'
    }
} : {
    apiBase: api_base,
    ip_url,
    identity: {
        clientId: 'client_password',
        clientSecret: 'client_secret_password',
        grantType: 'password',
        scopes: 'openid profile email phone address roles offline_access genders',
        usePkce: true,
        authority: identity_base,
        authUrl: identity_base + '/connect/authorize',
        tokenUrl: identity_base + '/connect/token',
        returnUrlKey: 'returnUrl',
        redirectToAfterLogin: '/signin-oidc'
    }
};
