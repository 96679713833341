import * as React from 'react';
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger, { OverlayInjectedProps } from 'react-bootstrap/OverlayTrigger';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import { FaRegLightbulb } from "react-icons/fa";
import { useTranslation } from 'react-i18next';


export const ProfilePhotoTips = () => {

    const { t } = useTranslation('resume');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className='photo-tips'>
                <div onClick={handleShow}>
                    <span className="rounded-5 profile-img01">
                        <OverlayTrigger
                            overlay={<Tooltip>{t('contactStep.photoTipsTooltip')}</Tooltip>}
                            placement="top">
                            {({ ref, ...triggerHandler }) => (
                                <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaRegLightbulb size={16} /></span>
                            )}
                        </OverlayTrigger>
                    </span>
                </div>
                <Modal show={show} onHide={handleClose} animation={false} backdrop="static" centered className='popup-modal-width top-cl'>
                    <Modal.Header className='popup-gray-bg'>
                        <div className='d-flex'>
                            <div className='me-3'>
                                <img src='/images/resume-img/icon-profile-imgGu.svg' alt='profile photo' />
                            </div>
                            <div>
                                <Modal.Title> {t('contactStep.photoTips.photoTipTitle')}</Modal.Title>
                                <p className='fw-500 mb-2 text-wrap'>{t('contactStep.photoTips.photoTipSubtitle')}!</p>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='p-4' style={{
                        maxHeight: 'calc(100vh - 350px)',
                        overflowY: 'auto'
                    }}>
                        <div className='g-3'>
                            <div className='tips-sec'>
                                <div className='text-center'>
                                    <img src='/images/resume-img/aitips-3VOq.gif' alt='extra section image' className='mb-2 yqEhG' />
                                </div>
                                <div>
                                    <ul>
                                        <li className="fs-14 mb-3 tip-line-height fw-500 tips-dot"> {t('contactStep.photoTips.photoTipList1')}.</li>
                                        <li className="fs-14 mb-3 tip-line-height fw-500 tips-dot"> {t('contactStep.photoTips.photoTipList2')}.</li>
                                        <li className="fs-14 mb-3 tip-line-height fw-500 tips-dot"> {t('contactStep.photoTips.photoTipList3')}!</li>
                                        <li className="fs-14 mb-3 tip-line-height fw-500 tips-dot"> {t('contactStep.photoTips.photoTipList4')}.</li>
                                        <li className="fs-14 mb-3 tip-line-height fw-500 tips-dot"> {t('contactStep.photoTips.photoTipList5')}!</li>
                                        <li className="fs-14 mb-3 tip-line-height fw-500 tips-dot"> {t('contactStep.photoTips.photoTipList6')}!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='tips-modal-footer'>
                        <div>
                            <Button className='fw-500 text-uppercase rounded-pill add-section-btn' onClick={handleClose}>
                                {t('contactStep.photoTips.gotIt')}!
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};