import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import TenderTable from "./TenderTable";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const Index = () => {
  const [pageSkillLimit, setPageSkillLimit] = useState(10);
  const [searchString, setSearchString] = useState('');
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation('admin');
  
  return (
    <>
      <div className="container-fuild py-50 margin15">
        <div className="card custom-shadow">
        <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <CardTitle> {t('common.tendersList')}  </CardTitle>
              </div>
              <div className="d-flex align-items-center">
                <Link
                  to="/admin/tenderregistration"
                  className="button-default text-decoration-none cursor-pointer mt-1 border-0 me-1">{t('common.postTender')}</Link>
              </div>
            </div>
            </div>

          <div className="card-body px-4">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="fw-500">{t('common.show')}</span>
                    <select
                      id="inputState"
                      value={pageSkillLimit}
                      onChange={e => setPageSkillLimit(Number(e.target.value))}
                      style={{ width: "75px", height: "31px" }}
                      className="form-select form-control w-select-list fsize-13 mx-2">
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                      <option value={60}>60</option>
                    </select>
                    <span className="fw-500">{t('common.entries')}</span>
                  </div>
                  <div className="me-2 d-flex align-items-center">
                    <span className="me-1 fw-500">{t('common.search')}:</span>
                    <input type="text" name='searchString' className="form-control" id="searchString" value={searchString} 
                    onChange={(e) => {
                      setSearchString(e.target.value);
                     //call tender list
                      setUpdate(!update);      
                  }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <TenderTable  pageSkillLimit={pageSkillLimit} setPageSkillLimit={setPageSkillLimit} searchString={searchString} update={update} setUpdate={setUpdate}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;


export const CardTitle = styled.h4`
margin-bottom: 0px;
font-weight: 700;
padding: 10px 0px;
`