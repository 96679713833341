import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { BiFileBlank } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../../../Environments';
import { GetAxios } from '../../../../../Shared';
import { setGlobalLoading } from '../../../../../Store';
import { setResumeActiveStep, setResumeTemplate } from '../../../../../Store/Slices/ResumeSlice';
import { TemplateList } from '../../../../../TemplateImageJSON';
import { AdjustInCenter, AvailableColorTitle, ButtonICON, ColorCircle, ColorWrapper, HoverOverlay, OverlayButtonWrapper, RESUMEBGIMAGE, ShowTemplateListWrapper, StickyHeaderWrapper, SubTitle, TabButtons, TemplateHTMLHeader, TemplateHTMLHeaderBadge, TemplateHTMLHeaderTitle, Title, UseThisTemplateButton, WrapperDiv } from '../Common';
import { useTranslation } from 'react-i18next';


function Templates() {

    const { t } = useTranslation('resume');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { resumeId } = useParams();

    const [TemplateFilter, setTemplateFilter] = useState<TemplateFilter>("All");
    const [RenderList, setRenderList] = useState(TemplateList);

    useEffect(() => {
        dispatch(setResumeActiveStep(1));
    }, []);

    useEffect(() => {
        const newlist = TemplateList.filter(x => x.type.includes(TemplateFilter));
        setRenderList(newlist)
    }, [TemplateFilter]);
    return (
        <>
            <WrapperDiv>
                <Title> {t('selectTemplate.chooseTemplate')} </Title>
                <SubTitle>{t('selectTemplate.chooseTemplateDesc')}</SubTitle>
                <StickyHeaderWrapper>
                    <AdjustInCenter className='d-flex align-items-end'>
                        <div className='d-flex align-items-end justify-content-center'>
                            <div className='d-flex flex-wrap'>
                                <TabButtons onClick={() => setTemplateFilter("All")} className='tabsButton' active={TemplateFilter === "All" ? true : false}> {t('selectTemplate.all')} </TabButtons>
                                <TabButtons onClick={() => setTemplateFilter("Modern")} className='tabsButton' active={TemplateFilter === "Modern" ? true : false}> {t('selectTemplate.modern')} </TabButtons>
                                <TabButtons onClick={() => setTemplateFilter("Minimalist")} className='tabsButton' active={TemplateFilter === "Minimalist" ? true : false}> {t('selectTemplate.minimalist')} </TabButtons>
                                <TabButtons onClick={() => setTemplateFilter("Classic")} className='tabsButton' active={TemplateFilter === "Classic" ? true : false}> {t('selectTemplate.classic')} </TabButtons>
                            </div>
                        </div>
                    </AdjustInCenter>
                </StickyHeaderWrapper>
            </WrapperDiv>
            <ShowTemplateListWrapper className='pt-4'>
                <Container >
                    <Row>
                        {resumeId &&
                            RenderList?.map((x, i) =>
                                <Col sm={12} md={6} lg={4} xxl={4} key={'colm_' + i}>
                                    <TemplateHTML navigate={navigate} data={x} resumeId={resumeId} />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </ShowTemplateListWrapper>
        </>
    );
}

export default Templates;


function TemplateHTML(props: {
    data: Step1TemplateSelection,
    resumeId: string,
    navigate: NavigateFunction
}) {

    const { t } = useTranslation('resume');

    const { data, navigate, resumeId } = props;
    const dispatch = useDispatch();

    const ChooseTemplate = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SetTemplate', undefined, {
            params: {
                id: resumeId,
                templateId: data.id
            }
        }).then(s => {
            dispatch(setResumeTemplate(data.id));
            navigate(`/resumes/builder/${resumeId}/editor/access-information`);
            dispatch(setGlobalLoading(false));
        });
    }
    return (
        <div>
            <TemplateHTMLHeader>
                <TemplateHTMLHeaderTitle>
                    {data?.name}
                </TemplateHTMLHeaderTitle>
                {
                    data?.badge
                    && <TemplateHTMLHeaderBadge>
                        {data?.badge}
                    </TemplateHTMLHeaderBadge>
                }
            </TemplateHTMLHeader>
            <HoverOverlay className='position-relative'>
                <RESUMEBGIMAGE bgImage={data?.imgsrc} />
                <OverlayButtonWrapper className='showOverlayButton'>
                    <UseThisTemplateButton to={''} onClick={ChooseTemplate}>
                        <ButtonICON><BiFileBlank fontSize={16} /></ButtonICON>
                        {t('selectTemplate.useThisTemplate')}
                    </UseThisTemplateButton>
                </OverlayButtonWrapper>
            </HoverOverlay>
            <div className='mb-5'>
                <AvailableColorTitle>
                    {t('selectTemplate.availableColors')}:
                </AvailableColorTitle>
                <div className='d-flex align-items-center'>
                    {
                        data?.colors?.map((x, i) => <ColorWrapper key={'col_' + i}><ColorCircle obj={x} /></ColorWrapper>)
                    }
                </div>
            </div>
        </div>
    )
}