import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AddAnother = (props: { action: () => void, text?: string})=> {

   
   const { t } = useTranslation('resume');

   return (
      <>
         <div className="card-add-section mb-4" onClick={props.action}>
            <div className="icon-container">
               <svg width="25px" height="25px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                     <circle id="BG" fill="#26a0f4" cx="16" cy="16" r="16"></circle>
                     <path
                        d="M16,8 C16.4909198,8 16.8888889,8.39796911 16.8888889,8.88888889 L16.8888889,8.88888889 L16.888,15.111 L23.1111111,15.1111111 C23.5642678,15.1111111 23.938224,15.4502091 23.9930743,15.8884997 L24,16 C24,16.4909198 23.6020309,16.8888889 23.1111111,16.8888889 L23.1111111,16.8888889 L16.888,16.888 L16.8888889,23.1111111 C16.8888889,23.5642678 16.5497909,23.938224 16.1115003,23.9930743 L16,24 C15.5090802,24 15.1111111,23.6020309 15.1111111,23.1111111 L15.1111111,23.1111111 L15.111,16.888 L8.88888889,16.8888889 C8.43573217,16.8888889 8.06177602,16.5497909 8.00692571,16.1115003 L8,16 C8,15.5090802 8.39796911,15.1111111 8.88888889,15.1111111 L8.88888889,15.1111111 L15.111,15.111 L15.1111111,8.88888889 C15.1111111,8.43573217 15.4502091,8.06177602 15.8884997,8.00692571 Z"
                        id="Combined-Shape"
                        fill="#ffffff"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        ></path>
                        </g>
                        </svg>
                        </div>
            <div className="text-section">
            <p className="text">{props.text ?? <>{t('addAnother').toString()}</>}</p>
            </div>
         </div>
      </>
      )
   }