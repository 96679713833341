import {
  FaPencilAlt, FaRegTrashAlt
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../../Environments";
import { GetAxios } from "../../../Shared";
import { setRemoveStepInformation } from "../../../Store/Slices/ResumeSlice";
import { StepsIcons } from "../SharedData";
import { useTranslation } from 'react-i18next';

const ResumeSectionCard = () => {

  const { t } = useTranslation('resume');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { resumeId } = useParams();

  const steps = useSelector((state: AppStore) => state.resume.stepsInformation);

  const removeSection = (position: AvailableSteps) => {
    GetAxios(navigate, dispatch)
      .delete(config.apiBase + '/api/Resume/RemoveSection', {
        params: { resumeId, position }
      })
      .then(res => {
        if (res.data.success) {
          dispatch(setRemoveStepInformation(position));
        }
      });
  };

  return (
    <>
      {
        steps.slice(1).map((v, i) =>
          <div key={'info_' + i} className="d-flex justify-content-between card-section rounded mb-3">
            <div className="d-flex align-items-center">
              <span className="kEVnji rounded-5">
                {StepsIcons[i]?.icon}
              </span>
              <div className="ms-2">
                <span className="title-exper text-capitalize">{t(v.title)}</span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {
                v.position > 3 &&
                <span className="me-4" onClick={_ => removeSection(v.position)}>
                  <FaRegTrashAlt className="icon-gray" size={17} />
                </span>
              }
              <span onClick={_ => {
                navigate('../' + resumeId + '/editor/' + v.path);
              }}>
                <FaPencilAlt className="icon-gray me-2" size={17} />
              </span>
            </div>
          </div>
        )
      }
    </>
  )
}
export default ResumeSectionCard;