import React, { useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import PrintIcon from '../../Components/Common/PrintIcon';
import { config } from "../../Environments";
import { GetAxios } from "../../Shared";
import { setGlobalLoading } from "../../Store";
import { useTranslation } from 'react-i18next';
const MainContent = () => {

  const { t } = useTranslation('candidate');
  const { t: ct } = useTranslation('common');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current as any,
  });

  const [skillResults, setSkillResults] = useState<Jobs[]>([]);
  const [sortBy, setSortBy] = useState('HTL');
  const { i18n } = useTranslation();
  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/JobMatcher/GetResult', {
      params: {
        lang: i18n.language,
        sortBy: sortBy
      }
      // params: {
      //   sortBy
      // }
    }).then(s => {
      setSkillResults(s.data.data || []);
      dispatch(setGlobalLoading(false));
    });
  }, [i18n.language, sortBy]);

  return (
    <div className="col-md-12">
      <div className="d-flex justify-content-between align-items-center">
        {/* <div className="border-0">
          <div
            className="cursor-pointer border-none"
            onClick={handlePrint}>
            <PrintIcon />
          </div>
        </div> */}
      </div>
      <div className="assessment-para pt-1">
        <p className="mb-3 fs-13 fw-500">
          {t('job.assessmentResult.weFound')} <span className="skill-match-bold">{skillResults.length}</span> {t('job.assessmentResult.jobSkillsProfile')}.
        </p>
      </div>
      <div className="result-table">
        <div className="theader">
          <div className="py-2">
            <span className="d-flex align-items-center justify-content-center justify-content-lg-end justify-content-md-end me-2">
              <b> {t('job.assessmentResult.sortBy')} </b>
              <select
                value={sortBy}
                onChange={s => setSortBy(s.target.value)}
                style={{ width: "165px", height: "31px" }}
                className="form-select form-control w-select-list float-end fsize-13">
                <option value={'HTL'}> {t('job.assessmentResult.matchHighToLow')} </option>
                <option value={'LTH'}> {t('job.assessmentResult.matchLowToHigh')} </option>
                <option value={'ATZ'}> {t('job.assessmentResult.jobAToZ')} </option>
                <option value={'ZTA'}> {t('job.assessmentResult.jobZToA')} </option>
              </select>
            </span>
          </div>
        </div>
        <div className="print-sec">
          <ComponentToPrint ref={componentRef} skillResults={skillResults} />
        </div>
      </div>
      <div className="download-file mt-3">
        <select
          id="inputState"
          style={{ width: "115px", height: "31px" }}
          className="form-select form-control w-select-list float-start fsize-13">
          <option value="Download">
            {ct('download')}
          </option>
          <option value="PDF"> {t('job.assessmentResult.pdf')} </option>
          <option value="Word"> {t('job.assessmentResult.Word')} </option>
        </select>
      </div>
    </div>
  );
};
export default MainContent;

export const ComponentToPrint = React.forwardRef<any, any>((props: { skillResults: Jobs[] }, ref) => {

  const { skillResults } = props;

  const { t } = useTranslation('candidate');


  const [currentPage, setCurrentPage] = useState(1);
  const [pageSkillLimit, setPageSkillLimit] = useState(10);
  return (
    <>
      <Table ref={ref as any}>
        <thead className="theader">
          <tr>
            <th className="p_header"> {t('job.assessmentResult.yourMatch')} </th>
            <th className="p_header"> {t('job.assessmentResult.career')} </th>
            <th className="p_header"> {t('job.assessmentResult.education')} </th>
          </tr>
        </thead>
        <tbody>
          {
            skillResults
              .slice(
                (currentPage - 1) * pageSkillLimit,
                currentPage * pageSkillLimit
              )
              .map((Item, Index) => {
                return (
                  <tr className="tr-skill-result fsize-13" key={'skjillls' + Index}>
                    <td className="p-result-td fw-500">
                      <span>{(currentPage - 1) * pageSkillLimit + Index + 1}</span>
                    </td>
                    <td className="p-result-td">
                      <Link to={'/job/jobpreview?id=' + Item.jobId} state={{ from: "jobMatcher" }} className="wel-color">
                        {Item.title}
                      </Link>
                    </td>
                    <td className="p-result-td fw-500">
                      <span>{Item.education}</span>
                    </td>
                  </tr>
                );
              })
          }
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <select
            value={pageSkillLimit}
            onChange={e => setPageSkillLimit(Number(e.target.value))}
            style={{ width: "75px", height: "31px" }}
            className="form-select form-control w-select-list float-start fsize-13">
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
          </select>
          <span className="ms-2"> {t('job.assessmentResult.perPage')} </span>
        </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}>
                  {" "}<FaChevronLeft />
                </button>
              </li>

              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              <li className="page-item me-2 pb-result"

              >
                <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(skillResults.length / pageSkillLimit))} >
                  {Math.ceil(skillResults.length / pageSkillLimit)}
                </a>
              </li>
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(skillResults.length / pageSkillLimit)
                      ? false
                      : true
                  }>{" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
});
type Jobs = {
  title: string,
  jobId: number,
  salaryRange: string,
  education: string
}