import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { FaRegFileAlt, FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../../../../Environments';
import { GetAxios } from '../../../../../../Shared';
import { setGlobalLoading } from '../../../../../../Store';
import { setResumeActive, setResumeAddRecord } from '../../../../../../Store/Slices/ResumeSlice';
import TipsPopup from '../../../../Components/TipsPopup';
import { ParseHTMLTips } from '../../../../../../Components/Common/ParseTipsHTML';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';

const currentSectionId = 12;
export const CertificationCourseAdd = () => {

    const { t } = useTranslation('resume');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, resumeId } = useParams();

    const records = useSelector((state: AppStore) => state.resume.customSection).filter(x => x.sectionId == currentSectionId);
    const information = useSelector((state: AppStore) => state.resume.activeCustomSection);
    const [isDefaultContentLoaded, setDefaultContextLoaded] = useState(false);

    const [editorState, setEditState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const defaultValue = records.find(s => s.id == Number(id));
        const htmlContent = defaultValue?.content;
        if (!isDefaultContentLoaded) {
            const html = convertFromHTML(htmlContent || '');
            setEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
            if (defaultValue || htmlContent) {
                setDefaultContextLoaded(true);
            }
        }
        dispatch(setResumeActive({ customSection: defaultValue }));
    }, [records, id, resumeId]);

    const setValue = (value: { [n in keyof ResumeCustomSection]: ResumeCustomSection[n] }) => {
        const info = { ...(information || {}), ...value };
        dispatch(setResumeActive({ customSection: info }));
        dispatch(setResumeAddRecord({ customSection: info }));
    };

    const Savebtn = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).post(config.apiBase + '/api/Resume/SaveAdditionalStep', {
            ...information,
            id: id,
            resumeId: resumeId
        }).then(s => {
            navigate('..');
            dispatch(setGlobalLoading(false));
        })
    }

    const Backbtn = () => {
        confirmAlert({
            title: t('newResumeCard.backAlertMsg.title').toString(),
            message: t('newResumeCard.backAlertMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('save').toString(),
                    onClick: Savebtn
                },
                {
                    label: t('exit').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        navigate('..');
                    }
                }
            ]
        })
    }


    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-4'>
                        <TipsPopup title="contactStep.tips" description="certificateMdl.description"
                            tipsList={ParseHTMLTips(coursesTipsBody, t)} />
                    </div>
                    <div className="mb-3">
                        <div className="dZcjzV sections-header">
                            <div className="feVKyO"> {t('certificateStep.certificateTitle')}? </div>
                        </div>
                        <div className="gJaquO"> {t('certificateStep.certificateDesc')}.</div>
                    </div>
                    <div className="fFIUJl sections-header">
                        <div className="dJqmzX">
                            <div className="hfzZao">
                                <div className="czFa-Df"> {t('certificateStep.certificate')} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Editor
                    editorState={editorState}
                    editorClassName="editor-height"
                    stripPastedStyles={true}
                    toolbar={{
                        options: ['inline', 'textAlign', 'list'],
                        inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough',] },
                        textAlign: { inDropdown: false, options: ['left', 'center', 'right'] },
                        list: { inDropdown: false, options: ['unordered', 'ordered'], },
                    }}
                    onEditorStateChange={e => {
                        setEditState(e);
                        setValue({ content: draftToHtml(convertToRaw(e.getCurrentContent())) });
                    }}
                />
                <p className='helper-text ms-3 text-level'>{t('eg')}. - {t('certificateStep.certificateHelpText')}</p>
            </div>
            <div className="bottom-btn-sticky">
                <div className='next-previous-btns d-flex align-items-center justify-content-between'>
                    <div className=''>
                        <Button className="fw-500 previous-btn bg-back" onClick={Backbtn}>
                            <FaArrowLeft className="me-2" /> {t('back')}
                        </Button>
                    </div>
                    <div className=''>
                        <Button className="save-btn-resume fw-500"
                            onClick={Savebtn}
                        ><FaRegFileAlt className="me-1" size={20} /> {t('save')} </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

const coursesTipsBody = {
    "tipsMdlTitle": ["certificateMdl.tipsList1",
        "certificateMdl.tipsList2",
        "certificateMdl.tipsList3",
        "certificateMdl.tipsList4",
    ],
    "certificateMdl.exmp.title": ["certificateMdl.exmp.exmpList1",
        "certificateMdl.exmp.exmpList2",
        "certificateMdl.exmp.exmpList3",
        "certificateMdl.exmp.exmpList4",
        "certificateMdl.exmp.exmpList5",
    ],
}