import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaRegFileAlt, FaArrowLeft } from "react-icons/fa";
import { ParseHTMLTips } from '../../../../../Components/Common/ParseTipsHTML';
import { config } from '../../../../../Environments';
import { GetAxios, KeyPairValue, LabelValuePair } from '../../../../../Shared';
import { setGlobalLoading } from '../../../../../Store';
import { setResumeActiveStep, setResumeAccessInformation, } from '../../../../../Store/Slices/ResumeSlice';
import TipsPopup from '../../../Components/TipsPopup';
import { BottomButtons } from '../BottomButtons';
import { useTranslation } from 'react-i18next';

function ContactInformation() {

    const { t } = useTranslation('resume');
    const { t: jt } = useTranslation('job');
    const { t: ct } = useTranslation('common');

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { resumeId } = useParams();
    const resume = useSelector((state: AppStore) => state.resume);
    const information = resume.accessInformation;

    const [sectorError, setSectorError] = useState(false);
    const [classfyError, setClassError] = useState(false);
   // const [deptError, setDeptError] = useState(false);
 
    useEffect(() => {
        dispatch(setResumeActiveStep(2));
    }, [resume]);


    const { i18n } = useTranslation();
    const [classifications, setClassifications] = useState<KeyPairValue[]>([]);
   // const [departments, setDepartments] = useState<LabelValuePair[]>([]);
    const [sectors, setSectors] = useState<KeyPairValue[]>([]);
    const [isLastStep, setIsLastStep] = useState(false);
    useEffect(() => {
        setIsLastStep(resume.activeStep === resume.stepsInformation.length);
    }, [resume.activeStep, resume.stepsInformation]);
    // useEffect(() => {
    //     GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
    //         setDepartments(res.data);
    //     })

    // }, []);
    useEffect(() => {
        GetAxios().get(config.apiBase + '/api/General/GetClassifications?lang=' + i18n.language).then(res => {
            setClassifications(res.data);
        })
        GetAxios().get(config.apiBase + '/api/General/GetListBySystemType?lang=' + i18n.language + '&type=Sectors').then(res => {
            setSectors(res.data)
        });

    }, [i18n.language]);




    const Savebtn = () => {

        if (information.sectorId == 0 || information.classId == 0 ){
           // || information.deptId == 0) {   
            setSectorError(information.sectorId == 0);
            setClassError(information.classId == 0);
            //setDeptError(information.deptId == 0)
            return;
        }
        GetAxios(navigate, dispatch)
            .post(config.apiBase + '/api/Resume/SaveAccessInformation', {
                ...information,
                resumeId: Number(resumeId)
            })
            .then(s => {
                if (isLastStep) {
                    navigate('../../' + resumeId + '/finish')
                } else {
                    navigate('../' + resume.stepsInformation[resume.activeStep].path)
                }
                dispatch(setGlobalLoading(false));
            });
    }

    const Backbtn = () => {
        navigate('../' + resume.stepsInformation[resume.activeStep - 2].path);
    }

    const handleSector = (e: any) => {
        setSectorError(Number(e.target.value) == 0);
        dispatch(setResumeAccessInformation({...information, sectorId: Number(e.target.value) }));
    }
    const handleClassification = (e: any) => {
        setClassError(Number(e.target.value) == 0);
        dispatch(setResumeAccessInformation({...information, classId: Number(e.target.value) }));
    }
    // const handleDepartment = (e: any) => {
    //     setDeptError(Number(e.target.value) == 0);
    //     dispatch(setResumeAccessInformation({ ...information,deptId: Number(e.target.value) }));
    // }

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-4'>
                        {/* <TipsPopup title="searchInfoStep.tips" description="searchInfoStep.searchTipsMdl.description"
                            tipsList={ParseHTMLTips(informationTipsBody, t)} /> */}
                    </div>
                    <div className="mb-3">
                        <div className="dZcjzV sections-header">
                            <div className="feVKyO">  {t('searchInfoStep.title')} </div>
                        </div>
                        <div className="gJaquO">  {t('searchInfoStep.desc')}. </div>
                    </div>
                    <div className="fFIUJl sections-header">
                        <div className="dJqmzX">
                            <div className="hfzZao">
                                <div className="czFa-Df"> {t('searchInfoStep.search')} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <select name="sectorId" onChange={e => handleSector(e)}
                            value={information.sectorId} className='form-select fs-14 form-select-sm'>
                            <option value={0}> {ct('pleaseSelect')} </option>
                            {
                                (sectors || []).map((v, index) => (
                                    <option key={'sectors+___' + index} value={v.key}>{v.value}</option>
                                ))
                            }
                        </select>
                        <label className='label-float'>  {jt('job.sectorOfActivity')}</label>
                        {sectorError && sectorError == true ? <span className='text-danger'>{t('searchInfoStep.required')}</span> : ""}
                    </div>
                </div>

            </div>
            {/* <div className='col-md-12'>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <select name="deptId" onChange={(e) => handleDepartment(e)}
                            value={information.deptId} className='form-select fs-14 form-select-sm'>
                            <option value={0}> {ct('pleaseSelect')} </option>
                            {
                                departments?.map((s, index) => (
                                    <option value={s.value} key={'depts_' + index}>{s.label}</option>
                                ))
                            }
                        </select>
                        <label className='label-float'>  {jt('job.department')}</label>
                        {deptError && deptError == true ? <small className='text-danger'>{t('searchInfoStep.required')}</small> : ""}
                    </div>
                </div>
            </div> */}
            <div className='col-md-12'>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <select name="classId" onChange={e => handleClassification(e)}
                            value={information.classId} className='form-select fs-14 form-select-sm'>
                            <option value={0}> {ct('pleaseSelect')} </option>
                            {
                                classifications?.map((s, index) => (
                                    <option value={s.key} key={'classss_' + index}>{s.value}</option>
                                ))
                            }
                        </select>
                        <label className='label-float'>   {jt('job.classification')}</label>
                        {classfyError && classfyError == true ? <small className='text-danger'>{t('searchInfoStep.required')}</small> : ""}
                 
                    </div>
                </div>
            </div>

            <div className="bottom-btn-sticky">
                <div className='next-previous-btns d-flex align-items-center justify-content-between'>
                    <div className=''>
                        <Button className="fw-500 previous-btn bg-back"
                            onClick={Backbtn}
                        ><FaArrowLeft className="me-2" /> {t('back')}</Button>
                    </div>
                    <div className=''>
                        <Button className="save-btn-resume fw-500"
                            onClick={Savebtn}
                        ><FaRegFileAlt className="me-1" size={20} /> {t('save')}</Button>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ContactInformation;





const informationTipsBody = {
    "<b>Info</b>": ['searchInfoStep.searchTipsMdl.tipsList1', 'searchInfoStep.searchTipsMdl.tipsList2', 'searchInfoStep.searchTipsMdl.tipsList3'],
}