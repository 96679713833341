import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import AuthSlice from './Slices/AuthSlice';
import GlobalSlice from './Slices/GlobalSlice';
import ResumeSlice from './Slices/ResumeSlice';

export const AppStore = configureStore<AppStore>({
  reducer: {
    auth: AuthSlice,
    global: GlobalSlice,
    resume: ResumeSlice
  },
})