import React from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import SideContent from './SideContent';
import MainContent from './MainContent';
import { config } from '../../../Environments';
import { GetAxios } from '../../../Shared';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setGlobalLoading } from "../../../Store";
import { useDispatch } from "react-redux";
const Proposal = () => {
    const [tenders, setTenders] = React.useState<{
        id: number,
        title: string,
        createdDateUtc: string,
        department: { name: string }
    }[]>([]);

    const [tenderId, setTenderId] = React.useState(0);

    const auth = useSelector((state: AppStore) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (auth.isAuthenticated)
            dispatch(setGlobalLoading(true));
        GetAxios(navigate).get(config.apiBase + '/api/Tender/MyPostedTenders').then(res => {
            if (res.data.success)
                setTenders(res.data.data);
            dispatch(setGlobalLoading(false));
        });
    }, [auth]);
    return (
        <>
            <Container>
                <Row className='py-50'>
                    <Col md={3} xs={12} className="ps-lg-0">
                        <SideContent tenders={tenders} onTabChange={setTenderId} />
                    </Col>
                    <Col md={9} xs={12}>
                        <MainContent tenderId={tenderId} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Proposal;