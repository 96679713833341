import * as React from 'react';
import { useState, useEffect } from 'react';
import { FaPlusCircle } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { BiMove } from 'react-icons/bi';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setResumeAddRecord, setResumeRemoveRecord, setResumeReordered } from '../../../../../../Store/Slices/ResumeSlice';
import { GetAxios, reorder } from '../../../../../../Shared';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../../../../Environments';
import { setGlobalLoading } from '../../../../../../Store';
import { confirmAlert } from 'react-confirm-alert';
import { BottomButtons } from "../../BottomButtons";
import { useTranslation } from 'react-i18next';


export const Skills = () => {

    const { t } = useTranslation('resume');


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { resumeId } = useParams();

    const skills = useSelector((state: AppStore) => state.resume.skills);

    const [InputTag, setInputTag] = useState("");

    const addTag = () => {
        if (InputTag) {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).post(config.apiBase + '/api/Resume/SaveSkills', { name: InputTag, resumeId }).then(s => {

                dispatch(setResumeAddRecord({ skills: { ...s.data.data } }));
                setInputTag("");
                dispatch(setGlobalLoading(false));
            });
        }
    }
    const removeTag = (skill?: ResumeSkills) => {
        if (skill) {
            Delete(skill);
        }
    }

    const Delete = (skill: ResumeSkills) => {
        confirmAlert({
            title: t('newResumeCard.deleteResumeMsg.title').toString(),
            message: t('newResumeCard.deleteResumeMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('yes').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Resume/DeleteRecord', {
                            params: {
                                id: skill.id,
                                resumeId,
                                type: 'Skills'
                            }
                        }).then(s => {
                            dispatch(setResumeRemoveRecord({ skills: skill }));
                        })
                    }
                },
                {
                    label: t('no').toString(),
                    onClick: _ => {
                        return
                    }
                }
            ]
        })
    }
    return (
        <>
            <div className="mb-4">
                <div className="dZcjzV sections-header">
                    <div className="feVKyO"> {t('skillStep.skillTitle')}</div>
                </div>
                <div className="gJaquO"> {t('skillStep.skillDesc')}.</div>
            </div>
            <div className='d-flex justify-content-between'>
                <Heading> {t('skillStep.skills')} </Heading>
                {/* <p className='fw-600 cursor-pointer text-blue w-100 text-end'><FaPlusCircle className='me-2' size={22} />Add pre-written text</p> */}
            </div>
            <div className='form-float'>
                <div className=' mb-3 rounded fw-500'>
                    <div className="input-group form-floating">
                        <input type="text" className="form-control rounded bg-field"
                            enterKeyHint='go'
                            onKeyDown={k => {
                                if (k.key == 'Enter') {
                                    addTag();
                                }
                            }}
                            onChange={(e) => setInputTag(e.target.value)} value={InputTag} placeholder={t('skillStep.enterSkills').toString()} aria-label={t('skillStep.skills').toString()} aria-describedby="basic-addon1" />
                        <label className='label-float'> {t('skillStep.skills')} </label>
                        <span className="input-group-text justify-content-center w-lg-25 w-md-25" id="basic-addon2">
                            <button className='add-btn fw-600 rounded' onClick={addTag}> {t('skillStep.add')}</button>
                        </span>
                    </div>
                    <span className='helper-text ms-3'>{t('eg')}. {t('skillStep.customerAssistance')}</span>
                </div>
            </div>
            <DragDropContext onDragEnd={e => {
                if (!e.destination) {
                    return;
                }

                const items = reorder(skills, e.source.index, e.destination.index);

                dispatch(setResumeReordered({ skills: items }));
                GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/UpdateSortOrder', items.map(s => s.id), {
                    params: {
                        resumeId: resumeId,
                        type: 'Skills'
                    }
                });
            }}>
                <Droppable droppableId='droppable' direction="horizontal">
                    {
                        (provided, snapshot) =>
                            <div className='showTagsWrapper' ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {
                                    skills?.map((x, i) =>
                                        <Draggable key={'a_' + x.id + x.name} draggableId={'a_' + x.id + x.name} index={i}>
                                            {
                                                (provided, snapshot) =>
                                                    <TagContainer ref={provided.innerRef}
                                                        {...provided.draggableProps}>
                                                        <TagDragger {...provided.dragHandleProps}>
                                                            <BiMove />
                                                        </TagDragger>
                                                        <span className='textSpan'>{x.name}</span>
                                                        <CloseBtn className='clsBtn' onClick={e => removeTag(x)}>
                                                            <FaTimes color='#fff' />
                                                        </CloseBtn>
                                                    </TagContainer>
                                            }
                                        </Draggable>

                                    )}
                            </div>
                    }
                </Droppable>
            </DragDropContext>
            <BottomButtons onSubmit={e => e()} />
        </>
    );
}

const Heading = styled.div`
color: rgb(0, 0, 0);
letter-spacing: 0.5px;
display: flex;
-webkit-box-align: center;
align-items: center;
width: 100%;
height: 28px;
font-size: 20px;
font-weight: 500;
line-height: 28px;

`
const TagContainer = styled.div`
color: rgb(255, 255, 255);
margin-bottom:0.5rem;
    height: 44px;
    opacity: 1;
    padding: 0px 10px 0px 10px;
    font-size: 14px;
    max-width: 100%;
    font-weight: 500;
    line-height: 16px;
    border-radius: 22px;
    justify-content: space-between;
    background-color: rgb(38, 160, 244);
    display: inline-flex;
    align-items:center;
    margin-right:20px;
    & .textSpan{
        margin:0 20px 0 0;
    }
    &:hover .clsBtn{
        opacity:1;
        pointer-events:all;
    }
`
const TagDragger = styled.span`
font-size : 20px;
& svg{
    width: 30px;
    height: 30px;
    background-color: rgb(0, 131, 228);
    padding: 4px;
    border-radius: 100%;
    margin-right: 8px;

}
`
const CloseBtn = styled.span`
color:#fff;
opacity:0;
pointer-events:none;
`