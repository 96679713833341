import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const TermsUseIndex = () => {

    const { t } = useTranslation('footer');

    return (
        <>
            <div className="container">
                <div className="row py-50 mx-auto">
                    <div className="col-12 fontsize-privacy">
                        <div>
                            <h1 className="mb-3 mt-0 fw-600 title-privacy"> {t('termUse.termsUseTitle')}</h1>
                            <p><strong> {t('termUse.effectiveDate')}: </strong>  {t('termUse.date')}</p>
                            <p> {t('termUse.WelcomeWebsite')}!</p>
                            <p><strong> {t('termUse.websiteSubsidiary')} </strong></p>
                            <p> {t('termUse.theseTerms')} "{t('termUse.bonjob')}", "{t('termUse.we')}", "{t('termUse.us')}" and "{t('termUse.our')}" {t('termUse.maestroMediaWhich')}. </p>
                            <p className='text-uppercase'> {t('termUse.bYAccessingUsing')}.
                            </p>
                            <p>{t('termUse.otherLegalEntity')}.
                            </p>
                            <p className='text-uppercase'> {t('termUse.notAgree')}.</p>
                            <ul className='ps-3'>
                                <li><p>  {t('termUse.aboutTheTerms')}</p>
                                </li>
                                <li><p>{t('termUse.aboutTheWebsite')}</p>
                                </li>
                                <li><p> {t('termUse.accounts')}</p>
                                </li>
                                <li><p> {t('termUse.conductGuide')}</p>
                                </li>
                                <li><p> {t('termUse.intellectualPropertry')}</p>
                                </li>
                                <li><p> {t('termUse.thirdPartyTerms')}</p>
                                </li>
                                <li><p> {t('termUse.indemnificationTitle')}</p>
                                </li>
                                <li><p> {t('termUse.disputeResolUTION')}</p>
                                </li>
                            </ul>
                            <p className='fw-600 subtitle-privacy text-uppercase'>1. {t('termUse.aboutTerms')}</p>

                            <p className='fs-22 fw-600'>1.1 {t('termUse.keyTerms')}</p>
                            <div className='mb-2'>
                                <span className='fs-18'>{t('termUse.usedTheseTerms')}:</span>
                            </div>
                            <ul className='ps-3'>
                                <li><p><strong>"{t('termUse.content')}"</strong> {t('termUse.contentDesc')}.</p>
                                </li>
                                <li><p><strong>"{t('termUse.users')}"</strong> {t('termUse.allPersons')} "{t('termUse.accessing')}" {t('termUse.andOr')} "{t('termUse.using')}" {t('termUse.browsingTheWebsite')}.</p>
                                </li>
                                <li><p><strong>"{t('termUse.website')}"</strong> {t('termUse.websiteDesc')} "{t('termUse.website')}"  {t('termUse.contentAvailable')}.</p>
                                </li>
                                <li><p><strong>"{t('termUse.bonjob')}",</strong> "{t('termUse.we')}", "{t('termUse.us')}" {t('termUse.or')} "{t('termUse.our')}" {t('termUse.contractorsAgents')}. </p>
                                </li>
                            </ul>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>1.2 {t('termUse.websiteRules')}</span>
                                </div>
                                <p>
                                    {t('termUse.websiteRulesDesc')}.
                                </p>
                                <p className='text-uppercase'>
                                    {t('termUse.carefully')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>1.3 {t('termUse.modificationTerms')}</span>
                                </div>
                                <p>
                                    {t('termUse.modificationTermsDesc')}.
                                </p>
                            </div>
                            <p className='fw-600 subtitle-privacy text-uppercase'>2. {t('termUse.aboutTheWebsite')}</p>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>2.1 {t('termUse.licenseToUse')}</span>
                                </div>
                                <p>
                                    {t('termUse.licenseToUseDesc')}<br /> {t('termUse.mannerPermitted')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>2.2 {t('termUse.changesUpdatesWebsite')}</span>
                                </div>
                                <p>
                                    {t('termUse.changesUpdatesWebsiteDesc1')}.
                                </p>
                                <p>{t('termUse.changesUpdatesWebsiteDesc2')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>2.3 {t('termUse.internationalUsers')}</span>
                                </div>
                                <p>
                                    {t('termUse.mediaBased')} <strong>{t('termUse.theSite')}</strong> {t('termUse.internationalUsersDesc1')} <strong>{t('termUse.and')} </strong> {t('termUse.internationalUsersDesc2')}.
                                </p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy text-uppercase'>3. {t('termUse.accounts')}</p>
                                <p> {t('termUse.publiclyAvailable')}.</p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy text-uppercase'>4. {t('termUse.userConductGuidelines')}</p>
                                <p> {t('termUse.userConductGuidelinesDesc')}.
                                </p>
                            </div>
                            <p className='fw-600 subtitle-privacy text-uppercase'>5. {t('termUse.intellectualProperty')}</p>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>5.1 {t('termUse.5.1theSite')}</span>
                                </div>
                                <p>
                                    {t('termUse.theSiteDesc1')}.
                                </p>
                                <p> {t('termUse.theSiteDesc2')}.
                                </p>
                                <p> {t('termUse.theSiteDesc3')}.</p>
                                <p> {t('termUse.theSiteDesc4')}.
                                </p>
                                <p> {t('termUse.theSiteDesc5')} <strong>Curriculosi.com,</strong> {t('termUse.theSiteDesc6')} <strong> {t('termUse.maestroMediaMarks')} </strong> {t('termUse.theSiteDesc7')}.</p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>5.2 {t('termUse.feedback')}</span>
                                </div>
                                <p>
                                    {t('termUse.feedbackDesc1')} <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=marketing@curriculosi.com" className='text-decoration-none'>marketing@curriculosi.com</a>.   {t('termUse.feedbackDesc2')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>5.3 {t('termUse.thirdPartyConditions')}</span>
                                </div>
                                <p>
                                    {t('termUse.thirdPartyConditionsDesc1')}.
                                </p>
                                <p><small>{t('termUse.thirdPartyConditionsDesc2')}.</small></p>
                            </div>
                            <p className='fw-600 subtitle-privacy text-uppercase'>6. {t('termUse.disclaimerIndemnification')}</p>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>6.1 {t('termUse.disclaimer')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.disclaimerDesc')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>6.2 {t('termUse.limitation')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.limitationDesc1')}.
                                </p>
                                <p className='text-uppercase'>
                                    {t('termUse.limitationDesc2')}.
                                </p>
                                <p className='text-uppercase'>
                                    {t('termUse.limitationDesc3')}.
                                </p>
                                <p className='text-uppercase'>
                                    {t('termUse.limitationDesc4')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>6.3 {t('termUse.exclusions')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.exclusionsDesc')}.
                                </p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy text-uppercase'>7. {t('termUse.governingLaw')}</p>
                                <p>{t('termUse.governingLawDesc')}.</p>
                            </div>
                            <p className='fw-600 subtitle-privacy text-uppercase'>8. {t('termUse.miscellaneous')}</p>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>8.1 {t('termUse.confidentiality')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.confidentialityDesc')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>8.2 {t('termUse.notices')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.noticesDesc')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>8.3 {t('termUse.waiver')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.waiverDesc')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>8.4 {t('termUse.severability')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.severabilityDesc')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>8.5 {t('termUse.titles')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.titlesDesc')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>8.6 {t('termUse.construction')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.constructionDesc')}.
                                </p>
                            </div>
                            <div className='term-details'>
                                <div className='mb-2'>
                                    <span className='fs-22 fw-600'>8.7 {t('termUse.contactUs')}</span>
                                </div>
                                <p className='text-uppercase'>
                                    {t('termUse.contactUsDesc')} : <br />
                                    <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=marketing@curriculosi.com" className='text-decoration-none'>marketing@curriculosi.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}