import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ParseHTMLTips } from '../../../../../Components/Common/ParseTipsHTML';
import { config } from '../../../../../Environments';
import { GetAxios } from '../../../../../Shared';
import { setGlobalLoading } from '../../../../../Store';
import { setResumeActiveStep, setResumeObjectives } from '../../../../../Store/Slices/ResumeSlice';
import TipsPopup from '../../../Components/TipsPopup';
import { BottomButtons } from '../BottomButtons';
import { useTranslation } from 'react-i18next';

function Objectives() {

    const { t } = useTranslation('resume');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { resumeId } = useParams();

    const objectives = useSelector((state: AppStore) => state.resume.objectives);
    console.log(objectives);
    console.log("Objective")
    const [isDefaultContentLoaded, setDefaultContextLoaded] = useState(false);


    const html = convertFromHTML('<p></p>');
    const [editorState, setEditState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)));

    React.useEffect(() => {
        const htmlContent = objectives || '<p></p>';
        if (!isDefaultContentLoaded) {
            const html = convertFromHTML(htmlContent || '<p></p>');
            setEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
            if (htmlContent) {
                setDefaultContextLoaded(true);
            }
        }
    }, [objectives, resumeId]);

    // React.useEffect(() => {
    //     dispatch(setResumeActiveStep(7));
    // }, [7]);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className='mb-4'>
                        <TipsPopup title="contactStep.tips" description="objectMdl.description"
                            tipsList={ParseHTMLTips(objectiveTipsBody, t)} />
                    </div>
                    <div className="subsection-container">
                        <div className="mb-3">
                            <div className="dZcjzV sections-header">
                                <div className="feVKyO"> {t('objectStep.objectTitle')} </div>
                            </div>
                            <div className="gJaquO"> {t('objectStep.objectDesc')}.</div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="gQiRPX">
                            <div className="mb-2 sections-header">
                                <div className="dJqmzX">
                                    <div className="hfzZao">
                                        <div className="czFa-Df fw-16 fw-600">  {t('objectStep.object')} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <Editor
                                editorState={editorState}
                                stripPastedStyles={true}
                                editorClassName="editor-height"
                                toolbar={{
                                    options: ['inline', 'textAlign', 'list'],
                                    inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough',] },
                                    textAlign: { inDropdown: false, options: ['left', 'center', 'right'] },
                                    list: { inDropdown: false, options: ['unordered', 'ordered'], },
                                }}
                                onEditorStateChange={e => {
                                    setEditState(e);
                                    dispatch(setResumeObjectives(draftToHtml(convertToRaw(e.getCurrentContent()))));
                                }} />
                            <p className='helper-text ms-3 text-start text-level'>{t('eg')}. {t('objectStep.objectHelpText')}.</p>
                        </div>

                    </div>
                </div>
            </div>
            <BottomButtons onSubmit={submitted => {
                dispatch(setGlobalLoading(true));
                GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SaveObjectives', undefined, {
                    params: {
                        id: resumeId,
                        objectives: objectives
                    }
                }).then(s => {

                    submitted();
                    dispatch(setGlobalLoading(false));

                    //navigate('/resumes/builder/' + resumeId + '/editor/projects');
                    //navigate('/resumes/builder/' + resumeId + '/finish');

                })
            }} />
        </>
    );
}

export default Objectives;

const objectiveTipsBody = {
    "tipsMdlTitle": ["objectMdl.tipsList1",
        "objectMdl.tipsList2",
    ],
    "objectMdl.exmp.title": ["objectMdl.exmp.exmpList1",
        "objectMdl.exmp.exmpList2",
        "objectMdl.exmp.exmpList3",
        "objectMdl.exmp.exmpList4",
        "objectMdl.exmp.exmpList5",
    ],
}