import { createSlice } from "@reduxjs/toolkit"

const initialState: GlobalSlice = {
    isLoading: false
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setGlobalLoading: (state: GlobalSlice, action: IAction<boolean>) => {
            state.isLoading = action.payload
        }
    },
});

export const {
    setGlobalLoading
} = globalSlice.actions;

export default globalSlice.reducer
