import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { UserRole } from "../../Shared";
import Dropdown from 'react-bootstrap/Dropdown';


const Index = () => {
  const auth = useSelector((state: AppStore) => state.auth);
  const userRole = UserRole(auth?.raw?.access_token);
  const navigate = useNavigate();
  const routeChange = (key: any) => {
   
    let path = `/employer-account/`;
    navigate(path + key);
  };
  
  const [currentTab, setCurrentTab] = useState(false);
  
  return (
    <>
      <div className="container">
        <div className="accounting">
          {/* {
            userRole == 'Employer' &&
            <Tabs
              onSelect={routeChange}
              id="uncontrolled-tab-example"
              className="my-3 tab-account"
            >
              <Tab eventKey="" title="My Ad(s)" className="">
                 
              </Tab>
              <Tab
                eventKey="account_setting"
                title="Account Settings"
                className="arrow-down"

              >
                <Outlet />
              </Tab>
              <Tab eventKey="upgrade_account" title="Upgrade My Account">
                <Outlet />
              </Tab>
              <Tab eventKey="upgrade_account" title="About my Billing">
                <Outlet />
              </Tab>
            </Tabs>
          } */}
          {/* {
            userRole == 'Employer' &&
            <div className="d-flex align-items-center flex-wrap tab-account-company mb-2 mt-3" onSelect={routeChange}>
                <div className="mx-3 nav-border-account active">
                  <Link onClick={() => setCurrentTab(true)} to={'/employer-account'} 
                  className={"text-decoration-none nav-head" + (currentTab === true ? ' active' : '')}>My Ad(s)</Link>
                </div>
                <div className="mx-3 nav-border-account">
                  <Link to={'/employer-account/account-setting'} className="text-decoration-none nav-head account-arrow"><span>Account Settings</span></Link>
                </div>
                <div className="mx-3 nav-border-account">
                  <Link to={'/employer-account/upgrade-account'} className="text-decoration-none nav-head">Upgrade My Account</Link>
                </div>
                <div className="mx-3 nav-border-account">
                  <Dropdown id="employers-btn-dropdown">
                    <Dropdown.Toggle id="dropdown-account" className="fw-600 nav-head text-decoration-none">
                      About my Billing
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/employer-account/order-history" className="fw-600 nav-head text-decoration-none">Order History</Dropdown.Item>
                      <Dropdown.Item as={Link} to="/employer-account/pending-invoice" className="fw-600 nav-head text-decoration-none">Pending Invoice(s)</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
            </div>
          } */}
          <Outlet />    
        </div>
      </div>
    </>
  );
};
export default Index;
