import React, { useRef, useState } from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { useLocation } from 'react-router-dom';

const SocialIconBar = () => {

    const [open, setOpen] = useState(false);
    const icon = useLocation();

    return (
        <>
            <div className='border-skill-icon'>
                <div className=''>
                    {icon.pathname === '/Skills-Matcher' || icon.pathname === '/Skills-Matcher-Results' ? null :
                        <span className='social-icon-skill facebook-color me-2 rounded-0 text-white'>
                            <FaFacebookF />
                        </span>
                    }
                    {icon.pathname === '/Skills-Matcher' || icon.pathname === '/Skills-Matcher-Results' ? null :
                        <span className='me-2 social-icon-skill rounded-0 twitter-color text-white'>
                            <FaTwitter />
                        </span>
                    }
                    {icon.pathname === '/Skills-Matcher' || icon.pathname === '/Skills-Matcher-Results' ? null :
                        <span className="social-icon-skill rounded-0 linkedin-color text-white">
                            <FaLinkedinIn />
                        </span>
                    }
                </div>
            </div>
        </>
    )
}
export default SocialIconBar;