import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IoDiamondOutline } from 'react-icons/io5';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { FaLink } from 'react-icons/fa';
import { LiaAwardSolid } from 'react-icons/lia';
import { VscGlobe } from 'react-icons/vsc';

export const Timeline = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume')

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <>
         <WrapperDiv additionalSettings={additionalSettings}>
            <div className='template'>
               <div className='preview-template'>
                  <div id='template-full' className='IvyLeagueResumePage shadow-lg'>
                     <div className='template-main'>
                        <section className='name-sec'>
                           {(contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) && (
                              <div className='template-prague-name text-uppercase'>
                                 <div
                                    className='selectable-section'
                                    onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                 >
                                    <h2 className='fw-800 word-wrap text-timeline'>
                                       <span>{contactInformation.firstName}</span> <span>{contactInformation.lastName}</span>
                                    </h2>
                                    <p className='fw-700 fs-17'>{contactInformation.occupation}</p>
                                 </div>
                              </div>
                           )}

                           {Object.values(contactInformation).filter((s) => s).length > 0 && (
                              <div className='contact-info-sec selectable-section'>
                                 <ul
                                    className='list-unstyled d-flex flex-wrap justify-content-between gap-2 selectable-section'
                                    onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                 >
                                    <li className='mb-1'>
                                       <div className='d-flex align-items-center'>
                                          <FaPhoneAlt className='text-timeline me-1' size={15} />
                                          <p className='text-decoration-none text-black fw-700 fs-13'>{contactInformation.phone}</p>
                                       </div>
                                    </li>
                                    <li className='mb-1'>
                                       <div className='d-flex align-items-center'>
                                          <MdOutlineAlternateEmail className='text-timeline me-1' size={15} />
                                          <p className='text-decoration-none text-black fw-700 fs-13'>{contactInformation.email}</p>
                                       </div>
                                    </li>
                                    <li className='mb-1'>
                                       <div className='d-flex align-items-center'>
                                          <FaRegCalendarAlt className='text-timeline me-1' size={15} />
                                          <p className='text-decoration-none text-black fw-700 fs-13'>
                                             {moment(contactInformation.dob).format('MM/DD/YYYY')}
                                          </p>
                                       </div>
                                    </li>
                                    <li className='mb-1'>
                                       <div className='d-flex align-items-center'>
                                          <VscGlobe className='text-timeline me-1' size={15} />
                                          <p className='text-decoration-none text-black fw-700 fs-13'>{contactInformation.nationality}</p>
                                       </div>
                                    </li>
                                    <li className='mb-1'>
                                       <div className='d-flex align-items-center'>
                                          <FaLink className='text-timeline me-1' size={15} />
                                          <p className='text-decoration-none text-black fw-700 fs-13'>{contactInformation.linkedInLink}</p>
                                       </div>
                                    </li>
                                    <li className='mb-1'>
                                       <div className='d-flex align-items-center'>
                                          <ImLocation2 className='text-timeline me-1' size={15} />
                                          <p className='text-decoration-none text-black fw-700 fs-13'>{contactInformation.address}</p>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           )}
                        </section>

                        {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                           <section
                              className='objective-sec'
                              onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-timeline-heading'>{t('Objective')}</p>
                                 <div className='selectable-section'>
                                    <p
                                       className='sec-value my-2 editor-list-margin-left'
                                       dangerouslySetInnerHTML={{ __html: objectives }}
                                    ></p>
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                           <section
                              className='skills-sec'
                              onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-timeline-heading'>{t('Skills')}</p>
                                 <div className='selectable-section my-2'>
                                    <ul className='list-unstyled d-flex align-items-center flex-wrap fs-14'>
                                       {skills.length > 0 &&
                                          skills.map((s, i) => (
                                             <li
                                                key={'skill_' + s.name + s.id + i}
                                                className='mb-3 me-4 border-bottom border-1 border-dark px-2 fw-600'
                                             >
                                                {s.name}
                                             </li>
                                          ))}
                                    </ul>
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                           <section
                              className='languages-sec'
                              onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-timeline-heading'>{t('Languages')}</p>
                                 <div className='selectable-section mt-2'>
                                    <div className='row'>
                                       {languages.length > 0 &&
                                          languages.map((s, i) => (
                                             <div className='col-4 pe-4' key={'languages_' + s.name + s.id + i}>
                                                <div className='lang-card mb-3 fs-14 border-bottom border-1 border-dark px-2'>
                                                   <div className='d-flex align-items-center justify-content-between'>
                                                      {s.name && <p className='fw-600 fs-16 text-timeline'>{s.name}</p>}
                                                      {s.level && <p className='fw-500'>{s.level}</p>}
                                                   </div>
                                                   <div>{s.course && <p className='mb-1 fw-500'>{s.course}</p>}</div>
                                                </div>
                                             </div>
                                          ))}
                                    </div>
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                           <section
                              className='experience-sec'
                              onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                           >
                              <p className='text-uppercase temp-timeline-heading mt-2'>{t('Experiences')}</p>
                              <div className='selectable-section mt-3 mb-16'>
                                 {experiences.map((e, i) => (
                                    <div className='row' key={'exp_' + i}>
                                       <div className='col-3'>
                                          <div className='fs-15'>
                                             <p className='fw-700 mb-1'>
                                                {e.startDate || e.endDate ? (
                                                   <>
                                                      {e.startDate ? (
                                                         <span>
                                                            {new Date(e.startDate).toLocaleString('default', {
                                                               month: 'short',
                                                            })}{' '}
                                                            {new Date(e.startDate).getFullYear()}
                                                         </span>
                                                      ) : (
                                                         <></>
                                                      )}
                                                      {e.endDate && !e.currentlyWorking ? (
                                                         <span className='barline'>
                                                            {new Date(e.endDate).toLocaleString('default', {
                                                               month: 'short',
                                                            })}{' '}
                                                            {new Date(e.endDate).getFullYear()}
                                                         </span>
                                                      ) : (
                                                         <></>
                                                      )}

                                                      {e.currentlyWorking ? <em className='barline'>{t('present')} </em> : <></>}
                                                   </>
                                                ) : (
                                                   <></>
                                                )}
                                             </p>
                                             {e.location && <p className='fw-500'>{e.location}</p>}
                                          </div>
                                       </div>
                                       <div className='col-9'>
                                          <div className='d-flex'>
                                             <div className='dot-vertical-timeline position-relative'></div>
                                             <div className='border-vertical-timeline'></div>
                                             <div className='ms-3'>
                                                <p className='fw-500 fs-15'>{e.title}</p>
                                                {e.company && <p className='fw-600 fs-16 text-timeline'>{e.company}</p>}
                                                {e.description && (
                                                   <p
                                                      className='my-2 sec-value editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </section>
                        ) : (
                           <></>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                           <section
                              className='education-sec'
                              onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                           >
                              <p className='text-uppercase temp-timeline-heading mt-2'>{t('Education')}</p>
                              <div className='selectable-section mt-3 mb-16'>
                                 {educations.map((e, i) => (
                                    <div className='row' key={'edu__' + i}>
                                       <div className='col-3'>
                                          <div className='fs-15'>
                                             <p className='fw-700 mb-1'>
                                                {e.startDate || e.completeDate ? (
                                                   <>
                                                      {e.startDate ? (
                                                         <span>
                                                            {new Date(e.startDate).toLocaleString('default', {
                                                               month: 'short',
                                                            })}{' '}
                                                            {new Date(e.startDate).getFullYear()}
                                                         </span>
                                                      ) : (
                                                         <></>
                                                      )}
                                                      {e.completeDate && !e.currentlyStudying ? (
                                                         <span className='barline'>
                                                            {new Date(e.completeDate).toLocaleString('default', {
                                                               month: 'short',
                                                            })}{' '}
                                                            {new Date(e.completeDate).getFullYear()}
                                                         </span>
                                                      ) : (
                                                         <></>
                                                      )}

                                                      {e.currentlyStudying ? <em className='barline'>{t('present')} </em> : <></>}
                                                   </>
                                                ) : (
                                                   <></>
                                                )}
                                             </p>
                                             {e.location && <p className='fw-500'>{e.location}</p>}
                                          </div>
                                       </div>
                                       <div className='col-9'>
                                          <div className='d-flex'>
                                             <div className='dot-vertical-timeline position-relative'></div>
                                             <div className='border-vertical-timeline'></div>
                                             <div className='ms-3'>
                                                <p className='fw-500 fs-15'>{e.degree}</p>
                                                {e.institute && <p className='fw-600 fs-16 text-timeline'>{e.institute}</p>}
                                                {e.description && (
                                                   <p
                                                      className='my-2 sec-value editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </section>
                        ) : (
                           <></>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                           <section
                              className='Project-sec'
                              onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                           >
                              <p className='text-uppercase temp-timeline-heading'>{t('Projects')}</p>
                              <div className='mt-2'>
                                 <div className='selectable-section'>
                                    {projects.map((v, i) => (
                                       <p
                                          className='sec-value my-2 editor-list-margin-left'
                                          key={'projects__' + i}
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                           <section
                              className='Publication-sec'
                              onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                           >
                              <p className='text-uppercase temp-timeline-heading'>{t('Publications')}</p>
                              <div className='selectable-section mt-2'>
                                 {publications.map((v, i) => (
                                    <div key={'publication__' + i} className=''>
                                       <p
                                          className='sec-value my-2 editor-list-margin-left'
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    </div>
                                 ))}
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                           <section
                              className='Achievements-sec'
                              onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-timeline-heading'>{t('Achievements')}</p>
                                 <div className='row selectable-section mt-2'>
                                    {achievements.map((v, i) => (
                                       <div className='col-6' key={'achievements__' + i}>
                                          <div className='d-flex align-items-start justify-content-start mb-2'>
                                             <span>
                                                <IoDiamondOutline className='text-timeline me-2' size={22} />
                                             </span>
                                             <div className='detail-achivements'>
                                                <p
                                                   className='sec-value editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 && (
                           <section
                              className='certifications-sec'
                              onClick={(_) => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-2'>
                                 <p className='text-uppercase temp-timeline-heading'>{t('Certifications & Courses')}</p>
                                 <div className='selectable-section mt-2'>
                                    <div className='row'>
                                       {certificates.map((v, i) => (
                                          <div className='col-6' key={'certificates__' + i}>
                                             <div>
                                                <p
                                                   className='sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                           <section
                              className='Honors-Award-sec'
                              onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-timeline-heading'>{t('Honors & Awards')}</p>
                                 <div className='row selectable-section mt-2'>
                                    {honours.map((v, i) => (
                                       <div className='col-6' key={'honour__' + i}>
                                          <div className='d-flex align-items-start justify-content-start mb-2 creative-border-bottom'>
                                             <span>
                                                <LiaAwardSolid className='text-timeline me-2' size={22} />
                                             </span>
                                             <div className='detail-achivements'>
                                                <p
                                                   className='sec-value editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                           <section
                              className='References-sec'
                              onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-timeline-heading'>{t('References')}</p>
                                 <div className='selectable-section mt-2'>
                                    <div className='d-grid display-grid-custom'>
                                       {references.map((v, i) => (
                                          <div className='' key={'refer__' + i}>
                                             <div className='lang-card fs-12 mb-2 lh-sm'>
                                                <p className='mb-1 fs-15 text-timeline fw-600'>{v.name}</p>
                                                <p className='mb-1 fw-500'>{v.city}</p>
                                                <p className='mb-1 fw-500'>{v.tel}</p>
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              </div>
                           </section>
                        )}
                        <section className='Custom-sec mt-4'>
                           <div className='mt-3'>
                              <p className='text-uppercase temp-timeline-heading'></p>
                              <div
                                 className='row selectable-section mt-2'
                                 onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                              >
                                 {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                    customSections.map((v, i) => (
                                       <div className='col-6' key={'custom__' + i}>
                                          <div className='d-flex align-items-start justify-content-start mb-2 creative-border-bottom'>
                                             <span>
                                                <IoDiamondOutline className='text-timeline me-2' size={22} />
                                             </span>
                                             <div className='detail-custom-sec mb-1'>
                                                <span className='text-timeline fw-600 fs-14'>{v.title}</span>
                                                <p
                                                   className='sec-value editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                              </div>
                           </div>
                        </section>
                     </div>
                  </div>
               </div>
            </div>
         </WrapperDiv>
      </>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .text-timeline {
      color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color} !important;
   }

   & .temp-timeline-heading {
      color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color} !important;
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`
