import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { config } from '../../../Environments';
import { GetAxios } from '../../../Shared';
import { setGlobalLoading } from '../../../Store';
import MainContent from './MainContent';
import SideContent from './SideContent';

const Profile = () => {
    const dispatch = useDispatch();

    const [data, setData] = useState<ProfilePublicView>();
    const [count, setJobCount] = useState(0);
    const [params] = useSearchParams();
    const [userId, setId] = useState(params?.get('id')||"");
 
   
    
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(setGlobalLoading(true));
        let url = config.apiBase + '/api/Employer/ProfilePublicView/?id='+userId;
      //  if (params.has('id')) {
        //    url += ('?id=' + params.get('id'));
            
       // }
        GetAxios(navigate).get(url).then(res => {
            setData(res.data.data);
            dispatch(setGlobalLoading(false));
        });
    }, []);
    return (
        <>
            <Container>
                <Row className='py-50'>
                    <Col md={4} xs={12} className="ps-lg-0">
                        <SideContent data={data} count={count} />
                    </Col>
                    <Col md={8} xs={12}>
                        <MainContent data={data}   onJobCount={setJobCount} userId={userId}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Profile;

export interface ProfilePublicView {
   
    firstName:string,
    lastName:string,
    email: string,
    phoneNumber: string,
    companyName: string,
    industry: string,
    sector: string,
    companyDescription: string,
    department: string,
    noOfEmployees: string,
    legalEntity: string,
    registrationNumber: string,
    specialities: string,
    website: string,
    contactPerson: string,
    profitNonProfit:string,
    banner: string,
    logo: string,
    address: string,
    language: string,
    socialNetwork: {
        "youtube": null,
        "linkedIn": null,
        "twitter": null,
        "instagram": null,
        "google": null,
        "facebook": "facebook.com"
    }
}