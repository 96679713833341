import FsLightbox from "fslightbox-react";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { FaAddressBook, FaBuilding, FaCity, FaEnvelope, FaEye, FaEyeSlash, FaGlobe, FaLock, FaPhoneAlt, FaPlayCircle, FaUserAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../Shared";
import { config } from "../../Environments";
import { setGlobalLoading } from "../../Store";
import { useNavigate } from "react-router-dom";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { AnyARecord } from "dns";
const Index = () => {

  const { t } = useTranslation('seminar');
  const { t: ct } = useTranslation('common');

  const dispatch = useDispatch();
  const [seminarlist, setSeminarList] = useState([]);
  const [sortBy, setSortBy] = useState('HTL');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSkillLimit, setPageSkillLimit] = useState(6);

  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios().get(config.apiBase + '/api/General/GetAnnounces', {
      params: {
        sortBy
      }
    }).then(s => {
      setSeminarList(s.data || []);
      dispatch(setGlobalLoading(false));
    });
  }, [sortBy]);

  return (
    <>
      <div className="container py-50">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="text-start fw-700">{t('announces')}</h4>
              <div className="">
                <select
                  value={sortBy}
                  onChange={s => setSortBy(s.target.value)}
                  id="inputState"
                  className="form-select form-control key-weight mt-2 w-selected">
                  <option key={"s_" + 1} value={'HTL'}>{ct('newToOld')}</option>
                  <option key={"s_" + 2} value={'LTH'}>{ct('oldToNew')}</option>
                  <option key={"s_" + 3} value={'ATZ'}>{ct('nameA')}-{">"}Z</option>
                  <option key={"s_" + 4} value={'ZTA'}>{ct('nameZ')}-{">"}{ct('a')}</option>

                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-4">
          <div className="">
            <SeminarAds list={seminarlist} pageSkillLimit={pageSkillLimit} currentPage={currentPage} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">

            <div className="d-flex justify-content-between align-items-center mt-3">
              <h4 className="text-start fw-700"></h4>
              <div >
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item me-2">
                      <button
                        type="button"
                        className="text-white pb-result-end"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage !== 1 ? false : true}
                      >
                        {" "}
                        <FaChevronLeft />
                      </button>
                    </li>
                    <li className="page-item me-2 pb-result">
                      <a className="pointer-event" onClick={() => setCurrentPage(currentPage)}>
                        {currentPage}{" "}
                      </a>
                    </li>
                    <li className="page-item me-2 pb-result">
                      <a className="pointer-event" onClick={() => setCurrentPage(Math.ceil(seminarlist.length / pageSkillLimit))}>
                        {Math.ceil(seminarlist.length / pageSkillLimit)}
                      </a>
                    </li>
                    <li className="page-item me-2">
                      <button
                        type="button"
                        className="text-white pb-result-end"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={
                          currentPage !== Math.ceil(seminarlist.length / pageSkillLimit)
                            ? false
                            : true
                        }
                      >
                        {" "}
                        <FaChevronRight />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;

export const SeminarAds = (props: { list: any, pageSkillLimit: any, currentPage: any }) => {

  const { t } = useTranslation('seminar');


  const [lightbox, setLightbox] = useState({
    toggler: false,
    slide: 1
  });

  const handleImageClick = (index: number) => {

    // var img = document.getElementById("img1") || undefined;
    // (HTMLElement)img.style.transform = "scale(1.5)";
    // // Animation effect
    // img.style.transition = "transform 0.25s ease";
    setLightbox({
      toggler: !lightbox.toggler,
      slide: index + 1,
    });
  }
  const items = [{ "id": 14, 'name': 'd' }, { "id": 15, 'name': 'c' }]
  const newArray = items.map(item => ({ [item.id]: 1 }));



  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-2">
          <BonjanBenefits />
        </div>
        <div className="col-md-8">
          <div className="row">
            {
              props.list && props.list.length == 0 ? <div className="mt-lg-5 pt-5"><p className="text-dark fw-700 fw-italic text-center fs-22 px-annonce">{t('noAnnounceFound')}</p></div> :
                (props.list || []).slice((props.currentPage - 1) * props.pageSkillLimit, props.currentPage * props.pageSkillLimit)
                  .map((item: any, index: any) => {

                    return (
                      <>
                        <div className="col-md-4 mb-3" key={"seminar_" + (props.currentPage - 1) * props.pageSkillLimit + index}>
                          <div className="seminar-card">
                            <div onClick={() => handleImageClick((props.currentPage - 1) * props.pageSkillLimit + index)}>
                              <img id="img12" src={config.apiBase + item.banner} className="w-100 seminar-img" alt="seminar image" />
                            </div>
                            <div className="">
                              <div className="seminar-title py-1">
                                <div className="d-flex flex-column align-items-start justify-content-start h-150px" >
                                  <div className="my-1">
                                    <p className="ms-1 mb-0 fw-500">{item.name}</p>

                                  </div>
                                  <div className="desc text-start d-flex aligm-items-start mt-2">
                                    <span className="margin-right-6" ><FaBuilding /></span>
                                    <p className="ms-1 mb-0 fw-500">{item.companyName}</p>
                                  </div>
                                  <div className="desc text-start d-flex aligm-items-start mt-2">
                                    <img src='/calendericon.png' className='margin-right-6' width={"20px"} height={"20px"} alt="calender icon" />
                                    <p className="ms-1 mb-0 fw-500">{item.announceDate}</p>
                                  </div>
                                  <div className="desc text-start d-flex aligm-items-start mt-2">
                                    <span className="margin-right-6" ><FaPhoneAlt /></span>
                                    <p className="ms-1 mb-0 fw-500">{item.phoneNumber}</p>
                                  </div>

                                </div>
                                <div className="border border-bottom my-2 border-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })
            }

          </div>
        </div>
        {
          props.list.length > 0 &&
          <FsLightbox
            toggler={lightbox.toggler}
            slide={lightbox.slide}
            type={'image'}
            sources={props.list.map((x: any, i: any) => (
              config.apiBase + x.banner
            ))}
          />
        }

        {/* <FsLightbox
          toggler={lightbox.toggler}
          slide={lightbox.slide}
          sources={props.list.map((x: any, i: any) => config.apiBase + x.banner)}
        /> */}

      </div>
    </>
  )
}


export const BonjanBenefits = () => {

  const { t } = useTranslation('seminar');

  return (
    <>
      <div className="card rounded-0">
        <div className="card-body">
          <div className="title text-center" key={"benefit_1"}>
            <h3 className="fw-800 text-skyblue text-wrap my-2"> {t('bonjanJobBenefit')} </h3>
            <div className="benefit-bar">
              <span className="bar text-uppercase fw-600">
                <img src="/images/seminar/ad-free.png" className="w-35px mx-auto d-block" alt="free ads image" />
              </span>
            </div>
          </div>
          <div className="bar-tab text-center" key={"benefit_2"}>
            <h5 className="text-skyblue fw-700"> {t('AdScamFree')} </h5>
            <p className="card-dark fw-600"> {t('topAllIndustries')} <br /> {t('aroundHaiti')}.</p>
            <div className="benefit-bar">
              <span className="bar text-uppercase fw-600">
                <img src="/images/seminar/job-search.png" className="w-35px mx-auto d-block" alt="job search image" />
              </span>
            </div>
          </div>
          <div className="bar-tab text-center" key={"benefit_3"}>
            <h5 className="text-skyblue fw-700"> {t('jobSearchResources')} </h5>
            <p className="card-dark fw-600"> {t('skillsSearchExpertContent')}. </p>
            <div className="benefit-bar">
              <span className="bar text-uppercase fw-600">
                <img src="/images/seminar/special-saving.png" className="w-35px mx-auto d-block" alt="special saving image" />
              </span>
            </div>
          </div>
          <div className="bar-tab text-center" key={"benefit_4"}>
            <h5 className="text-skyblue fw-700"> {t('specialSavings')} </h5>
            <p className="card-dark fw-600"> {t('discountsJobPosting')},<br /> {t('callForTenders')},<br /> {t('andSeminars')} </p>
            <div className="benefit-bar">
              <span className="bar text-uppercase fw-600">
                <img src="/images/seminar/great-service.png" className="w-35px mx-auto d-block" alt="great service image" />
              </span>
            </div>
          </div>
          <div className="bar-tab text-center" key={"benefit_5"}>
            <h5 className="text-skyblue fw-700"> {t('greatService')} </h5>
            <p className="card-dark fw-600"> {t('friendlyClientServices')} .</p>
            <div className="benefit-bar">
              <span className="bar text-uppercase fw-600">
                <img src="/images/seminar/satisfaction-guarantee.png" className="w-35px mx-auto d-block" alt="safisfaction guarantee image" />
              </span>
            </div>
          </div>
          <div className="bar-tab text-center" key={"benefit_6"}>
            <h5 className="text-skyblue fw-700"> {t('satisfactionGuaranteed')}</h5>
            <p className="card-dark fw-600">{t('andMore')}!</p>
          </div>
        </div>
      </div>
    </>
  )
}

const seminarData = [
  {
    banner: "/images/seminars/seminar-img-4.jpg",
    title: "Freepick",
    subTitle: "Business seminar flyer template",
  },
  {
    banner: "/images/seminars/seminar-img-5.jpg",
    title: "PSDFreebies.com",
    subTitle: "Business seminars Workshopes Flyer PSD...",
  },
  {
    banner: "/images/seminars/seminar-img-6.jpg",
    title: "GraphicRiver",
    subTitle: "Psd flyer templates bright bold",
  },
  {
    banner: "/images/seminars/seminar-img-5.jpg",
    title: "PosterMyWell",
    subTitle: "Modele Seminar Event Fly..",
  },
  {
    banner: "/images/seminars/seminar-img-6.jpg",
    title: "UI Creative",
    subTitle: "Flyer Business Seminar",
  },
  {
    banner: "/images/seminars/seminar-img-4.jpg",
    title: "Template.net",
    subTitle: "Seminar Flyer Templates",
  },
]