import React, { useState } from "react";
import { FaGripHorizontal } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";



export default function Index() {

  const templateResume = [
    {
      id: 1,
      img: "/images/resume-img/template-images/toronto-template.png",
    },
    {
      id: 2,
      img: "/images/resume-img/template-images/europass-template.png",
    },
    {
      id: 3,
      img: "/images/resume-img/template-images/montvideo-template.png",
    },
    {
      id: 4,
      img: "/images/resume-img/template-images/newyork-template.png",
    },
    {
      id: 5,
      img: "/images/resume-img/template-images/Omsk-template.png",
    },
    {
      id: 6,
      img: "/images/resume-img/template-images/johannesburg-template.png",
    },
    {
      id: 7,
      img: "/images/resume-img/template-images/illinois-template.png",
    },
    {
      id: 8,
      img: "/images/resume-img/template-images/Auckland-template.png",
    },
    {
      id: 9,
      img: "/images/resume-img/template-images/Haiphong-template.png",
    },
    {
      id: 10,
      img: "/images/resume-img/template-images/Amsterdam-template.png",
    },
  ];


  return (
    <div className="container">
      <div className="row py-50" style={{ paddingBottom: '160px' }}>
        <div className="col-md-12">
          <div className="row mb-3">
            <div className="col-md-4 mx-auto">
              <div className="text-center">
                <h2 className="title-resume">Congratulations !<br /> You are on your way to your dream job</h2>
                <div className="d-flex justify-content-center">
                  <Button variant="outline-dark rounded-pill me-2 fw-500">All</Button>
                  <Button variant="outline-dark rounded-pill me-2 fw-500">With Photo</Button>
                  <Button variant="outline-dark rounded-pill me-2 fw-500">No Photo</Button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Swiper
              slidesPerView={4}
              spaceBetween={0}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              navigation={{
                enabled: true
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper px-3"
            >
              {templateResume.map((item, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <div className="template-container" key={index}>
                        <div className="img-cont">
                          <img src={item.img} className="template-img" alt="resume template image" />
                          <div className="overlay-temp"></div>
                          <div className="button-temp w-100">
                            <Button className="rounded fw-500 btn-template rounded-pill text-nowrap text-uppercase">
                              <FaGripHorizontal className="me-1" size={16} />Use this template
                            </Button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                )
              })}
            </Swiper>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}
