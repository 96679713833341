import React, { useState } from 'react';
import { FaLock, FaEyeSlash, FaEye } from "react-icons/fa";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../Environments";
import { ApiResponse } from "../../Shared";
import { toast } from "react-toastify";
import { LoadingButton } from "../../Components";
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner'


const SetPassword = () => {
    const auth = useSelector((state: AppStore) => state.auth);
    const [submitting, setSubmitting] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const token = 'Bearer ' + auth.raw?.access_token;
    const { i18n } = useTranslation();
    const { t: ct } = useTranslation('common');
    const { t: at } = useTranslation('auth');
    const { t : ts} = useTranslation('alert');

    const passwordSchema = Yup.object().shape({
        newPassword: Yup.string().required(ts('account.required')|| 'Required').min(6, ts('account.tooShort') || "Password too short").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
            ts('account.passwordPattern') || "1 Upper, Lowercase, 1 Number and 1 Special Character"
        ),
        confirmPassword: Yup.string().required("Required").oneOf([Yup.ref('newPassword'), null],  ts('account.passwordMatch') ||'Passwords must match'),
        lang: Yup.string().required(ts('account.required') || 'Required'),
    });


    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
            lang:''
        },
        validationSchema: passwordSchema,
        onSubmit:  (values, { resetForm }) => {
            setSubmitting(true);
            axios.post<ApiResponse>(config.apiBase + '/api/Auth/SetPassword', values
            , {
                headers: {
                    'Authorization': token
                }
            }
        ).then(res => {
            toast(res.data.message, { type: res.data.success ? 'success' : 'error' });
            setSubmitting(false);
            //reset();
        }).catch(err => {
            console.error(err);
            setSubmitting(false);
        })
        },
    });

    React.useEffect(() => {
        formik.setFieldValue('lang', i18n.language)
      }, [i18n.language]);
    return (
        <>
            <div className="bg-img-height" style={{ maxHeight: 'calc(100vh - 14px)' }}>
                <div className="container">
                    <div className='form-m-center'>
                        <div className="form-section rounded-3">
                            <div className="padding-step1">
                                <h3 className="text-center step1-heading">{at('changePassword.setYourPassword')}?</h3>
                             
                                    <div className="mb-12">

                                        <div className="mb-3">
                                            <div className="input-group">
                                                <span className="input-group-text group-round" id="Email"><FaLock /></span>
                                                <input type={showNewPass !== false ? "text" : "password"}
                                                    className="form-control key-weight"
                                                    placeholder={at('changePassword.newPassword').toString()}
                                                    name="newPassword"
                                                    onChange={formik.handleChange} value={formik.values.newPassword}
                                                    // {...register('newPassword', { required: true })}
                                                     />
                                                <span className="input-group-text group-round" onClick={() => setShowNewPass(!showNewPass)}>{showNewPass ? <FaEyeSlash /> : <FaEye />}</span>
                                            </div>
                                            {formik.errors.newPassword && formik.touched.newPassword ? <small className='text-danger'>{formik.errors.newPassword}</small> : ""}

                                        </div>
                                        <div className="mb-4">
                                            <div className="input-group">
                                                <span className="input-group-text group-round" id="Email"><FaLock /></span>
                                                <input type={showConfirmPass !== false ? "text" : "password"}
                                                    className="form-control key-weight"
                                                    placeholder={at('changePassword.confirmPassword').toString()}
                                                    name="confirmPassword" onChange={formik.handleChange} value={formik.values.confirmPassword}
                                                
                                                    // {...register('confirmPassword', { required: true })} 
                                                    />
                                                <span className="input-group-text group-round" onClick={() => setShowConfirmPass(!showConfirmPass)}>{showConfirmPass ? <FaEyeSlash /> : <FaEye />}</span>
                                            </div>
                                            {formik.errors.confirmPassword && formik.touched.confirmPassword ? <small className='text-danger'>{formik.errors.confirmPassword}</small> : ""}

                                          
                                        </div>

                                        <div className="text-center mb-12">
                                        <button disabled={submitting} type="submit" onClick={formik.handleSubmit as any} className="border-0 p-btn btn-contine text-white fw-600">
                                            {
                                                submitting ? <RotatingLines
                                                    width='20' strokeColor='black'
                                                /> : <div >
                                                  {ct('save').toString()}
                                                </div>
                                            }
                                        </button>
                                          
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default SetPassword;