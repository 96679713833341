import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { FaEdit, FaMoneyCheck, FaTrash, FaEye, FaToggleOn, FaToggleOff, FaPlayCircle, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const AnnounceTable = (props: {
  pageSkillLimit: number, update: boolean,
  setPageSkillLimit: (arg: number) => void, searchString: string, setUpdate: (arg: boolean) => void
}) => {
  const dispatch = useDispatch();
  const [seminarlist, setSeminarList] = useState<any>([]);
  //const [update, setUpdate] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('admin');
  const { t: ts } = useTranslation('alert');
  const [currentPage, setCurrentPage] = useState(1);
  // React.useEffect(() => {
  //   fetchSeminars();
  // }, []);

  React.useEffect(() => {
    fetchSeminars();
  }, [props.update]);

  function fetchSeminars() {
    dispatch(setGlobalLoading(true));
    GetAxios()
      .get(config.apiBase + "/api/Admin/GetAnnounces?search=" + props.searchString)
      .then((res) => {
        setSeminarList(res.data.data || []);

        dispatch(setGlobalLoading(false));
      });
  }

  function handleOnViewClick(seminarId: number) {

    navigate("/admin/announcepreview?sid=" + seminarId);
  }

  function handleOnEditClick(seminarId: number) {

    navigate('/admin/announce/' + seminarId + '/edit');
  }


  function handleApproveClick(seminarId: number) {
    confirmAlert({
      title: t('common.confirmtoActive').toString(),
      message: t('common.sureActive').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/UpdateAnnounceStatus?seminarId=" + seminarId)
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });

                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleDisapproveClick(seminarId: number) {
    confirmAlert({
      title: t('common.confirmtoInactive').toString(),
      message: t('common.sureInactive').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/UpdateAnnounceStatus?seminarId=" + seminarId)
              .then((res) => {
                props.setUpdate(!props.update);
                toast(ts('account.statusUpdate'), { type: "success" });
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleOnDeleteClick(seminarId: number) {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('seminar.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Announce/DeleteAnnounce?id=' + seminarId,
            ).then(s => {
              toast(ts('seminar.delete'), { type: 'success' });
              dispatch(setGlobalLoading(false));
              props.setUpdate(!props.update);
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    });
  }

  return (
    <>
      <div className="table-responsive">
        <Table bordered className="bg-white">
          <thead>
            <tr>
              <th className="fw-700 fw-16 w-12">{t('common.adNo')}</th>
              <th className="fw-700 fw-16 w-15">{t('common.advertisngNo')}</th>
              <th className="fw-700 fw-16">{t('common.name')}</th>
              <th className="fw-700 fw-16">{t('common.companyName')}</th>
              <th className="fw-700 fw-16 w-8">{t('common.status')}</th>
              <th className="fw-700 fw-16 ">{t('common.paymentStatus')}</th>
              <th className="text-center w-20">{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {/* {seminarlist.map((item, index) => { */}
            {(seminarlist || []).slice((currentPage - 1) * props.pageSkillLimit, currentPage * props.pageSkillLimit).map((item: any, index: number) => {
              return (

                <tr key={index}>
                  <td className="text-center">{item.announceNo}</td>
                  <td className="text-center">{item.advertisingNo}</td>
                  <td>{item.name}</td>
                  <td>{item.companyName}</td>
                  <td className="text-center">
                    {item.status ? t('common.active').toString() : t('common.inactive').toString()}
                  </td>
                  <td>{item.paymentStatus == "Paid" ? t('payment.paid').toString() : item.paymentStatus == "UnPaid" ? t('payment.unpaid').toString() : t('payment.free').toString()}</td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary px-2 py-1"
                      onClick={() => handleOnViewClick(item.id)}
                    >
                      <FaEye className="text-white" title={t('common.view') || ''} size={16} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-info mx-1 px-2 py-1"
                      onClick={() => handleOnEditClick(item.id)}
                    >
                      <FaEdit className="text-white" title={t('common.edit') || ''} size={16} />
                    </button>
                    {item.status ? (
                      <button
                        type="button"
                        className="btn btn-success px-2 py-1"

                        onClick={() => handleDisapproveClick(item.id)}
                      >
                        <FaToggleOn size={16} title={t('common.setInactive') || ''} />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger px-2 py-1"
                        onClick={() => handleApproveClick(item.id)}
                      >
                        <FaToggleOff size={16} title={t('common.setActive') || ''} />
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-dark mx-1 px-2 py-1"
                      onClick={() => handleOnDeleteClick(item.id)}
                    >
                      <FaTrash className="text-white" title={t('common.delete') || ''} size={16} />
                    </button>


                    {/* <AddPaymentModal typeId={item.id} change={fetchSeminars} paymentType="S" paymentStatus={item.paymentStatus} /> */}

                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="">
          <div className="d-flex align-items-center">
            <span>  {t('common.showing')} {(currentPage > seminarlist.length) ? seminarlist.length : currentPage} {t('common.to')}  {(props.pageSkillLimit > seminarlist.length) ? seminarlist.length : props.pageSkillLimit} {t('common.of')}  {seminarlist.length} {t('common.entries')}</span>
          </div>
        </div>
        <div className="">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage !== 1 ? false : true}
                >
                  {" "}
                  <FaChevronLeft />
                </button>
              </li>
              <li className="page-item me-2 pb-result">
                <a className="pointer-event" onClick={() => setCurrentPage(currentPage)} >
                  {currentPage}{" "}
                </a>
              </li>
              {

                <li className={`page-item me-2 pb-result
                ${currentPage !== seminarlist.length / props.pageSkillLimit ? "" : ""}`}
                >
                  <a className="pointer-event"

                    onClick={() =>
                      setCurrentPage(Math.ceil(seminarlist.length / props.pageSkillLimit))} >
                    {Math.ceil(seminarlist.length / props.pageSkillLimit)}
                  </a>
                </li>
              }
              <li className="page-item me-2">
                <button
                  type="button"
                  className="text-white pb-result-end"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage !== Math.ceil(seminarlist.length / props.pageSkillLimit)
                      ? false
                      : true
                  }
                >
                  {" "}
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default AnnounceTable;


