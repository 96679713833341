import React, { useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import {
  FaStar, FaPrint, FaPlus, FaMinus, FaFacebookF, FaLinkedin,
  FaGooglePlusG, FaTwitter, FaWhatsapp
} from "react-icons/fa";
import Collapse from 'react-bootstrap/Collapse';
import { config } from "../../../Environments";
import { useTranslation } from 'react-i18next';


const MainContent = (props: any) => {

  const { t: ct } = useTranslation('common');

  const data = props?.data;
  const [skill, setSkill] = useState([]);
  const MySwal = withReactContent(Swal);
  var isMobile = window.orientation > 1;
  let message = `${window.location.href}/job/jobpreview?id=${data?.id}`;

  const handleShare = (type: string) => {
    const url = `${config.apiBase}/api/Jobs/ShareJob?id=${data.id}`;
    if (type === "FaceBook") {

      window.open(
        `https://www.facebook.com/sharer/sharer.php?href=${encodeURIComponent(url)}&u=${encodeURIComponent(url)}`, '_blank'
      );

    }
    else if (type === "LinkedIn") {

      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank'

      );


    }
    else if (type === "Twitter") {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(data.title)}`, '_blank');

    }
    else if (type === "Whatsapp") {
      MySwal.fire({
        title: 'Enter whatsapp number',
        input: 'text',
        inputPlaceholder: "+509-455-563-6507",
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
          return null;
        },
        confirmButtonText: 'Submit',
        customClass: {

          title: 'my-swal-title',
          input: 'my-swal-input',
          container: 'my-swal-container',
          popup: 'my-swal-popup',
          confirmButton: 'my-swal-confirm-button',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the selected option
          const whatsappnumber = result.value;
          // Do something with the selected option
          let number = whatsappnumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");
          if (isMobile) {
            let url = `whatsapp://send?phone=${number}`;
            url += `&text=${encodeURI(message)}&app_absent=0`;
            window.open(url);
          }
          else {
            let url = `https://web.whatsapp.com/send?phone=${number}`;
            url += `&text=${encodeURI(message)}&app_absent=0`;
            window.open(url);

          }
        }
      });

    }


  }

  React.useEffect(() => {
    setSkill(data?.jobPostSkills);
  }, [data?.jobPostSkills])

  const toggleopen = (index: number) => {
    let newarray = [...skill] as any;
    newarray[index].isShow = !newarray[index].isShow;
    setSkill(newarray);
  }

  return (
    <>
      <div className="client-description">
        <div className="card mb-4 border-0 rounded-0 p-0">
          <div className="p-2">
            <div className="card-body">
              <div className="candidate-sec">
                <div className="container p-1">
                  <div className="row">
                    <div className="col-lg-8 col-md-12 col-12 mt-1 margin-language">
                      <div className="">

                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-12">

                      <div className="language-print text-end">
                        <ul className="list-inline mb-2">

                          <li className={`list-inline-item text-uppercase ${data?.language === 'sp' ? "active" : ""}`}>
                            <a className="pointer-event">Sp</a>
                          </li>
                          <li className={`list-inline-item text-uppercase ${data?.language === 'en' ? "active" : ""}`}>
                            <a className="pointer-event">En</a>
                          </li>

                        </ul>
                      </div>


                    </div>
                  </div>
                </div>
                <div className="candidate-title mb-3">
                  <p className="h3 mb-3"> {ct('jobDescription')} </p>
                </div>
                <div className="detail-content-preview">
                  <p className="fs-14 lh-lg" dangerouslySetInnerHTML={{ __html: data?.shortDescription }}>

                  </p>
                </div>
              </div>
              <div className="candidate-sec">
                <div className="candidate-title mb-3">
                  <p className="h3 mb-3"> {ct('aboutTheJob')} </p>
                </div>
                <div className="detail-content-preview">
                  <p className="fs-14 lh-lg" dangerouslySetInnerHTML={{ __html: data?.description }}>

                  </p>
                </div>
              </div>
              <div className="candidate-sec">
                <h5 className="text-color-33 fw-600 py-3 text-uppercase">
                  {ct('requiredSkills')}
                </h5>
                {skill?.map((Item: any, index: number) => {
                  return (
                    <div className="row mb-3" key={'skl__e' + index}>
                      <div className="col-md-3">
                        <div>
                          <div className="d-flex">
                            <a
                              className="minus rounded-0 text-dark border icon-collaps1 pointer-event"
                              onClick={() => toggleopen(index)}
                              aria-controls={"logodesign" + index}
                              aria-expanded={Item.isShow}
                            >
                              {Item.isShow ? <FaMinus /> : <FaPlus />}
                            </a>
                            <p className="mb-0 ms-2 fsize-13 fw-normal mt-2">
                              {Item.skill}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 p-right-mobile">
                        {/* <div>
                          <div className="progress mb-3 rounded-0 mt-2">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: Item.percentage + '%' }}
                            ></div>
                          </div>
                          <Collapse in={Item.isShow}>
                            <div id={"logodesign" + index}>
                              <p className="fs-14 fw-normal lh-lg">
                                {Item.description}
                              </p>
                            </div>
                          </Collapse>
                        </div> */}

                        <div>
                          <div className='d-flex'>
                            <div className='col-md-11 col-12'>
                              <div>
                                <div className="progress mb-2 mt-2 rounded-0">
                                  <div
                                    className="progress-bar"
                                    style={{ width: Item.percentage + '%' }}>
                                  </div>
                                </div>
                                <Collapse in={Item.isShow}>
                                  <div id={"logodesign" + index}>
                                    <p className="fs-14 fw-normal lh-lg" dangerouslySetInnerHTML={{ __html: Item?.description || '' }}>

                                    </p>
                                  </div>
                                </Collapse>
                              </div>
                            </div>

                            <div className='col-md-1'>
                              <span className="text-nowrap ms-2">{Item.percentage}%</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="mt-5">
                <h5 className="text-color-33 fw-600 text-uppercase">
                  {ct('additionalSkill')}
                </h5>
                <div className="d-flex pt-3 additional-requirement-btn">
                  <ul className="list-unstyled list-inline-requirement">
                    {
                      !!data?.additionalSkills ? ((
                        data?.additionalSkills || '').split(',') || []).map((x: any, i: number) => (

                          <li key={'adition__sskl' + i}>
                            <a
                              className="text-decoration-none me-1 rounded-0 pointer-event">
                              {x}
                            </a>
                          </li>
                        )) : ""

                    }

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="candidate-sec">
          <div className="candidate-title mb-0 mt-5">
            <ul className="list-inline d-flex p-0 mb-0">
              <li>
                <p className="h5 fw-bold me-2">{ct('share')}:</p>
              </li>
              <li>  <a onClick={() => { handleShare("FaceBook") }} className="tags text-decoration-none mb-0 me-2 facebook-color pointer-event"> <FaFacebookF size={18} /></a></li>
              <li>  <a onClick={() => { handleShare("LinkedIn") }} className="tags text-decoration-none mb-0 me-2 linkedIn-color pointer-event"><FaLinkedin size={18} /></a></li>
              <li>  <a onClick={() => { handleShare("Whatsapp") }} className="tags text-decoration-none mb-0 me-2 whatsapp-bgcolor pointer-event"><FaWhatsapp size={18} /></a></li>
              <li>  <a onClick={() => { handleShare("Twitter") }} className="tags text-decoration-none mb-0 me-2 twitter-color pointer-event"><FaTwitter size={18} /></a></li>


            </ul>
          </div>
        </div>


      </div>
    </>
  );
};
export default MainContent;
