import React, { useState } from "react";
import Pagination from "../../../Components/Pagination";
import { SortList } from "../../../Components/Pagination/usePagination";
import { JobItem } from "./JobItem";
import { AdSliderSection } from "./AdSliderSection";
import { useTranslation } from 'react-i18next';


const PageSize = 5;

const MainContent = (props: { jobsList?: any[] }) => {

 
  const { t: ct } = useTranslation('common');


  const [sort, setSort] = React.useState('title|asc');
  const [currentPage, setCurrentPage] = React.useState(1);

  const currentTableData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return (props.jobsList || []).slice(firstPageIndex, lastPageIndex);
  }, [currentPage, props.jobsList]);



  return (
    <>
      <div className="">
       {/* <div className="container px-0 ">
          <AdSliderSection />
        </div> */}
        <div className="container px-0">
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between flexcolumn-mob-start-j02">
              <p className="text-start m-0 text-uppercase h5">{ct('availableJob')}</p>
              <div className="mt-10px-j02">
                <select
                  onChange={e => setSort(e.target.value)}
                  value={sort}
                  id="inputState"
                  className="form-select form-control key-weight mt-2 w-selected">
                  <option value={'title|asc'}>{ct('nameA')}- {">"} Z</option>
                  <option value={'title|desc'}>{ct('nameZ')}- {">"} {ct('a')}</option>
                  <option value={'id|desc'}>{ct('newToOld')}</option>
                  <option value={'id|asc'}>{ct('oldToNew')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
       
        <div className="client-description app-content">
          {
            currentTableData.length == 0 ? <p className="text-danger">{ct('noJobFound')}</p> :
              (SortList(currentTableData, sort.split('|')[0], sort.split('|')[1] as any)).map((item: any, index: number) => (
                <JobItem key={'job_post_' + index} job={item} i={index} />
              ))
          }
        </div>
        <div className="row mb-2">
          <div className="d-flex flex-column">
            <div className="d-flex flexcolumnsm align-items-center justify-content-between">
              <p className="fs-14">{ct('displaying')} {currentTableData.length} {ct('outOf')} {props.jobsList?.length} {ct('items')}</p>
              <nav aria-label="Page navigation example">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={props.jobsList?.length}
                  pageSize={PageSize}
                  onPageChange={(page: any) => setCurrentPage(page)} />
              </nav>
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
};
export default MainContent;
