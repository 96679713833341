import React from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Outlet, useNavigate } from "react-router-dom";
import ResumesTab from '../Resumes/ResumesList';
import SkillMatcherTab from '../Skills-Matchers/SkillMatcher';
import JobMatcherTab from '../Job-Matchers/JobMatcher';
import { useSelector } from "react-redux";
import { UserRole } from "../../Shared";
import AccountSetttingPage from "../AccountSetting/Index";


const JobSeekerTab =() => {
    const auth = useSelector((state: AppStore) => state.auth);
    const userRole = UserRole(auth?.raw?.access_token);

    return (
        <>
          <div className='container'>
            <div className='job-tab py-3'>
                {userRole == 'Employee' && 
                    <Tabs
                    id="jobseeker-maintabs"
                    className="mb-3 tab-account"
                    defaultActiveKey="accountsetting"
                    >
                        <Tab eventKey="accountsetting" title="Account Settings" className="arrow">
                            <AccountSetttingPage />
                        </Tab>
                        <Tab eventKey="resumes" title="My Resume(s)" className="">
                            <ResumesTab  /> 
                        </Tab>
                        <Tab eventKey="job-matcher" title="My Job Match">
                            <SkillMatcherTab />
                        </Tab>
                        <Tab eventKey="skills-matcher" title="My Skills Matcher">
                            <JobMatcherTab />
                        </Tab>
                    </Tabs>
                }
            </div>
          </div>
        </>
    )
}
export default JobSeekerTab;