import React , {useState} from "react";
import SideContent from "./SideContent";
import MainContent from "./MainContent";
import { config } from "../../../Environments";
import { GetAxios } from "../../../Shared";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../Store";
import { AdSliderSection } from "./AdSliderSection";
import { CirclesWithBar } from "react-loader-spinner";
const Index = () => {
  const [tendersList, setTendersList] = useState<any[]>([]);
  const [deptName,setDeptName]= useState<string>('');
  const [className,setClassName]= useState<string>('');
  const [sectorName,setSectorName]= useState<string>('');
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const dispatch = useDispatch();

  return (
    <>
      <div className="container">
        <div className="row py-50">
          <div className="col-md-4 col-12 ps-lg-0">
          <SideContent  sectorName={sectorName} deptName={deptName} className={className}      
           onSearch={(query, idType,searchId,sector , dept, classId,  lang,filter, limit, DaysPostedWithIn) => {
            
              dispatch(setGlobalLoading(true));
              setIsLoading(true);
              GetAxios().post(config.apiBase + '/api/Tender/SearchTenders', {
                Query: query,
                IdType:idType,
                SearchId: searchId,
                Sector: sector,
                DepartmentId: dept,
                ClassificationId: classId,     
                lang:lang,
                Filter:filter,  
                Limit:limit,            
                DaysPostedWithIn
              }).then(res => {
                setTendersList(res.data.data.tenderlist|| []);              
                setDeptName(res.data.data.deptJobCount || '')
                setClassName(res.data.data.classJobCount || '')
                setSectorName(res.data.data.sectorJobCount || '')
              
              }).finally(() => {
                console.log("return tenders");
                 dispatch(setGlobalLoading(false));
                 setIsLoading(false); // Set loading to false when the request is complete
              });
          
            
          }}  />
          </div>
          <div className="col-md-8 col-12 pe-lg-0">
          {isLoading ? (
              <div className="LoaderWrapper">
                <CirclesWithBar color="white" />
              </div>
            ) :
            <MainContent tendersList={tendersList} />
          }
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-12 px-0">
          <AdSliderSection />
          </div>
          {/* <div className="col-md-8 col-12 px-0">
           
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Index;
