import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { FaRegFileAlt, FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DatePickSelect } from "../../../../../../Components";
import { ParseHTMLTips } from "../../../../../../Components/Common/ParseTipsHTML";
import { config } from "../../../../../../Environments";
import { GetAxios } from "../../../../../../Shared";
import { setGlobalLoading } from "../../../../../../Store";
import { setResumeActive, setResumeAddRecord } from "../../../../../../Store/Slices/ResumeSlice";
import TipsPopup from "../../../../Components/TipsPopup";
import { CustomDateSelectControl } from "../../Common";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';

const defaultDate = '0001-01-01T00:00:00';
export const WorkExperienceAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation('resume');

    const { id, resumeId } = useParams();

    const experiences = useSelector((state: AppStore) => state.resume.experiences);
    const information = useSelector((state: AppStore) => state.resume.activeExperience);
    const [isDefaultContentLoaded, setDefaultContextLoaded] = useState(false);

    const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);

    useEffect(() => {
        if (information?.startDate === defaultDate) {
            setValue({ startDate: undefined })
        }
        const startDate = !information?.startDate || information?.startDate == defaultDate ? undefined : information?.startDate;
        if ((information?.currentlyWorking && !information.title))
            setIsSubmitEnabled(false);
        else if (information?.currentlyWorking && !startDate)
            setIsSubmitEnabled(false);
        else if (startDate && (!information?.currentlyWorking && !information?.endDate))
            setIsSubmitEnabled(false);
        else if (startDate && information?.endDate) {
            if (startDate >= information.endDate)
                setIsSubmitEnabled(false);
            else
                setIsSubmitEnabled(true);
        } else {
            setIsSubmitEnabled(true);
        }
    }, [information])

    const [editorState, setEditState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const defaultValue = experiences.find(s => s.id == Number(id));
        const htmlContent = defaultValue?.description;
        if (!isDefaultContentLoaded) {
            const html = convertFromHTML(htmlContent || '');
            setEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
            if (defaultValue || htmlContent) {
                setDefaultContextLoaded(true);
            }
        }
        dispatch(setResumeActive({ experiences: defaultValue }));
    }, [experiences, id, resumeId]);

    const setValue = (value: { [n in keyof ResumeExperiences]: ResumeExperiences[n] }) => {
        const info = { ...(information || {}), ...value };
        dispatch(setResumeActive({ experiences: info }));
        dispatch(setResumeAddRecord({ experiences: info }));
    };

    const Savebtn = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).post(config.apiBase + '/api/Resume/SaveExperience', {
            ...information,
            id: id,
            resumeId: resumeId
        }).then(s => {
            navigate('..');
            dispatch(setGlobalLoading(false));
        })
    }

    const Backbtn = () => {
        confirmAlert({
            title: t('newResumeCard.backAlertMsg.title').toString(),
            message: t('newResumeCard.backAlertMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('save').toString(),
                    onClick: Savebtn
                },
                {
                    label: t('exit').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        navigate('..');
                    }
                }
            ]
        })
    }


    return (
        <>
            <div className='position-relative'>
                <div className="mb-4">
                    <TipsPopup title="contactStep.tips" description="experTipsMdl.description"
                        tipsList={ParseHTMLTips(experienceTipsBody, t)} />
                </div>
                <div className="mb-4">
                    <div className="dZcjzV sections-header">
                        <div className="feVKyO"> {t('experStep.experAdd.title')} </div>
                    </div>
                    <div className="gJaquO"> {t('experStep.experAdd.description')}.</div>
                </div>
                <div className="row">
                    <div className='col-md-6'>
                        <div className='form-float'>
                            <div className="form-floating mb-3 rounded fw-500">
                                <input type="text" className={"form-control rounded bg-field " + (information?.currentlyWorking && !information?.title ? 'border border-danger' : '')} required={information?.currentlyWorking} placeholder={t('experStep.experAdd.salesAssociate').toString()} value={information?.title || ''} onChange={e => setValue({ title: e.target.value })} />
                                <label className='label-float'> {t('experStep.experAdd.position')} </label>
                                {
                                    information?.currentlyWorking && !information?.title ?
                                        <span className='helper-text text-danger ms-3'>{t('experStep.experAdd.titleAlert')}</span>
                                        : <span className='helper-text ms-3'>{t('eg')}. {t('experStep.experAdd.salesAssociate')}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-float'>
                            <div className="form-floating mb-3 rounded fw-500">
                                <input type="text" className="form-control rounded bg-field" placeholder={t('experStep.experAdd.louisVuitton').toString()} value={information?.company || ''} onChange={e => setValue({ company: e.target.value })} />
                                <label className='label-float'>  {t('experStep.experAdd.company')} </label>
                                <span className='helper-text ms-3'>{t('eg')}. {t('experStep.experAdd.louisVuitton')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='form-float'>
                <div className="form-floating mb-3 rounded fw-500">
                    <input type="text" className="form-control rounded bg-field" placeholder={t('experStep.experAdd.sanFranciscoCa').toString()} value={information?.location || ''} onChange={e => setValue({ location: e.target.value })} />
                    <label className='label-float'> {t('experStep.experAdd.cityStateCountry')} </label>
                    <span className='helper-text ms-3'>{t('eg')}. {t('experStep.experAdd.sanFranciscoCa')}</span>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 date-zindex'>
                    <DatePickSelect
                        yearFrom={1930}
                        yearTo={new Date().getFullYear()}
                        minDate={new Date(1930, 1, 1)}
                        maxDate={new Date()}
                        placeHolder={t('startDate').toString()}
                        selected={!information?.startDate || information?.startDate == defaultDate ? '' : information?.startDate}
                        onChange={(date) => { setValue({ startDate: date?.toISOString() }) }}
                        customInput={<CustomDateSelectControl label={t('startDate').toString()} className={information?.currentlyWorking && (!information?.startDate || information?.startDate === defaultDate) ? 'border border-danger' : ''} />}
                    />
                    {
                        information?.currentlyWorking && (!information?.startDate || information?.startDate === defaultDate) &&
                        <label className='text-danger'>  {t('experStep.experAdd.endDateRequire')} </label>
                    }
                </div>
                <div className='col-md-6 date-zindex'>
                    <DatePickSelect
                        disabled={information?.currentlyWorking}
                        yearFrom={1930}
                        yearTo={new Date().getFullYear()}
                        minDate={new Date(1930, 1, 1)}
                        maxDate={new Date()}
                        placeHolder={t('endDate').toString()}
                        selected={information?.endDate}
                        onChange={(date) => setValue({ endDate: date?.toISOString() })}
                        customInput={<CustomDateSelectControl label={information?.currentlyWorking ? '-' + t('present').toString() : t('endDate').toString()} placeHolder={'Date'} className={(!information?.currentlyWorking && information?.startDate) && !information.endDate ? 'border border-danger' : ''} />}
                    />
                    {
                        (!information?.currentlyWorking && information?.startDate) && !information.endDate &&
                        <label className='text-danger'> {t('experStep.experAdd.startDateRequire')} </label>
                    }
                    <label>
                        <input type={'checkbox'} value='' className="me-2" checked={information?.currentlyWorking === true} onChange={e => {
                            if (e.target.checked) {
                                setValue({ endDate: undefined, currentlyWorking: e.target.checked })
                            } else {
                                setValue({ currentlyWorking: e.target.checked });
                            }
                        }} />
                        {t('experStep.experAdd.jobRightNow')}
                    </label>
                </div>
                <div className='col-md-12 mb-2'>
                    <div className="gQiRPX">
                        <div className="mb-2 sections-header">
                            <div className="dJqmzX">
                                <div className="hfzZao">
                                    <div className="czFa-Df fw-16 fw-600"> {t('experStep.experAdd.functionsAchievements')} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Editor
                        editorState={editorState}
                        editorClassName="editor-height"
                        stripPastedStyles={true}
                        toolbar={{
                            options: ['inline', 'textAlign', 'list'],
                            inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough',] },
                            textAlign: { inDropdown: false, options: ['left', 'center', 'right'] },
                            list: { inDropdown: false, options: ['unordered', 'ordered'], },
                        }}
                        onEditorStateChange={e => {
                            setEditState(e);
                            setValue({ description: draftToHtml(convertToRaw(e.getCurrentContent())) });
                        }}
                    />
                    <p className='helper-text ms-3 text-level'>{t('eg')}. {t('experStep.experAdd.functionsAchievementsSubtitle')}.</p>
                </div>
            </div>
            <div className="bottom-btn-sticky">
                <div className='next-previous-btns d-flex align-items-center justify-content-between'>
                    <div className=''>
                        <Button className="fw-500 previous-btn bg-back" onClick={Backbtn}>
                            <FaArrowLeft className="me-2" /> {t('back')}
                        </Button>
                    </div>
                    <div className=''>
                        <Button className="save-btn-resume fw-500"
                            disabled={!isSubmitEnabled}
                            onClick={Savebtn}
                        ><FaRegFileAlt className="me-1" size={20} /> {t('save')} </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
const experienceTipsBody = {
    "tipsMdlTitle": ["experTipsMdl.tipsList1",
        "experTipsMdl.tipsList2",
        "experTipsMdl.tipsList3",
        "experTipsMdl.tipsList4",
    ],
    "experTipsMdl.question.title": ["experTipsMdl.question.questList1",
        "experTipsMdl.question.questList2",
        "experTipsMdl.question.questList3",
        "experTipsMdl.question.questList4",
        "experTipsMdl.question.questList5",
        "experTipsMdl.question.questList6",
    ],
    'experTipsMdl.examples.title': ["experTipsMdl.examples.exampleList1",
        "experTipsMdl.examples.exampleList2",
        "experTipsMdl.examples.exampleList3",
        "experTipsMdl.examples.exampleList4",
        "experTipsMdl.examples.exampleList5",
        "experTipsMdl.examples.exampleList6",
        "experTipsMdl.examples.exampleList7",
        "experTipsMdl.examples.exampleList8",
    ],
}