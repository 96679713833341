import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FaRegEnvelope } from 'react-icons/fa';
import { MdOutlinePhonelinkRing } from 'react-icons/md';

export const SanFrancisco = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume')

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <WrapperDiv additionalSettings={additionalSettings}>
         <div className='template'>
            <div className='preview-template'>
               <div id='template-full'>
                  <div className='template-main'>
                     <div className='sanfrancisco-template-container h-100'>
                        <div className='container-fluid'>
                           <div className='row'>
                              <div className='col-4'>
                                 <div className='template-sanfrancisco-left-col ps-5'>
                                    <div className='background-image-container'>
                                       {sanFranciscoBackgroundSvg}
                                       <div className='foreground-content'>
                                          {(contactInformation.firstName ||
                                             contactInformation.lastName ||
                                             contactInformation.occupation) && (
                                                <section
                                                   className='template-sanfrancisco-name-sec selectable-section text-center'
                                                   onClick={(_) =>
                                                      SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)
                                                   }
                                                >
                                                   <div className='name-sec'>
                                                      <h1 className='mb-0 text-capitalize firstName text-wrap'>
                                                         <span>{contactInformation.firstName}</span> <span>{contactInformation.lastName}</span>
                                                      </h1>
                                                      <p className='template-sanfrancisco-occupation'>{contactInformation.occupation}</p>
                                                   </div>
                                                </section>
                                             )}

                                          {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                             <section
                                                className='template-sanfrancisco-img-sec'
                                                onClick={(_) =>
                                                   SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)
                                                }
                                             >
                                                <div className='selectable-section text-center mt-3'>
                                                   <img
                                                      className={
                                                         imageData.isRounded
                                                            ? 'template-sanfrancisco-img rounded-circle img-fluid'
                                                            : 'template-sanfrancisco-img img-fluid'
                                                      }
                                                      src={image}
                                                      alt='sanfrancisco-profile-img'
                                                   />
                                                </div>
                                             </section>
                                          )}

                                          {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                                             <section
                                                className='template-sanfrancisco-objective-sec mt-4 px-2'
                                                onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                                             >
                                                <div className='text-center selectable-section'>
                                                   <p className='fs-20 mb-16 fw-600'>{t('Objective')}</p>
                                                   <p
                                                      id='sanfrancisco-obj'
                                                      className='sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: objectives }}
                                                   ></p>
                                                </div>
                                             </section>
                                          )}
                                       </div>
                                    </div>

                                    {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                       <section
                                          className='template-sanfrancisco-contact-sec'
                                          onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                       >
                                          <div className='text-center selectable-section'>
                                             <p className='template-sanfrancisco-heading mb-3 text-center'>{t('Contact Information')}</p>
                                             <div className='mb-3'>
                                                <FaRegEnvelope className='template-sanfrancisco-icon' size={22} />
                                             </div>
                                             <a
                                                className='text-decoration-none fs-14 fw-500 d-block'
                                                href={`mailto:${contactInformation.email}`}
                                             >
                                                {contactInformation.email}
                                             </a>
                                             <p className='fs-14 fw-500 text-wrap'>{contactInformation.address}</p>
                                             <p className='fs-14 fw-500'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</p>
                                             <p className='fs-14 fw-500'>{contactInformation.nationality}</p>
                                             <div className='my-3'>
                                                <MdOutlinePhonelinkRing className='template-sanfrancisco-icon' size={22} />
                                             </div>
                                             <a
                                                className='fs-14 fw-500 text-decoration-none d-block'
                                                href={`tel:${contactInformation.phone}`}
                                             >
                                                {contactInformation.phone}
                                             </a>
                                             <a
                                                className='fs-14 fw-500 text-decoration-none d-block'
                                                href={`https://${contactInformation.linkedInLink}`}
                                             >
                                                {contactInformation.linkedInLink}
                                             </a>
                                          </div>
                                       </section>
                                    )}
                                 </div>
                              </div>

                              <div className='col-8'>
                                 <div className='template-sanfrancisco-right-col'>
                                    {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                                       <section
                                          className='selectable-section template-sanfrancisco-experience-sec mt-3'
                                          onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-sanfrancisco-heading mb-2'>{t('Experiences')}</p>

                                          {experiences.map((e, i) => (
                                             <div className='ms-3' key={'exp_' + i}>
                                                <p className='fst-italic fs-12 text-gray'>
                                                   {e.startDate || e.endDate ? (
                                                      <>
                                                         {e.startDate ? (
                                                            <span>
                                                               {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.startDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}
                                                         {e.endDate && !e.currentlyWorking ? (
                                                            <span className='barline'>
                                                               {new Date(e.endDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.endDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}

                                                         {e.currentlyWorking ? <em className='barline'>{t('present')} </em> : <></>}
                                                      </>
                                                   ) : (
                                                      <></>
                                                   )}
                                                   {e.location && <span className='ms-1'>{e.location}</span>}
                                                </p>
                                                <p className='fw-700 template-sanfrancisco-experience text-black fs-14'>{e.title}</p>
                                                {e.company && <p className='fst-italic fs-12 text-gray'>{e.company}</p>}

                                                {e.description && (
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          ))}
                                       </section>
                                    ) : (
                                       <></>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                                       <section
                                          className='selectable-section template-sanfrancisco-education-sec mt-3'
                                          onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-sanfrancisco-heading mb-2'>{t('Education')}</p>

                                          {educations.map((e, i) => (
                                             <div className='ms-3' key={'edu__' + i}>
                                                <p className='fst-italic fs-12 text-gray'>
                                                   {e.startDate || e.completeDate ? (
                                                      <>
                                                         {e.startDate ? (
                                                            <span>
                                                               {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.startDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}
                                                         {e.completeDate && !e.currentlyStudying ? (
                                                            <span className='barline'>
                                                               {new Date(e.completeDate).toLocaleString('default', { month: 'short' })}{' '}
                                                               {new Date(e.completeDate).getFullYear()}
                                                            </span>
                                                         ) : (
                                                            <></>
                                                         )}

                                                         {e.currentlyStudying ? <em className='barline'>{t('present')} </em> : <></>}
                                                      </>
                                                   ) : (
                                                      <></>
                                                   )}
                                                   {e.location && <span className='ms-1'>{e.location}</span>}
                                                </p>
                                                <p className='fw-700 template-sanfrancisco-experience text-black fs-14'>{e.degree}</p>
                                                {e.institute && <p className='fst-italic fs-12 text-gray'>{e.institute}</p>}

                                                {e.description && (
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          ))}
                                       </section>
                                    ) : (
                                       <></>
                                    )}

                                    <div className='row mt-3'>
                                       <div className='col-6 pe-4'>
                                          {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                                             <section
                                                className='selectable-section template-sanfrancisco-skills-sec'
                                                onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                                             >
                                                <p className='template-sanfrancisco-heading mb-2'>{t('Skills')}</p>
                                                <ul id='sanfrancisco-ul' className='ms-3 lh-lg sec-value'>
                                                   {skills.length > 0 &&
                                                      skills.map((s, i) => (
                                                         <li key={'skill__' + i} className='fs-12'>
                                                            <p className='fw-500'>{s.name}</p>
                                                         </li>
                                                      ))}
                                                </ul>
                                             </section>
                                          )}
                                       </div>

                                       <div className='col-6'>
                                          {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                                             <section
                                                className='selectable-section template-sanfrancisco-languages'
                                                onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                                             >
                                                <p className='template-sanfrancisco-heading mb-2'>{t('Languages')}</p>
                                                <ul className='ps-3 list-unstyled'>
                                                   {languages.length > 0 &&
                                                      languages.map((s, i) => (
                                                         <li className='mb-2 fs-12' key={'languages_' + s.name + s.id + i}>
                                                            {s.name && <p className='fw-700 mb-0 text-black'>{s.name}</p>}
                                                            <p className='fw-500'>
                                                               {s.level && <span>{s.level}</span>}.{s.course && <span>{s.course}</span>}
                                                            </p>
                                                         </li>
                                                      ))}
                                                </ul>
                                             </section>
                                          )}
                                       </div>
                                    </div>

                                    {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                                       <section
                                          className='template-sanfrancisco-projects-sec'
                                          onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mt-3 selectable-section'>
                                             <p className='template-sanfrancisco-heading mb-2'>{t('Projects')}</p>
                                             {projects.map((v, i) => (
                                                <p
                                                   key={'projects__' + i}
                                                   className='ms-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                                       <section
                                          className='template-sanfrancisco-achievements-sec'
                                          onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mt-3 selectable-section'>
                                             <p className='template-sanfrancisco-heading mb-2'>{t('Achievements')}</p>
                                             {achievements.map((v, i) => (
                                                <p
                                                   key={'achievements__' + i}
                                                   className='ms-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 &&
                                       certificates.length > 0 && (
                                          <section
                                             className='template-sanfrancisco-certifications-sec'
                                             onClick={(_) =>
                                                SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)
                                             }
                                          >
                                             <div className='mt-3 selectable-section'>
                                                <p className='template-sanfrancisco-heading mb-2'>{t('Certifications & Courses')}</p>
                                                {certificates.map((v, i) => (
                                                   <p
                                                      key={'certificates__' + i}
                                                      className='ms-3 sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                ))}
                                             </div>
                                          </section>
                                       )}

                                    {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                                       <section
                                          className='template-sanfrancisco-awards-sec'
                                          onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mt-3 selectable-section'>
                                             <p className='template-sanfrancisco-heading mb-2'>{t('Honors & Awards')}</p>
                                             {honours.map((v, i) => (
                                                <p
                                                   key={'honour__' + i}
                                                   className='ms-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                                       <section
                                          className='template-sanfrancisco-publications-sec'
                                          onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mt-3 selectable-section'>
                                             <p className='template-sanfrancisco-heading mb-2'>{t('Publications')}</p>
                                             {publications.map((v, i) => (
                                                <p
                                                   key={'publication__' + i}
                                                   className='ms-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                                       <section
                                          className='template-sanfrancisco-references-sec'
                                          onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mt-3 selectable-section'>
                                             <p className='template-sanfrancisco-heading mb-2'>{t('References')}</p>
                                             {references.map((v, i) => (
                                                <div key={'refer__' + i} className='ms-3 sec-value my-2 editor-list-margin-left'>
                                                   <p>{v.name}</p>
                                                   <p>
                                                      {v.city} {v.city != '' && v.tel != '' ? '|' : ''} {v.tel}
                                                   </p>
                                                </div>
                                             ))}
                                          </div>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                       customSections.map((v, i) => (
                                          <section
                                             className='custom-sec'
                                             key={'custom_' + i}
                                             onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                                          >
                                             <div className='mt-3 selectable-section'>
                                                <p className='template-sanfrancisco-heading mb-2'>{v.title}</p>
                                                <p
                                                   className='ms-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          </section>
                                       ))}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </WrapperDiv>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .polygon-color {
      fill: ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & .template-sanfrancisco-icon {
      color: ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`

const sanFranciscoBackgroundSvg = (
   <svg
      width='160px'
      height='519px'
      viewBox='0 0 160 519'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className='sanfrancisco-bg-svg'
   >
      <g id='Templates-2019-—-CV' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
         <polygon id='Azul-#3176E0' className='polygon-color' points='0 0 160 0 160 477.457906 80 519 0 477.457906'></polygon>
      </g>
   </svg>
)
