import React, { useEffect, useState, useRef } from "react";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { FaEye, FaDownload } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { config } from "../../../Environments";
import { GetAxios, Sorter } from "../../../Shared";
import { setGlobalLoading } from "../../../Store";
import { TemplateList } from '../../../TemplateImageJSON';
import { AvailableExtraSteps, AvailableSteps } from "../../Resumes/SharedData";
import ReactToPrint from 'react-to-print';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { CardTitle } from "../Tenders/Index";

const JobseekerResumes = () => {

  const { t } = useTranslation('resume');
  const { t: ts } = useTranslation('alert');
  const auth = useSelector((state: AppStore) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { empId } = useParams();
  const [error, setError] = useState(false);
  const [key, setKey] = useState('resume');
  const { t: at } = useTranslation('admin');
  const [list, setList] = useState<ResumeList[]>([]);
  const { t: ct } = useTranslation('common');
  const loadData = () => {

    setError(false);
    dispatch(setGlobalLoading(true));
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/Resume/ResumeList?userId=' + empId).then(s => {
      setList(s.data.list);
      dispatch(setGlobalLoading(false));
    }).catch(s => {
      dispatch(setGlobalLoading(false));
      setError(true);
    });
  }

  useEffect(() => {

    loadData();
  }, []);

  const maxPossibleSteps = AvailableSteps.concat(AvailableExtraSteps);
  const publicationsId = maxPossibleSteps.find(x => x.title == 'Publications')?.position;
  const projectsId = maxPossibleSteps.find(x => x.title == 'Projects')?.position;
  const honoursId = maxPossibleSteps.find(x => x.title == 'Honors & Awards')?.position;
  const customSectionsId = maxPossibleSteps.find(x => x.title == 'Custom sections')?.position;
  const certificatesId = maxPossibleSteps.find(x => x.title == 'Certifications & Courses')?.position;
  const achievementsId = maxPossibleSteps.find(x => x.title == 'Achievements')?.position;

  return (
    <>
      <div className="container py-50">
        <div className="card custom-shadow">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <CardTitle> {at('common.resumeList')} </CardTitle>
              </div>
              <div className="d-flex align-items-center">
                <Link
                  to="/admin/jobseekers"
                  className="button-default text-decoration-none cursor-pointer mt-1 border-0 me-1"> {ct('backtoList')}</Link>
              </div>
            </div>
          </div>
          <Container>
            <Row className='py-40 '>
              <Col md={12} xs={12}>
                {
                  list.length == 0 && <p className='text-danger ms-1 ps-1'> {at('common.noResumeFound')}</p>
                }
                {
                  list.length > 0 &&
                  <div className="row ms-1 pt-3 g-3">
                    {
                      list.map((e, i) => (
                        <div className="col-md-3 " key={'res_' + i}>
                          <EditResumeDocument
                            achievementsId={achievementsId}
                            certificatesId={certificatesId}
                            customSectionsId={customSectionsId}
                            honoursId={honoursId}
                            projectsId={projectsId}
                            publicationsId={publicationsId}
                            maxPossibleSteps={maxPossibleSteps}
                            data={e}
                            onDelete={() => {
                              setList([]);
                              loadData();
                            }} />
                        </div>
                      ))
                    }
                  </div>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
export default JobseekerResumes;


const EditResumeDocument = (props: {
  data: ResumeList,
  onDelete: () => void,
  publicationsId?: AvailableSteps,
  projectsId?: AvailableSteps,
  honoursId?: AvailableSteps,
  customSectionsId?: AvailableSteps,
  certificatesId?: AvailableSteps,
  achievementsId?: AvailableSteps,
  maxPossibleSteps: StepsInformation[]
}) => {
  const {
    data,
    onDelete,
    achievementsId,
    certificatesId,
    customSectionsId,
    honoursId,
    projectsId,
    publicationsId,
    maxPossibleSteps
  } = props;
  const { t } = useTranslation('resume');
  const { t: ts } = useTranslation('alert');
  const [title, setTitle] = useState(data.resumeTitle || '');
  const [resume, setResume] = useState<ResumeSlice>({
    experiences: [],
    educations: [],
    skills: [],
    languages: [],
    references: [],
    objectives: "<p></p>",
    submitting: false,
    imageData: {
      isRounded: false
    },

    additionalSettings: {
      fontFamily: 'Classic',
      fontSize: 'medium',
      lineHeight: 1
    },
    title: '',
    activeStep: 1,
    stepsInformation: [],
    contactInformation: {

    },
    accessInformation: {
      deptId: 0,
      classId: 0,
      sectorId: 0
    },
    customSection: []
  });

  useEffect(() => {
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/Resume/GetResume', {
      params: {
        id: data.id
      }
    }).then(s => {
      if (s.data.data) {
        setResume({
          ...s.data.data,
          customSection: s.data.data.sections,
          imageData: JSON.parse(s.data.data.imageData || '{}'),
          image: s.data.data.image ? config.apiBase + s.data.data.image : undefined,
          stepsInformation: (s.data.data.appliedSections as AppliedSections[]).map(s => (maxPossibleSteps[s.position - 1])).sort(Sorter('description'))
        });
      }
    });
  }, [data]);

  useEffect(() => {
    setTitle(data.resumeTitle || '');
  }, [data.resumeTitle]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const component = TemplateList.find(s => s.id == data.templateId);

  const componentRef = useRef<any>();


  return (
    <div className="doc-untitle p-0">
      <div className="resume-card-img">
        <div style={{ overflow: 'hidden', position: 'relative' }}>
          <div className="edit-overlay">
            <div className="edit-btn">
              <Link to={'/admin/jobseeker/resume?id=' + data.id}
              >
                <Button variant="primary" className="rounded fw-500 rounded-pill">
                  <Badge bg="primary" className="rounded me-1 rounded-pill">
                    <FaEye size={12} />
                  </Badge>{t('newResumeCard.view')}
                </Button>
              </Link>
            </div>
          </div>
          <div className="resume-zoom">
            {
              component && component.component &&
              <div ref={componentRef as any}>
                <component.component
                  objectives={resume.objectives}
                  contactInformation={resume.contactInformation}
                  experiences={resume.experiences}
                  educations={resume.educations}
                  languages={resume.languages}
                  skills={resume.skills}
                  achievements={resume.customSection.filter(x => x.sectionId === achievementsId)}
                  certificates={resume.customSection.filter(x => x.sectionId === certificatesId)}
                  customSections={resume.customSection.filter(x => x.sectionId === customSectionsId)}
                  honours={resume.customSection.filter(x => x.sectionId === honoursId)}
                  projects={resume.customSection.filter(x => x.sectionId === projectsId)}
                  publications={resume.customSection.filter(x => x.sectionId === publicationsId)}
                  references={resume.references}
                  image={resume.image}
                  imageData={resume.imageData}
                  additionalSettings={resume.additionalSettings}
                  stepsInformation={resume.stepsInformation}
                />
              </div>
            }
          </div>
        </div>
        <div className="card-untitled2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div className="me-2 mb-1">
                <div className="color-text">
                  <Link className="text-decoration-none" to={'/admin/jobseeker/resume?id=' + data.id}>
                    <FaEye className="me-1" />
                    <span className="fw-600"> {t('newResumeCard.view')}</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="me-2 mb-1">
                <div className="color-text">
                  <ReactToPrint
                    documentTitle={new Date().toDateString()}
                    trigger={() =>
                      <Link className="text-decoration-none" to=''>
                        <FaDownload className="me-1" />
                        <span className="fw-600"> {t('download')}</span>
                      </Link>
                    }
                    content={() => componentRef.current}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <div className="card-untitled">
          <div className="d-flex justify-content-between">
             <div className="d-flex">
             <div className="me-2 mb-1">
                 <input
                  onChange={e => setTitle(e.target.value)}
                  value={title}
                  type="text"
                  className="form-control border-0 untitle w-100 ps-0 key-weight fw-16 input-resume"
                  placeholder={t('newResumeCard.documentUntitled').toString()} /> 
              </div>
            </div>
            <div className="d-flex">
              <span className="me-2">
                 <Link to={'/resumes/builder/' + data.id + '/finish'}>
                  <OverlayTrigger
                      overlay={<Tooltip>{t('newResumeCard.dragDropOrderTooltip')}</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                      placement="top">
                      {({ ref, ...triggerHandler }) => (
                          <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaPencilAlt className="icon-gray" /></span>
                      )}
                  </OverlayTrigger>
                </Link> 
              </span>

               <DropdownButton id="dropdown-menu-align-end new-resume-list" className="p-0 resume-dropdown" title={<FaEllipsisV className="icon-gray" />}>
              
                     <Dropdown.Item onClick={Delete}><FaRegTrashAlt className="me-2 icon-gray" /> {t('delete')} </Dropdown.Item>
              </DropdownButton> 
                      </div> 
          </div>
          <div className="border-bottom border-2 mb-2">
          <p className="sc-elJkPf ldOEfv fw-12"> {data.resumeTitle}</p>
            <p className="sc-elJkPf ldOEfv fw-12">{t('newResumeCard.updated')} {data.updatedDate}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="color-text">
              <ReactToPrint
                documentTitle={new Date().toDateString()}
                trigger={() =>
                  <Link className="text-decoration-none" to=''>
                    <FaDownload className="me-1" />
                    <span className="fw-600"> {t('download')}</span>
                  </Link>
                }
                content={() => componentRef.current}
              />

            </div>
            <div>
              <div className="d-flex">
                
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

