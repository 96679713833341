

import React, { useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import PrintIcon from "../../Components/Common/PrintIcon";
import SocialIconBar from "../../Components/Common/SocialIconBar";
import { config } from "../../Environments";
import { ApiResponseE, GetAxios } from "../../Shared";
import { setGlobalLoading } from "../../Store";
import { useTranslation } from 'react-i18next';
const jobskilllevels = [
  { value: "Beginner", type: 'Beginner' },
  { value: "Basic", type: 'Basic' },
  { value: "Skilled", type: 'Skilled' },
  { value: "Advanced", type: 'Advanced' },
  { value: "Expert", type: 'Expert' },
];
const SkillMatcherAssessment = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current as any,
  });

  return (
    <>
      <div className="row bg-white">
        <div className="col-md-12 p-3">
          <div className="d-flex justify-content-between align-items-center border-bottom-light">
            <div className="border-0">
              {/* <div
                className="cursor-pointer border-none"
                onClick={handlePrint}
              >
                <PrintIcon />
              </div> */}
            </div>
            <div className="border-none">
              <SocialIconBar />
            </div>
          </div>
        </div>
        <div className="print-sec">
          <ComponentToPrint ref={componentRef} />
        </div>
      </div>
    </>
  );
};
export default SkillMatcherAssessment;


export const ComponentToPrint = React.forwardRef<any>((props, ref) => {
  const navigate = useNavigate();

  const { t } = useTranslation('candidate');
  const { t: ct } = useTranslation('common');
  const { t: ts } = useTranslation('alert');

  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [requireMessage, setrequireMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const pageSkillLimit = 10;
  const [gender, setGender] = useState('');
  const [skillTest, setskillTest] = useState<SkillMatcherType[]>([]);

  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios().get(config.apiBase + '/api/JobMatcher/GetJobMatchs?lang='+i18n.language).then(res => {
      setskillTest(res.data.data || []);
      dispatch(setGlobalLoading(false));
    });
  }, [i18n.language]);


   
   React.useEffect(() => {  
    GetAxios().get(config.apiBase + '/api/General/GetUserSex')
    .then(res => {
    setGender(res.data.data || '');
   })
  }, [gender]);
  const handleSkill = (e: React.ChangeEvent<HTMLInputElement>, Item: SkillMatcherType) => {
    let array = [...skillTest];
    let index = array.findIndex(x => x.name === Item.name)
    if (index !== -1) {
      array[index].selected = e.target.value as SelectedOption
      setskillTest(array);
    }
  }

  const Save = () => {
    if (skillTest
      .slice(
        (currentPage - 1) * pageSkillLimit,
        currentPage * pageSkillLimit
      ).filter(s => s.selected === 'None').length > 0) {
      setrequireMessage(ts('employee.fillQuestions') || "Please answer all questions, and then click Next again.");
    }
    else {
      setSaving(true);
      GetAxios().post<ApiResponseE<SkillMatcherType>>(config.apiBase + '/api/JobMatcher/SaveUpdateJobMatch', skillTest
        .slice(
          (currentPage - 1) * pageSkillLimit,
          currentPage * pageSkillLimit
        )).then(res => {
          setSaving(false);
          if (res.data.success) {
            if (currentPage == (skillTest.length / pageSkillLimit)) {
              navigate('/job-matcher-results');
            } else {
              setCurrentPage(currentPage + 1);
            }
          } else {
           // toast(res.data.message, { type: 'warning' });
           toast(ts('error.wentWrong'), { type: 'warning'});
           console.warn(res.data.message)
          }
        }).catch(err => {
          toast(ts('error.unSaveSkill'), { type: 'error' });
          console.error(err);
          setSaving(false);
        });
    }
  }

  return (
    <div ref={ref} className="pb-3">
      <div className="assessment-para pt-3">
        <p className="mb-2 fs-13 fw-500"> {t('job.assessment.selectYourSkill')}.</p>
        <p className="fs-13 fw-500">
          {t('job.assessment.helpChooseLevels')}.
        </p>
      </div>
      <div className="skill-matcher-assessment-table">
        {
          requireMessage &&
          <div className="alert alert-secondary rounded-0" role="alert"> <small className='text-danger d-block'>{requireMessage}</small> </div>
        }
        <Table size="sm" className="border" responsive>
          <thead>
            <tr>
              <th className="ps-3 fw-700"> {t('job.assessment.skill')} </th>
              <th className="text-center fw-700"> { gender==='Female'? t('job.assessment.femalebeginner'): t('job.assessment.malebeginner')} </th>
              <th className="text-center fw-700"> {t('job.assessment.basic')} </th>
              <th className="text-center fw-700"> {gender==='Female'? t('job.assessment.femaleskilled'):t('job.assessment.maleskilled')} </th>
              <th className="text-center fw-700"> {gender==='Female'?t('job.assessment.femaleadvanced'):t('job.assessment.maleadvanced')} </th>
              <th className="text-center fw-700"> {gender==='Female'? t('job.assessment.femaleexpert'):t('job.assessment.maleexpert')} </th>
            </tr>
          </thead>
          <tbody>
            {skillTest
              .slice(
                (currentPage - 1) * pageSkillLimit,
                currentPage * pageSkillLimit
              )
              .map((Item, Index) => {
                return (
                  <tr key={'item++s+_' + Index}>
                    <td className='p-td'>
                      <ul className="ps-2">
                        <li className="list-group-item d-flex align-items-start lh-skill">
                          <span className="fw-700">{Item.skillId}.</span>
                          <div className="ms-2 me-auto">
                            <div className="fw-700 mb-2">{Item.skillTitle}</div>
                            <span className='td-data'>{Item.skillDescription}</span>
                          </div>
                        </li>
                      </ul>
                    </td>
                    {jobskilllevels.map((innerItem, innerIndex) => {
                      return (
                        <td className="p-td" key={'innder_items_' + innerIndex}>
                          <div className="text-center">
                            <div className="">
                              <input
                                className="form-check-input radio-btn"
                                type="radio"
                                name={Item.name}
                                checked={Item.selected === innerItem.value}
                                onChange={(e) => handleSkill(e, Item)}
                                value={innerItem.value}
                                id={innerItem.value.toString().replace(/\s/g, '')}
                              />
                            </div>                       
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className='d-flex justify-content-between align-items-center mt-2 mb-2'>
          <div className=''>
            <button type="button" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage !== 1 ? false : true} className="button-default  border-0">
              <FiChevronLeft className="" size={18} /> {ct('back')}
            </button>
          </div>
          <div className=''>
            <span>Page {currentPage} {t('job.assessment.of')} {skillTest.length / pageSkillLimit}</span>
          </div>
          <div className=''>
            <button type="button" onClick={Save}
              disabled={saving || currentPage !== (skillTest.length / pageSkillLimit) ? false : true}
              className={`button-default  border-0 ${currentPage !== (skillTest.length / pageSkillLimit) ? "" : 'd-none'}`}>
              {
                saving ? <RotatingLines width="20" /> :
                  <>
                   {ct('next')} <FiChevronRight className="" size={18} />
                  </>
              }
            </button>
            {
              currentPage == (skillTest.length / pageSkillLimit) &&
              <button className="button-default border-0" onClick={Save}>
                {
                  saving ? <RotatingLines width="20" /> : <>{ct('save')}</>
                }
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
});

interface SkillMatcherType {
  skillId: number,
  skillTitle: string,
  skillDescription: string,
  name: string,
  selected: SelectedOption,
  skillLevel: [
    {
      label: string,
      value: SelectedOption,
    },
    {
      label: string,
      value: SelectedOption,
    },
    {
      label: string,
      value: SelectedOption,
    },
    {
      label: string,
      value: SelectedOption,
    },
    {
      label: string,
      value: SelectedOption,
    }
  ]
}


interface SkillMatcherType {
  skillId: number,
  skillTitle: string,
  skillDescription: string,
  name: string,
  selected: SelectedOption
}
type SelectedOption =
  'None' |
  'Beginner' |
  'Basic' |
  'Skilled' |
  'Advanced' |
  'Expert'