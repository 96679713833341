import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaRegLightbulb , FaInfoCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';


const TipsPopup = (props: {tipsList: any, title: string, description: string, type ? : string }) => {

    const { t } = useTranslation('resume');

    const { tipsList} = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <div className="d-flex align-items-center justify-content-end">
                <div onClick={handleShow} className="cursor-pointer">
                    <span className="rounded-5 tips-icon">
                       {
                        props.title=="searchInfoStep.tips"?
                        <FaInfoCircle className="text-white" size={18} />:
                        <FaRegLightbulb className="text-white" size={18} />
                       }            
                    </span>
                    <span className="text-cap text-capitalize ms-1 fw-600"> {t(props.title)} </span>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={true} centered className='popup-modal-width top-cl'>
                <Modal.Header className='popup-gray-bg'>
                    <div className='d-flex'>
                        <div className='me-3'>
                            <img src='/images/resume-img/tips-aRSt.svg' alt='extra section image' />
                        </div>
                        <div>
                            <Modal.Title> {t('contactStep.tips')} </Modal.Title>
                            <p className='fw-500 mb-2 text-wrap'>{t(props.description)}</p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='p-4' style={{
                    maxHeight: 'calc(100vh - 380px)',
                    overflowY: 'auto'
                }}>
                    <div className='g-3'>
                    {props.type === "contact-information" ? 
                    <div className="rounded mb-4 contact-tips">
                        <div className="d-flex align-items-center fs-13">
                            <span className="rounded-5 tips-icon">
                                <FaRegLightbulb className="text-white" size={18} />
                            </span>
                            <div className="ms-2">
                                <p className='mb-0 fw-500 tip-line-height'> {t('contactStep.contactTipsMdl.badgeTagline')}.</p>
                            </div>
                        </div>
                     </div>
                    : <></> }
                        <div dangerouslySetInnerHTML={{__html:tipsList}}></div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='tips-modal-footer'>
                    <div>
                        <Button className='fw-500 text-uppercase rounded-pill add-section-btn' onClick={handleClose}>
                         {t('contactStep.understood')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default TipsPopup;

