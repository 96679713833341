import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { config } from '../../../Environments';
import { GetAxios } from '../../../Shared';
import { setAddStepInformation } from '../../../Store/Slices/ResumeSlice';
import { AvailableExtraSteps, AvailableSteps } from '../SharedData';
import { useTranslation } from 'react-i18next';

const ExtraSectionPopup = (props: {
   display: boolean | undefined;
   modalClose: () => void;
}) => {

   const { t } = useTranslation('resume');

   const { display, modalClose } = props;

   const { resumeId } = useParams();

   const dispatch = useDispatch();

   const maximumPossibleSteps = AvailableSteps.concat(AvailableExtraSteps);

   const existingSections = useSelector((state: AppStore) => state.resume.stepsInformation)
      .map(s => s.position);

   const [additionalSelectedSections, setAdditionalSelectedSections] = useState<AvailableSteps[]>([]);
   const AddSection = () => {
      if (additionalSelectedSections) {
         dispatch(setAddStepInformation(maximumPossibleSteps.filter(x => additionalSelectedSections.includes(x.position))));
         GetAxios().post(config.apiBase + '/api/Resume/AddNewSections', additionalSelectedSections, {
            params: { resumeId },
         })
         setAdditionalSelectedSections([]);
      }
      modalClose();
   };
   return (
      <Modal show={props.display} onHide={props.modalClose} className='popup-modal-width'>
         <Modal.Header className='popup-gray-bg'>
            <div className='d-flex'>
               <div className='me-3'>
                  <img src='/images/resume-img/sections-3FkQ.svg' alt='extra section image' />
               </div>
               <div>
                  <Modal.Title> {t('extraSectionMdl.title')}?</Modal.Title>
                  <p className='fw-500 mb-2'>  {t('extraSectionMdl.subTitle')}</p>
               </div>
            </div>
         </Modal.Header>
         <Modal.Body className='p-4' style={{
            maxHeight: 'calc(100vh - 350px)',
            overflowY: 'auto'
         }}>
            <div className='row g-3'>
               {

                  maximumPossibleSteps.filter(s => !existingSections.includes(s.position)).map((v, i) =>
                     <div className='col-md-6' key={'sections_' + i}>
                        <div className='experience popup-gray-bg popup-card rounded"'>
                           <div className='d-flex justify-content-between'>
                              <div>
                                 <h6 className='fw-700 fs-14'>{t(v.title)}</h6>
                              </div>
                              <div>
                                 <input
                                    className="form-check-input mt-0 w-check"
                                    type="checkbox"
                                    onChange={e => {
                                       if (additionalSelectedSections.includes(v.position)) {
                                          setAdditionalSelectedSections(state => {
                                             state.splice(state.indexOf(v.position), 1);
                                             return state;
                                          });
                                       } else {
                                          setAdditionalSelectedSections(state => {
                                             state.push(v.position);
                                             return state;
                                          });
                                       }
                                    }}
                                 />
                              </div>
                           </div>
                           <div>
                              <p className='fw-12'>{t(v.description || "")}</p>
                           </div>
                        </div>
                     </div>
                  )
               }
            </div>
         </Modal.Body>
         <Modal.Footer>
            <button className='rounded-pill me-2 fw-500 text-uppercase me-2 no-thanks-btn' onClick={props.modalClose}>
                {t('extraSectionMdl.noThanks')}
            </button>
            <Button className='fw-500 text-uppercase rounded-pill add-section-btn' onClick={AddSection}>
               {t('extraSectionMdl.addSection')}
            </Button>
         </Modal.Footer>
      </Modal>
   )
}
export default ExtraSectionPopup;