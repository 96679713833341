
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { AdSliderSection } from "./AdSliderSection";

const FormatIndex = () => {

  const { t } = useTranslation('resume');

  return (
    <>
      <div className="container my-3">
        <div className="card">
          <div className="card-body px-lg-4 px-sm-4 px-md-4 pt-4 mx-auto border-bottom-light">
            <div className="row">
              <div className="col-md-12">
                <div className="resume-format-text">
                  <h2 className="fw-700">  {t('resumeFormat.resumeFormatting')}</h2>
                  <div className="desc">
                    <p className="fw-500 fs-18"> {t('resumeFormat.simpleFormattingResume')}.</p>
                    <p className="fw-500 fs-18"> {t('resumeFormat.freeToSkipSection')} </p>
                    <h5 className="fw-700"> {t('resumeFormat.10formattingSteps')}:</h5>
                  </div>
                  <h4 className="fw-700"> {t('resumeFormat.howFormatResume')}?</h4>
                  <div className="resume-steps">
                    <ol>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700"> {t('resumeFormat.set')} </span>  {t('resumeFormat.oneInchMargins')} <span className="fw-700">  {t('resumeFormat.allFourSides')}.</span></p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700">  {t('resumeFormat.pick12pt')} </span> {t('resumeFormat.resumeFont')} <span className="fw-700">  {t('resumeFormat.andStickIt')}.</span></p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700"> {t('resumeFormat.createProper')} </span> {t('resumeFormat.resumeHeaderFormat')} <span className="fw-700"> {t('resumeFormat.yourContactDetails')}.</span></p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700">  {t('resumeFormat.divideResumeLegible')} </span> {t('resumeFormat.resumeSections')}.</p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700"> {t('resumeFormat.use')}</span>  {t('resumeFormat.bulletPoints')}<span className="fw-700"> {t('resumeFormat.aboutPastJobs')}.</span></p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700"> {t('resumeFormat.consistentResumeFormatting')} </span> {t('resumeFormat.sameDateFormat')}</p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700"> {t('resumeFormat.singleLineSpacing')}. </span></p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700"> {t('resumeFormat.eachSectionHeading')}. </span></p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700"> {t('resumeFormat.makeYour')} </span>  {t('resumeFormat.resumeLongNeeds')}.</p>
                      </li>
                      <li className="fs-18">
                        <p className="mb-0 fw-500 ms-2"><span className="fw-700">  {t('resumeFormat.dontUse')}  </span> {t('resumeFormat.jobDescriptionSpecifically')}.</p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 mx-auto w-50">
              <div className="border-bottom border-2 border-dark"></div>
            </div>
            <div className="my-5">
              <TenTipsHeadings />
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="file-format-sec">
                  <h4 className="fw-700"> {t('resumeFormat.aboutFileFormat')}?</h4>
                  <div className="file-format-desc">
                    <div className="ps-4">
                      <ul className="">
                        <li className="fs-18">
                          <p className="mb-0 fw-500 ms-2"> {t('resumeFormat.acrossAllDevices')}.</p>
                        </li>
                        <li className="fs-18">
                          <p className="mb-0 fw-500 ms-2"> {t('resumeFormat.companiesRequireResumesSent')}.</p>
                        </li>
                        <li className="fs-18">
                          <p className="mb-0 fw-500 ms-2"> {t('resumeFormat.fileFormats')}.</p>
                        </li>
                        <li className="fs-18">
                          <p className="mb-0 fw-500 ms-2"> {t('resumeFormat.avoidFileFormats')}.</p>
                        </li>
                      </ul>
                    </div>
                    <p className="fw-500 fs-18"> {t('resumeFormat.perfectResumeFormatLately')}.</p>
                    <p className="fw-500 fs-18"> {t('resumeFormat.resumeFormatBest')}?</p>
                    <p className="fw-500 fs-18"> {t('resumeFormat.properResumeFormat')}?</p>
                    <div className="my-5 mx-auto w-50">
                      <div className="border-bottom border-2 border-dark"></div>
                    </div>
                    <p className="fw-500 fs-18"><span className="fw-700"> {t('resumeFormat.saveTimeResume')}? </span>  {t('resumeFormat.tryOur')} <Link to="/resumes" className="text-decoration-underline" target='_blank'> {t('resumeFormat.resumeBuilder')}.</Link>  {t('resumeFormat.readyMadeContent')}.</p>
                    <div className="my-5 mx-auto w-50">
                      <div className="border-bottom border-2 border-dark"></div>
                    </div>
                    <h4 className="fw-700"> {t('resumeFormat.3commonResumeFormats')}: </h4>
                    <div className="ps-4">
                      <ul className="">
                        <li className="fs-18">
                          <p className="mb-0 fw-500 ms-2"> {t('resumeFormat.standardResumeFormat')}.</p>
                        </li>
                        <li className="fs-18">
                          <p className="mb-0 fw-500 ms-2"> {t('resumeFormat.skillsBasedFormat')}.</p>
                        </li>
                        <li className="fs-18">
                          <p className="mb-0 fw-500 ms-2"> {t('resumeFormat.chronoFunctionalResume')}.</p>
                        </li>
                      </ul>
                    </div>
                    <p className="fw-500 fs-18"> {t('resumeFormat.mainDifferenceBetween')}.</p>
                    <p className="fw-500 fs-18"> {t('resumeFormat.haveALook')}:</p>
                  </div>
                </div>
              </div>
            </div>
            <ResumeFormat />
          </div>
          <div className="col-lg-12 col-md-12 col-10 px-0 ">
            <AdSliderSection />
          </div>
        </div>
      </div>
    </>
  );
};
export default FormatIndex;


export const ResumeFormat = () => {

  const { t } = useTranslation('resume');

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <h3 className="fw-700"> {t('resumeFormat.3Formats.3resumeFormatExamples')}</h3>
          <div className="card pt-4 border-0 mx-auto">
            <div className="form-flyer">
              <div className="heading-flyer text-center p-3 format-3-bg">
                <h2 className="my-0 fw-900 text-uppercase"> {t('resumeFormat.3Formats.mainResumeFormats')}</h2>
              </div>

              <div className="form-detail p-lg-4 p-md-4 p-sm-3 p-xs-2">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-sm-3">
                    <div className="mt-10px-j02">
                      <h5 className="fw-700"> {t('resumeFormat.3Formats.chronologicalResume')} </h5>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-2">
                      <div className="me-2">
                        <img src="/images/resume-img/chronological-format.png" className="format-img" alt="Chronological Resume" />
                      </div>
                      <div>
                        <ul className="list-unstyled pt-3">
                          <li className="mb-2">
                            <p className="fw-700 text-uppercase mt-2 fs-9 mb-1"> {t('resumeFormat.3Formats.contact')} </p>
                          </li>
                          <li className="mb-3">
                            <p className="fw-700 text-uppercase fs-9 text-lg-nowrap text-md-nowrap"> {t('resumeFormat.3Formats.summaryOfObjective')} </p>
                          </li>
                          <li className="my-3">
                            <p className="fw-700 text-uppercase fs-9">  {t('resumeFormat.3Formats.experience')}</p>
                          </li>
                          <li className="mt-5">
                            <p className="fw-700 text-uppercase fs-9"> {t('resumeFormat.3Formats.education')}</p>
                          </li>
                          <li className="my-4">
                            <p className="fw-700 text-uppercase fs-9"> {t('resumeFormat.3Formats.skills')}</p>
                          </li>
                          <li className="my-3">
                            <p className="fw-700 text-uppercase fs-9 text-lg-nowrap text-md-nowrap"> {t('resumeFormat.3Formats.additionalInfo')}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="bottom-desc">
                      <p className="fw-700 fs-21 text-uppercase"> {t('resumeFormat.3Formats.idealFor')}:</p>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.candidateSeekingJob')} <br /> {t('resumeFormat.3Formats.previousPositions')}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.entryLevelCandidates')}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.academicResumes')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-sm-3">
                    <div>
                      <h5 className="fw-700">  {t('resumeFormat.3Formats.functionalResume')}</h5>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-2">
                      <div className="me-2">
                        <img src="/images/resume-img/functional-resume.png" className="format-img" alt="Functional Resume" />
                      </div>
                      <div>
                        <ul className="list-unstyled pt-3">
                          <li className="mb-2">
                            <p className="fw-700 text-uppercase mt-2 fs-9 mb-1"> {t('resumeFormat.3Formats.contact')} </p>
                          </li>
                          <li className="my-3">
                            <p className="fw-700 text-uppercase fs-9 text-lg-nowrap text-md-nowrap"> {t('resumeFormat.3Formats.resumeObjective')} </p>
                          </li>
                          <li className="my-5">
                            <p className="fw-700 text-uppercase fs-9 mb-3"> {t('resumeFormat.3Formats.skills')} </p>
                          </li>
                          <li className="mt-60">
                            <p className="fw-700 text-uppercase fs-9 mt-30"> {t('resumeFormat.3Formats.experience')} </p>
                          </li>
                          <li className="mt-2">
                            <p className="fw-700 text-uppercase fs-9"> {t('resumeFormat.3Formats.education')} </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="bottom-desc">
                      <p className="fw-700 fs-21 text-uppercase"> {t('resumeFormat.3Formats.idealFor')}:</p>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.creativeJobs')}<br />  {t('resumeFormat.3Formats.mattersMost')}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.militaryTransitioners')}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.candidatesWho')}<br /> {t('resumeFormat.3Formats.overqualified')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-sm-3">
                    <div>
                      <h5 className="fw-700"> {t('resumeFormat.3Formats.combinationResume')}</h5>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-2">
                      <div className="me-2">
                        <img src="/images/resume-img/combination-resume.png" className="format-img" alt="Combination Resume" />
                      </div>
                      <div>
                        <ul className="list-unstyled pt-2">
                          <li className="my-3">
                            <p className="fw-700 text-uppercase mt-1 fs-9"> {t('resumeFormat.3Formats.contact')} </p>
                          </li>
                          <li className="my-3">
                            <p className="fw-700 text-uppercase fs-9 text-lg-nowrap text-md-nowrap"> {t('resumeFormat.3Formats.skillsSummary')}</p>
                          </li>
                          <li className="mb-4 mt-5">
                            <p className="fw-700 text-uppercase fs-9">  {t('resumeFormat.3Formats.additionalSkills')}</p>
                          </li>
                          <li className="mt-5">
                            <p className="fw-700 text-uppercase fs-9"> {t('resumeFormat.3Formats.experience')} </p>
                          </li>
                          <li className="mt-4">
                            <p className="fw-700 text-uppercase fs-9"> {t('resumeFormat.3Formats.education')} </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="bottom-desc">
                      <p className="fw-700 fs-21 text-uppercase"> {t('resumeFormat.3Formats.idealFor')}:</p>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.seasonedProfessionals')}<br /> {t('resumeFormat.3Formats.specificPosition')}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.careerChangers')}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start mb-2">
                        <span className="badge-ideal me-2 rounded" mt-1> <FaCheck className="check-green" /></span>
                        <div>
                          <p className="fw-600 fs-16 mt-0"> {t('resumeFormat.3Formats.employmentGaps')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <Link to="/resumes" target='_blank'>
                      <button className="build-resume text-uppercase build-resume-now-btn"> {t('resumeExmpl.buildResumeNow')} </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const TenTipsHeadings = () => {

  const { t } = useTranslation('resume');

  return (
    <>
      <div className="row">
        <div className="col-md-2 col-12"></div>
        <div className="col-md-8 col-12 px-lg-5 px-md-5 seminar-bg-form">
          <div className="card mt-3 border-0">
            <div className="form-flyer">
              <div className="heading-flyer text-center p-3 format-tip-bg">
                <h1 className="my-0 fw-700 fst-italic"> {t('resumeFormat.tips.10Tips')} </h1>
                <p className="fw-500 fs-21 fst-italic"> {t('resumeFormat.tips.howFormatResume')} </p>
              </div>
              <div className="form-detail tip-body-bg">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="d-flex align-items-start justify-content-start mb-2">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">1</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.set')} <span className="fw-700"> {t('resumeFormat.tips.oneInchMargins')}</span> {t('resumeFormat.tips.allFourSides')}.<br /></p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-0">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">2</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.pickA')} <span className="fw-700">  {t('resumeFormat.tips.or12pt')}</span> {t('resumeFormat.tips.resumeFontAnd')} {t('resumeFormat.tips.stickIt')}.</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-0">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">3</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.createProper')} <br /> <span className="fw-700"> {t('resumeFormat.tips.resumeHeader')}</span> <br />{t('resumeFormat.tips.formatForYour')} {t('resumeFormat.tips.contactDetails')}.</p> <br />
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-0">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">4</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.divideYourResume')}<br />  {t('resumeFormat.tips.intoLegible')} <span className="fw-700"> {t('resumeFormat.tips.resumeSections')}. </span></p><br />
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-2">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">5</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.use')} <span className="fw-700">  {t('resumeFormat.tips.bulletPoints')}</span> {t('resumeFormat.tips.to')}<br />  {t('resumeFormat.tips.aboutPastJobs')}.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="d-flex align-items-start justify-content-start mb-2">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">6</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.beConsistentYour')} <br /> <span className="fw-700"> {t('resumeFormat.tips.resumeFormatting')}.</span><br /><br /></p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-4">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">7</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.use')} <span className="fw-700"> {t('resumeFormat.tips.single')} {t('resumeFormat.tips.single1.15')}</span>  {t('resumeFormat.tips.lineSpacing')}.</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-4">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">8</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.addAn')} <span className="fw-700"> {t('resumeFormat.tips.extraSpace')}</span> {t('resumeFormat.tips.eachSectionHeading')}.</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-4">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">9</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"> {t('resumeFormat.tips.makeResume')} <span className="fw-700"> {t('resumeFormat.tips.asLongNeeds')}</span>.</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start justify-content-start mb-2">
                      <div className="me-3">
                        <span className="tip-badge my-0 fw-700">10</span>
                      </div>
                      <div>
                        <p className="fw-500 fs-18"><span className="fw-700"> {t('resumeFormat.tips.donUsePhotos')}</span> {t('resumeFormat.tips.onYourResume')}.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-12"></div>
      </div>
    </>
  )
}