import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { AppStore } from './Store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './i18n/index';


const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={AppStore}>
      <BrowserRouter>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);


