import React, { useState, useEffect } from 'react';
import Collapse_section from "./Collapse_section";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import FlipSlider_Section from './FlipSlider_Section';
import TemplateSlider_HeroSection from './TemplateSlider_HeroSection';
import { RiContrastDropFill } from "react-icons/ri";
import { TbLayoutColumns } from "react-icons/tb";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModel } from '../../Store';


const Index = () => {
  const { t } = useTranslation('home');

  const dispatch = useDispatch();
  const auth = useSelector((state: AppStore) => state.auth);

  return (
    <>
      <section id="home-page" className='pt-40px p-top-no-xs p-top-no-sm p-bottom-1 pb-2 bg-homepage-sec position-relative'>
        <div className='HeroSection-bg'>
          <img src="images/home/hero-section-bg.svg" alt='Curriculosi Background Image' className='animation-fade-in hero_backgroundImage img-transition-opacity' />
        </div>
        <div className='container HeroSection_container'>
          <div className='row'>
            <div className='col-md-7 col-12'>
              <div className='hero_sec_left'>
                <h1 className="hero-title m-bottom-32 text-center-xsm">Curriculosi <span className="hero_headerGradient">{t('resumeBuilder')}</span>  {t('helpsTopCompanies')}</h1>
                <div className='d-flex p-inline-6'>
                  <div>
                    {!auth.isAuthenticated ?
                      <>
                        <Link to={''} onClick={e => dispatch(setShowModel(true))} className='custom_link custom_btn_CB01 text-decoration-none full-width-xsm'>{t('buildYourResume')}</Link>
                      </>
                      :
                      <Link to={'/resumes/builder/templates/50'} className='custom_link custom_btn_CB01 text-decoration-none full-width-xsm'>{t('buildYourResume')}</Link>
                    }
                  </div>
                  {/* <div>
                    <Link to="" className='custom_link_border custom_btn_CB01 text-decoration-none full-width-xsm'>{t('getYourResumeScore')}</Link>
                  </div> */}
                </div>
                <p className="text-label fw-700 pt-32px p-bottom-2 text-center-xsm font-curriculosi"> {t('lovedByInterviewers')}</p>
                <div className='d-flex align-items-center gap-4 flex-wrap flex-justify-center-xsm'>
                  <div className='d-flex align-items-center'>
                    <img src="images/home/logos/tesla-logo.webp" alt='Tesla Logo' className='HeroSection_logoContainer img-transition-opacity tesla-logo' />
                  </div>
                  <div className='d-flex align-items-center'>
                    <img src="images/home/logos/google-logo.webp" alt='Google Logo' className='HeroSection_logoContainer img-transition-opacity google-logo' />
                  </div>
                  <div className='d-flex align-items-center'>
                    <img src="images/home/logos/facebook-logo.webp" alt='Facebook Logo' className='HeroSection_logoContainer img-transition-opacity facebook-logo' />
                  </div>
                  <div className='d-flex align-items-center'>
                    <img src="images/home/logos/spotify-logo.webp" alt='Spotify Logo' className='HeroSection_logoContainer img-transition-opacity spotify-logo' />
                  </div>
                  <div className='d-flex align-items-center'>
                    <img src="images/home/logos/amazon-logo.webp" alt='Amazon Logo' className='HeroSection_logoContainer img-transition-opacity amazon-logo' />
                  </div>
                </div>
                <h2 className="hero-subtitle m-top-12 m-bottom-8 m-bottom-4-xsm text-center-xsm">{t('pickResumeTemplateMinutes')}</h2>
              </div>
            </div>
            <div className='col-md-5 col-12'>
              <div className='hero_sec_right'>
                <FlipSlider_Section />
              </div>
            </div>
          </div>
        </div>
        {/* <Collapse_section /> */}
        <div className='row mx-1'>
          <div className='col-md-12 px-lg-0'>
            <TemplateSlider_HeroSection />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 p-2'>
            <div className='step-sec text-center'>
              <div className='mt-3 mb-1'>
                <FaCheckCircle size={24} className='text-blue' />
              </div>
              <div className='browse-text-steps font-curriculosi'>
                {t('FriendlyProfessionallyDesigned').split('\n').map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 p-2'>
            <div className='step-sec text-center'>
              <div className='mt-3 mb-1'>
                <RiContrastDropFill size={24} className='text-blue' />
              </div>
              <div className='browse-text-steps font-curriculosi'>
                {t('changeColorCombinations').split('\n').map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 p-2'>
            <div className='step-sec text-center'>
              <div className='mt-3 mb-1'>
                <TbLayoutColumns size={24} className='text-blue' />
              </div>
              <div className='browse-text-steps font-curriculosi'>
                {t('twoColumnLayouts').split('\n').map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Index;
