import React, { useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { config } from "../../../Environments";
import MainContent from "./MainContent";
import SideContent from "./SideContent";
import { setGlobalLoading } from "../../../Store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaToggleOff } from "react-icons/fa";
import { GetAxios, UserRole } from "../../../Shared";
import { confirmAlert } from "react-confirm-alert";

const Index = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = React.useState<any>();
  const { t: ts } = useTranslation('alert');
  const { t } = useTranslation('admin');
  const { t: ct } = useTranslation('common')

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const location = useLocation()
  const { from } = location.state || "no";
  function handleApproveClick(jobId: number) {
    confirmAlert({
      title: t('common.confirmtoActive').toString(),
      message: t('common.sureActive').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/UpdateJobStatus?jobId=" + jobId)
              .then((res) => {
                setActive(true);
                toast(ts('account.statusUpdate'), { type: "success" });
                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleDisapproveClick(jobId: number) {
    confirmAlert({
      title: t('common.confirmtoInactive').toString(),
      message: t('common.sureInactive').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/UpdateJobStatus?jobId=" + jobId)
              .then((res) => {
                setActive(false);
                toast(ts('account.statusUpdate'), { type: "success" });

                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {

                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  React.useEffect(() => {

    dispatch(setGlobalLoading(true));
    if (searchParams.get('id')) {

      GetAxios(navigate).get(config.apiBase + '/api/Jobs/PublicView?id=' + searchParams.get('id')).then(res => {
        if (!res.data.success) {
          dispatch(setGlobalLoading(false));
          toast(ts('error.ungetJob'), { type: 'error' });
        } else {

          console.warn(res.data.data);
          setData(res.data.data);
          setActive(res.data.data.status);
          dispatch(setGlobalLoading(false));
        }
      });
    }
  }, [searchParams, active]);


  const auth = useSelector((state: AppStore) => state.auth);
  const userRole = UserRole(auth?.raw?.access_token);
  return (
    <>
      <div className="container">
        <div className="row py-50">
          <div className="col-md-12 col-sm-12 mb-2">
            <div>
              {
                from == 'searchJob' &&
                <button onClick={() => navigate('/job/jobsearch')} className="button-default text-decoration-none cursor-pointer border-0"><FaArrowLeft size={16} /> {ct('backtoList')}</button>
              }
              {

                (userRole == 'Admin' && !userRole) &&
                <button type="button" onClick={() => {
                  if (active) {
                    handleDisapproveClick(searchParams.get('id') || data?.id)
                  }
                  else {
                    handleApproveClick(searchParams.get('id') || data?.id)
                  }
                }
                } className="button-default text-decoration-none cursor-pointer mt-1 border-0 me-1"> {active ? t('common.setInactive') : t('common.setActive')} </button>
              }
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <SideContent data={data} />
          </div>
          <div className="col-md-8 col-sm-12">
            <MainContent data={data} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
