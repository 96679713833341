import { useTranslation } from 'react-i18next';

const Sidebar = (props:{
  title:string,
  description:string
}) => {

  const { t } = useTranslation('candidate');

  return (
    <>
      <div className='sidebar-skill-widget'>
        <div className='ribbon-widget'>
          <div className='ribbon-title'>
            <span>{props.title}</span>
          </div>
          <p className='ribbon-para mb-0 fs-14'>{props.description}</p>
          <p className='ribbon-para pb-4'> <a className='pointer-event fs-14 a-link fw-500'> {t('job.matcher.help')} </a></p>
        </div>
      </div>
    </>
  )
}
export default Sidebar;