import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Munich = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume')

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <>
         <WrapperDiv additionalSettings={additionalSettings}>
            <div className='template'>
               <div className='preview-template'>
                  <div id='template-full'>
                     <div className='template-main'>
                        <div className='munich-template-container'>
                           <div className='container-fluid'>
                              <div className='row template-munich-header'>
                                 <div className='col-9'>
                                    {(contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) && (
                                       <section
                                          className='selectable-section'
                                          onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-munich-occupation'>{contactInformation.occupation}</p>
                                          <h1 className='template-munich-name text-uppercase'>
                                             {contactInformation.firstName}
                                             <br />
                                             {contactInformation.lastName}
                                          </h1>
                                       </section>
                                    )}
                                 </div>

                                 <div className='col-3'>
                                    {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                       <section className='d-flex justify-content-center selectable-section'>
                                          {image && (
                                             <img
                                                onClick={(_) =>
                                                   SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)
                                                }
                                                src={image}
                                                alt='applicant profile'
                                                className={
                                                   imageData.isRounded ? 'rounded-circle template-munich-image' : 'template-munich-image'
                                                }
                                                onError={(e) => {
                                                   if (e.target instanceof HTMLImageElement) {
                                                      e.target.src = DefaultProfileImage
                                                   }
                                                }}
                                             />
                                          )}
                                       </section>
                                    )}
                                 </div>
                              </div>

                              <div className='row border-top-gray'>
                                 <div className='col-4 pr-35px border-r-2px'>
                                    <div>
                                       {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                          <section
                                             className='template-munich-contact-sec pt-32px pb-32px selectable-section'
                                             onClick={(_) =>
                                                SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)
                                             }
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Contact Information')}</p>
                                             <div className='list-unstyled d-flex flex-column gap-3 sec-value'>
                                                {contactInformation.email && (
                                                   <div className='d-flex align-items-center gap-2'>
                                                      <div>
                                                         <svg width='14' height='10' viewBox='0 0 14 10' xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                               d='M12 0a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h10zm.935 1.644L7.267 5.416a.5.5 0 0 1-.555 0L1.065 1.644A.998.998 0 0 0 1 2v6a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a.998.998 0 0 0-.065-.356zM12 1H2l-.093.005 5.082 3.393 5.1-3.394A1.013 1.013 0 0 0 12 1z'
                                                               fill='#3e423d'
                                                               fillRule='nonzero'
                                                               opacity='.55'
                                                            ></path>
                                                         </svg>
                                                      </div>
                                                      <a
                                                         className='text-decoration-none word-break'
                                                         href={`mailto:${contactInformation.email}`}
                                                      >
                                                         {contactInformation.email}
                                                      </a>
                                                   </div>
                                                )}

                                                {contactInformation.address && (
                                                   <div className='d-flex align-items-center gap-2'>
                                                      <div>
                                                         <svg width='12' height='14' viewBox='0 0 12 14' xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                               d='m6 0 5.521 4.685c.304.257.479.635.479 1.034v5.569A2.712 2.712 0 0 1 9.288 14H2.712A2.712 2.712 0 0 1 0 11.288v-5.57c0-.398.175-.776.479-1.033L6 0zm0 1.311L1.126 5.447c-.06.051-.1.12-.118.195L1 5.719v5.569C1 12.233 1.767 13 2.712 13H4v-2.644C4 9.607 4.607 9 5.356 9h1.288C7.393 9 8 9.607 8 10.356V13h1.288c.945 0 1.712-.767 1.712-1.712v-5.57a.356.356 0 0 0-.126-.27L6 1.31z'
                                                               fill='#3e423d'
                                                               fillRule='nonzero'
                                                               opacity='.55'
                                                            ></path>
                                                         </svg>
                                                      </div>
                                                      <p>{contactInformation.address}</p>
                                                   </div>
                                                )}

                                                {contactInformation.phone && (
                                                   <div className='d-flex align-items-center gap-2'>
                                                      <div>
                                                         <svg width='10' height='16' viewBox='0 0 10 16' xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                               d='M7.288 0A2.712 2.712 0 0 1 10 2.712v10.576A2.712 2.712 0 0 1 7.288 16H2.712A2.712 2.712 0 0 1 0 13.288V2.712A2.712 2.712 0 0 1 2.712 0h4.576zm0 1H2.712C1.767 1 1 1.767 1 2.712v10.576C1 14.233 1.767 15 2.712 15h4.576C8.233 15 9 14.233 9 13.288V2.712C9 1.767 8.233 1 7.288 1zM6.5 12a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1h3z'
                                                               fill='#3e423d'
                                                               fillRule='evenodd'
                                                               opacity='.55'
                                                            ></path>
                                                         </svg>
                                                      </div>
                                                      <a className='text-decoration-none' href={`tel:${contactInformation.phone}`}>
                                                         {contactInformation.phone}
                                                      </a>
                                                   </div>
                                                )}

                                                {contactInformation.dob && (
                                                   <div className='d-flex align-items-center gap-2'>
                                                      <div>
                                                         <svg width='14' height='13' viewBox='0 0 14 13' xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                               d='M11.288 2A2.712 2.712 0 0 1 14 4.712v5.576A2.712 2.712 0 0 1 11.288 13H2.712A2.712 2.712 0 0 1 0 10.288V4.712A2.712 2.712 0 0 1 2.712 2h8.576zM1 6v4.288C1 11.233 1.767 12 2.712 12h8.576c.945 0 1.712-.767 1.712-1.712V5.999L1 6zm0-1 12-.001v-.287C13 3.767 12.233 3 11.288 3H2.712C1.767 3 1 3.767 1 4.712V5zm3.5-5a.5.5 0 0 1 .5.5V2H4V.5a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5V2H9V.5a.5.5 0 0 1 .5-.5z'
                                                               fill='#3e423d'
                                                               fillRule='nonzero'
                                                               opacity='.55'
                                                            ></path>
                                                         </svg>
                                                      </div>
                                                      <p>{moment(contactInformation.dob).format('MM/DD/YYYY')}</p>
                                                   </div>
                                                )}

                                                {contactInformation.nationality && (
                                                   <div className='d-flex align-items-center gap-2'>
                                                      <div>
                                                         <svg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                               d='M7 0a7 7 0 1 1 0 14A7 7 0 0 1 7 0zm1.997 7.5-4.125.001c.096 1.96.732 3.777 1.917 5.495.07.003.14.004.211.004l.208-.004C8.32 11.29 8.914 9.48 8.997 7.5zm-3.379 5.34-.029-.048c-.967-1.655-1.495-3.414-1.578-5.29L1.051 7.5l-.03-.002a6.004 6.004 0 0 0 4.597 5.342zM12.98 7.5H9.991a11.561 11.561 0 0 1-1.51 5.316 6.005 6.005 0 0 0 4.498-5.315zM5.61 1.164l-.135.033A6.004 6.004 0 0 0 1.02 6.503l.032-.003h2.957a11.12 11.12 0 0 1 1.6-5.337zM7 1l-.211.004-.124.178C5.55 2.834 4.955 4.592 4.87 6.5h4.126C8.905 4.457 8.269 2.577 7.085.808l.127.196A6.109 6.109 0 0 0 7 1zm1.48.184.067.118A11.733 11.733 0 0 1 9.99 6.5h2.99a6.004 6.004 0 0 0-4.5-5.316z'
                                                               fill='#3e423d'
                                                               fillRule='nonzero'
                                                               opacity='.55'
                                                            ></path>
                                                         </svg>
                                                      </div>
                                                      <p>{contactInformation.nationality}</p>
                                                   </div>
                                                )}

                                                {contactInformation.linkedInLink && (
                                                   <div className='d-flex align-items-center gap-2'>
                                                      <div>
                                                         <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                               d='M9 0a2 2 0 0 1 2 2v2h1a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h7zm3 5h-1v1a2 2 0 0 1-2 2H4v2a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zM9 1H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1V6a2 2 0 0 1 2-2h5V2a1 1 0 0 0-1-1zm1 4H5a1 1 0 0 0-1 1v1h5a1 1 0 0 0 1-1V5z'
                                                               fill='#3e423d'
                                                               fillRule='nonzero'
                                                               opacity='.55'
                                                            ></path>
                                                         </svg>
                                                      </div>
                                                      <a
                                                         className='text-black word-break'
                                                         href={`https://${contactInformation.linkedInLink}`}
                                                      >
                                                         {contactInformation.linkedInLink}
                                                      </a>
                                                   </div>
                                                )}
                                             </div>
                                          </section>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                                          <>
                                             <section
                                                className='template-munich-skills-sec border-t-2px py-24px selectable-section'
                                                onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                                             >
                                                <p className='text-uppercase template-munich-heading'>{t('Skills')}</p>
                                                <ul className='d-flex flex-column gap-3 ps-2 sec-value'>
                                                   {skills.length > 0 &&
                                                      skills.map((s, i) => <li key={'skill_' + s.name + s.id + i}>{s.name}</li>)}
                                                </ul>
                                             </section>

                                             <section
                                                className='template-munich-languages-sec border-t-2px py-24px selectable-section'
                                                onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                                             >
                                                <p className='text-uppercase template-munich-heading'>{t('Languages')}</p>
                                                <ul className='d-flex flex-column gap-3 ps-2 sec-value'>
                                                   {languages.length > 0 &&
                                                      languages.map((s, i) => (
                                                         <li key={'languages_' + s.name + s.id + i}>
                                                            {s.name && <span>{s.name}</span>}
                                                            {s.level && <span className='vertical-linebar'>{s.level}</span>}.{' '}
                                                            {s.course && <span>{s.course}</span>}
                                                         </li>
                                                      ))}
                                                </ul>
                                             </section>
                                          </>
                                       )}
                                    </div>
                                 </div>

                                 <div className='col-8 pl-35px'>
                                    <div>
                                       {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                                          <section
                                             className='template-munich-objective-sec pt-32px pb-32px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Objective')}</p>
                                             <p
                                                className='sec-value editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: objectives }}
                                             ></p>
                                          </section>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                                          <section
                                             className='template-munich-experience-sec border-t-2px py-24px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Experiences')}</p>

                                             {experiences.map((e, i) => (
                                                <div className='d-flex flex-column gap-2 mb-3 sec-value' key={'exp_' + i}>
                                                   {e.title && <p className='fw-700 text-uppercase'>{e.title}</p>}
                                                   <p>
                                                      <span className='fw-700'>
                                                         {e.company && <span>{e.company}</span>}.{' '}
                                                         {e.location ? <span>{e.location}</span> : <></>}
                                                      </span>
                                                      {e.startDate || e.endDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span className='vertical-linebar'>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.endDate && !e.currentlyWorking ? (
                                                               <span className='barline'>
                                                                  {new Date(e.endDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.endDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.currentlyWorking ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>

                                                   {e.description && (
                                                      <p
                                                         className='template-munich-experience-desc sec-value editor-list-margin-left'
                                                         dangerouslySetInnerHTML={{ __html: e.description }}
                                                      ></p>
                                                   )}
                                                </div>
                                             ))}
                                          </section>
                                       ) : (
                                          <></>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                                          <section
                                             className='template-munich-experience-sec border-t-2px py-24px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Education')}</p>

                                             {educations.map((e, i) => (
                                                <div className='d-flex flex-column gap-2 mb-3 sec-value' key={'edu__' + i}>
                                                   {e.degree && <p className='fw-700 text-uppercase'>{e.degree}</p>}
                                                   <p>
                                                      <span className='fw-700'>
                                                         {e.institute && <span>{e.institute}</span>}.{' '}
                                                         {e.location ? <span>{e.location}</span> : <></>}
                                                      </span>
                                                      {e.startDate || e.completeDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span className='vertical-linebar'>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.completeDate && !e.currentlyStudying ? (
                                                               <span className='barline'>
                                                                  {new Date(e.completeDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.completeDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.currentlyStudying ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>

                                                   {e.description && (
                                                      <p
                                                         className='template-munich-education-desc sec-value editor-list-margin-left'
                                                         dangerouslySetInnerHTML={{ __html: e.description }}
                                                      ></p>
                                                   )}
                                                </div>
                                             ))}
                                          </section>
                                       ) : (
                                          <></>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                                          <section
                                             className='template-munich-projects-sec border-t-2px py-24px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Projects')}</p>
                                             {projects.map((v, i) => (
                                                <div key={'projects__' + i}>
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             ))}
                                          </section>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                                          <section
                                             className='template-munich-achievements-sec border-t-2px py-24px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Achievements')}</p>
                                             {achievements.map((v, i) => (
                                                <div key={'achievements__' + i}>
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             ))}
                                          </section>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 &&
                                          certificates.length > 0 && (
                                             <section
                                                className='template-munich-certifications-sec border-t-2px py-24px selectable-section'
                                                onClick={(_) =>
                                                   SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)
                                                }
                                             >
                                                <p className='text-uppercase template-munich-heading'>{t('Certifications & Courses')}</p>

                                                {certificates.map((v, i) => (
                                                   <div key={'certificates__' + i}>
                                                      <p
                                                         className='sec-value my-2 editor-list-margin-left'
                                                         dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                      ></p>
                                                   </div>
                                                ))}
                                             </section>
                                          )}

                                       {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                                          <section
                                             className='template-munich-awards-sec border-t-2px py-24px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Honors & Awards')}</p>

                                             {honours.map((v, i) => (
                                                <div key={'honour__' + i}>
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             ))}
                                          </section>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                                          <section
                                             className='template-munich-publications-sec border-t-2px py-24px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('Publications')}</p>
                                             {publications.map((v, i) => (
                                                <div key={'publication__' + i}>
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             ))}
                                          </section>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                                          <section
                                             className='template-munich-references-sec border-t-2px py-24px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                                          >
                                             <p className='text-uppercase template-munich-heading'>{t('References')}</p>

                                             {references.map((v, i) => (
                                                <div key={'refer__' + i}>
                                                   <p className='sec-value my-2 editor-list-margin-left'>{v.name}</p>
                                                   <p className='sec-value my-2 editor-list-margin-left'>
                                                      {v.city} {v.city != '' && v.tel != '' ? '|' : ''} {v.tel}
                                                   </p>
                                                </div>
                                             ))}
                                          </section>
                                       )}

                                       {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                          customSections.map((v, i) => (
                                             <section
                                                key={'custom_' + i}
                                                className='template-munich-driving-sec border-t-2px py-24px selectable-section'
                                                onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                                             >
                                                <p className='text-uppercase template-munich-heading'>{v.title}</p>
                                                <p
                                                   className='sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </section>
                                          ))}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </WrapperDiv>
      </>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Professional'
                  ? 'Bitter, serif;'
                  : 'default;'};
   }
`
